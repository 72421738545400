import React from 'react'
import { makeStyles } from '@material-ui/core'

import ShowItem from '../components/ShowItem'

const useAccountInfoStyles = makeStyles(_theme => ({
  itemLabel: {
    width: 100,
    display: 'inline-block',
  },
  itemValue: {
    width: 200,
    display: 'inline-block',
  },
}))

const QuickAccountInfo = ({ account }) => {
  const classes = useAccountInfoStyles()

  return <>
    <ShowItem
      label='Player name'
      classes={{
        label: classes.itemLabel,
        value: classes.itemValue,
      }}
    >
      {account.publicName}
    </ShowItem>

    <ShowItem
      label='Player ID'
      classes={{
        label: classes.itemLabel,
        value: classes.itemValue,
      }}
    >
      {account.id}
    </ShowItem>
  </>
}

export default QuickAccountInfo
