import React, { useState } from 'react'
import { useGetList } from 'react-admin'
import Autocomplete from '@material-ui/lab/Autocomplete'
import AccountCircle from '@material-ui/icons/AccountCircle'
import InputAdornment from '@material-ui/core/InputAdornment'
import { TextField } from '@material-ui/core'
import QuickAccountInfo from './QuickAccountInfo'
import { describeError } from '../utils/errors'

const SelectPlayerDropdown = ({ account, setAccount, label, error }) => {
  const [inputValue, setInputValue] = useState(account ? account.publicName : '')
  const suggestions = useGetList('accounts', { page: 1, perPage: 5 }, null, { q: inputValue })
  const combinedError = error || suggestions.error

  function handleSelectedChange(_event, selected) {
    setAccount(selected)
  }

  return <>
    <Autocomplete
      autoComplete
      fullWidth
      value={account}
      options={Object.values(suggestions.data)}
      filterOptions={x => x /* Disable default filter -- otherwise search in STM doesn't work */}
      onChange={handleSelectedChange}
      onInputChange={(e, input) => setInputValue(input)}
      getOptionLabel={option => option.publicName}
      loading={!suggestions.loaded}
      renderInput={params =>
        <TextField
          {...params}
          label={label}
          fullWidth
          error={!!combinedError}
          helperText={combinedError ? describeError(combinedError) : ''}
          InputProps={{
            ...params.InputProps,
            startAdornment: (
              <InputAdornment position='start'>
                <AccountCircle />
              </InputAdornment>
            ),
          }}
        />
      }
    />
    {account && <QuickAccountInfo account={account} />}
  </>
}

export default SelectPlayerDropdown
