import React from 'react'
import { Link, useGetMany } from 'react-admin'

const MapLinkInner = ({ id }) => {
  // useGetMany combines queries in a larger batch, in case this component is used in e.g. a list
  const mapList = useGetMany('maps', [id])
  const map = mapList?.data?.[0]

  return <Link to={`/maps/${id}/show`}>
    Map {map?.name ? map.name : `id ${id}`}
  </Link>
}

const MapLink = ({ id }) => {
  if (!id) return <span>No map</span>
  return <MapLinkInner id={id} />
}

export default MapLink
