import React, { useState } from 'react'
import PropTypes from 'prop-types'
import Dialog from '@material-ui/core/Dialog'
import DialogActions from '@material-ui/core/DialogActions'
import DialogContent from '@material-ui/core/DialogContent'
import DialogTitle from '@material-ui/core/DialogTitle'
import Typography from '@material-ui/core/Typography'
import Chip from '@material-ui/core/Chip'
import { makeStyles } from '@material-ui/core'
import classnames from 'classnames'

import { required } from '../utils/validations'
import useValidation from '../components/useValidation'
import TextField from '../components/TextField'
import CancelButton from '../components/CancelButton'
import ConfirmButton from '../components/ConfirmButton'
import {
  fetchJsonPut,
} from '../utils/fetch'
import ItemAttributes from './ItemAttributes'
import { describeError } from '../utils/errors'

const useStyles = makeStyles(theme => ({
  row: {
    display: 'flex',
    marginBottom: theme.spacing(2),
    flexDirection: 'column',
  },
  container: {
    display: 'flex',
    alignItems: 'top',
    flexDirection: 'row',
  },
  item: {
    width: '33.333%',
  },
  infoRow: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    marginBottom: 10,
  },
  pre: {
    padding: 10,
    wordBreak: 'break-all',
    wordWrap: 'break-word',
    backgroundColor: '#f5f5f5',
    border: '1px solid #ccc',
    borderRadius: 5,
  },
  total: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'flex-end',
  },
  error: {
    display: 'flex',
    marginBottom: theme.spacing(2),
    padding: theme.spacing(1),
    flexDirection: 'column',
    background: '#fff3e0',
  },
}))

// NOTE: constraints needs to be put here or useEffect() in useValidation()
// will run in  a loop.
const constraints = {
  amount: {
    numericality: {
      onlyInteger: true,
    },
    ...required,
  },
  total: {
    numericality: {
      onlyInteger: true,
      greaterThanOrEqualTo: 0,
      strict: true,
    },
    ...required,
  }
}

let initialValues = {
  amount: 0,
}

const PlayerEditInventoryDialog = ({
  title,
  item,
  player,
  onClose,
  onRequestUpdateView,
}) => {
  const classes = useStyles()
  const maxAmount = item.maxAmount || 0
  if (maxAmount) {
    constraints.amount.numericality.lessThanOrEqualTo = maxAmount
  }

  const [attributes, setAttributes] = useState(item.attributes)
  const [error, setError] = useState(null)

  const {
    getFieldProps,
    errors,
    isValid,
    values,
    changeFieldValue,
  } = useValidation(
    {
      constraints,
      initialValues,
      showErrors: 'blur',
    }
  )

  const handleSave = event => {
    event.preventDefault()
    setError(null)
    if (!isValid) return

    return fetchJsonPut(`api/inventory/${player.id}/items/${item.id}`, {
      id: item.id,
      typeId: item.typeId,
      count: values.total,
      attributes: attributes,
      supportNote: values.note,
      supportTicket: values.supportTicket
    }, { gameSpecific: true })
    .then(() => {
      onRequestUpdateView();
    })
    .catch(error => {
      setError(error)
    })
  }

  function handleAmountChange(event, _values) {
    setError(null)
    const amount = parseInt(event.target.value)
    changeFieldValue('total', item.count + amount)
  }

  function handleTotalChange(event, _values) {
    setError(null)
    const total = parseInt(event.target.value)
    changeFieldValue('amount', total - item.count)
  }

  function handleAttributesChange(newAttributes) {
    setError(null)
    setAttributes(newAttributes)
  }

  return (
    <Dialog
      open
      fullWidth
      onClose={ onClose }
    >
      <DialogTitle>
        { title }
      </DialogTitle>

      <DialogContent>
        <div className={ classes.row }>
          <div className={ classes.container }>
            <div className={ classes.item }>
              <Typography
                variant='caption'
              >
                Current
              </Typography>
              <Typography
                variant='body2'
              >
                { (item.count || 0).toLocaleString() }
              </Typography>
            </div>

            <div
              className={ classes.item }
              style={{ textAlign: 'center' }}
            >
              <Typography
                variant='caption'
              >
                Change
              </Typography>
              <TextField
                error={ !!errors.amount }
                errorMessage={ errors.amount }
                autoFocus
                fullWidth
                required
                messageProps={{
                  style: { textAlign: 'center' }
                }}
                style={{ width: 90, margin: '0 auto' }}
                inputProps={{
                  style: {
                    textAlign: 'center',
                  }
                }}
                { ...getFieldProps('amount', { onChange: handleAmountChange }) }
              />
            </div>

            <div
              className={ classnames(classes.item, classes.total) }
            >
              <Typography
                variant='caption'
              >
                Total
              </Typography>
              <TextField
                error={ !!errors.total }
                errorMessage={ errors.total }
                fullWidth
                required
                messageProps={{
                  style: { textAlign: 'right' }
                }}
                style={{ width: 90 }}
                formControlProps={{
                  style: {
                    alignItems: 'flex-end'
                  }
                }}
                inputProps={{
                  style: {
                    textAlign: 'right',
                  }
                }}
                { ...getFieldProps('total', { onChange: handleTotalChange }) }
              />
            </div>
          </div>
        </div>

        <div className={ classes.row }>
          <Typography
            variant='caption'
          >
            <b>NOTE: Inventory item will be modified directly and no claim message will be sent to player's inbox</b>
          </Typography>
        </div>

        <div className={ classes.infoRow }>
          <Typography
            variant='caption'
            style={{ width: 100 }}
          >
            Section:
          </Typography>
          <Typography
            variant='body2'
            component='span'
          >
            { item.section }
          </Typography>
        </div>

        <div className={ classes.infoRow }>
          <Typography
            variant='caption'
            style={{ width: 100 }}
          >
            Categories:
          </Typography>
          <Typography
            variant='body2'
            component='span'
          >
            { item.categories.map(r => (
              <Chip
                key={ r }
                label={ r }
                style={ { marginRight: 10 } }
              />
            ))}
          </Typography>
        </div>

        {(attributes && Object.keys(attributes).length > 0) ? (
          <div className={ classes.row }>
            <Typography
              variant='caption'
              style={{ width: 100 }}
            >
              Attributes
            </Typography>
            <ItemAttributes
              attributes={ attributes }
              onChange={ handleAttributesChange }
            />
          </div>
        ) : null }

        <div className={ classes.row }>
          <TextField
            error={ !!errors.note }
            errorMessage={ errors.note }
            label='Support agent note'
            fullWidth
            { ...getFieldProps('note') }
          />
        </div>

        <div className={ classes.row }>
          <TextField
            error={ !!errors.supportTicket }
            errorMessage={ errors.supportTicket }
            label='Support ticket number'
            fullWidth
            { ...getFieldProps('supportTicket') }
          />
        </div>

        {error ? <div className={classes.error}>{describeError(error)}</div> : null}
      </DialogContent>

      <DialogActions>
        <CancelButton
          onClick={ onClose }
        />
        <ConfirmButton
          onClick={ handleSave }
          disabled={ !isValid }
          text='Edit'
        />
      </DialogActions>
    </Dialog>
  )
}

PlayerEditInventoryDialog.propTypes = {
  player: PropTypes.object.isRequired,
  item: PropTypes.object.isRequired,
  title: PropTypes.string.isRequired,
  onClose: PropTypes.func.isRequired,
  onRequestUpdateView: PropTypes.func.isRequired,
}

PlayerEditInventoryDialog.displayName = 'PlayerEditInventoryDialog'

export default PlayerEditInventoryDialog
