import moment from 'moment'

export const statusForEvent = (event) => {
  const now = moment()
  if (event?.definition?.startTime && now.isBefore(event.definition.startTime)) {
    return 'Upcoming'
  } else if (event?.definition?.endTime && now.isAfter(event.definition.endTime)) {
    return 'Ended'
  }
  return 'Live'
}
