import React, { Fragment } from 'react'
import { Layout, MenuItemLink, } from 'react-admin'
import { withStyles } from '@material-ui/core/styles'
import ListSubheader from '@material-ui/core/ListSubheader'
import GamesListIcon from '@material-ui/icons/ViewModule'
import RecentActors from '@material-ui/icons/RecentActors'
import Sports from '@material-ui/icons/Games'

import { GlobalAppBar } from './AppBar'

const styles = {
  groupHeader: {
    paddingLeft: 16,
    textTransform: 'uppercase',
    fontSize: 11,
  },
}

const Menu = withStyles(styles)(({ classes, onMenuClick }) =>
  <Fragment>
    <MenuItemLink
      to='/select-games'
      leftIcon={<GamesListIcon />}
      primaryText='Switch game'
      onClick={onMenuClick}
    />
    <ListSubheader className={classes.groupHeader}>
      Admin Tool management
      </ListSubheader>
    <MenuItemLink
      to='/global/user-management'
      leftIcon={<RecentActors />}
      primaryText='Users'
      onClick={onMenuClick}
    />
    <MenuItemLink
      to='/global/game-management'
      leftIcon={<Sports />}
      primaryText='Games'
      onClick={onMenuClick}
    />

  </Fragment>
)

const GlobalViewLayout = props =>
  <Layout appBar={GlobalAppBar} menu={Menu} {...props} />

export default GlobalViewLayout
