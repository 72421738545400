import React from 'react'

import UserListView from '../user-management/UserListView'
import RoleListView from '../user-management/RoleListView'
import PermissionGridView from '../user-management/PermissionGridView'
import TabsView from '../components/TabsView'
import ContentContainer from '../components/ContentContainer'
import GlobalViewLayout from './GlobalViewLayout'

const GlobalUserManagement = () =>
  <GlobalViewLayout>
    <ContentContainer
      title='User Management'
    >
      <TabsView
        tabs={[
          {
            label: 'Users',
            content: <UserListView game={null} />,
          },
          {
            label: 'Roles',
            content: <RoleListView game={null} />,
          },
          {
            label: 'Permissions',
            content: <PermissionGridView game={null} />,
          },
        ]}
      />
    </ContentContainer>
  </GlobalViewLayout>

export default GlobalUserManagement
