import React from 'react'
import PropTypes from 'prop-types'
import Button from '@material-ui/core/Button'
import PenIcon from '@material-ui/icons/Edit'

const EditButton = ({ text, ...rest }) => (
  <Button
    color='primary'
    size='small'
    { ...rest }
  >
    <PenIcon
      style={ { marginRight: '0.5em', fontSize: 20 } }
    />
    { text }
  </Button>
)

EditButton.propTypes = {
  text: PropTypes.string,
}

EditButton.defaultProps = {
  text: 'Edit',
}

EditButton.displayName = 'EditButton'

export default EditButton
