import React, {useCallback, useMemo, useState} from 'react'
import PropTypes from 'prop-types'
import Card from '@material-ui/core/Card'
import CardContent from '@material-ui/core/CardContent'
import Tooltip from '@material-ui/core/Tooltip'
import {
  Datagrid,
  Filter,
  FunctionField,
  ListActions,
  ListContextProvider,
  ListToolbar,
  Pagination,
  SearchInput,
  TextField,
  useGetOne,
  useQuery,
} from 'react-admin'
import lodash from 'lodash'
import plugin from 'js-plugin'

import ShowButton from '../components/ShowButton'
import PlayerActivityDrawer from './PlayerActivityDrawer'
import DateRangeInput from '../components/DateRangeInput'
import {useListContextFromData, usePaginatedListController, useToken} from '../utils/listings'
import {describeError} from '../utils/errors'
import {combineByKey} from '../utils/plugins'
import TimestampField from '../components/TimestampField'
import {formatItem} from '../components/InventoryItemsField'
import {useCachedGetOne} from '../utils/caching'
import moment from "moment";

/** @typedef {import('admintool-api/v2/v2_common_inventory').GetItemTypesResponse} GetItemTypesResponse */
/** @typedef {import('react-admin').GetOneResult<GetItemTypesResponse>} ItemTypesGetOneResult */
/** @typedef {import('admintool-api/v2/v2_common_inventory').ItemType} ItemType */

const PlayerActivityWidget = ({
  player,
}) => {
  const token = useToken()
  const defaultTimeBegin = "1"
  const defaultTimeEnd = "200000000000000"
  const [filterValues, setFilterValues] = useState(() => combineByKey(
    {timeBegin: defaultTimeBegin, timeEnd: defaultTimeEnd},
    plugin.invoke('accounts.show.activity.filterDefaultValues')))
  const listProps = usePaginatedListController('activity', { perPage: 50, perPageOptions: [10, 25, 50, 100] })
  const filterAndToken = useMemo(() => ({ ...filterValues, token }), [filterValues, token])
  const log = useQuery({
    type: 'getList',
    resource: `activity/${player.id}`,
    payload: {
      pagination: listProps.pagination,
      sort: listProps.sort,
      filter: filterAndToken,
    },
  })
  const [selectedItem, setSelectedItem] = useState(null)

  const seasonConfig = useGetOne('config', 'season')
  const seasonRewardsMap = useMemo(() => {
    if (seasonConfig.data && seasonConfig.data.rewardPacks) {
      return Object.fromEntries(seasonConfig.data.rewardPacks.map(pack => [pack.id, pack]))
    }
    return {}
  }, [seasonConfig.data])
  /** @type ItemTypesGetOneResult */
  const itemTypes = useCachedGetOne('itemtypes', 'latest')
  /** @type Object.<string, ItemType> */
  const itemTypesMap = useMemo(() => {
    if (itemTypes.data && itemTypes.data.itemTypes) {
      return Object.fromEntries(itemTypes.data.itemTypes.map(type => [type.id, type]))
    }
    return {}
  }, [itemTypes])
  const activityNames = useCachedGetOne('config', 'activity')
  const activityNamesMap = useMemo(() => {
    if (activityNames.data && activityNames.data.translations) {
      return Object.fromEntries(activityNames.data.translations.map(t => [t.type, t]))
    }
    return {}
  }, [activityNames])
  const nameResolver = useCallback(commaSeparatedNames => {
    const transformName = name => {
      if(activityNamesMap[name]) return activityNamesMap[name].label
      if(itemTypesMap[name]) return itemTypesMap[name].name
      if(seasonRewardsMap[name]) {
        const packItems = seasonRewardsMap[name].items
        if (!packItems) return null
        const itemDesc = packItems.map(pack => {
          const chance = (pack.chance && pack.chance !== 1) ? `${pack.chance * 100}%: ` : ''
          const contents = pack.item || []
          return `${chance}${contents.map(x => formatItem(x, itemTypesMap)).join(', ')}`
        }).join(', ')
        return `${name} (${itemDesc})`
      }
      return null
    }
    return commaSeparatedNames.split(/([, ])/).map(id => {
      if(id === ',') return ', '
      if(id === ' ') return id
      const tr = transformName(id)
      if(tr) return tr
      const dotted = id.split('.')
      if(dotted.length === 2) {
        const trfirst = transformName(dotted[0])
        if(trfirst) return `${trfirst} lvl.${dotted[1]}`
      }
      return id
    }).join('')
  }, [activityNamesMap, seasonRewardsMap, itemTypesMap])

  const displayedFilters = {}
  for (const key of Object.keys(filterValues)) {
    if (filterValues[key] !== undefined) displayedFilters[key] = true
  }

  if (Array.isArray(log.data)) {
    log.data = log.data
      .map(entry => {
        const processedChanges = []
        if (entry.name) {
          processedChanges.push({
            kind: entry.name,
            before: entry.before,
            diff: entry.after - entry.before,
            after: entry.after,
          })
        }
        if (entry.changes) {
          processedChanges.push(...entry.changes.map(change => {
            const ret = { ...change }
            if ('before' in change && 'after' in change && !('diff' in change)) {
              ret.diff = change.after - change.before
            } else if (!('before' in change) && 'after' in change && 'diff' in change) {
              ret.before = change.after - change.diff
            } else if ('before' in change && !('after' in change) && 'diff' in change) {
              ret.after = change.before + change.diff
            }
            return ret
          }))
        }

        return { id: entry.logId, processedChanges, ...entry }
      })
      .reduce((map, x) => {
        if (!x) return map
        map[x.logId] = x
        return map
      }, {})
    log.ids = Object.keys(log.data)
  }

  const stripGenerated = (value) => {
    const { id: _ignored1, processedChanges: _ignored2, ...rest } = value
    return rest
  }

  const adjustFilterParams = (params) => {
    return combineByKey(params, {
      "timeBegin": params['timeFrom'] ? "" + moment(params['timeFrom']).valueOf() : defaultTimeBegin,
      "timeEnd": params['timeTo'] ? "" + moment(params['timeTo']).valueOf() : defaultTimeEnd
    })
  }

  const listContext = useListContextFromData('activity', log, {
    ...listProps,
    filterValues, displayedFilters,
    showFilter: (name, value) =>  {
      const props = {}
      props[name] = value
      setFilterValues(values => ({...values, ...adjustFilterParams(combineByKey(values, props))}))
    },
    hideFilter: name => setFilterValues(values => ({...values, [name]: undefined})),
    setFilters: (value) => {
      setFilterValues(adjustFilterParams(value))
    }
  })

  return <Card><CardContent>
    <ListContextProvider value={listContext}>
      <ListToolbar
        actions={<ListActions />}
        exporter={false}
        filters={<LogFilters />}
      />
      {!log.error ? <>
        <Datagrid
          rowClick={(_id, _basePath, record) => setSelectedItem(stripGenerated(record))}
        >
          <TimestampField source='time' label='Date / Time' />
          <ChangeAmountField label='Change' nameResolver={nameResolver} />
          <ChangeNameField label='Item' nameResolver={nameResolver} />
          <TranslatedTextField source='origin' label='Origin' nameResolver={nameResolver} />
          <TextField source='action' label='Action' />
          <FunctionField render={record =>
            <ShowButton onClick={() => { setSelectedItem(record) }} />
          } />
        </Datagrid>
        <Pagination rowsPerPageOptions={listProps.rowsPerPageOptions} />
        <PlayerActivityDrawer
          open={Boolean(selectedItem)}
          onClose={() => setSelectedItem(null)}
          selectedPlayerActivity={selectedItem}
          player={player}
        />
      </>
        :
        <p>{describeError(log.error)}</p>
      }
    </ListContextProvider>
  </CardContent></Card>
}

const LogFilters = (props) => (
  <Filter {...props}>
    <SearchInput source='q' alwaysOn />
    <DateRangeInput source='timeFrom' defaultValue='' sourceFrom='timeFrom' sourceTo='timeTo' label='Time range' />
  </Filter>
)
const diffText = (change) => {
  const diff = change.diff
  if (!diff) {
    if (change.before !== null && change.before !== undefined) return `was ${change.before}`
    if (change.after !== null && change.after !== undefined) return `now ${change.after}`
    if (diff === 0) return '0'
    return 'unknown'
  }
  if (diff > 0) return `+${diff}`
  return diff
}

const ChangeTooltip = ({change = {}, children, nameResolver}) => {
  const kind = nameResolver(change.kind)
  const maybeId = (kind !== change.kind) ? ` (id: ${change.kind})` : ''
  const before = (change.before !== null && change.before !== undefined) ? change.before : '?'
  const after = (change.after !== null && change.after !== undefined) ? change.after : '?'
  const diff = diffText(change)
  return <Tooltip
    title={`${kind}${maybeId}: ${before} \u2192 ${after} (${diff})`}
  >
    {children}
  </Tooltip>
}

const ChangeAmountField = ({ record = {}, nameResolver }) => {
  return <>
    {record.processedChanges.map(c =>
      <ChangeTooltip key={c.kind} change={c} nameResolver={nameResolver}>
          <div>{diffText(c)}</div>
      </ChangeTooltip>)}
  </>
}

const ChangeNameField = ({ record = {}, nameResolver }) => {
  return <>
    {record.processedChanges.map(c =>
      <ChangeTooltip key={c.kind} change={c} nameResolver={nameResolver}>
        <div>{nameResolver(c.kind)}</div>
      </ChangeTooltip>)}
  </>
}

const TranslatedTextField = ({ source, record = {}, nameResolver }) => {
  const text = lodash.get(record, source)
  const parts = text.split(/([, ])/)
  return <>
    {parts.map(name => {
      if(name === ' ') return name
      if(name === ',') return ', '
      const translated = nameResolver(name)
      if(translated === name) return name
      return <Tooltip key={name} title={name}>
        <div>{translated}</div>
      </Tooltip>
    })}
  </>
}

PlayerActivityWidget.propTypes = {
  player: PropTypes.object,
}

PlayerActivityWidget.displayName = 'PlayerActivityWidget'

export default PlayerActivityWidget
