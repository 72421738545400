import React from 'react'
import {
  Title,
  useGetOne,
  useVersion,
} from 'react-admin'
import Card from '@material-ui/core/Card'
import { makeStyles } from '@material-ui/core/styles'

import ShowHeader from '../components/ShowHeader'
import MessageList from './MessageList'
import plugin from 'js-plugin'
import TabsView from '../components/TabsView'
import ChannelMutedPlayers from './ChannelMutedPlayers'
import SendMessageInline from './SendMessageInline'

const useMessagesTabStyles = makeStyles(theme => ({
  sendMessage: {
    margin: theme.spacing(0, 2),
  },
}))

const ChannelMessagesTab = ({ id }) => {
  const version = useVersion()
  const classes = useMessagesTabStyles()

  return <>
    <SendMessageInline channelId={id} className={classes.sendMessage} />
    <MessageList filter={{ channel: id }} key={version} />
  </>
}

const useStyles = makeStyles(theme => ({
  header: {
    marginBottom: theme.spacing(2),
  },
}))

const ChannelShow = ({ id, ...rest }) => {
  const classes = useStyles()
  const channelGet = useGetOne('messaging/channel', id)
  const channel = channelGet.data || {}

  const headerItems = [
    { label: 'Channel ID', value: id },
    { label: 'Name', value: channel.name || '-' },
    { label: 'Description', value: channel.description || '-' },
  ]
  plugin.invoke('messaging.channels.show.headeritems', headerItems, id, channel)

  return <>
    <Title title={id} />

    <div className={classes.header}>
      <ShowHeader items={headerItems} />
    </div>
    <Card>
      <TabsView
        tabs={[
          {
            label: 'Messages',
            content: <ChannelMessagesTab id={id} />,
          },
          {
            label: 'Muted players',
            content: <ChannelMutedPlayers id={id} raProps={rest} />,
          },
        ]}
      />
    </Card>
  </>

  /* Can't use <Show> yet, Hydra server has to support single-channel fetch first.
  return <Show {...props}>
    <SimpleShowLayout>
      <MessageList />
    </SimpleShowLayout>
  </Show>
  */
}

export default ChannelShow
