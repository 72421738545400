import React, { useState } from 'react'
import PropTypes from 'prop-types'
import Dialog from '@material-ui/core/Dialog'
import DialogActions from '@material-ui/core/DialogActions'
import DialogContent from '@material-ui/core/DialogContent'
import DialogTitle from '@material-ui/core/DialogTitle'
import Grid from '@material-ui/core/Grid'
import Typography from '@material-ui/core/Typography'
import AccountCircle from '@material-ui/icons/AccountCircle'
import InputAdornment from '@material-ui/core/InputAdornment'
import {
  useGetList,
  useNotify,
} from 'react-admin'
import Autocomplete from '@material-ui/lab/Autocomplete'
import { Button, TextField, makeStyles, InputLabel } from '@material-ui/core'
import SwapHorizIcon from '@material-ui/icons/SwapHoriz'

import CancelButton from '../components/CancelButton'
import ConfirmButton from '../components/ConfirmButton'
import ShowItem from '../components/ShowItem'
import { fetchJsonPost } from '../utils/fetch';

const useAccountInfoStyles = makeStyles(_theme => ({
  itemLabel: {
    width: 100,
    display: 'inline-block',
  },
  itemValue: {
    width: 200,
    display: 'inline-block',
  },
}))

const AccountInfo = ({ account }) => {
  const classes = useAccountInfoStyles()

  return <>
    <ShowItem
      label='Player name'
      classes={{
        label: classes.itemLabel,
        value: classes.itemValue,
      }}
    >
      {account.publicName}
    </ShowItem>

    <ShowItem
      label='Player ID'
      classes={{
        label: classes.itemLabel,
        value: classes.itemValue,
      }}
    >
      {account.id}
    </ShowItem>
  </>
}

const useStaticAccountStyles = makeStyles(_theme => ({
  sectionHeader: {
    width: '100%',
    marginBottom: 10,
  },
  accountBrief: {
    display: 'flex',
    alignItems: 'center',
    width: '100%',
    /* These margins are weird, but seem to keep the account icon and player ID/name below in
     their correct place */
    marginTop: '1.5px',
    marginBottom: '2.5px',
  },
}))

const StaticAccount = ({ account, label }) => {
  const classes = useStaticAccountStyles()

  return <>
    <InputLabel shrink>{label}</InputLabel>
    <div className={classes.accountBrief}>
      <AccountCircle />
  - {account.publicName}
    </div>
    <AccountInfo account={account} />
  </>
}

const SelectableAccount = ({ account, setAccount, label, otherAccount }) => {
  const [errorMessage, setErrorMessage] = useState('')
  const [inputValue, setInputValue] = useState(account ? account.publicName : '')
  const suggestions = useGetList('accounts', { page: 1, perPage: 5 }, null, { q: inputValue })

  function handleSelectedChange(_event, selected) {
    if (!selected || selected.id !== otherAccount.id) {
      setAccount(selected)
      setErrorMessage('')
    } else {
      setErrorMessage('Desired account can\'t be the same as current account')
    }
  }

  return <>
    <Autocomplete
      autoComplete
      fullWidth
      value={account}
      options={Object.values(suggestions.data)}
      filterOptions={x => x /* Disable default filter -- otherwise search in STM doesn't work */}
      onChange={handleSelectedChange}
      onInputChange={(e, input) => setInputValue(input)}
      getOptionLabel={option => option.publicName}
      loading={!suggestions.loaded}
      renderInput={params =>
        <TextField
          {...params}
          label={label}
          fullWidth
          error={!!errorMessage}
          helperText={errorMessage}
          InputProps={{
            ...params.InputProps,
            startAdornment: (
              <InputAdornment position='start'>
                <AccountCircle />
              </InputAdornment>
            ),
          }}
        />
      }
    />
    {account && <AccountInfo account={account} />}
  </>
}

const useStyles = makeStyles(_theme => ({
  description: {
    marginTop: 20,
    marginBottom: 20,
    textAlign: 'center',
  },
}))

const PlayerAccountRecoveryDialog = ({
  player,
  onClose,
}) => {
  const classes = useStyles()
  const showNotification = useNotify()
  const [discardedAccount, setDiscardedAccount] = useState(player)
  const [desiredAccount, setDesiredAccount] = useState(null)
  const [notes, setNotes] = useState('')
  const [ticket, setTicket] = useState('')
  const [swapped, setSwapped] = useState(false)

  function handleRecovery() {
    return fetchJsonPost(`api/accounts/${discardedAccount.id}/replace`,
      {
        desiredAccountId: desiredAccount.id,
        notes,
        ticket,
      },
      { gameSpecific: true }
    ).then(() => {
      window.location = `/accounts/${desiredAccount.id}/show`
    }).catch((error) => {
      showNotification(error.message || 'Failed to recover account', 'warning')
    })
  }

  const swapItems = () => {
    const discarded = discardedAccount
    const desired = desiredAccount
    setDiscardedAccount(desired)
    setDesiredAccount(discarded)
    setSwapped(prev => !prev)
  }

  const discardedLabel = 'Player with access to (discarded account)'
  const desiredLabel = 'Should instead have access to (desired account)'

  return (
    <Dialog
      open
      fullWidth
      maxWidth='md'
      onClose={onClose}
    >
      <DialogTitle>
        Player account recovery
      </DialogTitle>

      <DialogContent>
        <Grid
          container
          spacing={2}
        >
          <Grid
            item
            xs={5}
          >
            {swapped ?
              <SelectableAccount
                account={discardedAccount}
                setAccount={setDiscardedAccount}
                label={discardedLabel}
                otherAccount={desiredAccount}
              /> :
              <StaticAccount
                account={discardedAccount}
                label={discardedLabel}
              />
            }
          </Grid>

          <Grid item xs={2}>
            <Button
              onClick={swapItems}
              variant='outlined'
              color='primary'
              fullWidth
              startIcon={<SwapHorizIcon />}
            >
              Swap
            </Button>
          </Grid>

          <Grid
            item
            xs={5}
          >
            {swapped ?
              <StaticAccount
                account={desiredAccount}
                label={desiredLabel}
              /> :
              <SelectableAccount
                account={desiredAccount}
                setAccount={setDesiredAccount}
                label={desiredLabel}
                otherAccount={discardedAccount}
              />
            }
          </Grid>
        </Grid>

        <Grid item>
          <Typography
            variant='body2'
            component='div'
            className={classes.description}
          >
            All player account IDs from the discarded account are moved to the desired account.
          </Typography>
        </Grid>

        <Grid
          container
          spacing={2}
        >
          <Grid
            item
            xs={6}
          >
            <TextField
              label='Support agent notes'
              fullWidth
              value={notes}
              onChange={event => setNotes(event.target.value)}
            />
          </Grid>
          <Grid
            item
            xs={6}
          >
            <TextField
              label='Support ticket number'
              fullWidth
              value={ticket}
              onChange={event => setTicket(event.target.value)}
            />
          </Grid>
        </Grid>
      </DialogContent>

      <DialogActions>
        <CancelButton
          onClick={onClose}
        />
        <ConfirmButton
          onClick={handleRecovery}
          disabled={!desiredAccount || !discardedAccount}
        />
      </DialogActions>
    </Dialog>
  )
}

PlayerAccountRecoveryDialog.displayName = 'PlayerAccountRecoveryDialog'
PlayerAccountRecoveryDialog.propTypes = {
  player: PropTypes.object.isRequired,
  onClose: PropTypes.func.isRequired,
}

export default PlayerAccountRecoveryDialog
