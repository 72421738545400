import React from 'react'
import { connect } from 'react-redux'
import ClickAwayListener from '@material-ui/core/ClickAwayListener';
import Grow from '@material-ui/core/Grow';
import Paper from '@material-ui/core/Paper';
import Popper from '@material-ui/core/Popper';
import TextField from '@material-ui/core/TextField';
import Typography from '@material-ui/core/Typography';
import IconButton from '@material-ui/core/IconButton'
import LinkIcon from '@material-ui/icons/Link';
import AssignmentIcon from '@material-ui/icons/Assignment'

const TEXTFIELD_ID = 'permalink-textfield'

const Permalink = ({ currentGame }) => {
  const [copyState, setCopyState] = React.useState('\xA0')
  const [anchorEl, setAnchorEl] = React.useState(null)
  const open = !!anchorEl

  const handleOpenClick = event => {
    setAnchorEl(anchorEl ? null : event.currentTarget)
    setCopyState('\xA0')
  }

  const handleClose = () => {
    setAnchorEl(null)
  }

  const target = new URL(document.URL)
  if (currentGame) {
    target.searchParams.set('game', currentGame.id)
    target.searchParams.set('env', currentGame.env)
  }

  const handleCopy = () => {
    if (!navigator.clipboard) {
      var tf = document.getElementById(TEXTFIELD_ID)
      tf.select()
      try {
        document.execCommand('copy')
        setCopyState('Copied link to clipboard')
      } catch (e) {
        console.log('Copy to clipboard using execCommand failed', e)
        setCopyState('Oops, copy failed!')
      }
    } else {
      navigator.clipboard.writeText(target).then(
        () => { setCopyState('Copied link to clipboard') },
        e => {
          console.log('Copy to clipboard using navigator failed', e)
          setCopyState('Oops, copy failed!')
        }
      )
    }
  }

  return <>
    <IconButton aria-label='Get link to current page' color='inherit' onClick={handleOpenClick}>
      <LinkIcon />
    </IconButton>
    <Popper open={open} anchorEl={anchorEl} transition disablePortal>
      {({ TransitionProps }) => (
        <Grow {...TransitionProps}>
          <Paper>
            <ClickAwayListener onClickAway={handleClose}>
              <div>
                <TextField
                  autoFocus
                  label='Link to current page'
                  defaultValue={target}
                  InputProps={{ readOnly: true, id: TEXTFIELD_ID }}
                />
                <IconButton aria-label='Copy link to clipboard' color='primary' onClick={handleCopy}>
                  <AssignmentIcon />
                </IconButton>
                <Typography>
                  {copyState}
                </Typography>
              </div>
            </ClickAwayListener>
          </Paper>
        </Grow>
      )}
    </Popper>
  </>
}

export default connect(({ games }) => ({ currentGame: games.currentGame }))(Permalink)
