import React from 'react'
import { Datagrid, TextField, useGetOne } from 'react-admin'

const LeaderboardContents = ({ leaderboard }) => {
  const board = useGetOne('leaderboard/board', leaderboard.id)
  const scores = (board.data && Array.isArray(board.data.entries)) ? board.data.entries : []
  const scoreLabels = (board.data && Array.isArray(board.data.scoreKind)) ? board.data.scoreKind : []

  return <Datagrid
    data={Object.fromEntries(scores.map(row => [row.id, row]))}
    ids={scores.map(row => row.id)}
    currentSort={{ field: 'rank', order: 'DESC' }}
    resource='leaderboard/board'
    basePath='/leaderboard/board'
    selectedIds={[]}
  >
    <TextField source='rank' />
    <TextField source='name' />
    {scoreLabels.map((label, i) => <TextField source={`score[${i}]`} key={label} label={label} />)}
  </Datagrid>
}

export default LeaderboardContents
