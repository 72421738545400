import React, { useState } from 'react'
import { NumberInput, SelectInput, minValue, number, required, useInput } from 'react-admin'
import Grid from '@material-ui/core/Grid'
import { makeStyles } from '@material-ui/core/styles'
import IconButton from '@material-ui/core/IconButton'
import DeleteIcon from '@material-ui/icons/Delete'
import Autocomplete, { suggestItems } from '../components/Autocomplete'

const useStyles = makeStyles(theme => ({
  deleteButton: {
    margin: theme.spacing(1, 0, 0.5, 0),
  },
}))

const InventoryItemPicker = ({
  source,
  itemTypes,
  onChange,
  onDelete,
  ...rest
}) => {
  const {
    id,
    input,
  } = useInput({
    onChange, source, ...rest,
  });
  const [sections, setSections] = useState([])
  const classes = useStyles()

  function changeType(newType) {
    if (newType) {
      const newSectionsList = newType.compatibleSections.map(secName => ({
        id: secName,
        name: secName,
      }))
      setSections(newSectionsList)
    }
    let newSection = ''
    if (newType && newType.compatibleSections.length === 1) {
      newSection = newType.compatibleSections[0]
    } else if (newType && newType.compatibleSections.includes(input.section)) {
      newSection = input.section
    }
    input.onChange({ id: newType ? newType.id : '', section: newSection, amount: input.value.amount })
  }

  return <Grid container spacing={2} alignContent='flex-start'>
    <Grid item xs={12} sm={5} md={12} lg={5}>
      <Autocomplete
        onChange={changeType}
        getSuggestions={query => suggestItems(itemTypes, query)}
        getItemId={item => item.id}
        getItemLabel={item => item.name}
        textFieldProps={{
          label: 'Item type',
          autoFocus: true,
          variant: 'filled',
          margin: 'dense',
        }}
        required
      />
    </Grid>
    <Grid item xs={12} sm={4} md={12} lg={4}>
      <SelectInput
        choices={sections}
        source={id + '.section'}
        label='Inventory section'
        fullWidth
        validate={required()}
      />
    </Grid>
    <Grid item xs={10} sm={2} md={10} lg={2}>
      <NumberInput source={id + '.amount'} label='Amount' fullWidth validate={[number(), minValue(1)]} />
    </Grid>
    <Grid item xs={2} sm={1} md={2} lg={1}>
      <IconButton onClick={onDelete} className={classes.deleteButton} >
        <DeleteIcon />
      </IconButton>
    </Grid>
  </Grid>
}

export default InventoryItemPicker
