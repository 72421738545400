import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react'
import {
  Datagrid,
  Filter,
  Link,
  ListActions,
  ListContextProvider,
  ListToolbar,
  RichTextField,
  TextField,
  useCreate,
  useDelete,
  useGetList,
  useGetMany,
} from 'react-admin'
import {
  Button,
  ButtonGroup,
  CircularProgress,
  IconButton,
  Popover,
  Tooltip,
  makeStyles
} from '@material-ui/core'
import MenuIcon from '@material-ui/icons/Menu'
import DeleteIcon from '@material-ui/icons/Delete'
import BlockIcon from '@material-ui/icons/Block'
import DateRangeInput from '../components/DateRangeInput'
import InfinitePagination from '../components/InfinitePagination'
import TimestampField from '../components/TimestampField'
import { useHasPermission } from '../utils/auth'
import { useDeleteSafeCopy, useListContextFromData, useStableCopy } from '../utils/listings'
import { useHistory } from 'react-router'
import { describeError } from '../utils/errors'

const muteOp = 'mute'
const unmuteOp = 'unmute'
const removeOp = 'remove'

const useDeleteButtonStyles = makeStyles(theme => ({
  removeButton: {
    color: theme.palette.error.main,
    justifyContent: 'flex-start',
  },
  muteButton: {
    color: theme.palette.warning.main,
    justifyContent: 'flex-start',
  },
  unmuteButton: {
    color: theme.palette.success.main,
    justifyContent: 'flex-start',
  },
  progress: {
    position: 'absolute',
    top: '50%',
    left: '50%',
    marginTop: -12,
    marginLeft: -12,
  },
}))

const MessageOpsButton = ({ banned, record = {} }) => {
  const delayMillis = 3000
  const classes = useDeleteButtonStyles()

  const [deleteMessage, { loading: deleteLoading, error: deleteError }] =
    useDelete('messaging/message', record.id, record)
  const [mutePlayer, { loading: muteLoading, error: muteError }] = useCreate('messaging/ban',
    {
      player: record.sender,
      bannedFrom: 'CHANNEL',
      channel: record.channel,
      visibility: 'VISIBLE',
    })
  const [unmutePlayer, { loading: unmuteLoading, error: unmuteError }] = useDelete(
    'messaging/ban', banned?.[record.sender])

  const [startTime, setStartTime] = useState(null)
  const [delayPercent, setDelayPercent] = useState(0)
  const [anchorElement, setAnchorElement] = useState(null)
  const timer = useRef(null)
  const operation = useRef(null)
  const isPlayerMuted = !!(banned?.[record.sender])

  const runOperation = useCallback(() => {
    if(operation.current === removeOp) deleteMessage()
    else if(operation.current === muteOp) mutePlayer()
    else if(operation.current === unmuteOp) unmutePlayer()
  }, [deleteMessage, mutePlayer, unmutePlayer])

  const updateProcess = useCallback((start) => {
    const elapsed = Date.now() - start
    const elapsedPercent = elapsed / delayMillis * 100
    setDelayPercent(elapsedPercent)
    // We give it our 110% as a fudge factor: progress bar clearly hits 100% before the delete runs
    if(elapsedPercent >= 110) {
      timer.current = null
      runOperation()
    } else {
      timer.current = setTimeout(updateProcess, 100, start)
    }
  }, [runOperation])

  useEffect(() => {
    if(!startTime) {
      setDelayPercent(0)
      return
    }
    timer.current = setTimeout(updateProcess, 100, startTime)
    return () => {
      if(timer.current) {
        clearTimeout(timer.current)
        timer.current = null
        runOperation()
      }
    }
    // This should only be run when startTime is updated (and at setup and teardown)
    // Using updateProcess and runOperation captured at that time is fine.
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [startTime])

  if (!operation.current) {
    return <>
      <IconButton color='primary' onClick={event => setAnchorElement(event.currentTarget)}>
        <MenuIcon />
      </IconButton>
      <Popover
        open={!!anchorElement}
        anchorEl={anchorElement}
        onClose={() => setAnchorElement(null)}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'right',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'right',
        }}
      >
        <ButtonGroup
          orientation='vertical'
          color='primary'
          variant='outlined'
        >
          <Button
            onClick={() => {
              setAnchorElement(null)
              setStartTime(Date.now())
              operation.current = removeOp
            }}
            className={classes.removeButton}
            startIcon={<DeleteIcon />}
          >
            Remove
          </Button>
          {!isPlayerMuted ?
            <Button
              onClick={() => {
                setAnchorElement(null)
                setStartTime(Date.now())
                operation.current = muteOp
              }}
              className={classes.muteButton}
              startIcon={<BlockIcon />}
              disabled={!record.sender}
            >
              Mute player
            </Button> :
            <Button
              onClick={() => {
                setAnchorElement(null)
                setStartTime(Date.now())
                operation.current = unmuteOp
              }}
              className={classes.unmuteButton}
              startIcon={<BlockIcon />}
              disabled={!record.sender}
            >
              Unmute player
            </Button>
          }
        </ButtonGroup>
      </Popover>
    </>
  }
  if (delayPercent < 110) {
    return <Button
      onClick={() => {
        clearTimeout(timer.current)
        timer.current = null
        setStartTime(null)
        operation.current = null
      }}
      startIcon={
        <CircularProgress
          size={24}
          variant='determinate'
          value={Math.min(delayPercent, 100)}
        />
      }
    >
      {operation.current === removeOp ? 'Cancel removal' :
       operation.current === muteOp ? 'Cancel muting' :
       operation.current === unmuteOp ? 'Cancel unmuting' : 'Cancel... something?'}
    </Button>
  }
  if(operation.current === removeOp) {
    if (deleteLoading) {
      return <div><CircularProgress size={24} className={classes.progress} /> Removing...</div>
    }
    return <p>
      {!deleteError ? 'Removed' : `Failed to remove: ${describeError(deleteError)}`}
    </p>
  } else if(operation.current === muteOp) {
    if (muteLoading) {
      return <div><CircularProgress size={24} className={classes.progress} /> Muting...</div>
    }
    return <p>
      {!muteError ? 'Player muted' : `Failed to mute: ${describeError(muteError)}`}
    </p>
  } else if(operation.current === unmuteOp) {
    if (unmuteLoading) {
      return <div><CircularProgress size={24} className={classes.progress} /> Unmuting...</div>
    }
    return <p>
      {!unmuteError ? 'Player unmuted' : `Failed to unmute: ${describeError(unmuteError)}`}
    </p>
  }
  return <p>Internal error!</p>
}

const MessageListFilters = (props) => {
  return <Filter {...props}>
    <DateRangeInput
      source='range'
      defaultValue={{ start: '', end: '' }}
      sourceFrom='range.start'
      sourceTo='range.end'
      label='Date range'
    />
  </Filter>
}

const ChannelLinkField = ({ source, record = {} }) => {
  return <Link to={`/messaging/channel/${record[source]}/show`}>
    {record[source]}
  </Link>
}

const ReportField = ({ reports, record = {} }) => {
  const messageId = record.id.split('/').pop()
  const reportArray = reports[messageId] || []
  const reporters = useGetMany('accounts', reportArray.map(report => report.reporter))
  const reporterMap = {}
  for (const account of reporters.data) {
    if (account) reporterMap[account.id] = account
  }

  if (reports[messageId]) {
    return <div>
      Reported by: {reportArray.map(report => <span key={`${messageId}-${report.reporter}`}>
        <Link to={`/accounts/${report.reporter}/show`}>
          {reporterMap[report.reporter] ?
            reporterMap[report.reporter].publicName :
            `Player #${report.reporter}`}
        </Link> {}
      </span>
    )}
    </div>
  }
  return <div></div>
}

const usePlayerWithBanFieldStyles = makeStyles(theme => ({
  blockIcon: {
    verticalAlign: 'bottom',
    color: theme.palette.error.light,
  },
}))

const PlayerWithBanField = ({ banned, record = {} }) => {
  const classes = usePlayerWithBanFieldStyles()
  const senderId = record.sender
  const playerInfo = useGetMany('accounts', [senderId], { enabled: !!senderId })
  const name = playerInfo?.data?.[0]?.publicName

  if(!senderId) return null

  return <div>
    {banned[senderId] ?
      <Tooltip title='This player has been muted in this channel'>
        <BlockIcon className={classes.blockIcon} />
      </Tooltip> : null
    }
    <Link to={`/accounts/${senderId}/show`}>
      {name ? name : `Player id ${senderId}`}
    </Link>
  </div>
}

const MessageList = ({ filter }) => {
  const externalFilter = useStableCopy(filter)
  const hasPermission = useHasPermission()
  const history = useHistory()
  const urlParams = new URLSearchParams(history.location.search)
  const [page, setPage] = useState(1)
  const [perPage, setPerPage] = useState(25)
  const [userFilters, setUserFilters] = useState({
    range: {
      start: urlParams.get('rangeStart') || undefined,
      end: urlParams.get('rangeEnd') || undefined,
    }
  })
  const sort = { field: 'timestamp', order: 'DESC' }

  const chatBans = useGetList('messaging/ban',
    null, null, { channel: filter.channel }, { enabled: !!filter.channel })
  const bannedIds = useMemo(() => {
    if(!chatBans?.data) return {}
    const ret = {}
    Object.values(chatBans.data).forEach(ban => ret[ban.player] = ban.id)
    return ret
  }, [chatBans])

  useEffect(() => {
    const params = new URLSearchParams(history.location.search)
    if(userFilters.range.start) params.set('rangeStart', userFilters.range.start)
    else params.delete('rangeStart')
    if(userFilters.range.end) params.set('rangeEnd', userFilters.range.end)
    else params.delete('rangeEnd')
    history.replace(history.location.pathname + '?' + new URLSearchParams(params))
  }, [userFilters, history])

  const fullFilters = useMemo(() => {
    const {range, ...otherUserFilters} = userFilters
    return {
      ...range,
      ...otherUserFilters,
      ...externalFilter,
    }
  }, [userFilters, externalFilter])
  const displayedFilters = useMemo(() => {
    const f = {}
    for (const key of Object.keys(userFilters)) {
      if (userFilters[key] !== undefined) f[key] = true
    }
    return f
  }, [userFilters])
  const reportFilters = useMemo(() => {
    const f = {}
    if (fullFilters.player) f.player = fullFilters.player
    if (fullFilters.channel) f.channel = fullFilters.channel
    return f
  }, [fullFilters])

  const messagesRaw = useGetList('messaging/message', { page, perPage }, sort, fullFilters)
  const messages = useDeleteSafeCopy(messagesRaw, { page, perPage, sort, fullFilters })

  const reports = useGetList('messaging/report', null, null, reportFilters)
  const reportMap = useMemo(() => {
    const map = {}
    for (const report of Object.values(reports.data)) {
      if (!map[report.messageId]) map[report.messageId] = []
      map[report.messageId].push(report)
    }
    return map
  }, [reports.data])

  const listContext = useListContextFromData('messaging/message', messages, {
    page, setPage, perPage, setPerPage, filterValues: userFilters, 
    showFilter: (name, value) => setUserFilters(values => ({ ...values, [name]: value })),
    hideFilter: name => setUserFilters(values => ({ ...values, [name]: undefined })),
    displayedFilters: displayedFilters,
    setFilters: setUserFilters,
  })

  return <ListContextProvider value={listContext}>
    <ListToolbar
      actions={<ListActions />}
      exporter={false}
      filters={<MessageListFilters />}
    />
    <Datagrid resource='messaging/message'>
      <TimestampField source='timestamp' label='Date / time' sortable={false} />
      <RichTextField source='message.html' label='Message' sortable={false} />
      <PlayerWithBanField banned={bannedIds} label='Sender' />
      {externalFilter.channel ? null : <ChannelLinkField source='channel' sortable={false} />}
      <ReportField label='Reports' reports={reportMap} sortable={false} />
      <TextField source='state' label='Status' sortable={false} />
      {hasPermission('EditChatMessages') || hasPermission('EditChatBans') ?
        <MessageOpsButton banned={bannedIds} /> :
        null}
    </Datagrid>
    <InfinitePagination {...listContext} />
  </ListContextProvider>
}

export default MessageList
