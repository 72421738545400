import {
  localStorageSet,
  localStorageGet,
  localStorageRemove,
} from './localStorage'

const redirectUriKey = 'redirect_uri'

export function setRedirectUri(uri) {
  if (!uri) {
    localStorageRemove(redirectUriKey)
  } else {
    localStorageSet(redirectUriKey, uri)
  }
}

export function getRedirectUri() {
  return localStorageGet(redirectUriKey)
}
