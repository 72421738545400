import React from 'react'
import PropTypes from 'prop-types'
import { makeStyles } from '@material-ui/core/styles'

const useStyles = makeStyles(theme => ({
  item: {
    marginBottom: theme.spacing(1),
  },
  label: {
    ...theme.typography.caption,
    width: 200,
    display: 'inline-block',
  },
  value: {
    ...theme.typography.body2,
    width: 300,
    display: 'inline-block',
  },
}))

const ShowItem = React.forwardRef(({
  label,
  children,
  ...rest
}, ref) => {
  const classes = useStyles(rest)
  return <div className={classes.item} ref={ref} {...rest}>
    <div className={classes.label}>
      {label}
    </div>
    <div className={classes.value}>
      {typeof children === 'function' ?
        children() :
        <>{children}</>
      }
    </div>
  </div>
})

ShowItem.displayName = 'ShowItem'
ShowItem.propTypes = {
  label: PropTypes.string.isRequired,
  children: PropTypes.oneOfType([PropTypes.node, PropTypes.func]),
}

export default ShowItem
