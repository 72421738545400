import React from 'react'
import {
  Checkbox,
  Chip,
  FormControl,
  FormHelperText,
  InputLabel,
  MenuItem,
  Select
} from '@material-ui/core'
import { makeStyles } from '@material-ui/styles'

const useStyles = makeStyles(theme => ({
  chip: {
    margin: theme.spacing(0, 1, 0, 0),
  },
}))

const ALL_VALUE = '__all__'

const MultiSelectInput = ({
  label,
  items,
  includeAllItem,
  labelField,
  valueField,
  value,
  className,
  error,
  errorMessage,
  onChange,
  ...rest
}) => {
  function handleChange(event) {
    onChange(event)
  }
  const classes = useStyles()

  return (
    <FormControl
      className={className}
      error={error}
      required={rest.required}
    >
      {label && <InputLabel>{label}</InputLabel>}
      <Select
        value={value || []}
        onChange={handleChange}
        renderValue={selected => (
          <div>
            {selected.map(value => value === ALL_VALUE ?
              <Chip size='small' className={classes.chip} key={ALL_VALUE} label={<em>All</em>} /> :
              <Chip size='small' className={classes.chip} key={value} label={value} />)}
          </div>
        )}
        multiple
        {...rest}
      >
        {includeAllItem && (
          <MenuItem value={ALL_VALUE}>
            <Checkbox checked={value.includes(ALL_VALUE)} />
            <em>All</em>
          </MenuItem>
        )}
        {
          items.map(item => (
            <MenuItem
              key={item[valueField]}
              value={item[valueField]}
            >
              <Checkbox checked={value.includes(item[valueField])} />
              {item[labelField]}
            </MenuItem>
          ))
        }
      </Select>
      {errorMessage && (
        <FormHelperText>
          {errorMessage}
        </FormHelperText>
      )}
    </FormControl>
  )
}

export default MultiSelectInput
