export const getBeaconCalendarUrl = (currentGame, eventId) => {
  let { beaconApp, beaconEnv } = getBeaconEnv(currentGame)

  return `https://beacon${beaconEnv}.rovio.com/dashboard/apps/${beaconApp}/tools/liveops/calendar?eventId=${eventId}`
}

export const getBeaconProfileUrl = (currentGame, beaconPlayerId) => {
  let { beaconApp, beaconEnv } = getBeaconEnv(currentGame)
  
  return `https://beacon${beaconEnv}.rovio.com/dashboard/apps/${beaconApp}/tools/players-and-devices/id/${beaconPlayerId}/ID/details`
}

const getBeaconEnv = (currentGame) => {
  let {
    beaconApp,
    beaconEnv,
  } = currentGame

  if (!beaconApp) beaconApp = 'TestClient'
  if (!beaconEnv) beaconEnv = 'dev'
  if (beaconEnv === 'prod') beaconEnv = ''
  beaconEnv = beaconEnv ? `-${beaconEnv}` : ''

  return { beaconApp, beaconEnv }  
}