import React from 'react'
import { useNotify, useRefresh } from 'react-admin'
import Dialog from '@material-ui/core/Dialog'
import DialogActions from '@material-ui/core/DialogActions'
import DialogContent from '@material-ui/core/DialogContent'
import DialogTitle from '@material-ui/core/DialogTitle'
import CancelButton from '../components/CancelButton'
import ConfirmButton from '../components/ConfirmButton'
import { fetchJsonPost } from '../utils/fetch'

const PlayerKickDialog = ({ player, onClose }) => {
  const notify = useNotify()
  const refresh = useRefresh()

  const handleSave = () => {
    return fetchJsonPost(`api/accounts/${player.id}/kick`, {}, { gameSpecific: true })
      .then(
        () => {
          onClose()
          refresh()
        },
        error => {
          console.log(error)
          notify(error.message || 'Kick operation failed', 'warning')
        })
  }

  return <Dialog open onClose={onClose}>
    <DialogTitle>Kick player {player.publicName}</DialogTitle>
    <DialogContent>
      Kicking a player removes them from their current map and guild. They can still return to the game and get assigned to new map and guild.
      </DialogContent>
    <DialogActions>
      <CancelButton onClick={onClose} />
      <ConfirmButton onClick={handleSave} text='Kick player'/>
    </DialogActions>
  </Dialog>
}

export default PlayerKickDialog
