import React from 'react'
import Card from '@material-ui/core/Card'
import CardContent from '@material-ui/core/CardContent'
import { useGetList } from 'react-admin'
import MessageList from '../messaging/MessageList'

const GuildChatWidget = ({ guild }) => {
  const sort = { field: 'id', order: 'DESC' }
  const filter = { guild: guild.id }
  const channels = useGetList('messaging/channel', null, sort, filter)

  return <Card>
    <CardContent>
      {Object.values(channels.data).map(ch =>
        <MessageList filter={{ channel: ch.id }} key={ch.id} />
      )}
    </CardContent>
  </Card>
}

export default GuildChatWidget
