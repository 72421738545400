import React, {useState} from 'react'
import Card from '@material-ui/core/Card'
import CardContent from '@material-ui/core/CardContent'
import ReportsList from '../messaging/ReportsList'
import { useListContextFromData, usePaginatedListController } from '../utils/listings'
import InfinitePagination from '../components/InfinitePagination'
import BlockIcon from '@material-ui/icons/Block';
import IconButton from '@material-ui/core/IconButton'
import Tooltip from '@material-ui/core/Tooltip'
import Grid from '@material-ui/core/Grid'
import { makeStyles } from '@material-ui/core/styles'
import Accordion from '@material-ui/core/Accordion'
import AccordionDetails from '@material-ui/core/AccordionDetails'
import EnhancedAccordionSummary from '../components/EnhancedAccordionSummary'
import {
  Datagrid,
  ListContextProvider,
  ReferenceField,
  TextField,
  useGetList
} from 'react-admin'

const useStyles = makeStyles(theme => ({
  gridContainer: {
    width: '100%',
    padding: theme.spacing(1, 0),
  }
}))

const BlockedPlayersReportList = ({ player, onSetAccordionTotalCount }) => {

  const classes = useStyles()
  const listController = usePaginatedListController('messaging/ban', { sort: false })
  const blockedPlayers = useGetList('messaging/ban',
    { page: listController.page, perPage: listController.perPage }, listController.currentSort,
    { recipient: player.id })
  const listContext = useListContextFromData('messaging/ban', blockedPlayers, listController)
  
  if (onSetAccordionTotalCount && (typeof onSetAccordionTotalCount == "function")) {
    onSetAccordionTotalCount(listContext.total)
  }

  return <ListContextProvider value={listContext}>
    <Grid className={classes.gridContainer}>
      <BlockedPlayersDatagrid />
      <InfinitePagination {...listContext} />
    </Grid>
  </ListContextProvider>
}

const BlockedPlayersDatagrid = () => {
  return <>
    <Datagrid resource='messaging/ban'>
      <ReferenceField label='Player name' source='player' reference='accounts' link='show'>
        <TextField source='publicName' />
      </ReferenceField>
      <UnblockIconButton title="Tool does not support unblock feature yet" icon={<BlockIcon />}></UnblockIconButton>
    </Datagrid>
  </>
}

const UnblockIconButton = ({
  title,
  icon,
  onClick
}) => {
  return (
    <Tooltip title={title}>
      <IconButton
        aria-label={title}
        onClick={onClick}
        disabled={false}
        style={{ float: 'right' }}
      >
        {icon}
      </IconButton>
    </Tooltip>
  )
}

const PlayerReportsList = ({ player }) => {
  
  const [blockedPlayersTotalCount, setBlockedPlayersTotalCount] = useState(0);
  const [reportsReceivedTotalCount, setReportsReceivedTotalCount] = useState(0);
  const [reportsSendTotalCount, setReportsSendTotalCount] = useState(0);

  return <Card>
    <CardContent >
      <Accordion>
        <EnhancedAccordionSummary
          ariaControls="blockedPlayers-list"
          id="blockedPlayers-header"
          title="Blocked players"
          totalCount={blockedPlayersTotalCount}
          secondaryTitle={'items'}>
        </EnhancedAccordionSummary>
        <AccordionDetails>
          {<BlockedPlayersReportList player={player} onSetAccordionTotalCount={setBlockedPlayersTotalCount}/>}
        </AccordionDetails>
      </Accordion>
      <Accordion>
        <EnhancedAccordionSummary
          ariaControls="reportsReceived-list"
          id="reportsReceived-header"
          title="Reports of this player"
          totalCount={reportsReceivedTotalCount}
          secondaryTitle={'items'}>
        </EnhancedAccordionSummary>
        <ReportsList filter={{ player: player.id}} onSetAccordionTotalCount={setReportsReceivedTotalCount}/>
      </Accordion>
      <Accordion>
        <EnhancedAccordionSummary
          ariaControls="reportsSend-list"
          id="reportsSend-header"
          title="Reports sent by this player"
          totalCount={reportsSendTotalCount}
          secondaryTitle={'items'}>
        </EnhancedAccordionSummary>
        <ReportsList filter={{ reporter: player.id }} onSetAccordionTotalCount={setReportsSendTotalCount} />
      </Accordion>
    </CardContent>
  </Card>
}

export default PlayerReportsList
