import React from 'react'
import { connect } from 'react-redux'

import UserListView from './UserListView'
import RoleListView from './RoleListView'
import PermissionGridView from './PermissionGridView'
import TabsView from '../components/TabsView'
import ContentContainer from '../components/ContentContainer'

const UserManagementView = ({ currentGame }) => (
  <ContentContainer
    title='User Management'
  >
    <TabsView
      tabs={ [
        {
          label: 'Users',
          content: <UserListView game={currentGame} />,
        },
        {
          label: 'Roles',
          content: <RoleListView game={currentGame} />,
        },
        {
          label: 'Permissions',
          content: <PermissionGridView game={currentGame} />,
        },
      ] }
    />
  </ContentContainer>
)

UserManagementView.displayName = 'UserManagementView'

const mapStateToProps = state => ({
  currentGame: state.games.currentGame,
})

export default connect(mapStateToProps)(UserManagementView)

