import React from 'react'
import PropTypes from 'prop-types'
import { withStyles } from '@material-ui/core/styles'
import IconButton from '@material-ui/core/IconButton'
import DeleteIcon from '@material-ui/icons/Delete'
import Typography from '@material-ui/core/Typography'
import { get } from 'lodash'

import TextField from '../components/TextField'

const GameEnvironmentHeader = ({
  classes,
  envIndex,
  headerIndex,
  onDelete,
  getFieldProps,
  errors,
}) => {
  const fieldMapping = {
    key: `environments[${envIndex}].customHeaders[${headerIndex}].key`,
    value: `environments[${envIndex}].customHeaders[${headerIndex}].value`,
  }

  return (
    <div className={ classes.container }>
      <div className={ classes.counter }>
        <Typography>
          { headerIndex + 1 }
        </Typography>
      </div>

      <div className={ classes.fieldsContainer }>
        <div className={ classes.row }>
          <TextField
            error={ !!get(errors, fieldMapping['key']) }
            errorMessage={ get(errors, fieldMapping['key']) }
            label='Name'
            required
            { ...getFieldProps(fieldMapping['key']) }
          />
        </div>

        <div className={ classes.row }>
          <TextField
            error={ !!get(errors, fieldMapping['value']) }
            errorMessage={ get(errors, fieldMapping['value']) }
            label='Value'
            multiline
            required
            { ...getFieldProps(fieldMapping['value']) }
          />
        </div>
      </div>

      <div className={ classes.actionContainer }>
        <IconButton
          variant='fab'
          onClick={ onDelete }
        >
          <DeleteIcon />
        </IconButton>
      </div>
    </div>
  )
}

GameEnvironmentHeader.propTypes = {
  classes: PropTypes.object.isRequired,
  envIndex: PropTypes.number.isRequired,
  headerIndex: PropTypes.number.isRequired,
  onDelete: PropTypes.func.isRequired,
  getFieldProps: PropTypes.func.isRequired,
  errors: PropTypes.object,
}

const styles = theme => ({
  container: {
    display: 'flex',
    flexDirection: 'row',
  },
  fieldsContainer: {
    flex: 1,
  },
  counter: {
    marginRight: theme.spacing(2),
  },
  button: {
    marginLeft: theme.spacing(1),
  },
  row: {
    display: 'flex',
    marginBottom: theme.spacing(2),
    flexDirection: 'column',
  },
})

export default withStyles(styles)(GameEnvironmentHeader)
