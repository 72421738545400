import React, { useState, useEffect, Fragment } from 'react'
import TableRow from '@material-ui/core/TableRow'
import Table from '@material-ui/core/Table'
import TableBody from '@material-ui/core/TableBody'
import TableCell from '@material-ui/core/TableCell'
import Checkbox from '@material-ui/core/Checkbox'
import Toolbar from '@material-ui/core/Toolbar'
import FormControlLabel from '@material-ui/core/FormControlLabel'
import Switch from '@material-ui/core/Switch'
import { makeStyles } from '@material-ui/core'
import { useNotify, useVersion } from 'react-admin'

import {
  fetchJson,
  fetchJsonPost,
} from '../utils/fetch'
import LoadingIndicator from '../components/LoadingIndicator'

const rolePermissionName = (role, permissionName) => {
  if (!role.game) return permissionName
  return `${permissionName}@${role.game}`
}

const roleHasPermission = (role, permission) => {
  if (role.name === 'SuperAdmin') return true
  if (!role.permissions) return false
  return role.permissions.includes(rolePermissionName(role, permission.name))
}

const useStyles = makeStyles(theme => ({
  actions: {
    color: theme.palette.text.secondary,
    flex: '1 1 100%',
    justifyContent: 'flex-end',
    display: 'flex',
  },
  groupName: {
    fontWeight: 'bold',
  },
  nameCell: {
    width: 500,
    verticalAlign: 'bottom',
    paddingTop: '1.5em',
    paddingBottom: '1.5em',
  },
  roleCell: {
    width: 10,
    paddingBottom: '2.5em',
    height: 100,
    whiteSpace: 'nowrap',
  },
  roleName: {
    fontWeight: 'bold',
    transform: 'translate(15px, 51px) rotate(-30deg)',
    width: 30,
    cursor: 'pointer',
  },
}))

const PermissionGridView = ({
  game,
}) => {
  const classes = useStyles()
  const notify = useNotify()
  const version = useVersion()
  const [editMode, setEditMode] = useState(false)
  const [permissions, setPermissions] = useState(null)
  const [roles, setRoles] = useState(null)
  const [loading, setLoading] = useState(false)

  const loadData = () => {
    const promises = [
      fetchJson('api/permissions'),
      fetchJson('api/roles?includePermissions=true'),
    ]

    setLoading(true)

    Promise.all(promises)
      .then(([permissions, roles]) => {
        setPermissions(permissions.json)
        setRoles(roles.json)
      })
      .catch(error => {
        notify(error.message, 'warning')
      })
      .finally(() => {
        setLoading(false)
      })
  }

  useEffect(loadData, [version, notify])

  const handleEditModeChange = () => setEditMode(!editMode)
  const handleCheckChange = (role, permissionName, checked) => {
    role.permissions = role.permissions || []

    if (checked) {
      role.permissions.push(rolePermissionName(role, permissionName))
    } else {
      const index = role.permissions.indexOf(rolePermissionName(role, permissionName))
      if (index >= 0) {
        role.permissions.splice(index, 1)
      }
    }

    setRoles([ ...roles ])

    fetchJsonPost(`api/roles/${role.id}/permission`, {
        permission: permissionName,
        set: checked,
      })
      .catch(error => {
        // TODO: Undo check in case or error.
        notify(error.message || 'Failed to update role permission', 'warning')
      })
  }

  const renderRolesHeader = roles => (
    <Fragment>
      {
        roles.map(r => (
          <TableCell
            className={ classes.roleCell }
            key={ r.id }
          >
            <div className={ classes.roleName }>
              <span>
                {r.combinedName + (r.environment ? '-' + r.environment : '')}
              </span>
            </div>
          </TableCell>
        ))
      }
      <TableCell />
    </Fragment>
  )

  const renderGroup = (groupName, permissions, index, roles) => {
    const colsCount = roles.length + 2

    return (
      <Fragment key={ index }>
        <TableRow>
          <TableCell
            className={ classes.nameCell }
            colSpan={ index === 0 ? 1 : colsCount }
          >
            <span className={ classes.groupName }>
              { groupName }
            </span>
          </TableCell>
          { index === 0 && renderRolesHeader(roles) }
        </TableRow>

        { permissions.map(p => (
          <TableRow key={ p.name }>
            <TableCell className={ classes.nameCell }>
              { p.name }
            </TableCell>

            { roles.map(r => (
              <TableCell key={ r.id }>
                <Checkbox
                  disabled={ !editMode || r.name === 'SuperAdmin' }
                  checked={ roleHasPermission(r, p) }
                  onChange={ event => handleCheckChange(r, p.name, event.target.checked) }
                  color='primary'
                  value={ p.name }
                />
              </TableCell>
            ))}
            <TableCell />
          </TableRow>
        ))}
      </Fragment>
    )
  }

  if (loading) {
    return (
      <LoadingIndicator />
    )
  }

  if (!permissions || !roles) {
    return null
  }

  let baseRoles = roles.filter(role => !role.environment && (!game || role.game === game.id))
  return (
    <Fragment>
      <Toolbar>
        <div className={ classes.actions }>
          <FormControlLabel
            control={ (
              <Switch
                checked={ editMode }
                onChange={ handleEditModeChange }
                value='editMode'
                color='primary'
              />
) }
            label='Edit Mode'
          />
        </div>
      </Toolbar>
      <Table>
        <TableBody>
          {
            Object.keys(permissions).map((groupName, index) => (
              renderGroup(groupName, permissions[groupName], index, baseRoles)
            ))
          }
        </TableBody>
      </Table>
    </Fragment>
  )
}

PermissionGridView.displayName = 'PermissionGridView'

export default PermissionGridView
