import React from 'react'
import { ListBase, Datagrid, TextField, NumberField, useListContext } from 'react-admin'
import { Card, CardContent } from '@material-ui/core'
import InfinitePagination from '../components/InfinitePagination'
import { describeError } from '../utils/errors'

/** @typedef {import('admintool-api/v1/map').Map} Map */

const EmptyOrErrorReport = () => {
  var message = null

  const ctx = useListContext()
  if (ctx?.error) message = describeError(ctx.error)
  else if (!ctx.loading && !ctx?.ids?.length) message = 'This map has no guilds'

  if(!message) return null
  return <CardContent><p>{message}</p></CardContent>
}

/**
 * @param {{map: Map}} props
 */
const MapGuildWidget = ({ map, ...rest }) => {
  return <ListBase {...rest} resource='guilds' filter={{mapId: map.id}}>
    <Card>
      <Datagrid basePath='/guilds' rowClick='show'>
        <TextField source='id' label='ID' />
        <TextField source='name' label='Name' />
        <TextField source='shortName' label='Short name' />
        <NumberField source='memberCount' label='Members' />
      </Datagrid>
      <InfinitePagination />
      <EmptyOrErrorReport />
    </Card>
  </ListBase>
}

export default MapGuildWidget
