import React, { Component } from 'react'
import PropTypes from 'prop-types'
import Paper from '@material-ui/core/Paper'
import MenuItem from '@material-ui/core/MenuItem'
import Downshift from 'downshift'
import { withStyles } from '@material-ui/core/styles'
import Chip from '@material-ui/core/Chip'
import CircularProgress from '@material-ui/core/CircularProgress'
import ErrorIcon from '@material-ui/icons/Error'
import { debounce as _debounce } from 'lodash'

import TextField from './TextField'

class Autocomplete extends Component {

  constructor(props) {
    super(props)
    this.debounceInputChange = _debounce(this.handleInputChangeCommit, props.debounce)
    this.state = {
      selectedItems: [],
      suggestions: [],
      inputValue: '',
      isLoading: false,
      errorMessage: null,
    }
  }

  handleKeyDown = event => {
    const {
      selectedItems,
      inputValue,
    } = this.state

    if (selectedItems.length && !inputValue.length && event.key === 'Backspace') {
      const items = selectedItems.slice(0, selectedItems.length - 1)
      this.setState({ selectedItems: items }, this.fireOnChange)
    }
  }

  handleInputChange = event => {
    const {
      multiple,
    } = this.props
    const text = event.target.value

    if (!multiple && !text) {
      this.setState({ selectedItems: [] }, this.fireOnChange)
    }
    this.setState({
      isLoading: true,
      suggestions: [],
      inputValue: text,
      errorMessage: null,
    }, this.debounceInputChange)
  }

  handleInputChangeCommit = () => {
    const {
      getSuggestions,
    } = this.props
    const {
      inputValue,
    } = this.state

    if (!inputValue) {
      this.setState({ isLoading: false, suggestions: [] })
      return
    }

    const s = getSuggestions(inputValue)
    Promise.resolve(s).then(
      (suggestions) => {
        if (!suggestions) {
          suggestions = []
        }
        this.setState({ isLoading: false, suggestions: suggestions })
      },
      (error) => {
        console.log(error)
        this.setState({ isLoading: false, suggestions: [], errorMessage: error })
      })
  }

  fireOnChange = () => {
    const {
      multiple,
      onChange,
    } = this.props
    const {
      selectedItems,
    } = this.state

    if (onChange) {
      onChange(multiple ? selectedItems : selectedItems[0])
    }
  }

  handleChange = item => {
    const {
      multiple,
      getItemId,
      getItemLabel,
    } = this.props
    const {
      selectedItems,
    } = this.state

    if (!item) {
      this.setState({ inputValue: '', selectedItems: [] }, this.fireOnChange)
      return
    }

    let items = multiple ? [ ...selectedItems ] : []

    if (!items.find(it => getItemId(it) === getItemId(item))) {
      items.push(item)
    }
    this.setState({
      inputValue: multiple ? '' : getItemLabel(items[0]),
      selectedItems: items,
    }, this.fireOnChange)
  }

  handleDelete = item => {
    const {
      getItemId,
    } = this.props
    const {
      selectedItems,
    } = this.state

    const items = [ ...selectedItems ]
    const index = items.findIndex(it => getItemId(it) === getItemId(item))
    items.splice(index, 1)
    this.setState({
      selectedItems: items,
    }, this.fireOnChange)
  }

  renderSuggestion = ({
    suggestion,
    index,
    itemProps,
    highlightedIndex,
  }) => {
    const {
      getItemLabel,
      getItemId,
    } = this.props
    const {
      selectedItems,
    } = this.state

    const isHighlighted = highlightedIndex === index

    const itemLabel = getItemLabel(suggestion)
    const key = getItemId(suggestion)
    const isSelected = selectedItems.some(it => getItemId(it) === key)

    return (
      <MenuItem
        { ...itemProps }
        key={ key }
        selected={ isHighlighted }
        component='div'
        style={{
          fontWeight: isSelected ? 800 : 400,
        }}
      >
        { itemLabel }
      </MenuItem>
    )
  }

  render() {
    const {
      selectedItems,
      inputValue,
      isLoading,
      suggestions,
      errorMessage,
    } = this.state
    const {
      multiple,
      textFieldProps: {
        placeholder,
        InputProps,
        ...otherTextFieldProps
      },
      getItemId,
      getItemLabel,
      classes,
      required,
    } = this.props

    const inputProps = {
      onChange: this.handleInputChange,
      onKeyDown: this.handleKeyDown,
      placeholder: selectedItems.length ? '' : placeholder,
      ...InputProps,
    }

    if (multiple) {
      inputProps.startAdornment = selectedItems.map(item => (
        <Chip
          key={ getItemId(item) }
          tabIndex={ -1 }
          label={ getItemLabel(item) }
          className={ classes.chip }
          onDelete={ () => this.handleDelete(item) }
        />
      ))
    }

    if (isLoading) {
      inputProps.endAdornment = (
        <CircularProgress size={ 20 } />
      )
    }
    if (errorMessage) {
      inputProps.endAdornment = (
        <div>
          <ErrorIcon color="error" /> {errorMessage}
        </div>
      )
    }

    return (
      <Downshift
        inputValue={ inputValue }
        onChange={ this.handleChange }
        selectedItem={ selectedItems }
        itemToString={ getItemLabel }
        defaultHighlightedIndex={ 0 }
      >
        {({
          getInputProps,
          getItemProps,
          isOpen,
          highlightedIndex,
        }) => (
          <div className={classes.container}>
            <TextField
              fullWidth
              InputProps={getInputProps(inputProps)}
              required={required}
              { ...otherTextFieldProps }
            />

            { isOpen ? (
              <Paper
                className={ classes.paper }
                square
              >
                { suggestions.map((suggestion, index) =>
                  this.renderSuggestion({
                    suggestion,
                    index,
                    itemProps: getItemProps({
                      item: suggestion,
                    }),
                    highlightedIndex,
                  }),
                )}
              </Paper>
            ) : null}
          </div>
        )}
      </Downshift>
    )
  }
}

Autocomplete.displayName = 'Autocomplete'
Autocomplete.propTypes = {
  classes: PropTypes.object.isRequired,
  multiple: PropTypes.bool,
  textFieldProps: PropTypes.object,
  getItemId: PropTypes.func.isRequired,
  getItemLabel: PropTypes.func.isRequired,
  debounce: PropTypes.number,
  getSuggestions: PropTypes.func.isRequired,
  onChange: PropTypes.func.isRequired,
}

Autocomplete.defaultProps = {
  textFieldProps: {},
  debounce: 100,
}

const styles = theme => ({
  container: {
    flexGrow: 1,
    position: 'relative',
  },
  chip: {
    margin: theme.spacing(0.5, 0.25),
  },
  paper: {
    position: 'absolute',
    zIndex: 1,
    marginTop: theme.spacing(1),
    left: 0,
    right: 0,
  },
})

export default withStyles(styles)(Autocomplete)

export function suggestItems(itemTypes, searchQuery) {
  const max = Math.max(8, searchQuery.length * 3);
  const terms = searchQuery.split(' ').filter(term => term.length > 0)

  if (terms.length === 0) {
    return []
  }

  let suggestions = itemTypes.filter(type => type.name.toLowerCase().indexOf(terms[0].toLowerCase()) === 0)
  for (let term of terms) {
    term = term.toLowerCase()
    suggestions = suggestions.filter(type => type.name.toLowerCase().indexOf(term) >= 0)
  }
  suggestions.sort((a, b) => a.name.toLowerCase().localeCompare(b.name.toLowerCase()))

  if (suggestions.length < max) {
    let others = itemTypes.filter(type => type.name.toLowerCase().indexOf(terms[0].toLowerCase()) > 0)
    for (let term of terms) {
      term = term.toLowerCase()
      others = others.filter(type => type.name.toLowerCase().indexOf(term) >= 0)
    }
    others.sort((a, b) => a.name.toLowerCase().localeCompare(b.name.toLowerCase()))
    suggestions.splice(suggestions.length, 0, ...others)
  }

  return suggestions.slice(0, max)
}
