import React, { useState } from 'react'
import { Button, Grid, makeStyles } from '@material-ui/core'
import { Datagrid, DeleteButton, ListBase, ReferenceField, TextField, useCreate, useRefresh } from 'react-admin'
import ClearIcon from '@material-ui/icons/Clear'
import SelectPlayerDropdown from '../players/SelectPlayerDropdown'
import InfinitePagination from '../components/InfinitePagination'
import { describeError } from '../utils/errors'

const useStyles = makeStyles(theme => ({
  muteArea: {
    margin: theme.spacing(1),
  },
}))

const ChannelMutedPlayers = ({ id, raProps }) => {
  const refresh = useRefresh()
  const classes = useStyles()
  const [muted, setMuted] = useState(null)
  const [mutePlayer, { loading: muteInProcess, error: muteError }] = useCreate('messaging/ban',
    {
      player: muted?.id,
      bannedFrom: 'CHANNEL',
      channel: id,
      visibility: 'VISIBLE',
    },
    {
      onSuccess: () => {
        setMuted(null)
        refresh()
      },
    })

  return <ListBase
    {...raProps}
    resource='messaging/ban'
    filter={{ channel: id }}
  >
    <Grid container spacing={2} className={classes.muteArea}>
      <Grid item>
        <SelectPlayerDropdown account={muted} setAccount={setMuted} label='Account to mute' />
      </Grid>
      <Grid item>
        <Button
          color='primary'
          variant='outlined'
          disabled={!muted || muteInProcess}
          onClick={mutePlayer}
        >
          Mute player
        </Button>
      </Grid>
    </Grid>
    {muteError && <p>Muting failed: {describeError(muteError)}</p>}
    <Datagrid resource='messaging/ban'>
      <ReferenceField label='Muted player' source='player' reference='accounts' link='show'>
        <TextField source='publicName' />
      </ReferenceField>
      <DeleteButton
        label='Unmute'
        icon={<ClearIcon />}
        redirect={() => `/messaging/channel/${id}/show/muted-players`}
      />
    </Datagrid>
    <InfinitePagination />
  </ListBase>
}

export default ChannelMutedPlayers
