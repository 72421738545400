import React, { useEffect } from 'react'
import Grid from '@material-ui/core/Grid'
import Typography from '@material-ui/core/Typography'
import Button from '@material-ui/core/Button'
import Card from '@material-ui/core/Card'
import CardMedia from '@material-ui/core/CardMedia'
import CardContent from '@material-ui/core/CardContent'
import CardActions from '@material-ui/core/CardActions'
import { makeStyles } from '@material-ui/core/styles'
import {
  Authenticated,
  Layout,
  Link,
  Title,
  getResources, registerResource, unregisterResource,
  useGetList
} from 'react-admin'
import { connect, useSelector } from 'react-redux'
import RecentActors from '@material-ui/icons/RecentActors'
import Sports from '@material-ui/icons/Games'

import { switchGame } from '../redux/actions'
import LoadingIndicator from '../components/LoadingIndicator'
import { hasPermission, useCurrentUser } from '../utils/auth'
import { GlobalAppBar } from './AppBar'
import { updateStoredGames } from '../utils/games'

const placeholderIcons = [
  '/blob/s3/icons/a13100ba-c351-4aeb-a404-51f0869b5a6c-pink-hatchling.jpg',
  '/blob/s3/icons/f4efa01a-c7ca-424c-8e7f-2864f15b59e6-green-hatchling.jpg',
  '/blob/s3/icons/45009da2-59b7-4d5a-b37c-eec91fb04f89-white-hatchling.jpg',
]

const useCardStyles = makeStyles(theme => ({
  root: {
    width: '20rem',
  },
  icon: {
    height: '13rem',
  },
  envContainer: {
    height: '5rem',
    margin: theme.spacing(0, -1),
  },
  envItem: {
    margin: theme.spacing(1),
  },
}))

const GameCard = connect(
  null,
  { switchGame, registerResource, unregisterResource }
)(({
  game,
  resources,
  switchGame,
  registerResource,
  unregisterResource,
}) => {
  const classes = useCardStyles()

  function handleSwitchGame(env) {
    // Remove and re-register all resource definitions to ensure that the selected game opens up
    // with clean state, and does not show state from the previous game
    for (const r of resources) {
      unregisterResource(r.name)
      registerResource(r)
    }

    switchGame({
      id: game.id,
      env: env.name,
      beaconApp: env.beaconApp,
      beaconEnv: env.beaconEnv,
      config: JSON.parse(game.config || '{}'),
    })
  }

  // Use game icon if configured, or a placeholder picked in a random but stable manner
  let imageUrl = game.iconWebUrl || placeholderIcons[
    game.id.split('').reduce((acc, str) => (((acc << 5) - acc) + str.charCodeAt(0)) | 0, 0) % placeholderIcons.length]

  return <Card elevation={4} className={classes.root}>
    <CardMedia className={classes.icon} image={imageUrl} />
    <CardContent>
      <Typography variant='h6' component='h2'>
        {game.name}
      </Typography>
      <div className={classes.envContainer}>
        {game.environments.map(env => (
          <Button
            key={env.name}
            color='default'
            variant='contained'
            size='small'
            disableElevation
            className={classes.envItem}
            onClick={() => handleSwitchGame(env)}
          >
            {env.name}
          </Button>
        ))}
      </div>
    </CardContent>
  </Card>
})

const Empty = () => <div></div>

const GamesListLayout = props =>
  <Layout title='Select game' appBar={GlobalAppBar} menu={Empty} sidebar={Empty} {...props} />

const useListStyles = makeStyles(theme => ({
  emptyMessage: {
    width: '20rem',
  },
  gameList: {
    alignSelf: 'center',
    // Make gameList fit exactly 1, 2 or 3 columns of game cards, as many as will comfortably fit
    width: `calc(20rem + ${theme.spacing(4 * 1)}px)`,
    '@media (min-width: 45rem) and (max-width: 70rem)': {
      width: `calc(40rem + ${theme.spacing(4 * 2)}px)`,
    },
    '@media (min-width: 70rem)': {
      width: `calc(60rem + ${theme.spacing(4 * 3)}px)`,
    },
  },
  globalAction: {
    padding: theme.spacing(1),
    textAlign: 'center',
  },
  icon: {
    verticalAlign: 'middle',
  },
  bottomBar: {
    position: 'fixed',
    top: 'auto',
    bottom: 0,
    padding: theme.spacing(2),
  },
  bottomSpacing: {
    padding: theme.spacing(6),
  },
}))

const GamesList = ({
  ...rest
}) => {
  const currentUser = useCurrentUser()
  const games = useGetList('games')
  const resources = useSelector(getResources)
  const classes = useListStyles()
  useEffect(() => {
    if (!games.loading && !games.error) {
      try {
        updateStoredGames(Object.values(games.data))
      } catch (error) {
        console.warn('Error occurred while updating stored games', error)
      }
    }
  }, [games.loading, games.error, games.data])

  if (!games.loaded) return <LoadingIndicator />

  return (
    <GamesListLayout {...rest}>
      <Title title='Select game' />
      <Authenticated><>
        {!games.loading && Object.keys(games.data).length === 0 ?
          <Grid container
            direction='row'
            justify='center'
            alignContent='flex-start'
            spacing={2}
          >
            <Grid item>
              <Card className={classes.emptyMessage}>
                <CardContent>
                  <p>Looks like there are no games here.</p>
                  <p>You now have an account in this tool, and next someone from a game team needs
                  to give you access permissions to a game.</p>
                  <p>If this was unexpected or you have other questions, you can try contacting
                  the #hydra-admin-tool channel in Slack.</p>
                  <p>After your permissions have been updated, it is recommended that you log out
                  of this tool and then log back in again. This ensures that you can access all
                  features that should be accessible to you.</p>
                </CardContent>
              </Card>
            </Grid>
          </Grid>
          : null}
        <Grid container
          direction='row'
          justify='flex-start'
          alignContent='flex-start'
          spacing={4}
          className={classes.gameList}
        >
          {Object.values(games.data).map(game => (
            <Grid item key={game.id}>
              <GameCard game={game} resources={resources} />
            </Grid>
          ))}
        </Grid>
        <div className={classes.bottomSpacing}></div>
        <Grid container
          direction='row'
          justify='center'
          alignContent='flex-start'
          spacing={2}
          className={classes.bottomBar}
        >
          {hasPermission(currentUser, 'ManageUsers') ?
            <Grid item>
              <Card className={classes.globalAction}>
                <CardActions>
                  <Link to='/global/user-management'><RecentActors className={classes.icon} /> User Admin</Link>
                </CardActions>
              </Card>
            </Grid>
            : null}
          {hasPermission(currentUser, 'ManageGames') ?
            <Grid item>
              <Card className={classes.globalAction}>
                <CardActions>
                  <Link to='/global/game-management'><Sports className={classes.icon} /> Games Admin</Link>
                </CardActions>
              </Card>
            </Grid>
            : null}
        </Grid>
      </></Authenticated>
    </GamesListLayout>
  )
}

GamesList.displayName = 'GamesList'

export default GamesList
