import React, { useState } from 'react'
import {
  Button,
  TextField as MaterialTextField,
  useTheme,
} from '@material-ui/core'
import { makeStyles } from '@material-ui/styles'
import {
  Datagrid,
  ListContextProvider,
  ReferenceField,
  RichTextField,
  TextField,
  useGetList,
  useGetOne,
} from 'react-admin'
import moment from 'moment'
import TimestampField from '../components/TimestampField'
import { useListContextFromData } from '../utils/listings'
import { describeError } from '../utils/errors'
import { useHistory } from 'react-router'

const useStyles = makeStyles(theme => ({
  goToChannel: {
    textAlign: 'right',
    backgroundColor: theme.palette.background.paper,
    color: theme.palette.text.primary,
  },
  minutesTextArea: {
    width: '4em',
    verticalAlign: 'baseline',
  },
  channelButton: {
    margin: theme.spacing(1, 2, 1, 0),
  },
}))

const MessageContext = ({ id, channel, count = 10 }) => {
  const theme = useTheme()
  const history = useHistory()
  const classes = useStyles()
  const [additionalMinutes, setAdditionalMinutes] = useState(10)
  const pagination = { page: 1, perPage: count + 1 }
  const sort = { field: 'timestamp', order: 'DESC' }
  const filter = {
    messageId: id,
    beforeCount: Math.ceil(count / 2),
    afterCount: Math.floor(count / 2),
  }
  const context = useGetList('messaging/message', pagination, sort, filter)
  // Should be only needed as a temporary measure, for cases where context is not yet available
  const message = useGetOne('messaging/message', `${channel}/${id}`)

  const rowStyle = (record, _index) => {
    if (record.id === id) {
      return {
        backgroundColor: theme.palette.background.default,
      }
    }
    return {
      backgroundColor: theme.palette.background.paper,
    }
  }

  const handleGoToChannelView = () => {
    const timestamps = Object.values(context.data).map(m => moment(m.timestamp))
    console.log(timestamps)
    const messageTime = moment(message.data.timestamp)
    const extra = parseInt(additionalMinutes, 10)
    // Wrapping to extra `moment` is required, since add and subtract modify the object in-place
    // The time range lookup doesn't include endpoints, so add a fudge factor of 1 second
    const start = moment(moment.min(messageTime, ...timestamps))
      .subtract(extra, 'minutes').subtract(1, 'second')
    const end = moment(moment.max(messageTime, ...timestamps))
      .add(extra, 'minutes').add(1, 'second')
    const params = {
      rangeStart: start.toISOString(),
      rangeEnd: end.toISOString(),
    }
    history.push(`/messaging/channel/${message.data.channel}/show?${new URLSearchParams(params)}`)
  }

  const listContext = useListContextFromData('messaging/message', context)
  return <>
    {context.error && <p>Failed to fetch message context: {describeError(context.error)}</p>}
    {message.error && <p>Failed to fetch reported message: {describeError(message.error)}</p>}
    <ListContextProvider value={listContext}>
      <Datagrid rowStyle={rowStyle}>
        <TimestampField source='timestamp' label='Date / time' sortable={false} />
        <RichTextField source='message.html' label='Message' sortable={false} />
        <ReferenceField source='sender' reference='accounts' link='show' allowEmpty sortable={false}>
          <TextField source='publicName' />
        </ReferenceField>
        <TextField source='state' label='Status' sortable={false} />
      </Datagrid>
    </ListContextProvider>
    {message?.data?.timestamp ?
      <div className={classes.goToChannel}>
        View with { }
        <MaterialTextField
          size='small'
          variant='outlined'
          value={additionalMinutes}
          onChange={event => setAdditionalMinutes(event.target.value)}
          className={classes.minutesTextArea}
        /> { }
      minutes extra { }
        <Button
          variant='outlined' color='primary'
          className={classes.channelButton}
          onClick={handleGoToChannelView}
        >
          Channel view
      </Button>
      </div>
      :
      null
    }
  </>
}

export default MessageContext
