import React, { Fragment } from 'react'
import PropTypes from 'prop-types'
import { Confirm } from 'react-admin'
import Button from '@material-ui/core/Button'

const PlayerUnbanButton = ({
  player,
  banId,
  onConfirm,
}) => {
  const [isOpen, setIsOpen] = React.useState(false)

  const handleClick = () => {
    setIsOpen(true)
  }

  const handleDialogClose = () => {
    setIsOpen(false)
  }

  const handleConfirm = () => {
    onConfirm(player, banId).finally(() => {
      setIsOpen(false)
    })
  }

  return (
    <Fragment>
      <Button
        color='primary'
        onClick={ handleClick }
      >
        Remove
      </Button>
      <Confirm
        isOpen={ isOpen }
        title='Unban player'
        content={ `Are you sure you want to remove a ${player.activeBans[banId].type} ban from this player (${player.publicName})?` }
        onConfirm={ handleConfirm }
        onClose={ handleDialogClose }
      />
    </Fragment>
  )
}

PlayerUnbanButton.propTypes = {
  player: PropTypes.object,
  onConfirm: PropTypes.func,
}

PlayerUnbanButton.displayName = 'PlayerUnbanButton'

export default PlayerUnbanButton
