import React from 'react'
import ReactDOM from 'react-dom'
import { BrowserRouter } from 'react-router-dom'
import { MuiThemeProvider, createMuiTheme } from '@material-ui/core/styles'
import * as serviceWorker from './registerServiceWorker'
import App from './App'
import blue from '@material-ui/core/colors/blue'
import deepPurple from '@material-ui/core/colors/deepPurple'
import { GoogleOAuthProvider } from '@react-oauth/google'
import { googleClientId } from './config/auth'

const theme = createMuiTheme({
  palette: {
    primary: {
      main: deepPurple[700],
    },
    secondary: {
      main: blue[500],
    }
  },
  props: {
    MuiButtonBase: {
      disableRipple: true,
    },
  },
  typography: {
    // To avoid depreciated warning
    useNextVariants: true,
  },
})

const WithProvider = () => (
  <BrowserRouter>
    <MuiThemeProvider theme={theme}>
      <GoogleOAuthProvider clientId={`${googleClientId}.apps.googleusercontent.com`}>
        <App />
      </GoogleOAuthProvider>
    </MuiThemeProvider>
  </BrowserRouter>
)

ReactDOM.render(<WithProvider />, document.getElementById('root'))

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: http://bit.ly/CRA-PWA
serviceWorker.unregister()

