import React from "react"
import { makeStyles } from "@material-ui/core/styles"
import AccordionSummary from "@material-ui/core/AccordionSummary"
import ExpandMoreIcon from "@material-ui/icons/ExpandMore"
import PropTypes from "prop-types"

const useStyles = makeStyles((theme) => ({
  primaryTitle: {
    flex: "1 0 auto",
    color: theme.palette.primary.main,
  },

  secondaryTitle: {
    color: theme.palette.text.secondary,
  },
}));

const EnhancedAccordionSummary = ({
  id = "missing-id",
  "aria-controls": ariaControls = "missing-aria-controls",
  title,
  secondaryTitle,
  totalCount,
  filteredCount,
  isFiltering,
}) => {
  const classes = useStyles();

  const FilteredAndTotalSpan = () => {
    return (
      <span className={classes.secondaryTitle}>
        {totalCount
          ? `${filteredCount} / ${totalCount} ${secondaryTitle}`
          : `${filteredCount} / ${"?"} ${secondaryTitle}`
        }
      </span>
    );
  };

  const TotalOnlySpan = () => {
    return (
      <span className={classes.secondaryTitle}>
        {totalCount} {secondaryTitle}
      </span>
    );
  };

  return (
    <AccordionSummary
      aria-controls={ariaControls}
      id={id}
      aria-label="Expand"
      expandIcon={<ExpandMoreIcon />}
    >
      <span className={classes.primaryTitle}>{title}</span>
      {isFiltering ? FilteredAndTotalSpan() : TotalOnlySpan()}
    </AccordionSummary>
  );
};

EnhancedAccordionSummary.propTypes = {
  title: PropTypes.string.isRequired,
  id: PropTypes.string.isRequired,
};

EnhancedAccordionSummary.displayName = "EnhancedAccordionSummary";

export default EnhancedAccordionSummary;
