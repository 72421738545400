const env = process.env.NODE_ENV || 'local'

const restEndpoints = {
  local: 'api',
  development: 'api',
  test: 'api',
  production: 'api',
}

export const endpoint = restEndpoints[env]
