import React, { useState } from 'react'
import Button from '@material-ui/core/Button'
import Dialog from '@material-ui/core/Dialog'
import DialogActions from '@material-ui/core/DialogActions'
import DialogContent from '@material-ui/core/DialogContent'
import DialogTitle from '@material-ui/core/DialogTitle'
import FilledInput from '@material-ui/core/FilledInput'
import FormControl from '@material-ui/core/FormControl'
import Tooltip from '@material-ui/core/Tooltip'
import { makeStyles } from '@material-ui/core/styles'
import {
  ArrayInput,
  FormWithRedirect,
  SaveButton,
  SimpleFormIterator,
  required,
  useMutation,
} from 'react-admin'
import RichTextInput from 'ra-input-rich-text'
import CancelButton from '../components/CancelButton'
import CheckCircleIcon from '@material-ui/icons/CheckCircle'
import DoneIcon from '@material-ui/icons/Done'
import ErrorIcon from '@material-ui/icons/Error'
import SendIcon from '@material-ui/icons/Send'
import { describeError } from '../utils/errors'
import { get } from 'lodash'
import { useField } from 'react-final-form'

const ChannelInput = ({ source, state, className }) => {
  const {
    input,
    meta: { touched, error }
  } = useField(source);
  const result = get(state, input.value)

  return <div className={className}>
    <FormControl hiddenLabel>
      <FilledInput margin='dense'
        inputProps={{ 'aria-label': 'name' }}
        {...input}
        error={!!(touched && error)} />
    </FormControl>
    {
      result === 'ok' ? <Tooltip title='Success'><CheckCircleIcon /></Tooltip> :
      result ? <Tooltip title={describeError(result)}><ErrorIcon color='error' /></Tooltip> :
      null
    }
  </div>
}

const useStyles = makeStyles(theme => ({
  dialog: {
    '& .ql-editor': {
      minHeight: '6rem',
    }
  },
  channelsIndex: {
    display: 'none',
  },
  listItem: {
    margin: theme.spacing(1),
    display: 'flex',
    alignItems: 'center',
  },
}))

const SendMessageDialog = ({ channels, onClose }) => {
  const classes = useStyles()
  const [channelState, setChannelState] = useState({})
  const [send, { loading: sending }] = useMutation()

  const sendMessages = (values) => {
    console.log('Sending...', values)
    const message = {
      message: {
        html: values.message,
      },
    }
    const recipients = values.channels.map(ch => ch.name)
    for (const c of recipients) {
      message.channel = c
      send(
        {
          type: 'create',
          resource: `messaging/message/${c}`,
          payload: {
            data: message,
          }
        },
        {
          onSuccess: () => {
            setChannelState(s => ({ ...s, [c]: 'ok' }))
          },
          onFailure: (error) => {
            setChannelState(s => ({ ...s, [c]: error }))
          }
        })
    }
  }

  const initialValues = {
    channels: channels.map(ch => ({ name: ch })),
  }
  return <FormWithRedirect
    save={sendMessages}
    saving={sending}
    initialValues={initialValues}
    render={formProps =>
      <Dialog open onClose={onClose} className={classes.dialog}>
        <DialogTitle>Send chat message</DialogTitle>

        <DialogContent>
          <ArrayInput source='channels'>
            <SimpleFormIterator classes={{ index: classes.channelsIndex }} >
              <ChannelInput source='name' state={channelState} className={classes.listItem} />
            </SimpleFormIterator>
          </ArrayInput>
          <RichTextInput
            source='message'
            label='Message'
            fullWidth
            toolbar={[['bold', 'italic', 'underline', 'strike', { 'color': [] }, 'link']]}
            validate={required()} />
        </DialogContent>

        <DialogActions>
          <CancelButton onClick={onClose} />
          <SaveButton
            submitOnEnter
            label='Send message'
            saving={formProps.saving}
            handleSubmitWithRedirect={formProps.handleSubmitWithRedirect}
            icon={<SendIcon />} />
          {Object.keys(channelState).length > 0 && !formProps.saving ?
            <Button
              variant='contained'
              color='primary'
              startIcon={<DoneIcon />}
              onClick={onClose}
            >
              Close
            </Button> :
            null
          }
        </DialogActions>
      </Dialog>
    }
  />
}

export default SendMessageDialog
