import { useCallback } from 'react'
import { useNotify } from 'react-admin'

export function describeError(error) {
  if (error) {
    if (typeof(error) === 'string') return error
    if (error.body) {
      if (error.body.type === 'http://dev.battle-admin.rovio.dev/game-server-error') {
        // This is a proxied error from the game server
        try {
          // The server response might be JSON? => Likely contains a message
          const response = JSON.parse(error.body.response)
          return `Error from game server: ${response.message} (status ${error.body.status})`
        } catch {
          // Server response was not JSON, so maybe plain text?
          return `Error from game server: ${error.body.response} (status ${error.body.status})`
        }
      }
      if (typeof error.body === 'string') {
        return `Error occurred: "${error.body}"`
      }
    }
    if (error.message) {
      return `Error occurred: ${error.message}`
    }
  }
  return "An error occurred, no details available"
}

export function useDisplayError() {
  const notify = useNotify()
  return useCallback((error) => {
    console.error('useDisplayError with error %o, body %o', error, error ? error.body : null)
    const message = describeError(error)
    notify(message, 'error')
  }, [notify])
}
