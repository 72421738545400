import { call, put, all, takeLatest } from 'redux-saga/effects'
import { crudUpdate, crudCreate } from 'react-admin'

const CRUD_FORM_SAVE = 'CRUD_FORM_SAVE'

function* saveHandler({ payload: { values, basePath, redirect, isEdit, handler, resource } }) {
  const modifiedValues = { ...values }

  // Call the provided handleSubmit handler, which may return a promise.
  yield call(handler, modifiedValues, isEdit)

  // Then dispatch (or yield) an action accordingly.
  if (isEdit) {
    yield put(crudUpdate(resource, modifiedValues.id, modifiedValues, values, basePath, redirect))
  } else {
    yield put(crudCreate(resource, modifiedValues, basePath, redirect))
  }
}

export default function* saveHandlerSaga() {
  // Watch for all dispatched CRUD_FORM_SAVE actions.
  yield all([
    takeLatest(CRUD_FORM_SAVE, saveHandler),
  ])
}
