import React, { useState } from 'react'
import { Button, Card, CardContent, Divider, makeStyles } from '@material-ui/core'
import {
  FormWithRedirect,
  required,
  SaveButton,
  TextInput,
  useGetOne,
  useMutation,
  useRefresh,
} from 'react-admin'
import { Alert } from '@material-ui/lab'
import SendIcon from '@material-ui/icons/Send'

import { describeError } from '../utils/errors'
import { useCurrentGame } from '../utils/games'
import TimestampText from '../components/TimestampText'

const MaintenanceAlert = ({ ready, maintenance, message, startTime, error }) => {
  if (error) {
    return <Alert severity='warning'>Failed to check status: {describeError(error)}</Alert>
  }
  if (ready) {
    if (maintenance) {
      return <Alert severity='warning'>Maintenance mode is <b>ON</b>
        {startTime ? <> since <TimestampText time={startTime} /></> : null}
        {message ? `: ${message}` : null}
      </Alert>
    } else {
      return <Alert severity='info'>Maintenance mode is <b>OFF</b></Alert>
    }
  }
  return <Alert severity='info'>Checking maintenance status...</Alert>
}

const useFormStyles = makeStyles(theme => ({
  body: {
    margin: theme.spacing(2, 0),
  },
}))

const EnableMaintenanceForm = (props) => {
  const refresh = useRefresh()
  const classes = useFormStyles()
  const [send, { loading }] = useMutation()
  const [error, setError] = useState(null)
  const [confirmMode, setConfirmMode] = useState(null)
  const currentGame = useCurrentGame()

  const initialValues = {
    message: 'Server maintenance.',
  }

  const handleSave = (values) => {
    setError(null)
    send(
      {
        type: 'create',
        resource: 'environment/maintenance/start',
        payload: {
          data: { message: values.message },
        },
      },
      {
        onSuccess: refresh,
        onFailure: setError,
      }
    )
  }

  return <FormWithRedirect {...props}
    save={handleSave}
    saving={loading}
    initialValues={initialValues}
    render={formProps => {
      return <div className={classes.body}>
        <TextInput
          source='message'
          label='Maintenance message'
          multiline rows={3}
          fullWidth
          validate={required()}
        />
        <Button
          variant='contained'
          color='primary'
          startIcon={<SendIcon />}
          disabled={formProps.invalid || confirmMode}
          onClick={() => { setConfirmMode(true); setError(null) }}
        >
          Activate maintenance mode in&nbsp;<b>{currentGame.env}</b>
        </Button>
        {error && <Alert severity='error'>{describeError(error)}</Alert>}
        {confirmMode && <>
          <p>Really activate? No-one will be able to play during maintenance!</p>
          <SaveButton
            submitOnEnter
            label='Activate maintenance'
            icon={<SendIcon />}
            saving={formProps.saving}
            handleSubmitWithRedirect={formProps.handleSubmitWithRedirect}
          />
        </>}
      </div>
    }}
  />
}

const DisableMaintenanceForm = (props) => {
  const refresh = useRefresh()
  const classes = useFormStyles()
  const [send, { loading }] = useMutation()
  const [error, setError] = useState(null)
  const [confirmMode, setConfirmMode] = useState(null)
  const currentGame = useCurrentGame()

  const initialValues = {}

  const handleSave = (_values) => {
    setError(null)
    send(
      {
        type: 'create',
        resource: 'environment/maintenance/end',
        payload: {
          data: {},
        },
      },
      {
        onSuccess: refresh,
        onFailure: setError,
      }
    )
  }

  return <FormWithRedirect {...props}
    save={handleSave}
    saving={loading}
    initialValues={initialValues}
    render={formProps => {
      return <div className={classes.body}>
        <Button
          variant='contained'
          color='primary'
          startIcon={<SendIcon />}
          disabled={formProps.invalid || confirmMode}
          onClick={() => { setConfirmMode(true); setError(null) }}
        >
          End maintenance mode in&nbsp;<b>{currentGame.env}</b>
        </Button>
        {error && <Alert severity='error'>{describeError(error)}</Alert>}
        {confirmMode && <>
          <p>Make sure the whole maintenance has been completed!</p>
          <SaveButton
            submitOnEnter
            label='End maintenance'
            icon={<SendIcon />}
            saving={formProps.saving}
            handleSubmitWithRedirect={formProps.handleSubmitWithRedirect}
          />
        </>}
      </div>
    }}
  />
}

const ServerMaintenance = (props) => {
  var status = useGetOne('environment', 'status')

  return <Card>
    <CardContent>
      <MaintenanceAlert
        ready={status.loaded}
        maintenance={status.data?.maintenance}
        message={status.data?.maintenanceMessage}
        startTime={status.data?.maintenanceStart}
        error={status.error}
      />
      {status.error && <>
        <p>The current server status is unknown due to the above error. You can still use the forms
        below to attempt to enable or disable the maintenance mode. However, depending on the
        error details, they may or may not work.
        </p>
        <Divider />
        <EnableMaintenanceForm {...props} />
        <Divider />
        <DisableMaintenanceForm {...props} />
      </>}
      {status.loaded && !status.data?.maintenance && <EnableMaintenanceForm {...props} />}
      {status.loaded && status.data?.maintenance && <DisableMaintenanceForm {...props} />}
    </CardContent>
  </Card>
}

export default ServerMaintenance
