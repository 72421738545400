import React from 'react'
import PropTypes from 'prop-types'
import { withStyles } from '@material-ui/core/styles'
import Drawer from '@material-ui/core/Drawer'

const DetailsDrawer = ({ open, onClose, classes, children, width = 400 }) => {
  return (
    <Drawer
      anchor='right'
      open={ open }
      onClose={ onClose }
    >
      <div className={ classes.container } style={{width: width}}>
        { children }
      </div>
    </Drawer>
  )
}

DetailsDrawer.propTypes = {
  classes: PropTypes.object.isRequired,
  open: PropTypes.bool,
  onClose: PropTypes.func.isRequired,
}

const styles = {
  container: {
    width: 400,
    padding: 30,
  },
}

export default withStyles(styles)(DetailsDrawer)
