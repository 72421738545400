import React from 'react'
import { useField } from 'react-final-form';
import Autocomplete from '@material-ui/lab/Autocomplete'
import { TextField } from '@material-ui/core';

const TextWithSuggestionsInput = ({ source, label, choices=[], onChange: customOnChange }) => {
  const {
    input: { onChange: fieldOnChange, value },
    meta: { touched, error }
  } = useField(source);

  return <Autocomplete
    freeSolo
    selectOnFocus handleHomeEndKeys
    options={choices}
    value={value}
    onInputChange={(_, value) => {
      fieldOnChange(value)
      if (customOnChange) customOnChange(value)
    }}
    renderInput={(params) =>
      <TextField
        {...params}
        variant='filled' size='small'
        label={label}
        error={touched && !!error}
        helperText={error}
      />
    }
  />
}

export default TextWithSuggestionsInput