import React, { Fragment } from 'react'
import Card from '@material-ui/core/Card'
import CardContent from '@material-ui/core/CardContent'
import {
  ShowController,
  Title,
} from 'react-admin'
import { makeStyles } from '@material-ui/core/styles'

import GuildShowHeader from './GuildShowHeader'
import GuildInfoWidget from './GuildInfoWidget'
import GuildMembersWidget from './GuildMembersWidget'
import GuildChatWidget from './GuildChatWidget'
import TabsView from '../components/TabsView'
import AuditlogListView from '../audit-log/AuditlogListView'
import { useGameFeatures } from '../utils/games'
import LeaderboardDatagrid from '../leaderboard/LeaderboardDatagrid'
import WithPermission from '../components/WithPermission'
import GuildSeasonsWidget from './GuildSeasonsWidget'
import GuildResearch from './GuildResearch'
import UserStatusGroupIcon from '../components/UserStatusGroupIcon'

const useStyles = makeStyles(theme => ({
  header: {
    marginBottom: theme.spacing(2),
  },
  logs: {
    marginTop: theme.spacing(2),
  },
}))

const GuildShow = (props) => {
  const gameFeatures = useGameFeatures()
  const classes = useStyles()

  return <ShowController {...props}>
    {({ record, ...rest }) => {
      if (!record) {
        return null
      }

      return (
        <Fragment>
          <Title title={record.name} />
          <div className={classes.header}>
            <GuildShowHeader guild={record} />
          </div>

          <div style={{ right: '36px', top: '66.84px', position: 'fixed', zIndex: 1499 }}>
            <UserStatusGroupIcon basePath={"guilds/" + record.id + "/show"} />
          </div>

          <TabsView
            tabs={[
              {
                label: 'Guild info',
                content: <GuildInfoWidget guild={record} />,
              },
              {
                label: 'Members',
                content: <GuildMembersWidget guild={record} {...rest} />,
              },
              /*{
                label: 'Logs',
              },*/
              gameFeatures['messaging/chat'] && {
                label: 'Chat',
                content: <WithPermission
                  permissions='ViewChats'
                  fallback={<Card>
                    <CardContent>
                      You do not have permissions to view player chats. (permission ViewChats)
                    </CardContent>
                  </Card>}
                >
                  <GuildChatWidget guild={record} />
                </WithPermission>,
              },
              gameFeatures['seasons/show'] && {
                label: 'Seasons',
                content: <GuildSeasonsWidget guild={record} />,
              },
              gameFeatures['leaderboards'] && {
                label: 'Leaderboards',
                content: <LeaderboardDatagrid filter={{ guild: record.id }} />,
              },
              gameFeatures['guilds/research'] && {
                label: 'Research',
                content: <GuildResearch guild={record} />,
              },
            ]}
          />
          <div className={classes.logs}>
            <AuditlogListView
              entityId={record.id}
              entityType={"Guild"}
              title={null}
            />
          </div>
        </Fragment>
      )
    }}
  </ShowController>
}


GuildShow.displayName = 'GuildShow'

export default GuildShow
