import React from 'react'
import Grid from '@material-ui/core/Grid'
import Card from '@material-ui/core/Card'
import CardContent from '@material-ui/core/CardContent'
import Button from '@material-ui/core/Button'
import { makeStyles } from '@material-ui/core'

import ShowItem from '../components/ShowItem'

const useStyles = makeStyles(() => ({
  container: {
    maxWidth: 600,
    display: 'inline-block',
  },
  label: {
    width: 200,
    display: 'inline-block',
  },
  value: {
    width: 300,
    display: 'inline-block',
  },
}))

const MapInfoWidget = ({ map }) => {
  const classes = useStyles()

  return (
    <div className={ classes.root }>
      <Card>
        <CardContent>
          <Grid
            container
            spacing={ 4 }
          >
            <Grid
              container
              item
              xs={ 6 }
              className={ classes.container }
            >
              <ShowItem label='Map ID'>
                {map.id}
              </ShowItem>

              <ShowItem label='Map name'>
                { map.name }
                <Button disabled
                  color='primary'
                >
                  Edit
                </Button>
              </ShowItem>

              <ShowItem label='Design'>
                {map.mapDesign || '-'}
              </ShowItem>

              <ShowItem label='Tier'>
                { map.tier || '-' }
              </ShowItem>

              <ShowItem label='Status'>
                { map.status || '-' }
              </ShowItem>

              <ShowItem label='Player count'>
                {map.players || '?'} / {map.maxPlayers || '?'}
              </ShowItem>

              <ShowItem label='Guild count'>
                {map.guilds || '?'} / {map.maxGuilds || '?'}
              </ShowItem>
            </Grid>

            <Grid
              container
              item
              xs={ 6 }
              className={ classes.container }
            >
              {Object.entries(map.locationCounts).map(([type, count]) => (
                <ShowItem key={type} label={type + ' count'}>{count}</ShowItem>
              ))}
            </Grid>
          </Grid>
        </CardContent>
      </Card>
    </div>
  )
}

MapInfoWidget.displayName = 'MapInfoWidget'

export default MapInfoWidget
