import React, { Fragment } from 'react'
import PropTypes from 'prop-types'
import * as moment from 'moment'

import DetailsDrawer from '../components/DetailsDrawer'
import DrawerItem from '../components/DrawerItem'
import ReactJson from 'react-json-view'

const PlayerActivityDrawer = ({
  selectedPlayerActivity,
  open,
  onClose,
}) => {

  return <DetailsDrawer
    open={open}
    onClose={onClose}
    width={600}
  >
    {Boolean(selectedPlayerActivity) && (
      <Fragment>
        <DrawerItem label='Date / Time'>
          {moment(selectedPlayerActivity.time).format('DD-MM-YYYY HH:mm:ss.SSS')}
        </DrawerItem>
        <DrawerItem label='Action'>
          {selectedPlayerActivity.action}
        </DrawerItem>
        <DrawerItem label='Payload'>
          <ReactJson
            name={false}
            displayDataTypes={false}
            src={selectedPlayerActivity}
          />
        </DrawerItem>
      </Fragment>
    )}
  </DetailsDrawer>
}

PlayerActivityDrawer.displayName = 'PlayerActivityDrawer'
PlayerActivityDrawer.propTypes = {
  playerActivity: PropTypes.object,
  open: PropTypes.bool,
  onClose: PropTypes.func.isRequired,
}

export default PlayerActivityDrawer
