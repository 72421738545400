import React, { Component } from 'react'
import PropTypes from 'prop-types'
import Select from '@material-ui/core/Select'
import MenuItem from '@material-ui/core/MenuItem'
import InputLabel from '@material-ui/core/InputLabel'
import FormControl from '@material-ui/core/FormControl'
import FormHelperText from '@material-ui/core/FormHelperText'

const ALL_VALUE = '__all__'

// NOTE: This implementation is due to a likely bug in material-ui for Select component.
// For some reason, refactor this to hooks doesn't seem to work so lets stick with it.
export default class SelectInput extends Component {
  constructor(props) {
    super(props)
    const defaultValue = props.includeAllItem ? ALL_VALUE : ''
    this.state = {
      currentValue: props.value || (props.multiple ? [] : defaultValue),
    }
  }

  static getDerivedStateFromProps(nextProps, prevState) {
    if (nextProps.value && nextProps.value !== prevState.currentValue) {
      return {
        currentValue: nextProps.value,
      }
    }
    return null
  }

  handleChange = event => {
    const { onChange } = this.props
    const value = event.target.value
    this.setState({ currentValue: value })
    onChange(event)
  }

  render() {
    const {
      label,
      items,
      includeAllItem,
      labelField,
      valueField,
      value: _unused1,
      className,
      error,
      errorMessage,
      onChange: _unused2,
      ...rest
    } = this.props
    const { currentValue } = this.state

    return (
      <FormControl
        className={ className }
        error={ error }
        required={ rest.required }
      >
        {label && <InputLabel>{label}</InputLabel>}
        <Select
          value={ currentValue || '' }
          onChange={ this.handleChange }
          { ...rest }
        >
          { includeAllItem && (
            <MenuItem value={ ALL_VALUE }>
              <em>All</em>
            </MenuItem>
          )}
          {
            items.map(item => (
              <MenuItem
                key={ item[valueField]}
                value={ item[valueField] }
              >
                { item[labelField] }
              </MenuItem>
            ))
          }
        </Select>
        { errorMessage && (
          <FormHelperText>
            { errorMessage }
          </FormHelperText>
        )}
      </FormControl>
    )
  }
}

SelectInput.propTypes = {
  label: PropTypes.string,
  includeAllItem: PropTypes.bool,
  items: PropTypes.array,
  labelField: PropTypes.string,
  valueField: PropTypes.string,
}

SelectInput.defaultProps = {
  includeAllItem: true,
  valueField: 'value',
  labelField: 'label',
}
