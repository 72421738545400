import React from 'react'
import CircularProgress from '@material-ui/core/CircularProgress'
import { withStyles } from '@material-ui/core/styles'

const LoadingIndicator = ({ classes }) => (
  <div className={ classes.loadingContainer }>
    <CircularProgress />
  </div>
)

LoadingIndicator.displayName = 'LoadingIndicator'

const styles = {
  loadingContainer: {
    padding: 50,
    display: 'flex',
    flex: 1,
    alignItems: 'center',
    justifyContent: 'center',
  },
}

export default withStyles(styles)(LoadingIndicator)
