import React from 'react'
import InfoIcon from '@material-ui/icons/Info'
import WarningIcon from '@material-ui/icons/Warning'
import HomeIcon from '@material-ui/icons/Home'
import BuildIcon from '@material-ui/icons/Build'
import purple from '@material-ui/core/colors/purple'
import red from '@material-ui/core/colors/red'
import green from '@material-ui/core/colors/green'

const EnvironmentIcon = ({ env }) => {
  switch (env) {
    case 'prod':
      return <WarningIcon style={{ color: red[700], verticalAlign: 'bottom' }} />
    case 'local':
      return <HomeIcon style={{ color: green[500], verticalAlign: 'bottom' }} />
    case 'development':
    case 'staging':
    case 'dev':
    case 'longdev':
    case 'stage':
      return <BuildIcon style={{ color: green[500], verticalAlign: 'bottom' }} />
    default:
      // Fall through
  }
  return <>
    <InfoIcon style={{ color: purple[500], verticalAlign: 'bottom' }} />
    Unidentified environment {env} -- Please ask Admin Tool team to fix this
  </>
}

export default EnvironmentIcon
