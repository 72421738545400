import React, { Fragment, useState, useEffect } from 'react'
import {
  useNotify,
} from 'react-admin'
import PropTypes from 'prop-types'
import moment from 'moment';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';

import DetailsDrawer from '../components/DetailsDrawer'
import DrawerItem from '../components/DrawerItem'
import ExternalLink from '../components/ExternalLink'
import {fetchJson} from "../utils/fetch";
import {getBeaconCalendarUrl} from "../utils/beacon";
import { useCurrentGame } from '../utils/games';

const PlayerEventDrawer = ({
  selectedPlayerEvent,
  open,
  onClose,
  player,
}) => {
  const currentGame = useCurrentGame()
  const [playerEvent, setPlayerEvent] = useState([])
  const notify = useNotify()

  useEffect(
    () => {
      if (selectedPlayerEvent == null) {
        return
      }
      
      fetchJson(`api/events/${player.id}/${selectedPlayerEvent.eventId}`, {gameSpecific: true})
        .then(result => {
          setPlayerEvent(result.json?.event)
        })
        .catch(error => {
          notify(error.message, 'warning')
        })
      },
    [player, selectedPlayerEvent, notify]
  )
  
  let rank = 0;
  
  return <DetailsDrawer
    open={open}
    onClose={onClose}
    width={600}
  >
    {Boolean(selectedPlayerEvent) && (
      <Fragment>
        <DrawerItem label='Event'>
          {selectedPlayerEvent.eventName + "(" + selectedPlayerEvent.eventType + ")" }
        </DrawerItem>
        
        <DrawerItem label='Event ID'>
          <ExternalLink href={ getBeaconCalendarUrl(currentGame, selectedPlayerEvent.eventId) }>
            {selectedPlayerEvent.eventId}
          </ExternalLink>
        </DrawerItem>
        
        <DrawerItem label='Start Time'>
          {moment(selectedPlayerEvent.startTime).format('DD-MM-YYYY HH:mm')}
        </DrawerItem>

        <DrawerItem label='End Time'>
          {moment(selectedPlayerEvent.endTime).format('DD-MM-YYYY HH:mm')}
        </DrawerItem>

        <DrawerItem label='Event Details'>
          {selectedPlayerEvent.eventDetails.map(detail => {
            return <DrawerItem label={detail.type}>{detail.value}</DrawerItem>
          })}
        </DrawerItem>

        <DrawerItem label='Leaderboard'>
          <Table size='small'>
            <TableHead>
              <TableRow>
                <TableCell>Rank</TableCell>
                <TableCell>Player</TableCell>
                <TableCell>Score</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              { Boolean(playerEvent.playerLeaderboard) &&
              playerEvent.playerLeaderboard.playerLeaderboardElements.map(leaderboardPlayer =>
                  <TableRow key={leaderboardPlayer.playerId}>
                    <TableCell>{++rank}</TableCell>
                    <TableCell>{leaderboardPlayer.publicName} <br />
                      <ExternalLink href={ 'accounts/' + leaderboardPlayer.playerId + '/show' }>
                        {leaderboardPlayer.playerId}
                      </ExternalLink>
                      </TableCell>
                    <TableCell>{leaderboardPlayer.score}</TableCell>
                  </TableRow>
                  )
              }
            </TableBody>
          </Table>
        </DrawerItem>

      </Fragment>
    )}
  </DetailsDrawer>
}

PlayerEventDrawer.displayName = 'PlayerEventDrawer'
PlayerEventDrawer.propTypes = {
  playerEvent: PropTypes.object,
  open: PropTypes.bool,
  onClose: PropTypes.func.isRequired,
}

export default PlayerEventDrawer
