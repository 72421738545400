import React, { Fragment } from 'react'
import {
  ShowController,
  Title,
} from 'react-admin'
import { makeStyles } from '@material-ui/core/styles'
import plugin from 'js-plugin'

import MapShowHeader from './MapShowHeader'
import MapInfoWidget from './MapInfoWidget'
import MapGuildsWidget from './MapGuildsWidget'
import MapPlayersWidget from './MapPlayersWidget'

import TabsView from '../components/TabsView'
import LeaderboardDatagrid from '../leaderboard/LeaderboardDatagrid'
import { useGameFeatures } from '../utils/games'
import WithPermission from '../components/WithPermission'
import { Card, CardContent } from '@material-ui/core'
import MapChatWidget from './MapChatWidget'
import MapSeasonsWidget from './MapSeasonsWidget'
import { combineByKey } from '../utils/plugins'

const useStyles = makeStyles(theme => ({
  header: {
    marginBottom: theme.spacing(2),
  },
}))

const MapShow = props => {
  const gameFeatures = useGameFeatures()
  const classes = useStyles()

  return (
    <ShowController { ...props }>
      {({ record, ...rest }) => {
        if (!record) {
          return null
        }

        return (
          <Fragment>
            <Title title={record.publicName || `Map #${record.id}`} />
            <div className={classes.header}>
              <MapShowHeader map={record} />
            </div>
            <TabsView
              tabs={ [
                {
                  label: 'Map info',
                  content: <MapInfoWidget map={ record } />,
                },
                {
                  label: 'Guilds',
                  content: <MapGuildsWidget map={record} {...rest} />,
                },
                /*{
                  label: 'Log',
                },*/
                gameFeatures['messaging/chat'] && {
                  label: 'Chat',
                  content:
                    <WithPermission
                      permissions='ViewChats'
                      fallback={<Card>
                        <CardContent>
                          You don't have permissions to view player chats. (permission ViewChats)
                        </CardContent>
                      </Card>}
                    >
                      <MapChatWidget map={record} />
                    </WithPermission>,
                },
                {
                  label: 'Players',
                  content: <MapPlayersWidget map={record} {...props} />,
                },
                gameFeatures['seasons/show'] && {
                  label: 'Seasons',
                  content: <MapSeasonsWidget map={record} />,
                },
                gameFeatures['leaderboards'] && {
                  label: 'Leaderboards',
                  content: <LeaderboardDatagrid filter={{map: record.id}} />,
                },
                ...Object.values(combineByKey(plugin.invoke('maps.show.tabs'))).map(entry => ({
                  label: entry.label,
                  content: React.cloneElement(entry.content, { map: record }),
                }))
                ] }
            />
          </Fragment>
        )
      }}
    </ShowController>
  )
}

MapShow.displayName = 'MapShow'

export default MapShow
