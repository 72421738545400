import React from 'react'
import PropTypes from 'prop-types'
import MaterialTextField from '@material-ui/core/TextField'
import FormHelperText from '@material-ui/core/FormHelperText'
import FormControl from '@material-ui/core/FormControl'

const TextField = ({
  error,
  errorMessage,
  messageProps,
  formControlProps,
  description,
  ...rest
}) => (
  <FormControl
    error={ error }
    fullWidth={ rest.fullWidth }
    { ...formControlProps }
  >
    <MaterialTextField
      error={ error }
      { ...rest }
    />
    { error && (
      <FormHelperText
        { ...messageProps }
      >
        { errorMessage }
      </FormHelperText>
    )}
    { description && (
      <FormHelperText
        { ...messageProps }
      >
        { description }
      </FormHelperText>
    )}
  </FormControl>
)

TextField.propTypes = {
  error: PropTypes.bool,
  errorMessage: PropTypes.string,
  messageProps: PropTypes.object,
  formControlProps: PropTypes.object,
  description: PropTypes.string,
}

TextField.defaultProps = {
  messageProps: {},
  formControlProps: {},
}

TextField.displayName = 'TextField'

export default TextField
