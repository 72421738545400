import React, { useState } from 'react'
import Tooltip from '@material-ui/core/Tooltip'
import Button from '@material-ui/core/Button'
import Menu from '@material-ui/core/Menu'
import AccountCircle from '@material-ui/icons/AccountCircle'
import {
  MenuItemLink,
  useGetIdentity,
  useLogout,
} from 'react-admin'
import SignOutIcon from '@material-ui/icons/PowerSettingsNew'
import { makeStyles } from '@material-ui/core/styles'
import { Divider, IconButton, ListSubheader, useMediaQuery } from '@material-ui/core'

import { InstanceMenuItem } from './InstanceSelector'

const useStyles = makeStyles({
  userButtonLabel: {
    textTransform: 'none',
  },
})

const CustomUserMenu = ({ children, label = 'Profile', icon = null }) => {
  const classes = useStyles()
  const [anchor, setAnchor] = useState(null)
  const identity = useGetIdentity()
  const logout = useLogout()
  const isSmall = useMediaQuery(theme => theme.breakpoints.down('sm'))

  const open = !!anchor

  return (
    <div>
      <Tooltip title={label}>
        <div>
          {isSmall ?
            <IconButton
              aria-label={label}
              aria-owns={open ? 'menu-appbar' : null}
              aria-haspopup
              color='inherit'
              onClick={event => setAnchor(event.currentTarget)}
            >
              {icon || <AccountCircle />}
            </IconButton>
            :
            <Button
              aria-label={label}
              aria-owns={open ? 'menu-appbar' : null}
              aria-haspopup
              color='inherit'
              onClick={event => { console.log(event.currentTarget); setAnchor(event.currentTarget) }}
              classes={{ label: classes.userButtonLabel }}
              startIcon={icon || <AccountCircle />}
            >
              {identity.identity?.fullName || identity.identity?.email}
            </Button>
          }
      </div>
      </Tooltip>
      <Menu
        id='menu-appbar'
        anchorEl={anchor}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'right',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'right',
        }}
        open={open}
        onClose={() => setAnchor(null)}
        getContentAnchorEl={null}
      >
        {isSmall ? <InstanceMenuItem /> : null}
        {isSmall ? <Divider /> : null}
        <ListSubheader>
          Logged in as {identity.identity?.fullName || identity.identity?.email}
        </ListSubheader>
        <MenuItemLink
          to=''
          primaryText='Log out'
          leftIcon={<SignOutIcon />}
          onClick={logout}
        />
        {children}
      </Menu>
    </div>
  )
}

export default CustomUserMenu
