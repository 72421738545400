import React from 'react'
import {
  List,
  TextField,
  ShowButton,
  ReferenceField,
  Filter,
  TextInput,
  SearchInput,
  useGetOne,
} from 'react-admin'
import lodash from 'lodash'
import AccountCircle from '@material-ui/icons/AccountCircle'

import { useCurrentGame } from '../utils/games'
import ReactiveDataList from '../components/react-admin-addons/ReactiveDataList'

const PlayersFilter = (props) => {
  const config = useGetOne('config', 'accounts')
  const extraFilters = (!config.error && !!config.data && !!config.data.accountIdTypes) ? config.data.accountIdTypes : []

  return <Filter {...props}>
    <SearchInput alwaysOn source='q' placeholder='Search for player id or name' style={{ width: 300 }} />
    <TextInput source='facebookId' label='Facebook Id' />
    <TextInput source='beaconPlayerId' label='Beacon player Id' />
    <TextInput source='email' />
    {extraFilters.map(id => <TextInput source={id.type} label={id.label} key={id.type} />)}
  </Filter>
}

const SubTextField = ({ record, source, subsource, label, ...rest }) => {
  const found = record && record[source] && record[source].filter(field => field.type === subsource)
  if (found && found.length > 0) {
    record[`${source}-${subsource}`] = found[0].value
  }
  return <TextField record={record} source={`${source}-${subsource}`} label={label} {...rest} />
}

export const PlayerDatagrid = ({ showTitle='Show', rowClick, ...rest }) => {
  const {dispatch: _, ...filteredRest} = rest
  const currentGame = useCurrentGame()
  const config = useGetOne('config', 'accounts')
  const idTypes = (!config.error && !!config.data && !!config.data.accountIdTypes) ? config.data.accountIdTypes : []

  return <ReactiveDataList
    rowClick={rowClick}
    basePath='/accounts'
    resource='accounts'
    smallProps={{
      sourcePrimary: 'publicName',
      sourceSecondary: 'id',
      titleSecondary: 'ID',
    }}
    {...filteredRest}
  >
    <TextField source='id' label='ID' />
    <TextField source='publicName' label='Player name' />
    {idTypes.map(type =>
      <SubTextField
        source='accountIds'
        subsource={type.type}
        label={type.label}
        key={`accountid.${type.type}`}
      />)}
    <TextField source='level' label='Level' />
    <TextField source='lastLogin.country' label='Country' />
    <TextField source='lastLogin.device.model' label='Device' />
    {currentGame && lodash.get(currentGame.config, 'features.guilds') && (
      <ReferenceField label='Guild' source='guildId' reference='guilds' link='show' allowEmpty>
        <TextField source='name' />
      </ReferenceField>
    )}
    <ShowButton label={showTitle} icon={<AccountCircle />} />
  </ReactiveDataList>
}

const PlayerList = props => {
   // Avoid a lot of unnecessary errors at initial app load
  const currentGame = useCurrentGame()
  if (!currentGame) return null

  return <List
    {...props}
    title='Players'
    filters={<PlayersFilter />}
    bulkActionButtons={false}
    exporter={false}
    perPage={25}
  >
    <PlayerDatagrid rowClick='show' />
  </List>
}

export default PlayerList

