/* In-memory cache for things like description of available inventory items.
 * These things don't change often, so there's no need to re-load them every time they are used.
 */

export const CACHE_STORE = 'ADMINTOOL_CACHE_STORE'
export const CACHE_INIT = 'ADMINTOOL_CACHE_INIT'

export const cacheStore = (key, data) => ({
  type: CACHE_STORE,
  payload: { key, data },
})

export const cacheInit = (key, fetch) => ({
  type: CACHE_INIT,
  payload: { key, fetch },
})
