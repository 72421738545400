import React, { Fragment } from 'react'
import PropTypes from 'prop-types'
import Typography from '@material-ui/core/Typography'
import * as moment from 'moment'
import { Link } from 'react-admin'
import ReactJson from 'react-json-view'

import DetailsDrawer from '../components/DetailsDrawer'
import DrawerItem from '../components/DrawerItem'

const typeMapping = {
  Player: 'accounts',
  Map: 'maps',
  Guild: 'guilds',
}

const AuditlogDrawer = ({
  log,
  open,
  onClose,
}) => {
  const renderLogView = () => {
    if (!log) {
      return null
    }

    const payload = log.payload
    if (payload.queryString) {
      ['filter', 'range', 'sort'].forEach(field => {
        if (payload.queryString[field]) {
          payload.queryString[field] = JSON.parse(payload.queryString[field])
        }
      })
    }

    return (
      <Fragment>
        <DrawerItem label='Time'>
          { moment(log.timestamp).format('DD-MM-YYYY HH:mm') }
        </DrawerItem>

        <DrawerItem label='Agent'>
          { log.userName }
        </DrawerItem>

        <DrawerItem label={ log.action.split(/(?=[A-Z])/)[0] }>
          { () => {
            if (!log.entityId) return 'N/A'
            const label = log.action.split(/(?=[A-Z])/)[0]

            return (
              <Link
                to={ `/${typeMapping[label]}/${log.entityId}/show` }
              >
                <Typography
                  style={ { display: 'inline' } }
                  color='primary'
                >
                  { log.entityId }
                </Typography>
              </Link>
            )
          }}
        </DrawerItem>

        <DrawerItem label='Description'>
          { log.description }
        </DrawerItem>

        <DrawerItem label='Payload'>
          <ReactJson
            name={ false }
            displayDataTypes={ false }
            src={ payload }
          />
        </DrawerItem>

        { log.result && (
          <DrawerItem label='Result'>
            <ReactJson
              name={ false }
              displayDataTypes={ false }
              src={ log.result }
            />
          </DrawerItem>
        )}
      </Fragment>
    )
  }

  return (
    <DetailsDrawer
      open={ open }
      onClose={ onClose }
    >
      { renderLogView() }
    </DetailsDrawer>
  )
}

AuditlogDrawer.propTypes = {
  open: PropTypes.bool,
  onClose: PropTypes.func.isRequired,
  log: PropTypes.object,
}

AuditlogDrawer.displayName = 'AuditlogDrawer'

export default AuditlogDrawer
