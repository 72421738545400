/**
 * Combines the entries of objects given as parameters. When multiple values for same key exist,
 * the last one within parameters or within an array is selected.
 * 
 * Accepts both plain objects and arrays of objects: the latter kind would be returned from
 * plugin.invoke.
 * 
 * @param  {...(Object[]|Object)} data 
 * @returns {Object}
 */
export function combineByKey(...data) {
  let ret = {}
  for (const x of data) {
    if (Array.isArray(x)) {
      for (const obj of x) {
        ret = {...ret, ...obj}
      }
    } else {
      ret = {...ret, ...x}
    }
  }
  return ret
}
