import React, { useState } from 'react'
import { Dialog, DialogTitle, DialogContent, TextField, DialogActions } from "@material-ui/core"
import { useRefresh } from 'react-admin'
import { useCurrentGame } from '../utils/games'
import CancelButton from '../components/CancelButton'
import ConfirmButton from '../components/ConfirmButton'
import { fetchJsonPost } from '../utils/fetch'
import { describeError } from '../utils/errors'

const CloseMapDialog = ({ onClose, map }) => {
  const refresh = useRefresh()
  const currentGame = useCurrentGame()
  const [numToKick, setNumToKick] = useState('')
  const [errorMessage, setErrorMessage] = useState(null)

  const handleSave = () => {
    return fetchJsonPost(`api/maps/${map.id}/close`, null, { gameSpecific: true })
      .then(
        () => {
          onClose()
          refresh()
        },
        error => {
          setErrorMessage(describeError(error))
        }
      )
  }

  return <Dialog open onClose={onClose}>
    <DialogTitle>Close map {map.id} in {currentGame.id}-{currentGame.env}</DialogTitle>
    <DialogContent>
      <p>This is a tier {map.tier} map with {map.guilds} guilds.</p>
      <p>Current map status is {map.status}</p>
      {map.players ?
        <p>This operation will {}
          <strong>kick {map.players} {map.players === 1 ? 'player' : 'players'}</strong> {}
          from their guilds
        </p> :
        map.players === 0 ?
          <p>The map is empty, zero players will get kicked</p> :
          <p>For some reason, <strong>number of players in this map is not known</strong>. {}
            (write number zero below)</p>
      }
      <TextField
        value={numToKick}
        onChange={event => setNumToKick(event.target.value)}
        label='Verification: enter the number of players kicked'
        fullWidth
      />
    </DialogContent>
    <DialogActions>
      <CancelButton onClick={onClose} />
      <ConfirmButton
        disabled={numToKick !== (map.players || 0).toString()}
        onClick={handleSave}
        text={`Close map ${map.id} in ${currentGame.id}-${currentGame.env}`}
      />
    </DialogActions>
    {errorMessage &&
      <DialogContent>
        {errorMessage}
      </DialogContent>
    }
  </Dialog>
}

export default CloseMapDialog
