import React from 'react'
import PropTypes from 'prop-types'
import Tab from '@material-ui/core/Tab'
import Tabs from '@material-ui/core/Tabs'
import { withStyles } from '@material-ui/core/styles'
import Divider from '@material-ui/core/Divider'
import Card from '@material-ui/core/Card'
import { useHistory, useRouteMatch } from 'react-router-dom'

function idFromLabel(id) {
  return id.toLowerCase().replace(/[^a-z]/g, '-')
}

const TabsView = ({
  classes,
  defaultTabIndex,
  tabs,
  onChange,
}) => {
  const history = useHistory()
  const baseMatch = useRouteMatch()
  const match = useRouteMatch(baseMatch.path + '/:tab')

  const filteredTabs = tabs ? tabs.filter(t => !!t) : []
  let tabIndex = defaultTabIndex
  if (match) {
    filteredTabs.forEach((tab, idx) => {
      const id = tab.id || idFromLabel(tab.label)
      if (id === match.params.tab) {
        tabIndex = idx
      }
    })
  }

  const handleTabChange = (_event, tabIndex) => {
    const tab = filteredTabs[tabIndex]
    history.push(`${baseMatch.url}/${tab.id || idFromLabel(tab.label)}`)
    if (onChange) {
      onChange(tabIndex)
    }
  }

  const activeTab = filteredTabs[tabIndex]

  return (
    <Card className={ classes.container }>
      <Tabs
        value={ tabIndex }
        indicatorColor='primary'
        textColor='primary'
        variant='scrollable'
        scrollButtons='auto'
        onChange={ handleTabChange }
      >
        {
          filteredTabs.map(t => (
            <Tab
              label={ t.label }
              key={ t.label }
            />
          ))
        }
      </Tabs>
      <Divider />
      <div className={ classes.tabContent }>
        {
          activeTab ? activeTab.content : <p>Internal error: no tab is selected</p>
        }
      </div>
    </Card>
  )
}

TabsView.propTypes = {
  classes: PropTypes.object,
  defaultTabIndex: PropTypes.number,
  tabs: PropTypes.arrayOf(PropTypes.oneOfType([
    PropTypes.shape({
      label: PropTypes.string.isRequired,
      content: PropTypes.node,
    }),
    PropTypes.bool])),
  onChange: PropTypes.func,
}

TabsView.defaultProps = {
  defaultTabIndex: 0,
}

TabsView.displayName = 'TabsView'

const styles = {
  container: {
    width: '100%',
    overflow: 'visible',
  },
  tabContent: {
    width: '100%',
  },
}

export default withStyles(styles)(TabsView)
