import React, { cloneElement, useState } from 'react'
import Card from '@material-ui/core/Card'
import {
  Datagrid,
  Filter,
  FunctionField,
  ListContextProvider,
  ListToolbar,
  SearchInput,
  TextField,
  Title,
  TopToolbar,
  sanitizeListRestProps,
  useGetList,
  useListContext,
  useMutation,
} from 'react-admin'
import { useListContextFromData } from '../utils/listings'
import TimestampField from '../components/TimestampField'
import { Button, Chip, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle } from '@material-ui/core'
import Event from '@material-ui/icons/Event'
import { statusForEvent } from './eventUtils'
import CalendarDrawer from './CalendarDrawer'
import moment from 'moment'
import { describeError } from '../utils/errors'
import { isNumber } from 'lodash'
import { useHasPermission } from '../utils/auth'

const UpdateServersButton = () => {
  const hasPermission = useHasPermission()
  const [send, { loading: sending }] = useMutation()
  const [open, setOpen] = useState(false)
  const [error, setError] = useState(null)
  const [count, setCount] = useState(null)

  const updateServers = () => {
    send(
      {
        type: 'create',
        resource: 'scheduledevents/update',
        payload: { data: null },
      },
      {
        onSuccess: result => {
          var c = result?.json?.count
          if (!isNumber(c)) {
            console.error('Missing json.count from result', result)
            setError("Server update returned successfully, but number of updated servers is missing")
          } else {
            setCount(c)
          }
        },
        onFailure: error => {
          setError(error)
        },
      }
    )
    setOpen(true)
  }

  return <>
    <Button
      variant='outlined' color='primary'
      onClick={updateServers}
      disabled={!hasPermission('CreateEvent')}
    >
      Update servers
    </Button>
    <Dialog open={open} onClose={() => setOpen(false)}>
      <DialogTitle>Updating servers</DialogTitle>
      <DialogContent>
        <DialogContentText>
          {sending && "Update of scheduled events definitions requested. Please wait."}
          {count !== null && `Successfully updated ${count} servers`}
          {error && describeError(error)}
        </DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button onClick={() => setOpen(false)} disabled={sending}>
          Close
        </Button>
      </DialogActions>
    </Dialog>
  </>
}

const CalendarViewActions = ({ className, filters, startCreate, ...rest }) => {
  const {
    resource,
    displayedFilters,
    filterValues,
    showFilter,
  } = useListContext();
  const hasPermission = useHasPermission()

  return <TopToolbar className={className} {...sanitizeListRestProps(rest)}>
    {filters && cloneElement(filters, {
      resource,
      showFilter,
      displayedFilters,
      filterValues,
      context: 'button',
    })}
    <UpdateServersButton />
    <Button
      variant='contained' color='primary'
      onClick={startCreate}
      disabled={!hasPermission('CreateEvent')}
    >
      Create event
    </Button>
  </TopToolbar>
}

const CalendarViewFilters = (props) => {
  return <Filter {...props}>
    <SearchInput source='q' alwaysOn placeholder='Search for an event' />
  </Filter>
}

const EventStatusField = ({ record = {} }) => {
  return <div>{statusForEvent(record)}</div>
}

const EventTypeField = ({ record = {} }) => {
  return <Chip label={record?.definition?.type || 'Unknown'} />
}

const defaultItem = {
  definition: {
    type: 'guild_wars',
    version: '',
    configId: {
      id: '',
      version: '',
    },
    startTime: moment().add(1, 'week').startOf('week'),
    endTime: moment().add(1, 'week').endOf('week'),
    applyOnNewMaps: false,
    parentDefinitionId: '',
    configOverridesJson: '',
  }
}

const CalendarView = () => {
  const [selectedId, setSelectedId] = useState(null)
  const [create, setCreate] = useState(false)
  const events = useGetList('scheduledevents')
  const eventContext = useListContextFromData(
    'scheduledevents', events, { onToggleItem: setSelectedId })
  
  const drawerOpen = selectedId !== null || create
  const selectedItem = selectedId !== null ? eventContext.data[selectedId] : defaultItem

  return <>
    <Title title='Events Calendar' />

    <ListContextProvider value={eventContext}>
      <ListToolbar
        filters={<CalendarViewFilters />}
        actions={<CalendarViewActions startCreate={() => setCreate(true)} />}
      />

      <Card>
        {events.error && <p>{describeError(events.error)}</p>}
        {Object.keys(events.data).length === 0 && <p>No events found</p>}
        <Datagrid rowClick='toggleSelection'>
          <EventStatusField label='Status' />
          <TextField source='definition.configId.id' label='Configuration ID' />
          <EventTypeField label='Event type' />
          <TimestampField label='Start' source='definition.startTime' />
          <TimestampField label='End' source='definition.endTime' />
          <FunctionField label='' render={() =>
            <Button color='primary' startIcon={<Event />}>Details</Button>
          } />
        </Datagrid>
      </Card>
    </ListContextProvider>

    <CalendarDrawer
      open={drawerOpen}
      onClose={() => { setSelectedId(null); setCreate(false) }}
      event={selectedItem}
      createNew={create}
    />
  </>
}

export default CalendarView
