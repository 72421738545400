import React from 'react'
import PropTypes from 'prop-types'

import ShowHeader from '../components/ShowHeader'

const GuildShowHeader = ({ guild }) => (
  <ShowHeader
    items={ [
      {
        label: 'Short name',
        value: guild.shortName,
      },
      {
        label: 'Members',
        value: guild.memberCount,
      },
      {
        label: 'Capacity',
        value: guild.capacity,
      },
      {
        label: 'Power',
        value: guild.power,
      },
      {
        label: 'Joinability',
        value: guild.joinability,
      },
    ] }
  />
)

GuildShowHeader.propTypes = {
  guild: PropTypes.object.isRequired,
}

GuildShowHeader.displayName = 'GuildShowHeader'

export default GuildShowHeader

