import React from 'react'
import PropTypes from 'prop-types'
import Typography from '@material-ui/core/Typography'
import { withStyles } from '@material-ui/core/styles'

const DrawerItem = ({
  classes,
  label,
  children,
}) => (
  <div className={ classes.row }>
    <Typography variant='caption'>
      { label }
    </Typography>
    <Typography variant='body2' component='div'>
      { typeof children === 'function' && children() }
      { typeof children !== 'function' && children }
    </Typography>
  </div>
)

const styles = {
  row: {
    marginBottom: 20,
  },
}

DrawerItem.displayName = 'DrawerItem'
DrawerItem.propTypes = {
  classes: PropTypes.object.isRequired,
  label: PropTypes.string.isRequired,
}

export default withStyles(styles)(DrawerItem)
