import { SWITCH_GAME, UPDATE_GAME } from '../actions'
import { getInitialGame } from '../../utils/games'

import plugin from 'js-plugin'
import hydra from '../../hydra'

const game = getInitialGame()

// TODO: don't depend on exact game ID, and allow plugins for other games
// See also: sagas/switchGame.js
if (game && game.id === 'hydra') {
  plugin.register(hydra)
}

const defaultState = {
  currentGame: game,
}

const gamesReducer = (state = defaultState, { type, payload }) => {
  // Switch and update both have same effect on games state. The switch event additionally
  // triggers the switchGame saga.
  if (type === SWITCH_GAME || type === UPDATE_GAME) {
    return { ...state, currentGame: payload.game }
  }
  return state
}

export default gamesReducer
