import React from 'react'
import { Layout, Sidebar } from 'react-admin'
import { makeStyles } from '@material-ui/styles'

import AppBar from './AppBar'

const useStyles = makeStyles(_theme => ({
  root: {
    minWidth: 'unset',
  },
  content: {
    overflowX: 'scroll',
  },
}))

const CustomLayout = props => {
  const classes = useStyles()

  return <Layout
    appBar={ AppBar }
    sidebar={ Sidebar }
    classes={{ root: classes.root, content: classes.content }}
    { ...props }
  />
}

CustomLayout.displayName = 'CustomLayout'

export default CustomLayout
