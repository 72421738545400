import React from 'react'
import PropTypes from 'prop-types'
import Card from '@material-ui/core/Card'
import Grid from '@material-ui/core/Grid'
import ListItem from '@material-ui/core/ListItem'
import ListItemText from '@material-ui/core/ListItemText'
import { withStyles } from '@material-ui/core/styles'

const ShowHeader = ({
  classes,
  items,
  actions,
}) => {
  const hasActions = Array.isArray(actions)
  const itemsSize = 12 - (hasActions ? Math.min(actions.length * 2, 6) : 0)
  return <Card className={classes.root}>
    <Grid container direction='row' justifyContent='space-between'>
      <Grid item container spacing={2} xs={itemsSize}>
        {items.map(({ label, value, size, render }, index) => {
          if (render) {
            return render()
          }
          return (
            <Grid
              item
              xs={size || 2}
              key={index}
            >
              <ListItem>
                <ListItemText
                  primary={value}
                  secondary={label}
                  className={classes.itemReverse}
                  secondaryTypographyProps={{ variant: 'caption' }}
                  primaryTypographyProps={{ variant: 'body2' }}
                />
              </ListItem>
            </Grid>
          )
        })}
      </Grid>
      {hasActions &&
        <Grid item container alignItems='center' spacing={2} xs={12 - itemsSize}>
          {Array.isArray(actions) &&
            actions.map((action, index) =>
              <Grid item key={index}>
                {React.cloneElement(
                  action,
                  { variant: 'outlined', color: 'primary' })
                }
              </Grid>)
      }
    </Grid>
    }
    </Grid>
  </Card>

}

ShowHeader.displayName = 'ShowHeader'

ShowHeader.propTypes = {
  classes: PropTypes.object.isRequired,
  items: PropTypes.arrayOf(PropTypes.shape({
    label: PropTypes.string.isRequired,
    value: PropTypes.node,
    size: PropTypes.number,
    render: PropTypes.func,
  })),
  actions: PropTypes.arrayOf(PropTypes.node),
}

const styles = _theme => ({
  root: {
    flexGrow: 1,
  },
  itemReverse: {
    display: 'flex',
    flexDirection: 'column-reverse',
  },
})

export default withStyles(styles)(ShowHeader)
