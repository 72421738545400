import React from 'react'
import {
  Filter,
  FunctionField,
  List,
  NumberInput,
  SearchInput,
  ShowButton,
  TextField,
} from 'react-admin'
import Map from '@material-ui/icons/Map'
import plugin from 'js-plugin'
import { combineByKey } from '../utils/plugins'
import ReactiveDataList from '../components/react-admin-addons/ReactiveDataList'

const MapsFilter = props => (
  <Filter { ...props }>
    <SearchInput
      alwaysOn
      source='q'
      placeholder='Search for map name'
      style={ { width: 300 } }
    />
    <NumberInput source='tierMin' label='Tier at least' defaultValue={1} />
    <NumberInput source='tierMax' label='Tier at most' defaultValue={0} />
    {Object.values(combineByKey(plugin.invoke('maps.list.filter')))}
  </Filter>
)

function fmtCount(num) {
  if (num !== null && num !== undefined) return num
  return '?'
}

const MapDatagrid = ({showTitle, ...rest}) =>
  <ReactiveDataList
    basePath='/maps' resource='map'
    smallProps={{
      sourcePrimary: 'name', titlePrimary: 'Map',
      sourceSecondary: 'mapDesign', titleSecondary: 'Map design',
    }}
    {...rest}
  >
    <TextField source='id' label='ID' />
    <TextField source='name' label='Map name' />
    <TextField source='mapDesign' label='Map design' />
    <FunctionField
      label='Guilds' source='guilds'
      render={record => `${fmtCount(record.guilds)} / ${fmtCount(record.maxGuilds)}`}
    />
    <FunctionField
      label='Players' source='players'
      render={record => `${fmtCount(record.players)} / ${fmtCount(record.maxPlayers)}`}
    />
    <TextField source='tier' label='Tier' />
    <TextField source='status' label='Status' />
    <ShowButton label={showTitle} icon={<Map />} />
  </ReactiveDataList>

const MapList = (props) => (
  <List
    { ...props }
    title='Maps'
    filters={ <MapsFilter /> }
    bulkActionButtons={ false }
    exporter={ false }
    perPage={ 25 }
    filterDefaultValues={combineByKey(plugin.invoke('maps.list.filterDefaultValues'))}
  >
    <MapDatagrid rowClick='show' showTitle='Show' />
  </List>
)

export default MapList
