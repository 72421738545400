import React, { Fragment, useState } from 'react'

let counter = 1000000;

const FormItemList = ({
  children,
  fields,
  add,
  emptyItem,
}) => {
  const [keys, setKeys] = useState(fields.value ? [...fields.value.keys()] : [])

  return <Fragment>
    {fields.map((fieldName, i) => {
      return React.cloneElement(children,
        {
          key: keys[i],
          source: fieldName,
          onDelete: () => {
            setKeys(keys.filter((_, k) => i !== k))
            fields.remove(i)
          }
        })
    })}
    {React.cloneElement(add, {
      onClick: () => {
        setKeys([...keys, counter++])
        fields.push(emptyItem)
      }
    })}
  </Fragment>
}

export default FormItemList
