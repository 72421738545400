import { fetchUtils } from 'react-admin'
import { getJwtToken } from '../authprovider'

import { getCurrentGame } from './games'

export function fetchJson(url, options = {}) {
  let requestUrl = url
  const jwtToken = getJwtToken()

  if (options.gameSpecific) {
    const currentGame = getCurrentGame()
    if (currentGame) {
      const { id, env } = currentGame
      requestUrl = `${id}/${env.toLowerCase()}/${url}`
    }
    delete options.gameSpecific
  }

  let allowForbidden = false
  if (options.allowForbidden) {
    allowForbidden = true;
    delete options.allowForbidden;
  }

  if (!options.headers) {
    options.headers = new Headers({ Accept: 'application/json' })
  }

  if (jwtToken) {
    options.headers.set('Authorization', `Bearer ${jwtToken}`)
  }

  return fetchUtils.fetchJson(requestUrl, options)
    .catch(error => {
      if (!allowForbidden && (error.status === 401 || error.status === 403)) {
        window.location = '#login'
      }
      throw error
    })
}

export function fetchJsonPost(url, body, options = {}) {
  return fetchJson(url, {
    method: 'POST',
    ...options,
    body: JSON.stringify(body),
  })
}

export function fetchJsonPut(url, body = {}, options = {}) {
  return fetchJson(url, {
    method: 'PUT',
    ...options,
    body: JSON.stringify(body),
  })
}

export function fetchJsonDelete(url, options = {}) {
  return fetchJson(url, {
    method: 'DELETE',
    ...options,
  })
}
