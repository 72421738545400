import React from 'react'
import PropTypes from 'prop-types'
import {
  MenuItemLink,
} from 'react-admin'
import GamesListIcon from '@material-ui/icons/ViewModule'
import ListSubheader from '@material-ui/core/ListSubheader'
import { makeStyles } from '@material-ui/core/styles'
import AccountCircle from '@material-ui/icons/AccountCircle'
import SupervisedUserCircle from '@material-ui/icons/SupervisorAccount'
import Map from '@material-ui/icons/Map'
import Email from '@material-ui/icons/Email'
import Event from '@material-ui/icons/Event'
import History from '@material-ui/icons/History'
import RecentActors from '@material-ui/icons/RecentActors'
import Sports from '@material-ui/icons/Games'
import ForumIcon from '@material-ui/icons/Forum'
import BuildIcon from '@material-ui/icons/Build'

import { useCurrentGame, useGameFeatures } from '../utils/games'
import { useHasPermission } from '../utils/auth'

const useStyles = makeStyles(theme => ({
  groupHeader: {
    paddingLeft: theme.spacing(2),
    textTransform: 'uppercase',
    fontSize: 11,
  },
}))

const Section = ({ title, children }) => {
  const classes = useStyles()
  const nonEmpty = children.filter(c => !!c)
  if (nonEmpty.length === 0) return null

  return <>
    <ListSubheader className={classes.groupHeader}>
      {title}
    </ListSubheader>
    {children}
  </>
}

const Menu = ({
  onMenuClick,
}) => {
  const gameFeatures = useGameFeatures()
  const hasPermission = useHasPermission()
  const currentGame = useCurrentGame()

  return (
    <div>
      {currentGame ?
        <ListSubheader>
          {currentGame.id || 'unknown'}&nbsp;-&nbsp;{currentGame.env || 'unknown'}
        </ListSubheader> :
        <ListSubheader>No&nbsp;game&nbsp;selected</ListSubheader>
      }
      <MenuItemLink
        to='/select-games'
        leftIcon={<GamesListIcon />}
        primaryText='Switch game'
        onClick={onMenuClick}
      />

      <Section title='Player&nbsp;support'>
        <MenuItemLink
          to='/accounts'
          primaryText='Players'
          leftIcon={<AccountCircle />}
          onClick={onMenuClick}
        />

        {gameFeatures.guilds && (
          <MenuItemLink
            to='/guilds'
            primaryText='Guilds'
            leftIcon={<SupervisedUserCircle />}
            onClick={onMenuClick}
          />
        )}

        {gameFeatures.maps && (
          <MenuItemLink
            to='/maps'
            primaryText='Maps'
            leftIcon={<Map />}
            onClick={onMenuClick}
          />
        )}

        {gameFeatures['messaging/chat'] && hasPermission('ViewChats') && (
          <MenuItemLink
            to='/messaging/monitor'
            primaryText='Chat monitor'
            leftIcon={<ForumIcon />}
            onClick={onMenuClick}
          />
        )}
      </Section>

      <Section title='Liveops'>
        {((gameFeatures['messaging/announcement'] && hasPermission('SendAnnouncement')) ||
          (gameFeatures['players/boost'] && hasPermission('BoostPlayerBulk'))) &&
          <MenuItemLink
            to='/bulkactions'
            primaryText='Bulk actions'
            leftIcon={<Email />}
            onClick={onMenuClick}
          />
        }

        {gameFeatures.events && (
          <MenuItemLink
            to='/calendar'
            primaryText='Calendar'
            leftIcon={<Event />}
            onClick={onMenuClick}
          />
        )}

        {null && gameFeatures.rewards && (
          <MenuItemLink
            to='/gifts'
            primaryText='Gifts & Rewards'
            onClick={onMenuClick}
          />
        )}

        {null && gameFeatures.coupons && (
          <MenuItemLink
            to='/coupons'
            primaryText='Coupons'
            onClick={onMenuClick}
          />
        )}

        {gameFeatures.server_tools && hasPermission('ServerMaintenance') && (
          <MenuItemLink
            to='/server-tools'
            primaryText='Server tools'
            leftIcon={<BuildIcon />}
            onClick={onMenuClick}
          />
        )}
      </Section>

      <Section title='Tool&nbsp;Admin'>
        {hasPermission('ViewAuditLogs') &&
          <MenuItemLink
            to='/auditlog'
            primaryText='Audit logs'
            leftIcon={<History />}
            onClick={onMenuClick}
          />
        }
        {hasPermission('ManageUsers') &&
          <MenuItemLink
            to='/user-management'
            primaryText='User management'
            leftIcon={<RecentActors />}
            onClick={onMenuClick}
          />
        }
        {hasPermission('ManageGames') &&
          <MenuItemLink
            to='/games'
            primaryText='Games'
            leftIcon={<Sports />}
            onClick={onMenuClick}
          />
        }
      </Section>
    </div>
  )
}

Menu.propTypes = {
  onMenuClick: PropTypes.func,
}

Menu.displayName = 'Menu'

export default Menu
