import React from 'react'
import { Link, useGetOne } from 'react-admin'

/**
 * @param {{id: string, startIcon: function(Object): React.ReactNode}} param0 
 */
const PlayerLink = ({ id, startIcon=null }) => {
  const player = useGetOne('accounts', String(id))
  return <Link to={`/accounts/${id}/show`}>
    {startIcon && <span>{startIcon(player.data)} </span>}
    {player.data && player.data.publicName ? player.data.publicName : `Player #${id}`}
  </Link>
}

export default PlayerLink
