import React, { Fragment, useState, useEffect } from 'react'
import PropTypes from 'prop-types'
import Drawer from '@material-ui/core/Drawer'
import Typography from '@material-ui/core/Typography'
import {
  useNotify,
  UPDATE,
  CREATE,
  DELETE,
  useDataProvider,
} from 'react-admin'
import { get } from 'lodash'
import IconButton from '@material-ui/core/IconButton'
import DeleteIcon from '@material-ui/icons/Delete'
import { makeStyles } from '@material-ui/core'

import SelectInput from '../components/SelectInput'
import MultiSelectInput from '../components/MultiSelectInput'
import SaveButton from '../components/SaveButton'
import DeleteButton from '../components/DeleteButton'
import TextField from '../components/TextField'
import { required } from '../utils/validations'
import useValidation from '../components/useValidation'
import AddButton from '../components/AddButton'
import { Grid, InputLabel } from '@material-ui/core'

const constraints = {
  email: {
    ...required,
    email: true,
  },
}

const AssignmentItem = ({
  classes,
  games,
  roles,
  assignment,
  errors,
  getFieldProps,
  index,
  onDelete,
  values,
}) => {
  const fieldMapping = {
    game: `roleAssignments[${index}].game`,
    roleId: `roleAssignments[${index}].roleId`,
    environments: `roleAssignments[${index}].environments`,
  }

  const selectedGame = get(values, fieldMapping['game'])
  let gameRoles = []
  let environments = []
  if (selectedGame && games[selectedGame]) {
    const game = games[selectedGame]
    // De-duplicate role names: show the selected one and base roles for any others
    gameRoles = Object.values(roles).filter(
      role => role.game === game.id && (role.name === assignment.roleName ? role.id === assignment.roleId : !role.environment))
    environments = game.environments
  } else {
    gameRoles = Object.values(roles).filter(role => !role.game)
  }

  return (
    <Grid container spacing={2}>
      <Grid item xs={3}>
        <SelectInput
          items={Object.values(games)}
          valueField='id'
          labelField='name'
          includeAllItem={true}
          className={classes.select}
          required
          error={!!get(errors, fieldMapping['game'])}
          errorMessage={get(errors, fieldMapping['game'])}
          {...getFieldProps(fieldMapping['game'])}
        />
      </Grid>
      <Grid item xs={3}>
        <SelectInput
          items={ gameRoles }
          valueField='id'
          labelField='name'
          includeAllItem={ false }
          className={classes.select}
          required
          error={ !!get(errors, fieldMapping['roleId']) }
          errorMessage={ get(errors, fieldMapping['roleId']) }
          { ...getFieldProps(fieldMapping['roleId']) }
        />
      </Grid>
      <Grid item xs={5}>
        <MultiSelectInput
          items={environments}
          valueField='name'
          labelField='name'
          includeAllItem={true}
          className={classes.select}
          required
          error={!!get(errors, fieldMapping['environments'])}
          errorMessage={get(errors, fieldMapping['environments'])}
          {...getFieldProps(fieldMapping['environments'])}
        />
      </Grid>
      <Grid item xs={1}>
        <IconButton
          variant='fab'
          onClick={ onDelete }
        >
          <DeleteIcon />
        </IconButton>
      </Grid>
    </Grid>
  )
}

const useStyles = makeStyles(theme => ({
  container: {
    width: '40rem',
    maxWidth: '80vw',
    padding: theme.spacing(4),
  },
  form: {
    display: 'flex',
    flexDirection: 'column',
  },
  buttons: {
    marginTop: 30,
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
  },
  select: {
    width: '100%',
  },
  row: {
    marginBottom: theme.spacing(4),
  },
  superadmin: {
    margin: 0,
    padding: '6px 0 7px',
  },
}))

const UserEditDrawer = ({
  user,
  roles,
  games,
  open,
  onClose,
  onRequestHide,
}) => {
  const notify = useNotify()
  const classes = useStyles()
  const dataProvider = useDataProvider()

  const [editableUser, setEditableUser] = useState([])
  const [initialUser, setInitialUser] = useState([])

  useEffect(
    () => {
      const assignments = (user && user.roleAssignments) || []
      const processed = {}
      assignments.forEach(assignment => {
        const role = roles[assignment.roleId] || { game: '?', name: `Role ${assignment.roleId}` }
        const id = `${role.name}@${role.game}`
        if (!processed[id]) {
          processed[id] = {
            game: role.game,
            roleId: assignment.roleId,
            roleName: role.name,
            environments: [],
          }
        }
        processed[id].environments.push(assignment.environment || '__all__')
      })
      const newUser = {
        id: user ? user.id : 0,
        email: user ? user.email : '',
        roleAssignments: processed,
      }
      setEditableUser(newUser)
      setInitialUser(newUser)
    },
    [user, roles]
  )

  function handleAddRole() {
    let newRole = {
      game: '',
      roleId: 0,
      roleName: '',
      environments: [],
    }
    let newUser = {
      ...editableUser,
      roleAssignments: { ...editableUser.roleAssignments }
    }
    newUser.roleAssignments['new-' + Object.keys(editableUser.roleAssignments).length] = newRole
    setEditableUser(newUser)
  }

  function handleDeleteRole(index) {
    let newUser = {
      ...editableUser,
      roleAssignments: { ...editableUser.roleAssignments }
    }
    delete newUser.roleAssignments[index]
    removeField(`roleAssignments[${index}]`)
    setEditableUser(newUser)
  }

  function handleFormSubmit(state) {
    const user = {
      id: state.values.id,
      email: state.values.email,
      roleAssignments: [],
    }
    Object.values(state.values.roleAssignments).forEach(a => {
      a.environments.forEach(env => user.roleAssignments.push({
        roleId: a.roleId,
        environment: env !== '__all__' ? env : null,
      }))
    })
    if (!state.values._isNew && state.values.id) {
      handleUpdate(user)
    } else {
      handleCreate(user)
    }
  }

  function handleUpdate(user) {
    dataProvider(UPDATE, 'users', {
      id: user.id,
      data: user,
    })
    .then(() => {
      notify('User updated')
      onRequestHide()
    })
    .catch(e => {
      notify(e.message, 'warning')
    })
  }

  function handleCreate(user) {
    dataProvider(CREATE, 'users', {
      data: user,
    })
    .then(() => {
      notify('User created')
      onRequestHide()
    })
    .catch(e => {
      notify(e.message, 'warning')
    })
  }

  function handleDelete() {
    dataProvider(DELETE, 'users', {
      id: user.id,
    })
    .then(() => {
      notify('User deleted')
      onRequestHide()
    })
    .catch(e => {
      notify(e.message, 'warning')
    })
  }

  const {
    getFieldProps,
    getFormProps,
    errors,
    isValid,
    values,
    removeField,
  } = useValidation(
    {
      constraints,
      showErrors: 'blur',
      initialValues: initialUser,
      onSubmit: handleFormSubmit,
    }
  )

  return (
    <Drawer
      anchor='right'
      open={ open }
      onClose={ onClose }
    >
      <div className={ classes.container }>
        { user && (
          <form
            className={ classes.form }
            { ...getFormProps() }
          >
            <div className={ classes.row }>
              { user.id && (
                <Fragment>
                  <Typography
                    variant='caption'
                    color='textSecondary'
                  >
                    Email
                  </Typography>
                  <Typography
                    variant='body2'
                  >
                    { user.email }
                  </Typography>
                </Fragment>
              )}
              { !user.id && (
                <TextField
                  error={ !!errors.email }
                  errorMessage={ errors.email }
                  label='Email'
                  required
                  { ...getFieldProps('email') }
                />
              )}
            </div>

            <div className={classes.row}>
              <Grid container spacing={2}>
                <Grid item xs={3}><InputLabel>Game</InputLabel></Grid>
                <Grid item xs={3}><InputLabel>Role</InputLabel></Grid>
                <Grid item xs={5}><InputLabel>Environments</InputLabel></Grid>
              </Grid>
              {
                Object.keys(editableUser.roleAssignments).map(key => (
                  <AssignmentItem
                    key={key}
                    index={key}
                    assignment={editableUser.roleAssignments[key]}
                    getFieldProps={getFieldProps}
                    errors={errors}
                    games={games}
                    roles={roles}
                    classes={classes}
                    values={values}
                    onDelete={() => handleDeleteRole(key)}
                  />
                ))
              }
            </div>

            <AddButton
              text='Add role'
              onClick={ handleAddRole }
            />

            <div className={ classes.buttons }>
              <SaveButton
                type='submit'
                disabled={ !isValid }
              />
              { user && !user._isNew && user.id && (
                <DeleteButton
                  onClick={ handleDelete }
                />
              )}
            </div>
          </form>
        )}
      </div>
    </Drawer>
  )
}

UserEditDrawer.propTypes = {
  roles: PropTypes.object,
  games: PropTypes.object,
  user: PropTypes.object,
  open: PropTypes.bool,
  onClose: PropTypes.func.isRequired,
  onRequestHide: PropTypes.func.isRequired,
}

export default UserEditDrawer
