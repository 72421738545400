import React, { Fragment, useState } from 'react'
import PropTypes from 'prop-types'
import {
  Datagrid,
  DateTimeInput,
  Filter,
  FunctionField,
  List,
  Pagination,
  SearchInput,
  SelectInput,
  TextField,
  TextInput,
  useGetOne
} from 'react-admin'
import Card from '@material-ui/core/Card'
import { makeStyles } from '@material-ui/core/styles'

import ShowButton from '../components/ShowButton'
import AuditlogDrawer from './AuditlogDrawer'
import AuditlogDetail from './AuditlogDetail'
import { useHasPermission } from '../utils/auth'
import TimestampField from '../components/TimestampField'

const useStyles = makeStyles(theme => ({
    form: {
      '&::before': {
        content: ({ singleEntity }) => singleEntity ? '"Support history"' : '',
        margin: theme.spacing(2),
        ...theme.typography.title
      },
    },
  }))

const extractPayload = log => {
  // Only do this once. Once payload is extracted, just return log as-is.
  if (typeof (log.payload) !== 'string') return log

  log.payload = JSON.parse(log.payload)
  if (log.payload.body) {
    log.payload.body = JSON.parse(log.payload.body)
  }
  if (log.result) {
    log.result = JSON.parse(log.result)
  }
  return log
}

const DetailField = ({ record = {}, entityId }) => <AuditlogDetail log={extractPayload(record)} entityId={entityId} />

const AuditLogFilters = ({ actions, singleAction, singleEntity, defaultFilters, ...rest }) => {
  const classes = useStyles({ singleEntity })
  return <Filter {...rest} classes={classes} >
    <SearchInput alwaysOn={defaultFilters.includes('q')} source='q' />
    {singleAction ?
      null :
      <SelectInput
        label='Action'
        source='action'
        choices={actions}
        optionText='description'
        optionValue='name'
        alwaysOn={defaultFilters.includes('action')} 
      />}
    <TextInput label='Agent email' source='email' alwaysOn={defaultFilters.includes('email')} />
    <DateTimeInput label='From time' source='start' alwaysOn={defaultFilters.includes('start')} />
    <DateTimeInput label='To time' source='end' alwaysOn={defaultFilters.includes('end')} />
    {singleEntity ?
      null :
      <TextInput
        label='Entity (player, guild etc) ID'
        source='entityId'
        alwaysOn={defaultFilters.includes('entityId')} 
      />}
    {singleEntity ? null : 
      <SelectInput
      source='entityType'
      label='Entity type'
      choices={[
        { id: 'Undefined', name: 'Undefined' },
        { id: 'Player', name: 'Player' },
        { id: 'Guild', name: 'Guild' },
        { id: 'Map', name: 'Map' },
        { id: 'User', name: 'User' },  
        { id: 'Role', name: 'Role' },  
        { id: 'Game', name: 'Cheating' },  
        { id: 'Season', name: 'Season' },  
        { id: 'MessagingChannel', name: 'Channel' },
        { id: 'MessagingMessage', name: 'Message' },
        { id: 'MessagingInvite', name: 'Invite' },  
        { id: 'MessagingReport', name: 'Report' },  
        { id: 'MessagingBan', name: 'Ban' }
      ]}
    />}
  </Filter>
}

const AuditlogListViewInner = ({
  actionFilter,
  entityId,
  entityType,
  title,
  defaultFilters = ["q"],
  basePath = '/auditlog',
  ...rest
}) => {
  const {dispatch: _a, staticContext: _b, ...filteredRest} = rest
  const [selectedLog, setSelectedLog] = useState(null)
  const actionsResult = useGetOne('auditlog', 'actions')
  const actions = []
  if (actionsResult.loaded && actionsResult.data) {
    Object.entries(actionsResult.data).forEach(([group, actionList]) => {
      if (group !== 'id') actions.push(...actionList)
    })
  }

  let staticFilter = {}
  if (entityId) staticFilter.entityId = entityId
  if (entityType) staticFilter.entityType = entityType
  if (actionFilter) staticFilter.action = actionFilter
  const singleAction = actionFilter && (
    typeof actionFilter === 'string' ||
    (Array.isArray(actionFilter) && actionFilter.length === 1))

  return <Fragment>
    <List
      pagination={<Pagination />}
      filter={staticFilter}
      filters={
        <AuditLogFilters
          actions={actions}
          singleEntity={!!entityId}
          singleAction={singleAction}
          defaultFilters={defaultFilters}
        />}
      exporter={false}
      bulkActionButtons={false}
      title={title ? title : ' '} /* Stop 'Auditlog' from appearing in player page title */
      component={(actionFilter || entityId) ? 'div' : Card}
      sort={{ field: 'timestamp', order: 'DESC' }}
      basePath={basePath}
      hasCreate={false} hasEdit={false} hasList={true} hasShow={false}
      resource='auditlog'
      {...filteredRest}
    >
      <Datagrid>
        <TimestampField source='timestamp' label='Timestamp' />
        {singleAction ? null : <TextField source='description' label='Action' />}
        <DetailField label='Detail' />
        <TextField source='userName' label='Agent' />
        <FunctionField render={record =>
          <ShowButton onClick={() => { setSelectedLog(record) }} />
        }/>
      </Datagrid>
    </List>
    <AuditlogDrawer
      open={!!selectedLog}
      log={selectedLog}
      onClose={() => setSelectedLog(null)}
    />
  </Fragment>
}

const AuditlogListView = ({entityId, entityType, ...rest }) => {
  // This permission check has to be in a separate component. Inside a single component, hooks can
  // not be called conditionally, and we use useGetOne to fetch the list of possible actions.
  // Always calling that hook is not an option, because it may return permission denied error,
  // triggering re-authentication.
  const hasPermission = useHasPermission()
  if (!hasPermission('ViewAuditLogs')) {
    return null
  }

  if (entityId) {
    if (!entityType){
      console.warn("entityType is missing");
    }
    return <Card>
      <AuditlogListViewInner entityId={entityId} entityType={entityType} {...rest} />
    </Card>
  }

  return <AuditlogListViewInner {...rest} />
}

AuditlogListView.propTypes = {
  entityId: PropTypes.string,
  entityType: PropTypes.string,
  title: PropTypes.string,
}

AuditlogListView.defaultProps = {
  title: 'Audit logs',
}

export default AuditlogListView
