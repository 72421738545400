import React, { useMemo, useState, useEffect } from 'react'
import PropTypes from 'prop-types'
import {
  Accordion,
  AccordionDetails,
  Card,
  CardContent,
  Chip,
  Typography,
  makeStyles,
} from '@material-ui/core'
import {
  useRefresh,
  useGetOne,
  ListContextProvider,
  ListToolbar,
  Filter,
  Datagrid,
  TextField,
  FunctionField,
  SearchInput,
  SelectInput,
  Pagination,
} from 'react-admin'
import lodash from 'lodash'

import PlayerEditInventoryDialog from './PlayerEditInventoryDialog'
import PlayerDonateInventoryDialog from './PlayerDonateInventoryDialog'
import EditButton from '../components/EditButton'
import AddButton from '../components/AddButton'
import { useHasPermission } from '../utils/auth'
import { uCaseFirst } from '../utils/string'
import { describeError } from '../utils/errors'
import EnhancedAccordionSummary from '../components/EnhancedAccordionSummary'
import { useFiltersController, useListContextFromData, usePaginateDataOnClient, useSortDataOnClient } from '../utils/listings'
import {
  fieldAsListData,
  filterDataBy,
  repackProcessedData,
  filterMatchAny,
  filterMatchFieldExact,
} from '../utils/dataTransforms'
import LoadingIndicator from '../components/LoadingIndicator'

/** @typedef {import('admintool-api/v1/account').Account} Account */
/** @typedef {import('admintool-api/v1/inventory').ItemStack} ItemStack */
/** @typedef {import('admintool-api/v2/v2_common_inventory').GetItemTypesResponse} GetItemTypesResponse */
/** @typedef {import('react-admin').GetOneResult<GetItemTypesResponse>} ItemTypesGetOneResult */
/** @typedef {import('admintool-api/v2/v2_common_inventory').ItemType} ItemType */

/**
 * @param {{record: ItemStack, source: string}} props
 */
const ChipArrayField = ({ record = {}, source }) => {
  const values = lodash.get(record, source, [])
  return <div>
    {values.map(v => <Chip key={v} label={v} />)}
  </div>
}

/**
 * @param {{record: ItemStack, source: string}} props
 */
const AttributesField = ({ record = {}, source }) => {
  const attributes = lodash.get(record, source, {})
  if (!attributes) return null
  return <div>
    {Object.entries(attributes).map(([key, value]) => (
      <div key={key}>
        <Typography
          variant='caption'
          style={{ display: 'inline' }}
        >
          {`${uCaseFirst(key)}: `}
        </Typography>
        <Typography
          variant='body2'
          style={{ display: 'inline' }}
        >
          {value}
        </Typography>
      </div>
    ))}
  </div>
}

const useStyles = makeStyles(_theme => ({
  accordionDetailsRoot: {
    flexDirection: 'column',
  },
}))

/**
 * @param {{player: Account}} props
 */
const PlayerInventoryWidget = ({ player }) => {
  const refresh = useRefresh()
  const classes = useStyles()

  const inventory = useGetOne('inventory', player.id)
  /** @type ItemTypesGetOneResult */
  const types = useGetOne('itemtypes', 'latest')
  const loading = !(inventory.loaded && types.loaded)

  const [editItem, setEditItem] = useState(null)
  const [donateItemType, setDonateItemType] = useState(null)
  const [donateNewItem, setDonateNewItem] = useState(false)

  const [currentItemsTotalCount, setCurrentItemsTotalCount] = useState(null);
  const [inventoryCatalogueTotalCount, setInventoryCatalogueTotalCount] = useState(null);

  /** @type Object.<string, ItemType> */
  const typesMap = useMemo(() => {
    if (types.data && types.data.itemTypes) {
      return Object.fromEntries(types.data.itemTypes.map(type => [type.id, type]))
    }
    return {}
  }, [types])

  const [categories, sections] = useMemo(() => {
    if (!types.data || !types.data.itemTypes) return [[], []]
    const categoriesSet = new Set()
    const sectionsSet = new Set()
    types.data.itemTypes.forEach(type => {
      type.categories.forEach(c => categoriesSet.add(c))
      type.compatibleSections.forEach(s => sectionsSet.add(s))
    })
    return [[...categoriesSet], [...sectionsSet]]
  }, [types])

  const currentItems = useMemo(() => {
    if (!inventory.data || !inventory.data.sections) return []
    const currentItems = []

    Object.entries(inventory.data.sections).forEach(([name, sec]) => {
      Object.values(sec.items).forEach(item => {
        const itemType = typesMap[item.typeId]
        if (itemType) {
          item.name = itemType.name
          item.categories = itemType.categories
          item.section = name
          item.maxAmount = (itemType.maxStackSize && itemType.maxStackSize > 0)
            ? itemType.maxStackSize.toLocaleString()
            : '-'
          currentItems.push(item)
        }
      })
    })

    return currentItems
  }, [inventory, typesMap])

  const hasPermission = useHasPermission()
  const canEditInventory = hasPermission('EditPlayerInventory')

  const filtering = useFiltersController()

  const filteredCurrentItems = useMemo(() => {
    const currentItemsData = repackProcessedData(inventory, currentItems)
    return filterDataBy(currentItemsData, filtering.filterValues,
      {
        q: filterMatchAny(),
        category: filterMatchFieldExact('categories'),
        section: filterMatchFieldExact('section'),
      })
  }, [currentItems, inventory, filtering.filterValues])
  const currentItemsContext = useListContextFromData(
    'inventory', usePaginateDataOnClient(useSortDataOnClient(filteredCurrentItems)), filtering)

  const filteredItemTypes = useMemo(() => {
    const itemTypesData = fieldAsListData(types, 'itemTypes')
    return filterDataBy(itemTypesData, filtering.filterValues,
      {
        q: filterMatchAny(),
        category: filterMatchFieldExact('categories'),
        section: filterMatchFieldExact('compatibleSections'),
      })
  }, [types, filtering.filterValues])
  const itemTypesContext = useListContextFromData(
    'itemtypes', usePaginateDataOnClient(useSortDataOnClient(filteredItemTypes)), filtering)

  const hasExplicitFilters = () => {
    return Object.keys(filtering.filterValues).length === 0 ? false : true;
  };

  useEffect(() => {
    //memorize totals only when there are no UI filters applied
    if (filteredCurrentItems && !hasExplicitFilters()) {
      setCurrentItemsTotalCount(filteredCurrentItems.total);
    }
    if (filteredItemTypes && !hasExplicitFilters()) {
      setInventoryCatalogueTotalCount(filteredItemTypes.total);
    }
  }, [filteredCurrentItems, filteredItemTypes, filtering.filterValues]); //eslint-disable-line react-hooks/exhaustive-deps

  if (loading) {
    return <Card>
      <CardContent>
        <LoadingIndicator />
      </CardContent>
    </Card>
  }
  if (inventory.error) {
    const message = describeError(inventory.error)
    return <Card>
      <CardContent>
        <Typography variant='h6'>Failed to load inventory</Typography>
        <Typography variant='body1'>{message}</Typography>
      </CardContent>
    </Card>
  }
  if (types.error) {
    const message = describeError(types.error)
    return <div>
      <Typography variant='h6'>Failed to load inventory item descriptions</Typography>
      <Typography variant='body1'>{message}</Typography>
    </div>
  }

  return <>
    <Card>
      <ListContextProvider value={currentItemsContext}>
        <CardContent>
          <ListToolbar
            filters={
              <Filter>
                <SearchInput source='q' alwaysOn />
                <SelectInput
                  source='category'
                  label='Category'
                  alwaysOn
                  allowEmpty
                  emptyText='All'
                  choices={categories.map(cat => ({ id: cat, name: cat }))}
                />
                <SelectInput
                  source='section'
                  alwaysOn
                  allowEmpty
                  emptyText='All'
                  choices={sections.map(sec => ({ id: sec, name: sec }))}
                />
              </Filter>
            }
            actions={
              <AddButton
                text='Donate'
                onClick={() => { setDonateNewItem(true) }}
              />
            }
          />
        </CardContent>

        <Accordion defaultExpanded>
          <EnhancedAccordionSummary
            id="current-items-list-header"
            aria-controls="current-items-list"
            title="Current items"
            filteredCount={filteredCurrentItems.total}
            totalCount={currentItemsTotalCount}
            isFiltering={hasExplicitFilters()}
            secondaryTitle="items"
          />
          <AccordionDetails classes={{root: classes.accordionDetailsRoot}}>
            <Datagrid>
              <TextField source='name' label='Name' />
              <FunctionField
                source='count' label='Amount/Max'
                render={record => `${(record.count || 0).toLocaleString()}/${record.maxAmount}`}
              />
              <ChipArrayField source='categories' label='Categories' />
              <TextField source='section' label='Section' />
              <AttributesField source='attributes' label='Attributes' sortable={false} />
              {canEditInventory &&
                <FunctionField
                  label=''
                  render={record =>
                    <>
                      <EditButton
                        onClick={() => { setEditItem(record) }}
                        disabled={
                          typesMap[record.typeId].preventEditingCount &&
                          typesMap[record.typeId].preventEditingAttributes
                        }
                      />
                      <AddButton
                        text='Donate'
                        onClick={() => { setDonateItemType(typesMap[record.typeId]) }}
                        disabled={!!typesMap[record.typeId].preventDonation}
                      />
                    </>
                  }
                />
              }
            </Datagrid>
            <Pagination rowsPerPageOptions={currentItemsContext.rowsPerPageOptions} />
          </AccordionDetails>
        </Accordion>
      </ListContextProvider>

      <ListContextProvider value={itemTypesContext}>
        <Accordion id='all-items-list'>
          <EnhancedAccordionSummary
            id="all-items-list-header"
            ariaControls="all-items-list"
            title="Inventory catalogue"
            filteredCount={filteredItemTypes.total}
            totalCount={inventoryCatalogueTotalCount}
            isFiltering={hasExplicitFilters()}
            secondaryTitle="items"
          />
          <AccordionDetails classes={{root: classes.accordionDetailsRoot}}>
            <Datagrid>
              <TextField source='name' label='Name' />
              <ChipArrayField source='categories' label='Categories' />
              <ChipArrayField source='compatibleSections' label='Compatible sections' />
              <AttributesField source='defaultAttributes' label='Default attributes' sortable={false} />
              <AttributesField source='typeAttributes' label='Type attributes' sortable={false} />
              {canEditInventory &&
                <FunctionField
                  label=''
                  render={record =>
                    <AddButton
                      text='Donate'
                      onClick={() => { setDonateItemType(record) }}
                      disabled={!!record.preventDonation}
                    />
                  }
                />
              }
            </Datagrid>
            <Pagination rowsPerPageOptions={currentItemsContext.rowsPerPageOptions} />
          </AccordionDetails>
        </Accordion>
      </ListContextProvider>
    </Card>

    {(donateItemType || donateNewItem) && (
      <PlayerDonateInventoryDialog
        title={donateItemType ? `Donate - ${donateItemType.name}` : 'Donate'}
        type={donateItemType}
        onClose={() => {
          setDonateNewItem(false)
          setDonateItemType(null)
        }}
        onRequestUpdateView={() => {
          setDonateNewItem(false)
          setDonateItemType(null)
          refresh()
        }}
        player={player}
        inventory={currentItems}
        itemTypes={Object.values(typesMap)}
      />
    )}

    {editItem && (
      <PlayerEditInventoryDialog
        title={`Edit - ${editItem.name}`}
        item={editItem}
        onClose={() => { setEditItem(null) }}
        onRequestUpdateView={() => {
          setEditItem(null)
          refresh()
        }}
        player={player}
      />
    )}
  </>
}

PlayerInventoryWidget.displayName = 'PlayerInventoryWidget'
PlayerInventoryWidget.propTypes = {
  player: PropTypes.object.isRequired,
}

export default PlayerInventoryWidget
