import React, { useEffect, useRef, useState } from 'react'
import {
  Button,
  Card,
  CardContent,
  Checkbox,
  FormControlLabel,
  makeStyles
} from '@material-ui/core'
import { useDataProvider, useGetOne } from 'react-admin'
import { Alert } from '@material-ui/lab'

import { describeError } from '../utils/errors'
import { useCurrentGame } from '../utils/games'
import GuildLink from '../components/GuildLink'
import LoadingIndicator from '../components/LoadingIndicator'
import MapLink from '../components/MapLink'

const MaintenanceIndicator = ({ loaded, data, error }) => {
  if (error) {
    return <Alert severity='warning'>
      Error checking maintenance status: {describeError(error)}
    </Alert>
  }
  if (loaded && !data?.maintenance) {
    return <Alert severity='warning'>Server is NOT in maintenance mode.</Alert>
  }
  return null
}

const useStyles = makeStyles(theme => ({
  body: {
    '& > *': {
      margin: theme.spacing(0, 0, 2, 0),
    },
  },
  dbsnapshot: {
    '& p': {
      margin: 0,
    },
  },
  results: {
    backgroundColor: theme.palette.background.default,
    padding: theme.spacing(1),
  },
  cancelButton: {
    marginLeft: theme.spacing(2),
  },
}))

const Matchmaking = () => {
  const classes = useStyles()
  const status = useGetOne('environment', 'status')
  const [matchmaking, setMatchmaking] = useState(null)
  const [loading, setLoading] = useState(false)
  const [calculateError, setCalculateError] = useState(null)
  const [applyInProcess, setApplyInProcess] = useState(false)
  const [applyError, setApplyError] = useState(null)
  const [applyInfo, setApplyInfo] = useState(null)
  const [backupDone, setBackupDone] = useState(false)
  const [confirmMode, setConfirmMode] = useState(false)
  const queryStatusTask = useRef(null)
  const dataProvider = useDataProvider()
  const currentGame = useCurrentGame()

  const calculateMatchmaking = () => {
    setCalculateError(null)
    setLoading(true)
    setConfirmMode(false)
    dataProvider.getOne('matchmaking', { id: 'calculate' }).then(
      data => { setMatchmaking(data.data) },
      error => { setCalculateError(error) }
    ).finally(() => setLoading(false))
  }

  const applyMatchmaking = () => {
    setApplyError(null)
    setApplyInfo(null)
    setApplyInProcess(true)
    const {id: _ignored, ...matchmakingData} = matchmaking
    dataProvider.create('matchmaking/apply', { data: matchmakingData }).then(
      _ => {
        const fetch = () => {
          queryStatusTask.current = setTimeout(
            () => {
              queryStatusTask.current = null
              dataProvider.getOne('matchmaking', { id: 'status' }).then(
                statusResult => {
                  const results = statusResult.data?.results
                  if (Array.isArray(results) && results.length > 0) {
                    setApplyInfo(results)
                    setApplyInProcess(false)
                  }
                  else fetch()
                },
                error => {
                  console.error(error)
                  setApplyError('Failed to move guilds')
                  setApplyInfo(error?.body?.results)
                  setApplyInProcess(false)
                }
              )
            },
            2000
          )
        }
        fetch()
      },
      error => {
        console.error(error)
        setApplyError(error)
        setApplyInProcess(false)
      }
    )
  }

  useEffect(() => {
    // No setup for this useEffect.
    // If a timeout is active at component teardown, cancel that timeout
    return () => { if (queryStatusTask.current) clearTimeout(queryStatusTask.current) }
  }, [])

  const hasMatchmaking = Array.isArray(matchmaking?.maps)
  const hasResults = Array.isArray(applyInfo)

  return <Card>
    <CardContent className={classes.body}>
      <MaintenanceIndicator {...status} />

      <div className={classes.results}>
        {!hasMatchmaking && !loading &&
          <p>To run matchmaking, we first need to calculate the new maps for the guilds.</p>
        }
        {(loading || applyInProcess) && <LoadingIndicator /> }
        {hasMatchmaking && !applyInProcess && !hasResults &&
          <ul>
            {matchmaking.maps.map(mapDef => (
              <li key={`${mapDef?.mapConfigId}-${mapDef?.guildIds?.[0]}`}>
                Map config: {mapDef?.mapConfigId}
                {Array.isArray(mapDef?.guildIds) ?
                  <ul>
                    {mapDef.guildIds.map(id => <li key={id}><GuildLink id={id} /></li>)}
                  </ul> :
                  <p>Somehow, this map is missing a list of guild IDs.</p>
                }
              </li>
            ))}
          </ul>
        }
        {hasResults &&
          <>
            <p>Matchmaking results:</p>
            <ul>
              {applyInfo.map((info, idx) =>
                <li key={info?.guildMoveGroup?.mapId || `index-${idx}`}>
                  {info?.guildMoveGroup?.mapId && <MapLink id={info?.guildMoveGroup?.mapId} />}
                  {} &ndash; {}
                  {info?.error ? `Error code ${info.error} ` : 'Success'}
                  {Array.isArray(info?.guildMoveGroup?.guildIds) &&
                    <ul>
                      {info.guildMoveGroup.guildIds.map(guild =>
                        <li key={guild}><GuildLink id={guild} /></li>)
                      }
                    </ul>
                  }
                </li>
              )}
            </ul>
          </>
        }
      </div>

      {calculateError &&
        <Alert severity='error'>
          Failed to compute matchmaking: {describeError(calculateError)}
        </Alert>
      }

      {applyError &&
        <Alert severity='error'>
          Failed to apply: {describeError(applyError)}
        </Alert>
      }

      {!hasMatchmaking &&
        <Button
          variant='contained'
          color='primary'
          onClick={calculateMatchmaking}
          disabled={loading}
        >
          Calculate for&nbsp;<b>{currentGame.env}</b>
        </Button>
      }

      {hasMatchmaking && !hasResults &&
        <>
          <div className={classes.dbsnapshot}>
            <p>Please take a database snapshot before applying!</p>
            <FormControlLabel
              control={
                <Checkbox
                  checked={backupDone}
                  onChange={event => {
                    const done = event.target.checked
                    if(!done) setConfirmMode(false)
                    setBackupDone(done)
                  }}
                />
              }
              label='Database snapshot done'
            />
          </div>

          <Button
            variant={confirmMode ? 'outlined' : 'contained'}
            color='primary'
            onClick={() => setConfirmMode(true)}
            disabled={!backupDone || applyInProcess}
          >
            Apply in&nbsp;<b>{currentGame.env}</b>
          </Button>

          <Button
            variant='outlined'
            color='secondary'
            className={classes.cancelButton}
            onClick={() => {
              setMatchmaking(null)
              setBackupDone(false)
              setConfirmMode(false)
            }}
            disabled={applyInProcess}
          >
            Cancel
          </Button>

          {confirmMode &&
            <>
              <p>This will move the guilds to new maps. Please confirm</p>
              <Button
                variant='contained'
                color='primary'
                onClick={applyMatchmaking}
                disabled={!backupDone || applyInProcess}
              >
                Confirm apply in&nbsp;<b>{currentGame.env}</b>
              </Button>
            </>
          }
        </>
      }
    </CardContent>
  </Card>
}

export default Matchmaking
