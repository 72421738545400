import React, { useState } from 'react'
import Divider from '@material-ui/core/Divider';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';
import Typography from '@material-ui/core/Typography'
import { withStyles } from '@material-ui/core/styles'
import Popper from '@material-ui/core/Popper'
import { ClickAwayListener, Button, Paper, ListSubheader, MenuItem, Collapse } from '@material-ui/core'
import Grow from '@material-ui/core/Grow'
import ExpandLess from '@material-ui/icons/ExpandLess'
import ExpandMore from '@material-ui/icons/ExpandMore'

import UpdateNotifier from './UpdateNotifier'
import Version from '../config/version'

const Environment = ({ envName, envText, href, description, currentEnv }) => {
  if (envName === currentEnv) {
    return <ListItem href={href}>
      <ListItemText primary={envText + ' (current)'} secondary={description} />
    </ListItem>
  } else {
    return <ListItem button component='a' href={href}>
      <ListItemText primary={envText} secondary={description} />
    </ListItem>
  }
}

const currentEnv = () => {
  const host = window.location.hostname
  const hostParts = host.split('.')
  const env = hostParts[0]
  const beacon = hostParts[1]
  switch (env) {
    case 'localhost': return 'dev'
    case 'admintool':
      switch (beacon) {
        case 'beacon': return 'cloud'
        case 'beacon-dev': return 'smoke'
        case 'beacon-stage': return 'mist'
        default: return 'beacon'
      }
    case 'dev': return 'unstable'
    case 'stable': return 'stable'
    case 'staging': return 'staging'
    default: return 'unknown'
  }
}

export const InstanceMenuItem = React.forwardRef((props, ref) => {
  const [open, setOpen] = useState(false)

  return <>
    <ListSubheader ref={ref}>Using Admin Tool {currentEnv()} {Version.version}</ListSubheader>
    <MenuItem button onClick={() => setOpen(o => !o)}>
      Other instances
      {open ? <ExpandLess /> : <ExpandMore />}
    </MenuItem>
    <Collapse in={open} timeout='auto' unmountOnExit>
      <EnvironmentList />
    </Collapse>
  </>
})

const EnvironmentList = () => {
  const adminEnv = currentEnv()
  return <List>
    <Environment
      envName='cloud'
      envText='Cloud'
      href='https://admintool.beacon.rovio.com'
      description='Shared production instance'
      currentEnv={adminEnv}
    />
    <Environment
      envName='mist'
      envText='Mist'
      href='https://admintool.beacon-stage.rovio.com'
      description='Staging environment for new tool releases'
      currentEnv={adminEnv}
    />
    <Environment
      envName='smoke'
      envText='Smoke'
      href='https://admintool.beacon-dev.rovio.com'
      description='Development environment for new tool features'
      currentEnv={adminEnv}
    />
    <Divider />
    <Environment
      envName='stable'
      envText='Stable (battle-admin)'
      href='https://stable.battle-admin.rovio.dev'
      description='Currently for Hardhead Squad use only'
      currentEnv={adminEnv}
    />
    <Environment
      envName='staging'
      envText='Staging (battle-admin)'
      href='https://staging.battle-admin.rovio.dev'
      description='Currently for Hardhead Squad use only'
      currentEnv={adminEnv}
    />
    <Environment
      envName='unstable'
      envText='Unstable (battle-admin)'
      href='https://dev.battle-admin.rovio.dev'
      description='Currently for Hardhead Squad use only'
      currentEnv={adminEnv}
    />
  </List>
}

const InstanceSelector = ({
  classes
}) => {
  const label = React.useRef(null)
  const [open, setOpen] = useState(false)
  const [anchorEl, setAnchorEl] = useState(null)

  const handleOpen = event => {
    setAnchorEl(event.currentTarget)
    setOpen(prevOpen => !prevOpen)
  }

  const handleClose = event => {
    if (!anchorEl || !anchorEl.contains(event.target)) {
      setOpen(false)
    }
  }

  return (
    <div>
      <UpdateNotifier version={Version.version} />
      <Button
        id='instance-label'
        className={classes.title}
        ref={label}
        onClick={handleOpen}
      >
        <div className={classes.titleContainer}>
          Admin Tool
            <span className={classes.env}>{currentEnv()}</span>
        </div>
      </Button>
      <Popper open={open} anchorEl={anchorEl} placement='bottom' transition disablePortal>
        {({ TransitionProps }) => (
          <Grow {...TransitionProps}>
              <Paper className={classes.details} >
            <ClickAwayListener onClickAway={handleClose}>
              <div>
                <Typography variant='h6'>Available environments</Typography>
                <EnvironmentList />
                <Divider />
                <Typography>Current environment: {currentEnv()}</Typography>
                <Typography>Current version: {Version.version}</Typography>
                </div>
            </ClickAwayListener>
            </Paper>
          </Grow>
        )}
      </Popper>
    </div>
  )
}

const styles = {
  title: {
    color: '#fff',
  },
  titleContainer: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'flex-end',
  },
  env: {
    marginTop: '-0.5ex',
    font: 'italic x-small sans-serif',
  },
  details: {
    maxWidth: '20em',
    padding: 10,
    color: 'black',
  },
}

export default withStyles(styles)(InstanceSelector)
