import React, { useState } from 'react'
import {
  useRefresh,
  Datagrid,
  ListBase,
  TextField,
  ArrayField,
  SingleFieldList,
  ChipField,
  FunctionField,
  ListToolbar,
} from 'react-admin'
import { CardContent } from '@material-ui/core'

import ContentContainer from '../components/ContentContainer'
import EditButton from '../components/EditButton'
import AddButton from '../components/AddButton'
import GameEditDrawer from './GameEditDrawer'
import { useCurrentUser, hasPermission } from '../utils/auth'

const GamesListView = (props) => {
  const [selectedGame, setSelectedGame] = useState(null)
  const refresh = useRefresh()
  const currentUser = useCurrentUser()

  const handleAddGame = () => setSelectedGame({
    id: 'Id',
    name: 'Name',
    _isNew: true,
    environments: [],
  })

  return <>
    <ContentContainer title='Games'>
      <ListBase {...props} basePath='/games' resource='games'>
        <CardContent>
          <ListToolbar
            actions={
              <AddButton
                text='Add'
                onClick={handleAddGame}
                disabled={!hasPermission(currentUser, 'ManageGames')}
              />
            }
          />
        </CardContent>
        <Datagrid basePath='/games'>
          <TextField source='id' label='ID' />
          <TextField source='name' label='Name' />
          <ArrayField source='environments' label='Environments'>
            <SingleFieldList linkType={false}>
              <ChipField source='name' />
            </SingleFieldList>
          </ArrayField>
          <FunctionField
            render={record =>
              <EditButton
                onClick={() => setSelectedGame(record)}
                disabled={!hasPermission(currentUser, 'ManageGames', record.id)}
              />
            }
          />
        </Datagrid>
      </ListBase>
    </ContentContainer>
    <GameEditDrawer
      open={!!selectedGame}
      game={selectedGame}
      onClose={() => { setSelectedGame(null) }}
      onRequestHide={() => {
        setSelectedGame(null)
        refresh()
      }}
    />
  </>
}

export default GamesListView
