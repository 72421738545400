import React, { useEffect, useState } from 'react'
import { GoogleLogin } from '@react-oauth/google'
import { makeStyles } from '@material-ui/core/styles'
import { Button, Paper, Typography, Divider } from '@material-ui/core'
import {
  Notification,
  useLogin,
  useDataProvider,
  useRefresh,
  useVersion,
} from 'react-admin'
import { useDispatch } from 'react-redux'

import {
  blockGameDependentFetches,
  unblockGameDependentFetches,
  useCurrentGame
} from '../utils/games'
import { getRedirectUri } from '../utils/general'
import { refreshGames } from '../redux/actions'
import { describeError } from '../utils/errors'

const useStyles = makeStyles(theme => ({
  overlay: {
    position: 'fixed',
    top: 0,
    bottom: 0,
    left: 0,
    right: 0,
    background: '#f5f5f5',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  paper: {
    display: 'flex',
    width: 400,
    height: 400,
    flexDirection: 'column',
    alignItems: 'stretch',
    justifyContent: 'space-between',
  },
  header: {
    textAlign: 'center',
    margin: theme.spacing(2, 0, -1, 0),
  },
  contentWrapper: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'center',
    textAlign: 'center',
  },
  noticeWrapper: {
    padding: '2ex',
    margin: '2ex',
  },
}))

const LoginPage = () => {
  const classes = useStyles()
  const login = useLogin()
  const game = useCurrentGame()
  const dispatch = useDispatch()
  const [message, setMessage] = useState(null)
  const [beaconAutologinError, setBeaconAutologinError] = useState(null)
  const dataProvider = useDataProvider()
  const [config, setConfig] = useState({ loading: true, loaded: false, data: null, error: null })
  const refresh = useRefresh()
  const version = useVersion()

  const hasGoogleOauth = config?.data?.methods?.includes('GoogleOauth')
  const hasBeaconRsid = config?.data?.methods?.includes('BeaconRsid')

  function handleGoogleOauthSuccess(loginInfo) {
    const idToken = loginInfo.credential;
    const uri = getRedirectUri()
    setMessage('Logging in – please wait')
    blockGameDependentFetches()
    login(
      { method: 'googleOauth', idToken },
      !game ? '/select-games' : (uri || '/')
    )
      .then(() => dispatch(refreshGames()))
      .catch(error => setMessage(describeError(error)))
      .finally(unblockGameDependentFetches)
  }

  // Get the login config, describing the available login methods.
  // NB: it's not possible to use useGetOne when not logged in: redux state for resources gets
  // fully initialized only after login.
  useEffect(() => {
    dataProvider.getOne('config', { id: 'login' })
      .then(
        ({ data }) => {
          setConfig({ loading: false, loaded: true, data, error: null })
        },
        (error) => {
          setConfig({ loading: false, loaded: true, data: null, error })
        }
      )
    // No need to refresh the config, it just has to be loaded when the component is rendered
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  // Try auto-login with Beacon RSID cookie on page load and at each refresh() call.
  // NB: to save a UI-server roundtrip, this is done even before config is loaded. If Beacon RSID
  // is not actually an available method, this login attempt fails silently.
  useEffect(() => {
    // If we know Beacon RSID login is not available, skip the auto-login
    if (Array.isArray(config?.data?.methods) && !hasBeaconRsid) return
    const uri = getRedirectUri()
    blockGameDependentFetches()
    login({ method: 'beaconRsid' }, !game ? '/select-games' : (uri || '/'))
      .then(() => dispatch(refreshGames()))
      .catch(error => { setBeaconAutologinError(error) })
      .finally(unblockGameDependentFetches)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [version])

  // Attempt automatical Beacon re-login when the user comes back to this tab
  useEffect(() => {
    const handleVisibilityChange = () => {
      if (document.visibilityState === 'visible') refresh()
    }
    document.addEventListener('visibilitychange', handleVisibilityChange)
    return () => document.removeEventListener('visibilitychange', handleVisibilityChange)
  }, [refresh])

  return (
    <div className={classes.overlay}>
      <Paper className={classes.paper}>
        <div className={classes.headerWrapper}>
          <Typography
            variant='h4'
            className={classes.header}
          >
            Admin Tool
          </Typography>
          <Divider />
        </div>

        {!config.loaded &&
          <div className={classes.contentWrapper}>
            Initializing, please wait...
          </div>
        }

        {config?.error &&
          <div className={classes.contentWrapper}>
            <p>It appears that the Admin Tool server is not currently available.</p>
            <p>{describeError(config.error)}</p>
          </div>
        }

        {(config.loaded && !config.error && !(hasGoogleOauth || hasBeaconRsid)) &&
          <div className={classes.contentWrapper}>
            An error occurred: no possible authentication methods seem to be available.
          </div>
        }

        {hasGoogleOauth &&
          <div className={classes.contentWrapper}>
            <GoogleLogin
              onSuccess={handleGoogleOauthSuccess}
              onError={() => console.log('Login failed')}
              size='large'
              auto_select
            />
          </div>
        }

        {hasBeaconRsid &&
          (!beaconAutologinError ?
            <div className={classes.contentWrapper}>
              <p>Trying to log you in automatically using your Beacon account</p>
            </div> :
            <div className={classes.noticeWrapper}>
              <p>Beacon authentication not available. To use this page:</p>
              <ol>
                <li>
                  { /* Beacon is a trusted page, passing referer info is OK */}
                  { /* eslint-disable-next-line react/jsx-no-target-blank */}
                  <a target='_blank' rel='noopener' href={config?.data?.beaconLoginPage}>
                    Log in to Beacon
                  </a> (opens in a new tab/window)
                </li>
                <li>
                  Come back to this tab, and your requested view should load. If this doesn't
                  happen automatically, click here:
                  <Button variant='outlined' size='small' color='primary' onClick={refresh}>
                    Re-try login
                  </Button>
                </li>
              </ol>
            </div>
          )
        }

        {message ?
          <Paper className={classes.noticeWrapper}>
            {message}
          </Paper>
          : <p></p>
        }
      </Paper>
      <Notification />
    </div>
  )
}

LoginPage.displayName = 'LoginPage'

export default LoginPage
