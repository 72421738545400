export const featureList = [
  { value: 'players', label: 'Players', required: true },
  { value: 'players/events', label: 'Event leaderboard view', },
  { value: 'players/activity', label: 'Player activity log', },
  { value: 'players/devices', label: 'Player device list', },
  { value: 'players/boost', label: 'Activate boosts for players' },
  { value: 'players/missions', label: 'Players participate in missions' },
  { value: 'guilds', label: 'Guilds', },
  { value: 'guilds/research', label: 'Guild research', },
  { value: 'maps', label: 'Maps', },
  { value: 'bulk_donations', label: 'Bulk Donations', },
  { value: 'donation/claim', label: 'Donations are claimed', },
  { value: 'donation/message', label: 'Donations with message', },
  { value: 'events', label: 'Calendar', },
  { value: 'segments', label: 'Segments', },
  { value: 'rewards', label: 'Rewards', },
  { value: 'coupons', label: 'Coupons', },
  { value: 'messaging/announcement', label: 'Announcements', },
  { value: 'messaging/chat', label: 'Chat', },
  { value: 'leaderboards', label: 'Leaderboards (new generic API)', },
  { value: 'seasons/show', label: 'View seasons', },
  { value: 'server_tools', label: 'Server tools (game server state management)', },
]
