import React, { Fragment } from 'react'
import PropTypes from 'prop-types'

import { hasPermission, useCurrentUser } from '../utils/auth'
import { useCurrentGame } from '../utils/games'

const WithPermission = ({
  permissions,
  children,
  game = null,
  env = null,
  fallback = null
}) => {
  const currentUser = useCurrentUser()
  const currentGame = useCurrentGame()
  if (!game && currentGame) {
    game = currentGame.id
    env = currentGame.env
  }
  const permissionArray = typeof(permissions) === 'string' ? [permissions] : permissions
  const granted = permissionArray.some(perm => hasPermission(currentUser, perm, game, env))
  return granted ? <Fragment>{ children }</Fragment> : fallback
}

WithPermission.displayName = 'WithPermission'
WithPermission.propTypes = {
  permissions: PropTypes.oneOfType([
    PropTypes.string.isRequired,
    PropTypes.arrayOf(PropTypes.string)
  ]).isRequired,
  game: PropTypes.string,
  env: PropTypes.string,
  fallback: PropTypes.node,
}

export default WithPermission
