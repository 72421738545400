import React from 'react'
import PropTypes from 'prop-types'
import Button from '@material-ui/core/Button'
import AddIcon from '@material-ui/icons/Add'

const AddButton = ({ text, ...rest }) => (
  <Button
    color='primary'
    size='small'
    { ...rest }
  >
    <AddIcon
      style={ { marginRight: '0.5em', fontSize: 20 } }
    />
    { text }
  </Button>
)

AddButton.propTypes = {
  text: PropTypes.string,
}

AddButton.defaultProps = {
  text: 'Create',
}

AddButton.displayName = 'AddButton'

export default AddButton
