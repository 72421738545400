import React, { useState, Fragment } from 'react'
import WarningIcon from '@material-ui/icons/Warning'
import { ClickAwayListener, Button, Grow, Paper, Popper, Typography } from '@material-ui/core'
import { withStyles } from '@material-ui/core/styles'

import { fetchJson } from '../utils/fetch'

const UpdateNotifier = ({ version, classes }) => {
  const label = React.useRef(null)
  const [open, setOpen] = useState(false)
  const [anchorEl, setAnchorEl] = useState(null)
  const [updatedVersion, setUpdatedVersion] = useState(null)

  const handleOpen = event => {
    setAnchorEl(event.currentTarget)
    setOpen(prevOpen => !prevOpen)
  }

  const handleClose = event => {
    if (!anchorEl || !anchorEl.contains(event.target)) {
      setOpen(false)
    }
  }

  React.useEffect(() => {
    const checkVersion = () => {
      if (!document.hidden) {
        fetchJson('/version.json', { cache: 'no-store' }).then(response => {
          console.log(`Checked version: at start ${version}, now ${response.json.version}`)
          if (version !== response.json.version) {
            setUpdatedVersion(response.json.version)
          }
        })
      }
    }
    document.addEventListener('visibilitychange', checkVersion)
    return () => {
      document.removeEventListener('visibilitychange', checkVersion)
    }
  }, [version])

  if (!updatedVersion) return null
  return (
    <Fragment>
      <Button
        variant='contained'
        ref={label}
        onClick={handleOpen}
      >
        Outdated version <WarningIcon color='error' />
      </Button>
      <Popper open={open} anchorEl={anchorEl} transition disablePortal>
        {({ TransitionProps }) => (
          <Grow
            {...TransitionProps}
            style={{ transformOrigin: 'center bottom', zOrder: 10 }}
          >
            <ClickAwayListener onClickAway={handleClose}>
              <Paper className={classes.details}>
                <Typography>You are running version {version} but the latest is {updatedVersion}</Typography>
                <Button variant='contained' color='primary' onClick={() => document.location.reload(true)}>
                  Reload page
                </Button>
              </Paper>
            </ClickAwayListener>
          </Grow>
        )}
      </Popper>
    </Fragment>
  )
}

const styles = {
  details: {
    maxWidth: '20em',
    padding: 10,
    color: 'black',
  },
}

export default withStyles(styles)(UpdateNotifier)
