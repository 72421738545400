import React from 'react'
import PropTypes from 'prop-types'
import Button from '@material-ui/core/Button'
import VisibilityIcon from '@material-ui/icons/RemoveRedEye'

const ShowButton = ({ text, ...rest }) => (
  <Button
    color='primary'
    size='small'
    { ...rest }
  >
    <VisibilityIcon
      style={ { marginRight: '0.5em', fontSize: 20 } }
    />
    { text }
  </Button>
)

ShowButton.propTypes = {
  text: PropTypes.string,
}

ShowButton.defaultProps = {
  text: 'Show',
}

ShowButton.displayName = 'ShowButton'

export default ShowButton
