import React, { useState } from 'react'
import { useGetList, useRefresh } from 'react-admin'
import Dialog from '@material-ui/core/Dialog'
import DialogActions from '@material-ui/core/DialogActions'
import DialogContent from '@material-ui/core/DialogContent'
import DialogTitle from '@material-ui/core/DialogTitle'
import MenuItem from '@material-ui/core/MenuItem'
import TextField from '@material-ui/core/TextField'
import { makeStyles } from '@material-ui/core/styles'
import CancelButton from '../components/CancelButton'
import ConfirmButton from '../components/ConfirmButton'
import { fetchJsonPost } from '../utils/fetch'
import { useDisplayError } from '../utils/errors'

const useStyles = makeStyles(theme => ({
  input: {
    minWidth: '20ex',
    margin: theme.spacing(0, 2, 2, 0),
  },
}))
const MoveDialog = ({ player, onClose }) => {
  const classes = useStyles()
  const refresh = useRefresh()
  const displayError = useDisplayError()
  const [mapId, setMapId] = useState(-1)
  const [guildId, setGuildId] = useState(-1)
  const maps = useGetList(
    'maps',
    { page: 1, perPage: 100 },
    { field: 'id', order: 'ASC' },
    { tierMin: 1, status: ['active', 'stable'] })
  const guilds = useGetList(
    'guilds',
    { page: 1, perPage: 100 },
    { field: 'id', order: 'ASC' },
    { mapId },
    { enabled: mapId >= 0 })

  const handleSave = () => {
    var query = guildId >= 0 ? { newGuild: guildId } : { newMap: mapId }
    return fetchJsonPost(`api/accounts/${player.id}/move`, query, { gameSpecific: true })
      .then(
        () => {
          onClose()
          refresh()
        },
        error => {
          displayError(error)
        })
  }

  return <Dialog open onClose={onClose}>
    <DialogTitle>Move player {player.publicName} to a new guild and map</DialogTitle>
    <DialogContent>
      <TextField select
        className={classes.input}
        disabled={!maps.loaded}
        value={mapId >= 0 ? mapId : ''}
        onChange={event => setMapId(parseInt(event.target.value, 10))}
        label="Map"
      >
        {Object.values(maps.data).map(map =>
          <MenuItem key={map.id} value={map.id}>
            {map.id}: tier {map.tier} &ndash; {map.mapDesign}
          </MenuItem>
        )}
      </TextField>
      <TextField select
        className={classes.input}
        disabled={!guilds.loaded || mapId < 0}
        value={guildId}
        onChange={event => setGuildId(parseInt(event.target.value, 10))}
        label="Guild"
      >
        <MenuItem key='anyGuild' value={-1}>Any guild</MenuItem>
        {Object.values(guilds.data).map(guild =>
          <MenuItem key={guild.id} value={guild.id}>
            {guild.id}: {guild.name}
          </MenuItem>
        )}
      </TextField>
    </DialogContent>
    <DialogActions>
      <CancelButton onClick={onClose} />
      <ConfirmButton disabled={mapId < 0} onClick={handleSave} text='Move player' />
    </DialogActions>
  </Dialog>
}

export default MoveDialog
