import React from 'react'
import { BooleanField, FunctionField, ListContextProvider, NumberField, ReferenceField, TextField, useGetOne } from 'react-admin'
import { Card, CardContent, Grid, Typography } from '@material-ui/core'
import lodash from 'lodash'
import StarIcon from '@material-ui/icons/Star'
import { describeError } from '../utils/errors'
import ReactiveDataList from '../components/react-admin-addons/ReactiveDataList'
import { useListContextFromArray } from '../utils/listings'

const FractionField = ({numerator, denominator, ...rest}) => (
  <FunctionField
    source={numerator}
    render={record => {
      const numerVal = lodash.get(record, numerator)
      const denomVal = lodash.get(record, denominator)
      if(!denomVal) return numerVal
      return `${numerVal} / ${denomVal}`
    }}
    {...rest}
  />
)

const GuildResearch = ({ guild }) => {
  const research = useGetOne('research/guild', guild.id)

  const skills = research?.data?.skills || []
  const leaderboard = research?.data?.leaderboard || {}
  const scoreKind = leaderboard.scoreKind || ['Effort points']

  const skillsContext = useListContextFromArray('research/guild/skill', skills)
  const leaderboardContext = useListContextFromArray(
    'research/guild/leaderboard', leaderboard.entries)

  return <Card>
    <CardContent>
      {research.error && <p>{describeError(research.error)}</p>}
      <Grid container spacing={2}>
        <Grid item xs={12} lg={8}>
          <Typography variant='h5'>Skills</Typography>
          <ListContextProvider value={skillsContext}>
            <ReactiveDataList>
              <BooleanField
                source='recommended'
                label=''
                valueLabelTrue='Officer recommendation'
                valueLabelFalse=''
                TrueIcon={StarIcon}
                FalseIcon={'span'}
              />
              <FunctionField
                source='name'
                label='Skill'
                render={record => record.name || record.id}
              />
              <FractionField numerator='level' denominator='maxLevel' label='Level' />
              <FractionField
                numerator='progress' denominator='progressTarget'
                label='Effort points'
              />
            </ReactiveDataList>
          </ListContextProvider>
        </Grid>
        <Grid item xs={12} lg={4}>
          <Typography variant='h5'>Leaderboard</Typography>
          <p>
            Work in process.
            Here we would show the players who have donated towards this guild's research, ranked
            by the donation amounts.
          </p>
          <ListContextProvider value={leaderboardContext}>
            <ReactiveDataList>
              <NumberField source='rank' />
              <ReferenceField label='Player' source='id' reference='accounts' linkType='show'>
                <TextField source='publicName' />
              </ReferenceField>
              {scoreKind.map(
                (score, i) => <NumberField key={score} label={score} source={`score[${i}]`} />)
              }
            </ReactiveDataList>
          </ListContextProvider>
        </Grid>
      </Grid>
    </CardContent>
  </Card>
}

export default GuildResearch
