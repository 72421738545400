import React from 'react'
import { Datagrid } from 'react-admin';
import { useMediaQuery } from '@material-ui/core';
import DataCardList from './DataCardList';

const ReactiveDataList = ({
  breakpoint = 'sm',
  smallComponent = DataCardList,
  largeComponent = Datagrid,
  smallProps = {},
  largeProps = {},
  children,
  ...rest
}) => {
  const isSmall = useMediaQuery(theme => theme.breakpoints.down(breakpoint))

  const listComponent = isSmall ? smallComponent : largeComponent
  const additionalProps = isSmall ? smallProps : largeProps

  return React.createElement(listComponent, {...rest, ...additionalProps}, ...children)
}

export default ReactiveDataList
