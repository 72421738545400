import React, { useCallback } from 'react'
import { makeStyles } from '@material-ui/core'
import { FormWithRedirect, SaveButton, required, useMutation, useNotify, useRefresh, useVersion } from 'react-admin'
import RichTextInput from 'ra-input-rich-text'
import SendIcon from '@material-ui/icons/Send'

const useStyles = makeStyles(theme => ({
  sendPanel: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'flex-end',
    '& > *': {
      marginLeft: theme.spacing(2),
    },
    '& > :first-child': {
      margin: theme.spacing(0),
    },
  },
  button: {
    marginBottom: '1em',
  },
}))

const SendMessageInline = ({ channelId, className }) => {
  const classes = useStyles()
  const notify = useNotify()
  const refresh = useRefresh()
  const version = useVersion()
  const [send, { loading: sending }] = useMutation()

  const handleSendMessage = useCallback(values => {
    send(
      {
        type: 'create',
        resource: `messaging/message/${channelId}`,
        payload: {
          data: values,
        },
      },
      {
        onSuccess: () => {
          notify('Message sent')
          refresh()
        },
        onFailure: error => {
          console.log(error)
          notify(error.message || 'Failed to send message', 'warning')
        },
      })
  }, [channelId, notify, refresh, send])

  return <div className={classes.sendPanel + (className ? ` ${className}` : '')}>
    <FormWithRedirect
      save={handleSendMessage}
      saving={sending}
      initialValues={{ channel: channelId, message: { html: '' } }}
      version={version}
      render={formProps => <>
        <RichTextInput
          source='message.html'
          label=''
          toolbar={[['bold', 'italic', 'underline', 'strike', { 'color': [] }, 'link']]}
          validate={required()} />
        <SaveButton
          submitOnEnter
          label='Send&nbsp;message'
          saving={formProps.saving}
          handleSubmitWithRedirect={formProps.handleSubmitWithRedirect}
          className={classes.button}
          icon={<SendIcon />} />
      </>} />
  </div>
}

export default SendMessageInline
