import React from 'react'
import {
  ArrayField,
  Datagrid,
  FunctionField,
  Link,
  ListBase,
  NumberField,
  SingleFieldList,
  TextField,
  useListContext,
} from 'react-admin'
import { Card, CardContent } from '@material-ui/core'

import TimestampField from '../components/TimestampField'
import { describeError } from '../utils/errors'

const EmptyOrErrorReport = () => {
  const ctx = useListContext()
  if (ctx?.error) return <p>{describeError(ctx.error)}</p>
  if (!ctx.loading && !ctx?.ids?.length) return <p>This guild has no members</p>
  return null
}

const GuildMembersWidget = ({ guild, ...rest }) => {
  return <ListBase {...rest} resource='guildmembers' filter={{ parent_id: guild.id }}>
    <Card>
      <Datagrid
        rowClick={(_id, _basePath, record) => `/accounts/${record.playerId}/show`}
      >
        <TextField source='playerId' label='ID' sortable={false} />
        <FunctionField
          source='publicName'
          label='Name'
          sortable={false}
          render={record => <Link to={`/accounts/${record.playerId}/show`}>{record.publicName}</Link>}
        />
        <ArrayField source='roles' label='Roles' linkType={false} sortable={false}>
          <SingleFieldList>
            <FunctionField render={record => record} />
          </SingleFieldList>
        </ArrayField>
        <TimestampField source='joinedAt' label='Joined' sortable={false} />
        <NumberField source='power' label='Power' sortByOrder='DESC' sortable={false} />
      </Datagrid>
      <CardContent>
        <EmptyOrErrorReport />
      </CardContent>
    </Card>
  </ListBase>
}

export default GuildMembersWidget
