import React from 'react'
import { withStyles, createStyles } from '@material-ui/core/styles'

const styles = theme => createStyles({
  link: {
    textDecoration: 'none',
    color: theme.palette.primary.main,
  },
})

const ExternalLink = ({ classes, href, children, ...rest }) => (
  <a
    href={ href }
    className={ classes.link }
    target='_blank'
    rel='noopener noreferrer'
    { ...rest }
  >
    { children }
  </a>
)

ExternalLink.displayName = 'ExternalLink'

export default withStyles(styles)(ExternalLink)
