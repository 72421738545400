import React, { useMemo } from 'react'

import { useHasPermission } from '../utils/auth'
import ContentContainer from '../components/ContentContainer'
import TabsView from '../components/TabsView'
import AnnouncementForm from './AnnouncementForm'
import MessagingHistory from './MessagingHistory'
import ActivateBoostForm from '../boosts/ActivateBoostForm'

function BulkActionsView() {
  const hasPermission = useHasPermission()
  const tabs = useMemo(() => {
    const tabs = []
    if (hasPermission('SendAnnouncement')) {
      tabs.push({
        label: 'Send Announcement',
        content: <AnnouncementForm />,
      })
    }
    if (hasPermission('BoostPlayerBulk')) {
      tabs.push({
        label: 'Activate Boost',
        content: <ActivateBoostForm />,
      })
    }
    if (hasPermission('ViewAuditLogs')) {
      tabs.push({
        label: 'History',
        content: <MessagingHistory />,
      })
    }
    return tabs
  }, [hasPermission])

  return <ContentContainer title='Bulk Operations'>
    <TabsView tabs={tabs} />
  </ContentContainer>
}

export default BulkActionsView
