import React, { Fragment } from 'react'
import PropTypes from 'prop-types'
import Typography from '@material-ui/core/Typography'
import { Link } from 'react-admin'

import ExternalLink from '../components/ExternalLink'
import TimestampText from '../components/TimestampText'

const LineItem = ({ label, value }) => (
  <div>
    <Typography
      variant='caption'
      style={ { display: 'inline' } }
    >
      { `${label}: ` }
    </Typography>
    <Typography
      variant='body2'
      style={ { display: 'inline' } }
    >
      { value }
    </Typography>
  </div>
)

const EntityOnly = ({
  entityId,
  entityType,
  label,
  log,
}) => (
  <div>
    { !entityId && log.entityId && (
      <LineItem
        label={ label }
        value={ (
          <Link
            to={ `/${entityType}/${log.entityId}/show` }
          >
            { log.entityId }
          </Link>
) }
      />
    )}
  </div>
)

const EntityBan = ({
  entityId,
  entityType,
  label,
  log,
}) => {
  const {
    payload: {
      params: {
        ban,
      },
    },
  } = log

  return (
    <div>
      { ban.userReason && (
        <LineItem
          label='Reason'
          value={ ban.userReason }
        />
      )}
      <EntityOnly
        entityId={ entityId }
        entityType={ entityType }
        label={ label }
        log={ log }
      />
    </div>
  )
}

const EntityNameChange = ({
  entityId,
  entityType,
  label,
  log,
}) => {
  const {
    payload: {
      params: {
        request,
      },
    },
  } = log

  const reason = request.reason || request.Reason

  return (
    <div>
      { reason && (
        <LineItem
          label='Reason'
          value={ reason }
        />
      )}
      <EntityOnly
        entityId={ entityId }
        entityType={ entityType }
        label={ label }
        log={ log }
      />
    </div>
  )
}

const EditInventory = ({
  log: {
    payload: {
      params,
    },
  },
}) => {
  const item = params.donation ? (params.donation.item || params.donation.Item) : params.item
  if (!item) return null

  const section = params.donation ? (params.donation.section || params.donation.Section) : null

  return (
    <div>
      { item.TypeId && (
        <LineItem
          label='Type'
          value={ item.typeId || item.TypeId }
        />
      )}
      { section && (
        <LineItem
          label='Section'
          value={ section }
        />
      )}
      <LineItem
        label='Count'
        value={ item.count || item.Count }
      />
    </div>
  )
}

const PlayerBulkDonation = ({
  log: {
    payload: {
      params,
    },
    result
  }
}) => {
  const request = params.request
  if (!request) return null

  const description = request.description || request.Description
  const sourceType = request.sourceType || request.SourceType
  const csv = result?.csvFileUrl || result?.CSVFileUrl

  return (
    <Fragment>
      { description && (
        <Typography
          variant='body2'
        >
          { description }
        </Typography>
      )}
      <LineItem
        label='Players from'
        value={ sourceType }
      />
      { result && (
        <Fragment>
          <LineItem
            label='Players count'
            value={ sourceType === 'ALL_PLAYERS' ? 'All' : (result.playersCount || result.PlayersCount) }
          />
          { csv && (
            <LineItem
              label='CSV file'
              value={ (
                <ExternalLink
                  href={ csv }
                  onClick={ event => event.stopPropagation() }
                >
                  { result.csvFileName || result.CSVFileName || 'CSV file' }
                </ExternalLink>
              )}
            />
          )}
        </Fragment>
      )}
    </Fragment>
  )
}

const SendAnnouncement = ({
  log: {
    payload: {
      params,
    },
    result
  }
}) => {
  const announcement = params ? params.announcement : null
  const group = announcement ? announcement.group : null
  const message = announcement ? announcement.message : null

  return <>
    <LineItem
      label='Send to'
      value={!group ? 'Error: group missing' :
        group.allPlayers ? 'All players' :
        group.csv ? <ExternalLink href={result.csvFileUrl}>CSV contents</ExternalLink> :
        group.playerIds ? 'List of IDs' : 'Unknown'}
    />
    <LineItem
      label='Title'
      value={message ? message.title : 'Error: message missing'}
    />
  </>
}

const ChatMessageView = ({
  log: {
    payload: {
      params,
    },
  }
}) => {
  const filters = (params && Array.isArray(params.filter)) ? params.filter : []
  const channelFilter = filters.filter(f => f.field === 'channel')
  const channel = channelFilter.length > 0 ? channelFilter.map(f => f.values.join(', ')).join(' && ') : 'No channel selected'

  return <LineItem label='Channel' value={channel} />
}

const ChatMessageSend = ({
  log: {
    entityId,
    entityType,
  },
}) => {
  if (entityType === 'MessagingChannel'){
    return <LineItem label='Channel' value={entityId} />
  }
  return <LineItem label='Recipient' value={`${entityType} ${entityId}`} />
}

const EventCreate = ({log}) => {
  const definition = log?.payload?.params?.eventDefinition
  return <>
    <LineItem label='Config ID' value={definition?.configId?.id} />
    <LineItem label='Version' value={definition?.version} />
    <LineItem label='Event type' value={definition?.type} />
    <LineItem label='Start' value={<TimestampText time={definition.startTime} />} />
  </>
}

const EventDelete = ({log}) => <LineItem label='Event ID' value={log?.payload?.params?.eventId} />

const componentMapping = {
  PlayerNameChange: EntityNameChange,
  PlayerBan: EntityBan,
  PlayerUnban: EntityOnly,
  PlayerEditInventory: EditInventory,
  GuildNameChange: EntityNameChange,
  GuildBan: EntityBan,
  GuildUnban: EntityOnly,
  MapNameChange: EntityNameChange,
  PlayerBulkDonation: PlayerBulkDonation,
  SendAnnouncement: SendAnnouncement,
  ChatMessageView: ChatMessageView,
  ChatMessageSend: ChatMessageSend,
  EventCreate: EventCreate,
  EventDelete: EventDelete,
}

const typeMapping = {
  Player: 'accounts',
  Map: 'maps',
  Guild: 'guilds',
}

const AuditlogDetail = ({ log, entityId }) => {
  const action = log?.action || 'unknown'
  const Comp = componentMapping[action]
  const label = action.split(/(?=[A-Z])/)[0]
  const type = typeMapping[label]

  return (
    <Fragment>
      { Comp && (
        <Comp
          log={ log }
          entityId={ entityId }
          entityType={ type }
          label={ label }
        />
      )}
    </Fragment>
  )
}

AuditlogDetail.displayName = 'AuditlogDetail'
AuditlogDetail.propTypes = {
  log: PropTypes.object.isRequired,
  entityId: PropTypes.string,
}

export default AuditlogDetail
