import React, { useState } from 'react'
import {
  Datagrid,
  Filter,
  Link,
  ListActions,
  ListContextProvider,
  ListToolbar,
  ReferenceField,
  SelectArrayInput,
  SingleFieldList,
  TextField,
  useGetList,
  useGetOne,
} from 'react-admin'
import { Button, Popover } from '@material-ui/core'
import ForumIcon from '@material-ui/icons/Forum'
import { makeStyles } from '@material-ui/core/styles'
import lodash from 'lodash'
import { describeError } from '../utils/errors'
import TimestampText from '../components/TimestampText'
import InfinitePagination from '../components/InfinitePagination'
import { useFiltersController, useListContextFromData, usePaginatedListController } from '../utils/listings'
import MessageContext from './MessageContext'

const useMessageDetailsStyles = makeStyles(_theme => ({
  condensed: {
    margin: 0,
  },
}))

const MessageDetailsContent = ({ id }) => {
  const classes = useMessageDetailsStyles()
  const message = useGetOne('messaging/message', id)

  if (message.error) return <div>{describeError(message.error)}</div>
  if (!message.loaded || !message.data) return <div>loading...</div>
  return <div>
    <p className={classes.condensed}>{message.data.message.html}</p>
    <p className={classes.condensed}>
      At <TimestampText time={message.data.timestamp} /> in {/* Save the space */}
      <Link to={`/messaging/channel/${message.data.channel}/show`}>{message.data.channel}</Link>
    </p>
  </div>
}

const MessageDetailsField = ({ source, channelSource, record = {} }) => {
  const msgId = lodash.get(record, source)
  if (msgId) {
    const chId = lodash.get(record, channelSource) || '-'
    const fullId = `${chId}/${msgId}`
    return <MessageDetailsContent id={fullId} />
  }
  return <div></div>
}

const useReportInfoStyles = makeStyles(theme => ({
  container: {
    width: '100%',
    padding: theme.spacing(1, 0),
    borderBottom: 'thin solid #e0e0e0',
    '&:first-child': {
      borderTop: 'thin solid #e0e0e0',
    }
  },
  topLine: {
    margin: 0,
  },
  messageDetails: {
    margin: theme.spacing(0, 0, 0, 2),
  },
}))

const ReportInfo = (props) => {
  const classes = useReportInfoStyles()
  return <div className={classes.container}>
    <div className={classes.topLine}>
      <ReferenceField source='player' reference='accounts' link='show' label='Reported player' allowEmpty {...props}>
        <TextField source='publicName' />
      </ReferenceField>
      { } was reported by { }
      <ReferenceField source='reporter' reference='accounts' link='show' allowEmpty {...props}>
        <TextField source='publicName' />
      </ReferenceField>
      { } for { }
      <TextField source='reason' {...props} />
    </div>
    <div className={classes.messageDetails}>
      <MessageDetailsField label='Message' source='messageId' channelSource='messageChannel' {...props} />
      <ContextForReport record={props.record} />
    </div>
  </div>
}

const ContextForReport = ({ record = {} }) => {
  const [anchor, setAnchor] = useState(null)

  return <>
    <Button
      color='primary'
      startIcon={<ForumIcon />}
      onClick={e => setAnchor(e.currentTarget)}
    >
      Context
    </Button>
    <Popover
      open={!!anchor}
      anchorEl={anchor}
      onClose={() => setAnchor(null)}
      anchorOrigin={{
        vertical: 'bottom',
        horizontal: 'right',
      }}
      transformOrigin={{
        vertical: 'top',
        horizontal: 'right',
      }}
    >
      {record.messageId ?
        <MessageContext id={record.messageId} channel={record.messageChannel} /> :
        <p>No chat context available for this report</p>
      }
    </Popover>
  </>
}

const ReportsFilters = (props) => {
  return <Filter {...props}>
    <SelectArrayInput
      source='reason'
      label='Reason'
      choices={[
        { id: 'Cheating', name: 'Cheating' },
        { id: 'ChatBehavior', name: 'Chat behaviour' },
      ]}
    />
  </Filter>
}

const ReportsCardList = () => {
  return <SingleFieldList linkType={null}>
    <ReportInfo />
  </SingleFieldList>
}

const ReportsDatagrid = () => {
  return <>
    <ListToolbar
      actions={<ListActions />}
      exporter={false}
      filters={<ReportsFilters />}
    />
    <Datagrid resource='messaging/report'>
      <ReferenceField source='player' reference='accounts' link='show' label='Reported player' allowEmpty>
        <TextField source='publicName' />
      </ReferenceField>
      <ReferenceField source='reporter' reference='accounts' link='show' allowEmpty>
        <TextField source='publicName' />
      </ReferenceField>
      <TextField source='reason' />
      <MessageDetailsField label='Message' source='messageId' channelSource='messageChannel' />
      {/*<TextField source='message_channel' /> // Disabled: game server doesn't give this */}
      {/*<TextField source='description' /> // Disabled: no in-game support */}
      <ContextForReport />
    </Datagrid>
  </>
}

const ReportsList = ({ filter = {}, tiny = false, onSetAccordionTotalCount }) => {
  const filtering = useFiltersController(filter)
  const reportsController = usePaginatedListController(
    'messaging/report', { perPage: 25, sort: false })
  const reports = useGetList(
    'messaging/report', reportsController.pagination, null /*sort*/, filtering.filterValues)
  if (reports.data) {
    Object.values(reports.data).forEach(item => {
      if (item.messageId) {
        item.messageApiId = `${item.messageChannel || '-'}/${item.messageId}`
      }
    })
  }
  
  const listContext = useListContextFromData(
    'messaging/report', reports, reportsController, filtering)

  if (onSetAccordionTotalCount && (typeof onSetAccordionTotalCount == "function")) {
    onSetAccordionTotalCount(listContext.total)
  }

  return <ListContextProvider value={listContext}>
    {tiny ? <ReportsCardList /> : <ReportsDatagrid />}
    <InfinitePagination {...listContext} />
  </ListContextProvider>
}

export default ReportsList
