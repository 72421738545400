import React from 'react'
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableRow from '@material-ui/core/TableRow';
import TextField from '../components/TextField'
import { Typography } from '@material-ui/core';

const ItemAttributes = ({ attributes, onChange }) => {
  function onDataChange(e) {
    var newAttribs = {}
    for (var key in attributes) {
      newAttribs[key] = attributes[key]
    }
    newAttribs[e.target.name] = e.target.value
    onChange(newAttribs)
  }

  return (
    <Table>
      <TableBody>
        {
          Object.keys(attributes).map((key) =>
            <TableRow key={key}>
              <TableCell><Typography variant='body2'>{key}</Typography></TableCell>
              <TableCell>
                <TextField label='Value'
                  name={key}
                  value={attributes[key]}
                  onChange={onDataChange} />
              </TableCell>
            </TableRow>
          )
        }
      </TableBody>
    </Table>
  )
}

export default ItemAttributes
