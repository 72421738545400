import React, { useState } from 'react'
import PropTypes from 'prop-types'
import Button from '@material-ui/core/Button'
import ActionCheck from '@material-ui/icons/CheckCircle'
import classnames from 'classnames'
import { withStyles, createStyles } from '@material-ui/core/styles'
import { fade } from '@material-ui/core/styles/colorManipulator'
import { CircularProgress } from '@material-ui/core'

const ConfirmButton = ({
  text,
  classes,
  confirmColor,
  disabled,
  onClick,
  ...rest
}) => {
  const [activated, setActivated] = useState(false)

  function handleClick(event) {
    if (activated) return
    setActivated(true)
    Promise.resolve(onClick(event)).finally(() => setActivated(false))
  }

  return <Button
    autoFocus
    className={classnames({
      [classes.confirmWarning]:
        confirmColor === 'warning',
      [classes.confirmPrimary]:
        confirmColor === 'primary',
    })}
    color='primary'
    disabled={disabled || activated}
    onClick={handleClick}
    {...rest}
  >
    {activated ?
      <CircularProgress size='24px' /> :
      <ActionCheck />
    }
    <span className={classes.text}>{text}</span>
  </Button>
}

ConfirmButton.propTypes = {
  text: PropTypes.string,
  confirmColor: PropTypes.oneOf(['warning', 'primary']),
}

ConfirmButton.defaultProps = {
  text: 'Confirm',
  confirmColor: 'primary',
}

ConfirmButton.displayName = 'ConfirmButton'

const styles = theme => createStyles({
  confirmPrimary: {
    color: theme.palette.primary.main,
  },
  confirmWarning: {
    color: theme.palette.error.main,
    '&:hover': {
      backgroundColor: fade(theme.palette.error.main, 0.12),
      // Reset on mouse devices
      '@media (hover: none)': {
        backgroundColor: 'transparent',
      },
    },
  },
  text: {
    marginLeft: '0.5rem',
  },
})

export default withStyles(styles)(ConfirmButton)
