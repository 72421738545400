import React, { useState } from 'react'
import PropTypes from 'prop-types'
import {
  useGetOne,
  Datagrid,
  FunctionField,
  ListContextProvider,
  TextField,
  ListToolbar,
  Filter,
  TextInput,
} from 'react-admin'
import EyeIcon from '@material-ui/icons/RemoveRedEye'

import { getBeaconCalendarUrl } from "../utils/beacon";
import PlayerEventDrawer from './PlayerEventDrawer'
import ExternalLink from "../components/ExternalLink";
import { useCurrentGame } from '../utils/games'
import { Button } from '@material-ui/core'
import TimestampField from '../components/TimestampField'
import { useFiltersController, useListContextFromData } from '../utils/listings';
import { fieldAsListData, filterDataBy, filterMatchAnyOfFields } from '../utils/dataTransforms';

/** @typedef {import('admintool-api/v1/account').Account} Account */
/** @typedef {import('admintool-api/v2/v2_common_event').GetEventsResponse} GetEventsResponse */
/** @typedef {import('react-admin').GetOneResult<GetEventsResponse>} EventsListResult */
/**
 * @param {{player: Account}} props
 */
const PlayerEventsWidget = ({ player }) => {
  /** @type {EventsListResult} */
  const playerEvents = useGetOne('events', player.id)
  const [selectedItem, setSelectedItem] = useState(null)
  const currentGame = useCurrentGame()
  const filtering = useFiltersController()

  const eventList = fieldAsListData(playerEvents, 'events', 'eventId')
  const filteredEvents = filterDataBy(eventList, filtering.filterValues,
    {
      q: filterMatchAnyOfFields(['eventName', 'eventType', 'eventId', 'startTime', 'endTime']),
    })

  const context = useListContextFromData('events', filteredEvents, filtering)

  return <>
    <ListContextProvider value={context}>
      <ListToolbar
        filters={
          <Filter>
            <TextInput label='Search' source='q' alwaysOn />
          </Filter>
        }
      />
      <Datagrid
        rowClick={(_id, _basePath, record) => {
          setSelectedItem(record)
          return false
        }}
      >
        <TextField source='eventName' label='Event name' />
        <TextField source='eventType' label='Type' />
        <FunctionField
          label='Event ID'
          source='eventId'
          render={record => (
            <ExternalLink href={ getBeaconCalendarUrl(currentGame, record.eventId) }>
              { record.eventId }
            </ExternalLink>
          )}
        />
        <TimestampField source='startTime' label='Start' />
        <TimestampField source='endTime' label='End' />
        <FunctionField
          label=''
          render={() => (
            <Button color='primary' startIcon={<EyeIcon />}>
              Show
            </Button>
          )}
        />
      </Datagrid>
    </ListContextProvider>
    <PlayerEventDrawer
      open={Boolean(selectedItem)}
      onClose={() => setSelectedItem(null)}
      selectedPlayerEvent={ selectedItem }
      player={ player }
    />
  </>
}

PlayerEventsWidget.propTypes = {
  player: PropTypes.object.isRequired,
}

PlayerEventsWidget.displayName = 'PlayerEventsWidget'

export default PlayerEventsWidget
