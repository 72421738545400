import { useCurrentGame } from "./games"
import { useCallback } from "react"
import { useGetIdentity } from 'react-admin'

/** @typedef {{
 *   id: number,
 *   email: string,
 *   roles: Array<{
 *     name: string,
 *     permissions: Array<string>
 *   }>}} User
 */


export const hasRole = (user, role) => {
  if (!user) return false
  return user.roles.some(r => r.name === role)
}

export const isSuperAdmin = user => hasRole(user, 'SuperAdmin')

/**
 * Checks if an user has a permission in the given game and environment.
 * @param {User} user User account information. Preferably from useCurrentUser().
 * @param {string} permission Name of the permission. See Battle.Admin/Permissions.cs
 * @param {string} game ID of the game to check, or empty to query generic permissons.
 * @param {string} env ID of game enc to check, or empty to query for all environments.
 */
export const hasPermission = (user, permission, game = null, env = null) => {
  if (!user) return false
  if (!Array.isArray(user.roles)) {
    console.log("Provided user has no roles array. Maybe useHasPermission() call is missing?",
      user, permission)
  }

  if (isSuperAdmin(user)) return true

  const gamePermission = `${permission}@${game}`
  const gameEnvPermission = `${permission}@${game}-${env}`
  return user.roles.some(
    r => r.permissions.some(
      p => p === permission || p === gamePermission || p === gameEnvPermission))
}

/**
 * @returns {User} Account information for currently logged in user.
 */
export const useCurrentUser = () => {
  const permissions = useGetIdentity()
  return permissions.identity
}

/**
 * @returns {function(string): boolean} Function that checks if current user has given permission
 *   in current game.
 */
export const useHasPermission = () => {
  const game = useCurrentGame()
  const user = useCurrentUser()
  return useCallback(
    permission => {
      if (!user) return false
      if (!game) return hasPermission(user, permission)
      return hasPermission(user, permission, game.id, game.env)
    },
    [game, user])
}
