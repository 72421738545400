import React from 'react'
import PropTypes from 'prop-types'
import Dialog from '@material-ui/core/Dialog'
import DialogActions from '@material-ui/core/DialogActions'
import DialogContent from '@material-ui/core/DialogContent'
import DialogTitle from '@material-ui/core/DialogTitle'
import { withStyles } from '@material-ui/core/styles'

import { required } from '../utils/validations'
import useValidation from '../components/useValidation'
import SelectInput from '../components/SelectInput'
import CancelButton from '../components/CancelButton'
import ConfirmButton from '../components/ConfirmButton'
import TextField from "../components/TextField";

const durations = [
  { value: 1, label: '1 hour' },
  { value: 5, label: '5 hours' },
  { value: 12, label: '12 hours' },
  { value: 24, label: '24 hours' },
  { value: 'indefinite', label: 'Indefinite' },
]

const reasons = [
  { value: 'cheating', label: 'Cheating' },
  { value: 'trolling', label: 'Trolling' },
]

const constraints = {
  type: required,
  duration: required,
  reason: required,
}

const PlayerBanDialog = ({
  classes,
  onSubmit,
  title,
  onClose,
  banTypes
}) => {
  const {
    getFieldProps,
    errors,
    isValid,
    values,
  } = useValidation(
    {
      constraints,
      showErrors: 'blur',
    }
  )

  const handleSave = () => {
    return onSubmit(values)
  }

  return (
    <Dialog
      open
      fullWidth
      onClose={ onClose }
    >
      <DialogTitle>
        { title }
      </DialogTitle>

      <DialogContent>
        <div className={ classes.row }>
          <SelectInput
            fullWidth
            label='Type'
            items={ banTypes }
            includeAllItem={ false }
            error={ !!errors.type }
            errorMessage={ errors.type }
            required
            { ...getFieldProps('type') }
          />
        </div>

        <div className={ classes.row }>
          <SelectInput
            fullWidth
            label='Duration'
            items={ durations }
            includeAllItem={ false }
            error={ !!errors.duration }
            errorMessage={ errors.duration }
            required
            { ...getFieldProps('duration') }
          />
        </div>

        <div className={ classes.row }>
          <TextField
            fullWidth
            label='Reason'
            items={ reasons }
            error={ !!errors.reason }
            errorMessage={ errors.reason }
            required
            { ...getFieldProps('reason') }
          />
        </div>
      </DialogContent>

      <DialogActions>
        <CancelButton
          onClick={ onClose }
        />
        <ConfirmButton
          onClick={ handleSave }
          disabled={ !isValid }
        />
      </DialogActions>
    </Dialog>
  )
}

PlayerBanDialog.propTypes = {
  title: PropTypes.string,
  onClose: PropTypes.func,
  onSubmit: PropTypes.func,
}

PlayerBanDialog.displayName = 'PlayerBanDialog'

PlayerBanDialog.defaultProps = {
  title: 'Ban player',
}

const styles = theme => ({
  row: {
    display: 'flex',
    marginBottom: theme.spacing(2),
    flexDirection: 'column',
  },
})

export default withStyles(styles)(PlayerBanDialog)
