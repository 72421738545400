import React, { useMemo, useState } from 'react'
import Card from '@material-ui/core/Card'
import CardContent from '@material-ui/core/CardContent'
import CardHeader from '@material-ui/core/CardHeader'
import Grid from '@material-ui/core/Grid'
import { makeStyles } from '@material-ui/core/styles'
import {
  Link,
  ReferenceManyField,
  ShowButton,
  TextInput
} from 'react-admin'
import { Form } from 'react-final-form'
import { useHistory } from 'react-router-dom'
import { isEqual } from 'lodash'
import ForumIcon from '@material-ui/icons/Forum'
import Email from '@material-ui/icons/Email'

import MessageList from '../messaging/MessageList'
import DateRangeInput from '../components/DateRangeInput'
/* import InfinitePagination from '../components/InfinitePagination' */
import Accordion from '@material-ui/core/Accordion'
import AccordionDetails from '@material-ui/core/AccordionDetails'
import EnhancedAccordionSummary from '../components/EnhancedAccordionSummary'

const useIteratorStyles = makeStyles(theme => ({
  actionArea: {
    margin: 0
  },

  card: {
    width: '100%',
  },

  primaryTitle: {
    flex: '1 0 auto',
  },

  secondaryTitle: {
    color: theme.palette.text.secondary,
  }

}))

const ChannelsIterator = ({ ids = [], data = {}, basePath, channelFilter }) => {
  const classes = useIteratorStyles()

  return <div>
    <Grid container spacing={2}>
      {ids.map(id => (
        <Grid item
          key={id}
          xs={12}
          md={data[id]?.kind === 'CHAT' ? 12 : 6}
          lg={data[id]?.kind === 'CHAT' ? 12 : 4}
        >
          <Accordion>
              <EnhancedAccordionSummary
                id={`channel-${id}-header`}
                ariaControls={`channel-${id}-messages`}
                title={data[id]?.description}
                totalCount=""
                secondaryTitle={'messages'}>
              </EnhancedAccordionSummary>
            <AccordionDetails>
            <Card className={classes.card}>
              <CardHeader
                title={
                  <Link to={`/messaging/channel/${id}/show`}>
                    {data[id]?.description || data[id]?.name || id}
                  </Link>}
                subheader={data[id]?.name}
                avatar={data[id]?.kind === 'CHAT' ? <ForumIcon /> : <Email />}
                action={<ShowButton resource='messaging/channel' basePath={basePath} record={data[id]} />}
                classes={{ action: classes.actionArea }}
              />
              {data[id]?.kind === 'CHAT' ?
                <CardContent>
                  <MessageList filter={{ ...channelFilter, channel: id }} />
                </CardContent>
                : null
              }
              </Card>
              </AccordionDetails>
          </Accordion>
        </Grid>
      ))}
    </Grid>
  </div>
}

const useStyles = makeStyles(theme => ({
  filters: {
    margin: theme.spacing(0, -2, -2, 0),
    '& > *': {
      margin: theme.spacing(0, 2, 2, 0),
    }
  },
}))

const PlayerChatsList = ({ player, ...rest }) => {
  const classes = useStyles()
  const history = useHistory()
  const urlParams = new URLSearchParams(history.location.search)
  const [search, setSearch] = useState(() => {
    const search = {}
    if (urlParams.get('q')) search.q = urlParams.get('q')
    if (urlParams.get('from')) search.from = urlParams.get('from')
    if (urlParams.get('to')) search.to = urlParams.get('to')
    return search
  })

  const updateFilter = (values) => {
    const newSearch = {}
    if (values.q) newSearch.q = values.q
    if (values.from) newSearch.from = values.from
    if (values.to) newSearch.to = values.to
    if (!isEqual(search, newSearch)) {
      setSearch(newSearch)
      history.replace(history.location.pathname + '?' + new URLSearchParams(newSearch))
    }
  }

  const filter = useMemo(() => {
    const filter = { player: player.id }
    if (search.q) filter.q = search.q
    if (search.from) filter.start = search.from
    if (search.to) filter.end = search.to
    return filter
  }, [player, search])

  return <Card>
    <CardContent className={classes.filters}>
      <Form initialValues={search} onSubmit={() => null} validate={updateFilter}>
        {_formProps => (
          <>
            <TextInput source='q' label='Keyword search' />
            <DateRangeInput sourceFrom='from' sourceTo='to' label='Date range' />
          </>
        )}
      </Form>
    </CardContent>
    <CardContent>
      <ReferenceManyField
        resource='accounts'
        record={player}
        target='player'
        basePath='/accounts'
        reference='messaging/channel'
       /*  pagination={<InfinitePagination />}  TODO: Fix bug. This creates incorrect number of pages and pressing next crashes the page. ChannelsIterator uses Accordions so the pagination might not be needed anymore*/
        sort={{ field: 'kind', order: 'ASC' }}
        {...rest}
      >
        <ChannelsIterator channelFilter={filter} />
      </ReferenceManyField>
    </CardContent>
  </Card>

}

export default PlayerChatsList
