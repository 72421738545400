import { useEffect } from 'react'
import { useDataProvider } from 'react-admin'
import { useDispatch, useSelector } from 'react-redux'
import { cacheInit, cacheStore } from '../redux/actions/cache'

const emptyData = { data: null, error: null, loading: true, loaded: false }

/** Fetches resource as react-admin's useGetOne, and caches the result for future fetches.
 * @param {string} resource Name of the resource to fetch
 * @param {string} id ID of the specific resource item to fetch
 * @returns {{data: ?Object, error: ?Object, loading: boolean, loaded: boolean}}
 */
export function useCachedGetOne(resource, id) {
  const key = `${resource}/${id}`
  const cached = useSelector(state => state.cache[key])
  const dispatch = useDispatch()
  const dataProvider = useDataProvider()

  useEffect(() => {
    // cacheInit action calls the function to init cache only if key doesn't exist there yet
    dispatch(cacheInit(key, () =>
      dataProvider.getOne(resource, { id })
        .then(result => {
          dispatch(cacheStore(key, { data: result.data, error: null, loading: false, loaded: true }))
        })
        .catch(error => {
          dispatch(cacheStore(key, { data: null, error, loading: false, loaded: true }))
        })))
    }, [resource, id, key, dataProvider, dispatch])

    // Above cacheInit & cacheStore init the cache with the promise to get the data, and later
    // swap the actual data in its place. If there's still a promise, return the empty data
    if (cached && typeof cached.then !== 'function') {
      return cached
    }
    return emptyData
}

