import React, { useState } from 'react'
import {
  Card,
  CardContent,
  MenuItem,
  TextField as MaterialTextField,
} from '@material-ui/core'
import {
  Datagrid,
  ListContextProvider,
  ReferenceField,
  TextField,
  useGetList,
  useGetOne
} from 'react-admin'
import TimestampText from '../components/TimestampText'
import { useListContextFromArray } from '../utils/listings'

const SeasonInfo = ({ map, seasonDescription }) => {
  const leaderboard = useGetOne('season/leaderboard/map', `${map.id}/${seasonDescription.id}`)
  const leaderboardListContext = useListContextFromArray(
    'season/leaderboard/map',
    (leaderboard && leaderboard.data) ? leaderboard.data.leaderboard : null)

  if (!leaderboard || !leaderboard.data) return null

  return <ListContextProvider value={leaderboardListContext}>
    <Datagrid>
      <TextField label='' source='rank' />
      <ReferenceField label='Guild' source='guild.guildId' reference='guilds' link='show'>
        <TextField source='name' />
      </ReferenceField>
      <TextField label='Domination points' source='guild.score.domination' />
      <TextField label='Outposts owned' source='guild.score.outposts' />
      <TextField label='Power' source='guild.score.power' />
      <TextField label='NPC kills' source='guild.score.npcKills' />
    </Datagrid>
  </ListContextProvider>
}

const MapSeasonsWidget = ({ map }) => {
  const seasons = useGetList('season/list', null, null, { })
  const [selectedSeason, setSelectedSeason] = useState(
    () => (seasons && seasons.ids && seasons.ids.length > 0) ? seasons.ids[0] : null)
  if (!selectedSeason && seasons && seasons.ids && seasons.ids.length > 0) {
    setSelectedSeason(seasons.ids[seasons.ids.length - 1])
  }

  return <Card>
    <CardContent>
      <MaterialTextField
        select
        label='Pick season'
        value={selectedSeason || ''}
        onChange={event => setSelectedSeason(event.target.value)}
      >
        {Object.entries(seasons.data).map(([key, season]) =>
          <MenuItem key={key} value={key}>
            Season {key} from <TimestampText time={season.startTime} /> {}
            to <TimestampText time={season.endTime} />
          </MenuItem>
        )}
      </MaterialTextField>
    </CardContent>
    <CardContent>
      {selectedSeason && seasons.data[selectedSeason] ?
        <SeasonInfo map={map} seasonDescription={seasons.data[selectedSeason]} /> :
        null
      }
    </CardContent>
  </Card>
}

export default MapSeasonsWidget
