import React from 'react'
import plugin from 'js-plugin'

import { combineByKey } from '../utils/plugins'
import TabsView from '../components/TabsView'
import ContentContainer from '../components/ContentContainer'
import ServerToolsHistory from './ServerToolsHistory'

const ServerToolsView = () => {
  var tabs = combineByKey(
    {
      history: {
        label: 'History',
        content: <ServerToolsHistory />,
        rank: 999,
      },
    },
    plugin.invoke('server_tools.tabs'))
  var rankedTabs = Object.values(tabs).sort((a, b) => (a?.rank || 0) - (b?.rank || 0))

  return (
    <ContentContainer title='Server tools'>
      {rankedTabs.length > 1 ?
        <TabsView tabs={Object.values(rankedTabs)} /> :
        <p>No server tools are available for the current game.</p>
      }
    </ContentContainer>
  )
}

export default ServerToolsView
