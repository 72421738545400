import {
  localStorageSet,
  localStorageRemove,
} from './utils/localStorage'

const storageKey = 'google_auth'

const googleAuthProvider = {
  login: params => {
    const { idToken } = params
    if (!idToken) {
      return Promise.reject({
        message: "Google OAuth authentication failed: authentication token missing",
      })
    }
    localStorageSet(storageKey, JSON.stringify(params))

    return new Promise((resolve, reject) => {
      fetch('/api/login/authenticate', {
        method: 'POST',
        body: JSON.stringify(idToken),
        headers: {
          'Content-Type': 'application/json',
        },
      })
        .then(resp => resp.json())
        .then(userInfo => {
          if (typeof userInfo.token === 'string' && userInfo.token !== '') {
            resolve({
              fullName: params.name,
              ...userInfo
            })
          } else if (userInfo.message) {
            console.log('Login failure', userInfo)
            reject({ message: `Login failed: ${userInfo.message}` })
          } else {
            console.log('Login failure', userInfo)
            reject({ message: 'Could not complete the login due to an unknown issue' })
          }
        })
        .catch(error => {
          console.log('Authentication failed', error)
          reject({
            message: 'Failed to contact the Admin Tool authentication backend – are you connected to VPN or office network?',
          })
        })
    })
  },

  logout: () => {
    localStorageRemove(storageKey)
    return Promise.resolve()
  },

  checkAuth: (_authInfo) => {
    // TODO: check validity of the auth token
    return Promise.resolve()
  },

  /* NOTE: part of react-admin AuthProvider interface, but not actually used here. */
  checkError: (_error) => Promise.reject(),
  getIdentity: () => Promise.reject(),
  getPermissions: () => Promise.reject(),
}

export default googleAuthProvider
