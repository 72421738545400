import React, { useMemo } from 'react'
import lodash from 'lodash'
import { useCachedGetOne } from '../utils/caching'

/** @typedef {import('admintool-api/v2/v2_common_inventory').GetItemTypesResponse} GetItemTypesResponse */
/** @typedef {import('react-admin').GetOneResult<GetItemTypesResponse>} ItemTypesGetOneResult */
/** @typedef {import('admintool-api/v2/v2_common_inventory').ItemType} ItemType */

export const formatItem = (item, typesMap) => {
  const name = typesMap[item.typeId] ? typesMap[item.typeId].name : item.typeId
  const count = item.count !== undefined ? `${item.count} × ` : ''
  return count + name
}

const InventoryItemsField = ({ source = null, record = {} }) => {
  /** @type ItemTypesGetOneResult */
  const types = useCachedGetOne('itemtypes', 'latest')

  /** @type Object.<string, ItemType> */
  const typesMap = useMemo(() => {
    if (types.data && types.data.itemTypes) {
      return Object.fromEntries(types.data.itemTypes.map(type => [type.id, type]))
    }
    return {}
  }, [types])

  let itemList = source ? lodash.get(record, source) : record
  if (!itemList) {
    itemList = []
  } else if (!Array.isArray(itemList)) {
    itemList = [itemList]
  }

  return <div>
    {itemList.map(i => formatItem(i, typesMap)).join(', ')}
  </div>
}

export default InventoryItemsField
