import React, { useState } from 'react'
import { SelectArrayInput } from 'react-admin'
import moment from 'moment'
import Chip from '@material-ui/core/Chip'
import Button from '@material-ui/core/Button'
import { makeStyles } from '@material-ui/core/styles'
import KickDialog from './KickDialog';
import MoveDialog from './MoveDialog';
import WithPermission from '../components/WithPermission';
import MapLink from '../components/MapLink'
import GuildLink from '../components/GuildLink'
import MapPreview from './MapPreview'
import ServerMaintenance from './ServerMaintenance'
import Matchmaking from './Matchmaking'
import ClientBuilds from './ClientBuilds'

const useQuickFilterStyles = makeStyles(theme => ({
  chip: {
    marginBottom: theme.spacing(1),
  },
}));

const QuickFilter = ({ label }) => {
  const classes = useQuickFilterStyles()
  return <Chip label={label} className={classes.chip} />
}

const PlayerInfoActions = ({ player }) => {
  const [moveOpen, setMoveOpen] = useState(false)
  const [kickOpen, setKickOpen] = useState(false)
  return <>
    <WithPermission permissions='MovePlayer'>
      <Button color='primary' onClick={() => setMoveOpen(true)}>Move</Button>
    </WithPermission>
    <WithPermission permissions='KickPlayer'>
      <Button color='primary' onClick={() => setKickOpen(true)}>Kick</Button>
    </WithPermission>
    {moveOpen && (
      <MoveDialog
        onClose={() => setMoveOpen(false)}
        player={player}
      />
    )}
    {kickOpen && (
      <KickDialog
        onClose={() => setKickOpen(false)}
        player={player}
      />
    )}
  </>
}

const hydraPlugin = {
  name: 'hydra',
  accounts: {
    show: {
      info: {
        actions: (props) => <PlayerInfoActions {...props} key='hydra' />,
      },
      activity: {
        filterDefaultValues: () => ({
          timeFrom: moment().subtract(1, 'day').startOf('day'),
          timeTo: moment().endOf('day'),
        }),
      },
    },
  },

  guilds: {
    list: {
      filter: {
        normalGuilds: <QuickFilter
          key='normalGuilds'
          source='guildType'
          label='Only non-tutorial guilds'
          defaultValue='normal'
        />,
        openMapsOnly: <QuickFilter
          key='openMapsOnly'
          source='openMapsOnly'
          label='Open maps only'
          defaultValue='true'
        />,
      },
      filterDefaultValues: {
        guildType: 'normal',
        openMapsOnly: 'true',
      },
    },
  },

  maps: {
    list: {
      filterDefaultValues: {
        tierMin: 1,
        status: ['active', 'stable'],
      },
      filter: {
        mapStatus: <SelectArrayInput
          key='mapStatus'
          source='status'
          label='Map status'
          choices={[
            { id: 'active', name: 'active' },
            { id: 'stable', name: 'stable' },
            { id: 'closed', name: 'closed' },
            { id: 'closing', name: 'closing' },
          ]}
        />
      },
    },
    show: {
      tabs: {
        preview: {
          label: 'Preview',
          content: <MapPreview />,
        },
      },
    },
  },

  messaging: {
    channels: {
      show: {
        headeritems: (items, channelId, _channelDetails = {}) => {
          const match = channelId.match(/^([a-z]+)-(\d+)$/)
          if(!match || match.length !== 3) return
          switch(match[1]) {
            case 'map':
              items.push({label: 'Map link', value: <MapLink id={match[2]} /> })
              break
            case 'guild':
              items.push({ label: 'Guild link', value: <GuildLink id={match[2]} /> })
              break
            default:
              break
          }
        },
      }
    }
  },

  server_tools: {
    tabs: {
      maintenance: {
        label: 'Maintenance',
        content: <ServerMaintenance />,
        rank: 1,
      },
      matchmaking: {
        label: 'Matchmaking',
        content: <Matchmaking />,
        rank: 2,
      },
      clientbuilds: {
        label: 'Client builds',
        content: <ClientBuilds />,
        rank: 3,
      },
    },
  },
}

export default hydraPlugin
