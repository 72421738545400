import { Card, CardContent } from '@material-ui/core'
import React from 'react'

import AuditlogListView from '../audit-log/AuditlogListView'

const ServerToolsHistory = () => {
  return <Card>
    <CardContent>
      <AuditlogListView
        title={null}
        actionFilter={['MaintenanceStatusUpdate', 'MatchmakingApply', 'ClientStatusUpdate']}
      />
    </CardContent>
  </Card>
}

export default ServerToolsHistory
