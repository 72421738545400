import React, { useState, useMemo } from 'react'
import {
  BulkActionsToolbar,
  Datagrid,
  Filter,
  Link,
  ListActions,
  ListContextProvider,
  ListToolbar,
  NumberField,
  NumberInput,
  Pagination,
  ShowButton,
  TextField,
  useGetList
} from 'react-admin'
import { makeStyles } from '@material-ui/core/styles'
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Button,
  FormControl,
  FormControlLabel,
  InputLabel,
  MenuItem,
  Select,
  Switch,
  TextField as MaterialTextField,
} from '@material-ui/core'
import { useHistory } from 'react-router-dom'
import { get } from 'lodash'
import plugin from 'js-plugin'
import ForumIcon from '@material-ui/icons/Forum'
import ExpandMoreIcon from '@material-ui/icons/ExpandMore'
import {
  usePaginatedListController,
  useBulkActionsController,
  useGetIndirectList,
  listPropsForData,
  useListContextFromData
} from '../utils/listings'
import { combineByKey } from '../utils/plugins'
import { PlayerDatagrid } from '../players/PlayerList'
import SendMessageDialog from './SendMessageDialog'
import MessageList from './MessageList'
import ChannelMutedPlayers from './ChannelMutedPlayers'
import SendMessageInline from './SendMessageInline'

const resourceToFilter = {
  accounts: 'player',
  guilds: 'guild',
  maps: 'map',
}

const EntityChannelsList = ({ type, id, className }) => {
  const listProps = usePaginatedListController('messaging/channel')
  const searchType = resourceToFilter[type] || type
  const channels = useGetList(
    'messaging/channel', listProps.pagination, listProps.currentSort, { [searchType]: id })

  return <div className={className} >
    <Datagrid
      rowClick='show'
      {...listPropsForData(channels)}
      {...listProps}
    >
      <TextField source='id' label='ID' />
      <TextField source='name' label='Name' />
      <TextField source='description' label='Description' />
    </Datagrid>
    <Pagination {...listPropsForData(channels)} {...listProps} />
  </div>
}

const PlayersList = ({filter}) => {
  const listProps = usePaginatedListController('accounts')
  const entities = useGetList('accounts', listProps.pagination, listProps.currentSort, filter)
  const context = useListContextFromData('accounts', entities, listProps)

return <ListContextProvider value={context}>
    <PlayerDatagrid
      showTitle='Player info'
      rowClick='expand'
      expand={<EntityChannelsList type={'accounts'} />}
    />
    <Pagination />
  </ListContextProvider>
}

const MessageButton = ({ selectedIds, children }) => {
  const [open, setOpen] = useState(false)

  return <>
    <Button
      onClick={() => setOpen(true)}
      size='small'
      color='primary'
    >
      {children}
    </Button>
    {open ?
      <SendMessageDialog
        channels={selectedIds}
        onClose={() => setOpen(false)}
      /> : null}
  </>
}

const LinkField = ({source, linkText, targetResource, record={}, render=null}) => {
  const sourceVal = get(record, source)
  if(sourceVal) {
    return <Link to={`/${targetResource}/${sourceVal}/show`} onClick={e => e.stopPropagation()}>
      {render ? render(record) : get(record, linkText)}
    </Link>
  }
  return <p>{get(record, linkText)}</p>
}

const GuildBulkButtons = props =>
  <MessageButton {...props}>Guild chat message</MessageButton>

const GuildChatsList = ({filter, className}) => {
  const listProps = usePaginatedListController('messaging/channel')
  const bulkProps = useBulkActionsController()

  const channels = useGetIndirectList(
    'guilds', filter,
    'messaging/channel', 'guild', listProps.pagination, listProps.currentSort)

  return <div className={className} >
    <BulkActionsToolbar {...listProps} selectedIds={bulkProps.selectedIds}>
      <GuildBulkButtons />
    </BulkActionsToolbar>
    <Datagrid
      rowClick='show'
      {...listPropsForData(channels)}
      {...listProps}
      {...bulkProps}
    >
      <LinkField source='backref.id' linkText='backref.name' targetResource='guilds' label='Guild' />
      <NumberField source='backref.memberCount' label='Guild members' />
      <TextField source='id' label='Channel ID' />
      <TextField source='name' label='Channel name' />
      <TextField source='description' label='Description' />
      <ShowButton label='Show channel' icon={<ForumIcon />} />
    </Datagrid>
    <Pagination
      {...listPropsForData(channels)}
      {...listProps}
    />
  </div>
}

const MapBulkButtons = props =>
  <MessageButton {...props}>Map chat message</MessageButton>

const MapsList = ({filter, className}) => {
  const listProps = usePaginatedListController('messaging/channel')
  const bulkProps = useBulkActionsController()

  const channels = useGetIndirectList(
    'maps', filter,
    'messaging/channel', 'map', listProps.pagination, listProps.currentSort)

  return <div className={className} >
    <BulkActionsToolbar {...listProps} selectedIds={bulkProps.selectedIds}>
      <MapBulkButtons />
    </BulkActionsToolbar>
    <Datagrid
      rowClick='show'
      {...listPropsForData(channels)}
      {...listProps}
      {...bulkProps}
    >
      <LinkField source='backref.id' targetResource='maps' label='Map'
        render={record => 'Map ' + (record.backref.name || `id ${record.backref.id}`)}
      />
      <LinkField source='backref.id' linkText='backref.mapDesign' targetResource='maps' label='Map design' />
      <TextField source='id' label='Channel ID' />
      <TextField source='name' label='Channel name' />
      <TextField source='description' label='Description' />
      <ShowButton label='Show channel' icon={<ForumIcon />} />
    </Datagrid>
    <Pagination
      {...listPropsForData(channels)}
      {...listProps}
    />
  </div>
}

const MessageSearch = ({filter}) => <MessageList filter={filter} />

const useGlobalChatStyles = makeStyles(theme => ({
  tabPanel: {
    padding: theme.spacing(3, 0),
  },
  accordionContents: {
    flexDirection: 'column',
  },
}))

const GlobalChatList = () => {
  const classes = useGlobalChatStyles()

  return <>
    <Accordion>
      <AccordionSummary expandIcon={<ExpandMoreIcon />}>
        Muted players
      </AccordionSummary>
      <AccordionDetails classes={{root: classes.accordionContents}}>
        <ChannelMutedPlayers
          id='global'
          raProps={{
            basePath: '/messaging/monitor',
          }}
        />
      </AccordionDetails>
    </Accordion>
    <Accordion>
      <AccordionSummary expandIcon={<ExpandMoreIcon />}>
        Send message
      </AccordionSummary>
      <AccordionDetails classes={{root: classes.accordionContents}}>
        <SendMessageInline channelId='global' />
      </AccordionDetails>
    </Accordion>
    <Accordion defaultExpanded>
      <AccordionSummary expandIcon={<ExpandMoreIcon />}>
        Messages
      </AccordionSummary>
      <AccordionDetails classes={{root: classes.accordionContents}}>
        <MessageList filter={{ channel: 'global' }} />
      </AccordionDetails>
    </Accordion>
  </>
}

const filterDefaults = (type) => {
  switch (type) {
    case 'guild':
      return combineByKey(plugin.invoke('guilds.list.filterDefaultValues'))
    case 'map':
      return combineByKey(plugin.invoke('maps.list.filterDefaultValues'))
    case 'word':
    case 'player':
    case 'global':
      break;
    default:
      console.warn('filterDefaults: unknown type', type)
  }
  return {}
}

const TypeSpecificFilters = ({type, filterValues, setFilterValues, ...rest}) => {
  const typeToResource = {
    guild: 'guilds',
    map: 'maps',
    player: 'accounts',
  }
  const filters = useMemo(() => {
    switch (type) {
      case 'guild':
        return <Filter resource='guilds'>
          {Object.values(combineByKey(plugin.invoke('guilds.list.filter')))}
        </Filter>
      case 'map':
        return <Filter resource='maps'>
          <NumberInput source='tierMin' label='Tier at least' defaultValue={1} />
          <NumberInput source='tierMax' label='Tier at most' defaultValue={0} />
          {Object.values(combineByKey(plugin.invoke('maps.list.filter')))}
        </Filter>
      case 'word':
      case 'player':
      case 'global':
        break;
      default:
        console.warn('TypeSpecificFilters: unknown type', type)
    }
    return null
  }, [type])

  if (!filters) return null
  const displayedFilters = {}
  for(const key of Object.keys(filterValues)) {
    if(filterValues[key]) displayedFilters[key] = true
  }
  return <ListToolbar
    resource={typeToResource[type]}
    actions={<ListActions />}
    exporter={false}
    filters={filters}
    filterValues={filterValues}
    showFilter={(name, value) => setFilterValues(values => ({...values, [name]: value}))}
    hideFilter={name => setFilterValues(values => ({...values, [name]: undefined}))}
    displayedFilters={displayedFilters}
    setFilters={setFilterValues}
    {...rest}
  />
}

const useStyles = makeStyles(theme => ({
  root: {
  },
  search: {
    display: 'flex',
    flexWrap: 'wrap',
    margin: theme.spacing(2),
    '& > *': {
      minWidth: '10em',
      margin: theme.spacing(0, 1, 1, 0),
    },
  },
  switch: {
    padding: theme.spacing(2, 0),
  },
  extraFilters: {
    flex: '1 0',
  },
}))

const ChatMonitorSearch = () => {
  const classes = useStyles()
  const history = useHistory()
  const urlParams = new URLSearchParams(history.location.search)
  const [search, setSearch] = useState(() => ({
    type: urlParams.get('type') || 'player',
    value: urlParams.get('value') || '',
    idsOnly: urlParams.get('idsOnly') === 'true',
  }))
  const [filters, setFilters] = useState(() => filterDefaults(search.type))
  const combinedFilters = useMemo(() => {
    var ret = {...filters}
    if (search.type === 'word') {
      if (search.value.trim() !== '') ret['q'] = search.value.trim()
    } else {
      const parts = search.value.split(/[,;\s]/).map(t => t.trim()).filter(t => !!t)
      if (parts.length > 0) {
        if (search.idsOnly) ret['id'] = parts
        else ret['q'] = parts
      }
    }
    return ret
  }, [search, filters])

  const handleSearchChanged = event => {
    const newSearch = { ...search }
    if (event.target.name === 'search-type') {
      if (newSearch.type !== event.target.value) {
        newSearch.type = event.target.value
        setFilters(filterDefaults(newSearch.type))
      }
    } else if (event.target.id === 'search-value') {
      newSearch.value = event.target.value
    } else if (event.target.id === 'search-idsOnly') {
      newSearch.idsOnly = event.target.checked
    } else {
      console.warn(`Unknown control with ID "${event.target.id}" changed the search`)
    }
    setSearch(newSearch)
    history.replace(history.location.pathname + '?' + new URLSearchParams(newSearch))
  }

  const listForTypedSearch = (type, filter) => {
    if (Object.keys(filter).length === 0 && type !== 'global') return null
    switch (type) {
      case 'player':
        return <PlayersList filter={filter} />
      case 'guild':
        return <GuildChatsList filter={filter} />
      case 'map':
        return <MapsList filter={filter} />
      case 'word':
        return <MessageSearch filter={filter} />
      case 'global':
        return <GlobalChatList />
      default:
        console.warn('listForTypedSearch: unknown type', type)
    }
    return null
  }

  return <div className={classes.root}>
    <div className={classes.search}>
      <FormControl variant='filled' margin='dense'>
        <InputLabel htmlFor='search-type'>Search chats for</InputLabel>
        <Select
          value={search.type}
          onChange={handleSearchChanged}
          inputProps={{
            name: 'search-type',
            id: 'search-type',
          }}
        >
          <MenuItem value='player'>Player</MenuItem>
          <MenuItem value='guild'>Guild</MenuItem>
          <MenuItem value='map'>Map</MenuItem>
          <MenuItem value='word'>Word</MenuItem>
          <MenuItem value='global'>Global chat</MenuItem>
        </Select>
      </FormControl>
      <MaterialTextField id='search-value'
        label='Search'
        value={search.type !== 'global' ? search.value : ''}
        onChange={handleSearchChanged}
        disabled={false/*search.type === 'global'*/}
        multiline={search.type !== 'word'}
        helperText={search.type === 'word' ? '' : 'Enter one or more search terms'}
        variant='filled'
        margin='dense'
      />
      <FormControlLabel
        label='Only match IDs'
        className={classes.switch}
        disabled={search.type === 'word' || search.type === 'global'}
        control={
          <Switch
            id='search-idsOnly'
            checked={search.idsOnly}
            onChange={handleSearchChanged}
          />
        }
      />
      <TypeSpecificFilters
        classes={{ toolbar: classes.extraFilters }}
        type={search.type}
        filterValues={filters}
        setFilterValues={setFilters}
      />
    </div>
    { listForTypedSearch(search.type, combinedFilters) }
  </div>
}

export default ChatMonitorSearch
