// This is based on https://github.com/marmelab/react-admin/tree/master/packages/ra-data-simple-rest

import { stringify } from 'query-string'
import {
  fetchUtils,
  GET_LIST,
  GET_ONE,
  GET_MANY,
  GET_MANY_REFERENCE,
  CREATE,
  UPDATE,
  UPDATE_MANY,
  DELETE,
  DELETE_MANY,
} from 'react-admin'
import urlMapper from './urlMapper'

/**
 * Maps react-admin queries to a simple REST API
 *
 * The REST dialect is similar to the one of FakeRest
 * @see https://github.com/marmelab/FakeRest
 * @example
 * GET_LIST     => GET http://my.api.url/posts?sort=['title','ASC']&range=[0, 24]
 * GET_ONE      => GET http://my.api.url/posts/123
 * GET_MANY     => GET http://my.api.url/posts?filter={ids:[123,456,789]}
 * UPDATE       => PUT http://my.api.url/posts/123
 * CREATE       => POST http://my.api.url/posts
 * DELETE       => DELETE http://my.api.url/posts/123
 */
const createRestProvider = (apiUrl, httpClient = fetchUtils.fetchJson) => {

  /**
   * Turns a data object into format that works as body of a PUT/POST request.
   * Notably, this will extract any files and send them separately using multipart/form-data
   * format. The rest of the data will be sent as JSON.
   * @param {Object} data Contents of the form
   * @return {String|FormData} The data in format that can be passed as request body to fetch()
   */
  const generateRequestBody = (data) => {
    const files = {}
    const recurse = (object, path) => {
      const deleteKeys = []
      for (const key in object) {
        const memberKey = [...path, key].join('.')
        if (object[key] && object[key].rawFile) {
          // react-admin FileInput behaviour
          files[memberKey] = object[key]
          deleteKeys.push(key)
        } else if (object[key] instanceof File) {
          // components/FileUpload behaviour
          files[memberKey] = {
            rawFile: object[key],
            title: object[key].name,
          }
          deleteKeys.push(key)
        } else if (object[key] instanceof Object) {
          recurse(object[key], [...path, key])
        }
      }
      for (const key of deleteKeys) {
        delete object[key]
      }
    }

    recurse(data, [])

    if (Object.keys(files).length > 0) {
      const form = new FormData()
      for (const key in files) {
        form.append(key, files[key].rawFile, files[key].title)
      }
      form.append('json', JSON.stringify(data))
      return form
    }
    return JSON.stringify(data)
  }

  /**
   * @param {String} type One of the constants appearing at the top if this file, e.g. 'UPDATE'
   * @param {String} resource Name of the resource to fetch, e.g. 'posts'
   * @param {Object} params The data request params, depending on the type
   * @returns {Object} { url, options } The HTTP request parameters
   */
  const convertDataRequestToHTTP = (type, resource, params) => {
    let url = ''
    const options = {}
    switch (type) {
      case GET_LIST: {
        url = `${apiUrl}/${urlMapper(resource)}`
        const query = {}
        if (params && params.pagination) {
          const { page, perPage } = params.pagination
          query.range = JSON.stringify([
            (page - 1) * perPage,
            page * perPage - 1,
          ])
        }
        if (params && params.sort) {
          const { field, order } = params.sort
          query.sort = JSON.stringify([field, order])
        }
        if (params && params.filter) {
          query.filter = JSON.stringify(params.filter)
        }
        if (Object.keys(query).length) {
          url = `${apiUrl}/${urlMapper(resource)}?${stringify(query)}`
        }
        break
      }
      case GET_ONE:
        url = `${apiUrl}/${urlMapper(resource, params.id)}`
        break
      case GET_MANY: {
        const query = {
          filter: JSON.stringify({ id: params.ids }),
        }
        url = `${apiUrl}/${urlMapper(resource)}?${stringify(query)}`
        break
      }
      case GET_MANY_REFERENCE: {
        const { page, perPage } = params.pagination
        const { field, order } = params.sort
        const query = {
          sort: JSON.stringify([field, order]),
          range: JSON.stringify([
            (page - 1) * perPage,
            page * perPage - 1,
          ]),
          filter: JSON.stringify({
            ...params.filter,
            [params.target]: params.id,
          }),
        }
        url = `${apiUrl}/${urlMapper(resource)}?${stringify(query)}`
        break
      }
      case UPDATE:
        url = `${apiUrl}/${urlMapper(resource, params.id)}`
        options.method = 'PUT'
        options.body = generateRequestBody(params.data)
        break
      case CREATE:
        url = `${apiUrl}/${urlMapper(resource)}`
        options.method = 'POST'
        options.body = generateRequestBody(params.data)
        break
      case DELETE:
        url = `${apiUrl}/${urlMapper(resource, params.id)}`
        options.method = 'DELETE'
        break
      default:
        throw new Error(`Unsupported fetch action type ${type}`)
    }
    return { url, options }
  }

  /**
   * @param {Object} response HTTP response from fetch()
   * @param {String} type One of the constants appearing at the top if this file, e.g. 'UPDATE'
   * @param {String} resource Name of the resource to fetch, e.g. 'posts'
   * @param {Object} params The data request params, depending on the type
   * @param {{url: string}} context Request context for debugging purposes
   * @returns {Object} Data response
   */
  const convertHTTPResponse = (response, type, resource, params, context) => {
    const { headers, json } = response
    switch (type) {
      case GET_LIST:
      case GET_MANY_REFERENCE:
        var total = NaN
        if (headers.has('content-range')) {
          total = parseInt(
            headers
              .get('content-range')
              .split('/')
              .pop(),
            10
          )
        } else {
          console.warn(`Internal error fetching ${context.url} - missing total item count`)
        }

        // Backends that don't provide a total number can give an asterisk instead
        // In such case, total becomes NaN. Since NaN !== NaN, the total seems to constantly change
        return {
          data: json,
          total: isFinite(total) ? total : -1,
        }
      case CREATE:
        if (response.status === 202) return { data: { id: json?.id }, response, json }
        return { data: { ...params.data, id: json?.id }, response, json }
      default:
        return { data: json }
    }
  }

  /**
   * @param {string} type Request type, e.g GET_LIST
   * @param {string} resource Resource name, e.g. "posts"
   * @param {Object} payload Request parameters. Depends on the request type
   * @returns {Promise} the Promise for a data response
   */
  return (type, resource, params) => {
    // simple-rest doesn't handle filters on UPDATE route, so we fallback to calling UPDATE n times instead
    if (type === UPDATE_MANY) {
      return Promise.all(
        params.ids.map(id =>
          httpClient(`${apiUrl}/${resource}/${id}`, {
            method: 'PUT',
            body: JSON.stringify(params.data),
          })
        )
      ).then(responses => ({
        data: responses.map(response => response.json),
      }))
    }
    // simple-rest doesn't handle filters on DELETE route, so we fallback to calling DELETE n times instead
    if (type === DELETE_MANY) {
      return Promise.all(
        params.ids.map(id =>
          httpClient(`${apiUrl}/${resource}/${id}`, {
            method: 'DELETE',
          })
        )
      ).then(responses => ({
        data: responses.map(response => response.json),
      }))
    }

    const { url, options } = convertDataRequestToHTTP(
      type,
      resource,
      params
    )
    return httpClient(url, options).then(response =>
      convertHTTPResponse(response, type, resource, params, {url})
    )
  }
}

export default createRestProvider
