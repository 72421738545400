import React from 'react'
import PropTypes from 'prop-types'
import Dialog from '@material-ui/core/Dialog'
import DialogActions from '@material-ui/core/DialogActions'
import DialogContent from '@material-ui/core/DialogContent'
import DialogTitle from '@material-ui/core/DialogTitle'
import { withStyles } from '@material-ui/core/styles'

import { required } from '../utils/validations'
import useValidation from './useValidation'
import TextField from './TextField'
import SelectInput from './SelectInput'
import CancelButton from './CancelButton'
import ConfirmButton from './ConfirmButton'

const defaultReasons = [
  { value: 'profanity', text: 'Profanity' },
  { value: 'player_request', text: 'Player request' },
  { value: 'name_conflict', text: 'Name conflict' },
]

// NOTE: constraints needs to be put here or useEffect() in useValidation()
// will run in  a loop.
const constraints = {}

const NameEditDialog = ({
  classes,
  title,
  nameLabel,
  nameField,
  reasonLabel,
  reasonField,
  reasons,
  record,
  onClose,
  onSave,
}) => {
  constraints[nameField] = required

  const {
    getFieldProps,
    errors,
    isValid,
    values,
  } = useValidation(
    {
      constraints,
      showErrors: 'blur',
      initialValues: record,
    }
  )

  const handleSave = () => {
    return onSave(values)
  }

  return (
    <Dialog
      open
      fullWidth
      onClose={ onClose }
    >
      <DialogTitle>
        { title }
      </DialogTitle>

      <DialogContent>
        <div className={ classes.row }>
          <TextField
            error={ !!errors[nameField] }
            errorMessage={ errors[nameField] }
            autoFocus
            label={ nameLabel }
            fullWidth
            required
            { ...getFieldProps(nameField) }
          />
        </div>

        <div className={ classes.row }>
          <SelectInput
            fullWidth
            label={ reasonLabel }
            items={ reasons }
            includeAllItem={ false }
            labelField='text'
            { ...getFieldProps(reasonField) }
          />
        </div>
      </DialogContent>

      <DialogActions>
        <CancelButton
          onClick={ onClose }
        />
        <ConfirmButton
          onClick={ handleSave }
          disabled={ !isValid }
        />
      </DialogActions>
    </Dialog>
  )
}

NameEditDialog.propTypes = {
  classes: PropTypes.object.isRequired,
  record: PropTypes.object.isRequired,
  title: PropTypes.string.isRequired,
  nameLabel: PropTypes.string.isRequired,
  reasonLabel: PropTypes.string.isRequired,
  nameField: PropTypes.string.isRequired,
  reasonField: PropTypes.string.isRequired,
  reasons: PropTypes.arrayOf(PropTypes.object).isRequired,
  onClose: PropTypes.func.isRequired,
  onSave: PropTypes.func.isRequired,
}

NameEditDialog.defaultProps = {
  title: 'Edit name',
  nameLabel: 'Name',
  nameField: 'name',
  reasonLabel: 'Reason',
  reasonField: 'reason',
  reasons: defaultReasons,
}

NameEditDialog.displayName = 'NameEditDialog'

const styles = theme => ({
  row: {
    display: 'flex',
    marginBottom: theme.spacing(2),
    flexDirection: 'column',
  },
})

export default withStyles(styles)(NameEditDialog)
