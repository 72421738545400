import React from 'react'
import {
  Filter,
  FunctionField,
  List,
  NumberField,
  ReferenceField,
  SearchInput,
  ShowButton,
  TextField,
} from 'react-admin'
import plugin from 'js-plugin'
import SupervisedUserCircle from '@material-ui/icons/SupervisorAccount'
import { combineByKey } from '../utils/plugins'
import ReactiveDataList from '../components/react-admin-addons/ReactiveDataList'

const GuildsFilter = props => (
  <Filter {...props}>
    <SearchInput
      alwaysOn
      source='q'
      placeholder='Search for guild id or name'
      style={{ width: 300 }}
    />
    {Object.values(combineByKey(plugin.invoke('guilds.list.filter')))}
  </Filter>
)

const GuildDatagrid = ({ showTitle, ...rest }) =>
  <ReactiveDataList
    basePath='/guilds' resource='guilds'
    smallProps={{
      sourcePrimary: 'name',
      sourceSecondary: 'power', titleSecondary: 'Power',
    }}
    {...rest}
  >
    <TextField source='id' label='ID' />
    <TextField source='name' label='Guild name' />
    <ReferenceField label='Map' source='mapId' reference='maps' link='show'>
      <FunctionField render={record => 'Map ' + (record.name || `id ${record.id}`)} />
    </ReferenceField>
    <NumberField source='memberCount' label='Members' sortByOrder='DESC' />
    <NumberField source='capacity' label='Capacity' sortByOrder='DESC' />
    <NumberField source='power' label='Power' sortByOrder='DESC' />
    <TextField source='createdAt' label='Created at' />
    <TextField source='joinability' label='Joinability' />
    <ShowButton label={showTitle} icon={<SupervisedUserCircle />} />
  </ReactiveDataList>

const GuildList = (props) => (
  <List
    { ...props }
    title='Guilds'
    filters={ <GuildsFilter /> }
    bulkActionButtons={ false }
    exporter={ false }
    perPage={ 25 }
    filterDefaultValues={combineByKey(plugin.invoke('guilds.list.filterDefaultValues'))}
  >
    <GuildDatagrid rowClick='show' showTitle='Show' />
  </List>
)

export default GuildList
