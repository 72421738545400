//import simpleRestProvider from 'ra-data-simple-rest'
import { fetchUtils, GET_ONE, UPDATE, DELETE } from 'react-admin'
import simpleRestProvider from './simpleRestProvider'

import { endpoint } from './config/api'
import { getCurrentGameAsync } from './utils/games'
import { getJwtToken } from './authprovider'

const httpClient = async (url, options = {}) => {
  const jwtToken = getJwtToken()

  if (!options.headers) {
    options.headers = new Headers({ Accept: 'application/json' })
  }
  if (jwtToken) {
    options.headers.set('Authorization', `Bearer ${jwtToken}`)
  }

  // Put here any URLs that are not game/env specific.
  const sharedUrls = [
    'api/games',
    'api/roles',
    'api/users',
    'api/login',
  ]
  if (sharedUrls.some(u => url.indexOf(u) === 0)) {
    return await fetchUtils.fetchJson(url, options)
  }

  const currentGame = await getCurrentGameAsync()
  if (!currentGame) throw new Error('Tried to fetch game data, but don\'t know which game')

  const { id, env } = currentGame
  return await fetchUtils.fetchJson(`${id}/${env.toLowerCase()}/${url}`, options)
}

const defaultDataProvider = simpleRestProvider(endpoint, httpClient)

const cacheInfo = {game: '', env: '', loadtime: 0}
let configCache = {}

const dataProvider = () => async (type, resource, params) => {
  // When fetching login config, there might not be a current game available (or any games at all)
  const currentGame = (await getCurrentGameAsync()) ?? { id: 'none', env: 'none' }
  if (resource === 'config' && configCache[params.id]) {
    if (cacheInfo.type !== currentGame.id ||
      cacheInfo.env !== currentGame.env ||
      Date.now() - cacheInfo.loadtime > 60 * 60 * 1000) {
      console.log('Clearing config cache')
      configCache = {}
    } else {
      console.log('Received config.' + params.id + ' from cache')
      return Promise.resolve(configCache[params.id])
    }
  }

  const response = await defaultDataProvider(type, resource, params)

  if (resource === 'config') {
    // react-admin requires that returned data contains field 'id', but config requests never do
    response.data.id = params.id
    configCache[params.id] = response
    cacheInfo.type = currentGame.id
    cacheInfo.env = currentGame.env
    cacheInfo.loadtime = Date.now()
  }

  if ((type === 'GET_LIST' || type === 'GET_MANY' || type === 'GET_MANY_REFERENCE')) {
    if (!Array.isArray(response.data)) {
      // playerId and meta fields come from the api/activity endpoint
      var { playerId: _ignored1, meta: _ignored2, ...filtered } = response.data
      var values = Object.values(filtered)
      if (values.length !== 1) {
        console.log(`Expected object with one attribute as response to ${type} request, instead we got:`, response.data)
      }
      response.data = values[0]
    }
    // Every resource needs to have the field `id`, but it's missing in some APIs.
    switch (resource) {
      case 'scheduledevents':
        response.data.forEach(event => { event.id = event.uri.split('/').pop() })
        break
      case 'scheduledevents/config':
        response.data.forEach(c => { c.id = `${c.configId.id}/${c.configId.version}` })
        break
      case 'guildmembers':
        response.data.forEach(x => { x.id = x.playerId })
        break
      case 'messaging/message':
        response.data.forEach(x => {
          // Treat the combination of message channel and ID as the message ID
          x.originalId = x.id
          x.id = `${x.channel || '-'}/${x.id}`
        })
        break
      default:
      // Fall through
    }
    if (resource.indexOf('activity/') === 0) {
      response.data.forEach(x => { x.id = x.logId })
      console.log(response)
    }
  }

  if (type === 'GET_ONE' && !response.data.id) response.data.id = params.id

  if (type === DELETE && !response.data) {
    // selectedIds reducer in react-admin needs the ID of the deleted item in response data
    response.data = { id: params.id }
  }

  if ((type === GET_ONE || type === UPDATE || type === DELETE) && response.data.id !== params.id) {
    console.error(`ID mismatch! Requested "${params.id}", got "${response.data.id}". Will ignore the received ID and use the requested one instead`)
    response.data.id = params.id
  }
  return response
}

export default dataProvider
