import React, { useState } from 'react'
import { makeStyles } from '@material-ui/core/styles'
import PropTypes from 'prop-types'
import MessageBodyInput from '../messaging/MessageBodyInput'
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogTitle from '@material-ui/core/DialogTitle';
import WithPermission from '../components/WithPermission'
import Divider from '@material-ui/core/Divider';

import { describeError } from '../utils/errors'
import {
  useNotify,
  FormWithRedirect,
  required,
  useMutation,
  useRefresh,
  TextInput,
  useVersion,
} from 'react-admin'

const constraints = {}
const useStyles = makeStyles((theme) => ({
  form: {
    margin: 0,
    padding: theme.spacing(2),
  },

  title: {
    padding: theme.spacing(1, 2, 0, 2),
    margin: 0
  },

  errorLine: {
    padding: theme.spacing(1),
    color : 'red',
    width: 'max-content',
    margin: 0,
  },

}));

const PlayerMessageEditDialog = ({
  player
}) => {

  constraints[player] = required

  const initialValues = {
    message: '',
    errorMessage: '',
    playerId: player.id,
    title: '',
    description: '',
    agentNote: ''
  }

  const [open, setOpen] = React.useState(false);
  const notify = useNotify()
  const refresh = useRefresh()
  const classes = useStyles()
  const version = useVersion()
  const [send, { loading: sending }] = useMutation()
  const [errorMessage, setErrorMessage] = useState('')


  function handleFormSubmit(data) {

    const personalMessage = {
      message: {
        html: data.message,
        title: data.title,
        description: data.description,
      },
      playerId: player.id,
      agentNote: data.agentNote,
    }

    send(
      {
        type: 'create',
        resource: 'messaging/notification',
        payload: {
          data: personalMessage,
        },
      },
      {
        onSuccess: () => {
            notify('Announcement sent successfully')
            setErrorMessage('')
            refresh()
        },
        onFailure: error => {
          const message = describeError(error)
          setErrorMessage(message)
          notify('Sent failed:'+ message, 'error')
        },
      })
  }

  const handleClickOpen = () => 
  {
    setOpen(true);
    setErrorMessage('')
  }
  const handleClose = () => setOpen(false);

  return <>

<WithPermission permissions='SendAnnouncement'>

    <Button variant="outlined" color="primary" onClick={handleClickOpen}>MESSAGE
    </Button>

    <Dialog open={open} onClose={handleClose} aria-labelledby="form-dialog-title" className={classes.form}   >
      <DialogTitle id="personal-message-form-dialog-title" className={classes.title} >{"New message to " + player.publicName}   </DialogTitle>

      <Divider></Divider>
        
      <div className={classes.form}>
        <FormWithRedirect

          save={handleFormSubmit}
          saving={sending}
          initialValues={initialValues}
          version={version}
          render={formProps => {

            return <>
              <TextInput
                source='title'
                autoFocus
                margin="dense"
                placeholder="Title"
                type="text"
                fullWidth
                size="small"
                validate={required()}
              />
              <TextInput
                source='description'
                autoFocus
                margin="dense"
                placeholder="Description"
                type="text"
                fullWidth
                size="small"
                validate={required()}
              />
              <MessageBodyInput
                source='message'
                label='Message'
                fullWidth
                validate={required()}
              />
              <TextInput
                source='agentNote'
                label=''
                autoFocus
                margin="dense"
                placeholder="Write comments"
                type="text"
                size="small"
                fullWidth
                helperText="Support agent notes (Optional)"
              />

              <p className={classes.errorLine} >{errorMessage}</p>  

              <DialogActions>
                <Button
                  variant='contained'
                  color='primary'
                  disabled={formProps.pristine || formProps.invalid}
                  onClick={formProps.handleSubmitWithRedirect}
                >SEND</Button>

                <Button color='primary'
                  onClick={handleClose}
                >CANCEL</Button>
              </DialogActions>
            </>
          }
          }
        />
      </div>
    </Dialog>
    </WithPermission>
  </>
}

PlayerMessageEditDialog.propTypes = {
  player: PropTypes.object.isRequired
}

PlayerMessageEditDialog.displayName = 'PlayerMessageEditDialog'

export default (PlayerMessageEditDialog)
