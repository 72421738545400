import React, { Component } from 'react'
import { Admin, Resource } from 'react-admin'
import { createMuiTheme } from '@material-ui/core/styles'
import { createBrowserHistory } from 'history'
import URI from 'urijs'

import customSagas from './sagas'
import dataProviderFactory from './dataProvider'
import authProvider from './authprovider'
import players from './players'
import guilds from './guilds'
import maps from './maps'
import { channels } from './messaging'
import { LoginPage, Layout, Menu, customRoutes } from './layout'
import customReducers from './redux/reducers'
import { setRedirectUri } from './utils/general'

const theme = createMuiTheme({
  palette: {
    secondary: {
      light: '#6ec6ff',
      main: '#2196f3',
      dark: '#0069c0',
      contrastText: '#fff',
    },
  },
  typography: {
    title: {
      fontWeight: 400,
    },
  },
  props: {
    MuiButtonBase: {
      disableRipple: false,
    },
  },
})

const history = createBrowserHistory()

class App extends Component {
  state = {
    dataProvider: dataProviderFactory(),
  }

  componentDidMount() {
    const uri = new URI()
    let path = uri.path()
    if (path !== '/login') {
      if (uri.query()) {
        path += `?${uri.query()}`
      }
      setRedirectUri(path)
    }
  }

  render() {
    const { dataProvider } = this.state

    return (
      <Admin
        history={ history }
        dataProvider={ dataProvider }
        theme={ theme }
        customSagas={ customSagas }
        authProvider={ authProvider }
        menu={ Menu }
        loginPage={ LoginPage }
        layout={ Layout }
        logoutButton={ null }
        customRoutes={ customRoutes }
        customReducers={ customReducers }
        disableTelemetry
      >
        <Resource
          name='accounts'
          { ...players }
        />
        <Resource name='accounts/devices' />
        <Resource
          name='guilds'
          { ...guilds }
        />
        <Resource name='guildmembers' />
        <Resource
          name='maps'
          { ...maps }
        />
        <Resource name='maps/pois' />
        
        <Resource name='inventory' />
        <Resource name='config' />
        <Resource name='itemtypes' />
        <Resource name='auditlog/search' />
        <Resource name='auditlog' options={{ label: 'Audit logs' }} />

        <Resource
          name='messaging/channel'
          {...channels}
        />
        <Resource name='messaging/message' />
        <Resource name='messaging/report' />
        <Resource name='messaging/announcement' />
        <Resource name='messaging/ban' />
        <Resource name='leaderboard/board' />
        <Resource name='activity' />
        <Resource name='games' />
        <Resource name='users' />
        <Resource name='roles' />
        <Resource name='boost' />
        <Resource name='boost/activate' />
        <Resource name='missions/player' />
        <Resource name='season/list' />
        <Resource name='season/player' />
        <Resource name='season/guild' />
        <Resource name='season/leaderboard/guild' />
        <Resource name='season/leaderboard/map' />
        <Resource name='scheduledevents' />
        <Resource name='scheduledevents/config' />
        <Resource name='research/guild' />
        <Resource name='environment' />
        <Resource name='environment/clientbuilds/build' />
        <Resource name='environment/clientbuilds/env' />
        <Resource name='events' />
      </Admin>
    )
  }
}

export default App

