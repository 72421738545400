import React from 'react'
import { Link, useGetMany } from 'react-admin'

const GuildLinkInner = ({ id }) => {
  // useGetMany combines queries in a larger batch, in case this component is used in e.g. a list
  const guildList = useGetMany('guilds', [id])
  const guild = guildList?.data?.[0]

  return <Link to={`/guilds/${id}/show`}>
    {guild && guild.name ? guild.name : `Guild #${id}`}
  </Link>
}

const GuildLink = ({ id }) => {
  if (!id) return <span>No guild</span>
  return <GuildLinkInner id={id} />
}

export default GuildLink
