import React, { useState, Fragment } from 'react'
import Chip from '@material-ui/core/Chip'
import {
  useGetList,
  useRefresh,
  ListToolbar,
  Datagrid,
  Pagination,
  TextField,
  FunctionField,
  ListContextProvider,
  Filter,
  SearchInput,
} from 'react-admin'

import EditButton from '../components/EditButton'
import UserEditDrawer from './UserEditDrawer'
import AddButton from '../components/AddButton'
import { Typography, CardContent } from '@material-ui/core'
import { useListContextFromData, usePaginateDataOnClient, useSortDataOnClient, useFilterDataOnClient } from '../utils/listings'
import { filterMatchAny } from '../utils/dataTransforms'

const RoleChipsField = ({ source, record = {}, roles, game }) => {
  if (!roles.loaded) return null
  const roleAssignments = record[source] || []
  const rolesEnvs = {}
  const globalRoles = []
  const hidden = {}
  roleAssignments.forEach(assignment => {
    const role = roles.data[assignment.roleId]
    const roleGame = role ? `${role.name}@${role.game}` : null
    if (!role) {
      if (!rolesEnvs[assignment.roleId]) rolesEnvs[assignment.roleId] = []
      rolesEnvs[assignment.roleId].push(assignment.environment)
    } else if (!role.game) {
      globalRoles.push(role)
    } else if (!game || game.id === role.game) {
      if (!rolesEnvs[roleGame]) rolesEnvs[roleGame] = []
      rolesEnvs[roleGame].push(assignment.environment)
    } else {
      hidden[roleGame] = true
    }
  })
  const hiddenCount = Object.keys(hidden).length
  return <Fragment>
    {globalRoles.map(r =>
      <Chip key={r.name} label={r.name} style={{ marginRight: 10 }} />)}
    {Object.entries(rolesEnvs).map(([name, envs]) =>
      <Chip
        key={name}
        label={`${name} (${envs.map(env => env || 'All').join(', ')})`}
        style={{ marginRight: 10 }}
      />
    )}
    {hiddenCount > 0 ?
      (hiddenCount === 1 ?
        <Typography variant='body2'>(one role in another game)</Typography> :
        <Typography variant='body2'>({Object.keys(hidden).length} roles in other games)</Typography>) :
      null}
  </Fragment>
}

const UserListView = ({ game }) => {
  const [selectedUser, setSelectedUser] = useState(null)
  const roles = useGetList('roles')
  const games = useGetList('games')
  const users = useGetList('users')

  const refresh = useRefresh()

  const handleAddUser = () => {
    setSelectedUser({
      email: '',
      roles: [],
      _isNew: true,
    })
  }

  const usersContext = useListContextFromData(
    'users',
    usePaginateDataOnClient(
      useSortDataOnClient(
        useFilterDataOnClient(users, {
          q: filterMatchAny(),
        }))))

  return <>
    <ListContextProvider value={usersContext}>
      <CardContent>
        <ListToolbar
          filters={
            <Filter>
              <SearchInput source='q' alwaysOn />
            </Filter>
          }
          actions={
            <AddButton
              text='Add User'
              onClick={handleAddUser}
            />
          }
        />
      </CardContent>
      <Datagrid>
        <TextField source='email' label='E-Mail' />
        <RoleChipsField source='roleAssignments' label='Roles' roles={roles} game={game} />
        <FunctionField render={record => (
          <EditButton onClick={() => { setSelectedUser(record) }} />
        )} />
      </Datagrid>
      <Pagination rowsPerPageOptions={usersContext.rowsPerPageOptions} />
    </ListContextProvider>
    {roles.loaded && games.loaded && (
      <UserEditDrawer
        open={!!selectedUser}
        user={selectedUser}
        onClose={() => { setSelectedUser(null) }}
        onRequestHide={() => {
          setSelectedUser(null)
          refresh()
        }}
        roles={roles.data}
        games={games.data}
      />
    )}
  </>
}

UserListView.displayName = 'UserListView'

export default UserListView
