import React from 'react'
import { AppBar } from 'react-admin'
import Typography from '@material-ui/core/Typography'
import { makeStyles } from '@material-ui/styles'
import { useMediaQuery } from '@material-ui/core'

import UserMenu from './UserMenu'
import InstanceSelector from './InstanceSelector'
import Permalink from './Permalink'
import { useCurrentGame } from '../utils/games'

const useStyles = makeStyles(_theme => ({
  title: {
    flex: '1 0 auto',
    textOverflow: 'ellipsis',
    whiteSpace: 'nowrap',
    overflow: 'hidden',
  },
  spacer: {
    flex: 1,
  },
  game: {
    color: '#fff',
  },
}))

const CustomAppBar = ({
  ...rest
}) => {
  const currentGame = useCurrentGame()
  const classes = useStyles()
  const isXSmall = useMediaQuery(theme => theme.breakpoints.down('xs'));
  const isSmall = useMediaQuery(theme => theme.breakpoints.down('sm'));

  return <AppBar
    userMenu={<UserMenu />}
    {...rest}
  >
    <Typography
      variant='h6'
      color='inherit'
      className={classes.title}
      id='react-admin-title'
    />
    {currentGame && !isXSmall &&
      <Typography
        variant='h6'
        className={classes.game}
      >
        {`${currentGame.id} - ${currentGame.env}`}
      </Typography>
    }
    <span className={classes.spacer} />
    {isSmall ? null : <InstanceSelector />}
    <Permalink />
  </AppBar>
}

CustomAppBar.displayName = 'CustomAppBar'

const GlobalAppBar = CustomAppBar
export { GlobalAppBar }

export default CustomAppBar
