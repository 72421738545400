import React from 'react'
import PropTypes from 'prop-types'
import Card from '@material-ui/core/Card'
import { Title } from 'react-admin'

const ContentContainer = ({
  title,
  children,
  ...rest
}) => (
    <Card {...rest}>
      {title ? <Title title={title} /> : null}
      {children}
    </Card>
  )

ContentContainer.displayName = 'ContentContainer'

ContentContainer.propTypes = {
  title: PropTypes.string,
}

export default ContentContainer
