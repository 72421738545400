function formatDuration(seconds) {
  let mag = Math.abs(seconds)
  let sgn = seconds > 0 ? '' : '-'
  let y = (mag / 31536000) | 0
  mag -= y * 31536000
  let d = (mag / 86400) | 0
  mag -= d * 86400
  let h = (mag / 3600) | 0
  mag -= h * 3600
  let m = (mag / 60) | 0
  mag -= m * 60
  let s = mag | 0
  mag -= s

  if (y > 0 && d === 0)
    return `${sgn}${y}y`
  if (y > 0)
    return `${sgn}${y}y ${d}d`

  if (d > 0 && h === 0)
    return `${sgn}${d}d`
  if (d > 0)
    return `${sgn}${d}d ${h}h`

  if (h > 0 && m === 0)
    return `${sgn}${h}h`
  if (h > 0)
    return `${sgn}${h}h ${m}m`

  if (m > 0 && s === 0)
    return `${sgn}${m}m`
  if (m > 0)
    return `${sgn}${m}m ${s}s`

  return `${sgn}${s}s`
}

export function formatBan(ban) {
    let duration = 'permanent'
    if (ban.end != null) {
      let d_ms = new Date(ban.end).getTime() - new Date(ban.start).getTime()
      duration = formatDuration(d_ms / 1000)
    }
    return `${ban.type}: ${duration}`
}

export function formatSpend(player) {
  if (typeof player.iapGrossSpend !== 'number')
    return '0 R$'

  return player.iapGrossSpend + ' R$'
}