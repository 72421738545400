import React, { useMemo, useState } from 'react'
import {
  Card,
  CardContent,
  Grid,
  MenuItem,
  TextField as MaterialTextField,
} from '@material-ui/core'
import {
  BooleanField,
  Datagrid,
  Link,
  ListContextProvider,
  useGetList,
  useGetOne
} from 'react-admin'
import TimestampText from '../components/TimestampText'
import ShowItem from '../components/ShowItem'
import GuildLink from '../components/GuildLink'
import MapLink from '../components/MapLink'
import { useListContextFromArray } from '../utils/listings'
import { formatItem } from '../components/InventoryItemsField'
import { describeError } from '../utils/errors'
import { useCachedGetOne } from '../utils/caching'

/** @typedef {import('admintool-api/v1/season').RewardPackConfig} RewardPackConfig */
/** @typedef {import('admintool-api/v2/v2_common_inventory').GetItemTypesResponse} GetItemTypesResponse */
/** @typedef {import('react-admin').GetOneResult<GetItemTypesResponse>} ItemTypesGetOneResult */
/** @typedef {import('admintool-api/v2/v2_common_inventory').ItemType} ItemType */

const SeasonRewardField = ({ record = {} }) => {
  const seasonConfig = useGetOne('config', 'season')
  /** @type ItemTypesGetOneResult */
  const itemTypes = useCachedGetOne('itemtypes', 'latest')

  /** @type Object.<string, ItemType> */
  const itemTypesMap = useMemo(() => {
    if (itemTypes.data && itemTypes.data.itemTypes) {
      return Object.fromEntries(itemTypes.data.itemTypes.map(type => [type.id, type]))
    }
    return {}
  }, [itemTypes])

  const packTypesMap = useMemo(() => {
    if (seasonConfig.data && seasonConfig.data.rewardPacks) {
      return Object.fromEntries(seasonConfig.data.rewardPacks.map(pack => [pack.id, pack]))
    }
    return {}
  }, [seasonConfig])

  const count = (record.count !== undefined) ? `${record.count} × ` : ''
  const packType = itemTypesMap[record.typeId] ? itemTypesMap[record.typeId].name : record.typeId
  /** @type RewardPackConfig */
  const packConfig = packTypesMap[record.typeId]
  const packItems = packConfig?.items || []
  return <Grid container>
    <Grid item xs={6}>
      {count}{packType}
    </Grid>
    <Grid item xs={6}>
      {packItems.map((pack, i) => {
        const chance = (pack.chance && pack.chance !== 1) ? `with ${pack.chance * 100}% chance: ` : ''
        const contents = pack.item || []
        return <div key={`${packConfig.id}-${i}`}>
          {chance}
          {contents.map(x => formatItem(x, itemTypesMap)).join(', ')}
        </div>
      })}
    </Grid>
  </Grid>
}

const SeasonInfo = ({ player, seasonDescription }) => {
  const seasonFetch = useGetOne('season/player', `${player.id}/${seasonDescription.id}`)
  const season = seasonFetch.data || {}
  const score = season.score || {}
  const processedRewards = useMemo(() => {
    // In Hydra, claimed-status of a reward is encoded into reward ID. Let's extract it
    if (!season.rewards) return []
    return season.rewards.map(reward => {
      const newReward = {...reward}
      const parts = reward.id?.split(':')
      if (parts && parts.length === 3) {
        if (parts[2].toLowerCase() === 'true') newReward.claimed = true
        else if (parts[2].toLowerCase() === 'false') newReward.claimed = false
      }
      return newReward
    });
  }, [season.rewards])
  const rewardsListContext = useListContextFromArray('inventory', processedRewards)

  if (seasonFetch.error) return <p>{describeError(seasonFetch.error)}</p>

  return <Grid container spacing={4}>
    <Grid container item xs={6}>
      <ShowItem label='Fame points'>
        {score.fame !== undefined ? score.fame : '?'}
      </ShowItem>

      <ShowItem label='Season played in guild'>
        {season.guildId !== undefined ? <GuildLink id={season.guildId} /> : '?'}
      </ShowItem>

      <ShowItem label='Season played on map'>
        {season.mapId !== undefined ? <MapLink id={season.mapId} /> : '?'}
      </ShowItem>
    </Grid>

    <Grid container item xs={6}>
      <ShowItem label='NPC kills'>
        {score.npcKills !== undefined ? score.npcKills : '?'}
      </ShowItem>

      <ShowItem label='Guild position'>
        {season.rank !== undefined ? season.rank : '?'} {}
        {season.guildId !== undefined ?
          <Link to={`/guilds/${season.guildId}/show/seasons`}>
            View guild leaderboard
          </Link>
          : null
        }
      </ShowItem>

      <ShowItem label='Reward level'>
        {season.rewardLevel !== undefined ? season.rewardLevel : '?'}
      </ShowItem>
    </Grid>

    <Grid item xs={12}>
      {season.rewards && (
        season.rewards.length > 0 ?
          <ListContextProvider value={rewardsListContext}>
            <Datagrid>
              <SeasonRewardField label='Reward' />
              <BooleanField source='claimed' />
            </Datagrid>
          </ListContextProvider>
        : <p>No rewards</p>
      )}
    </Grid>
  </Grid>
}

const PlayerSeasonsWidget = ({ player }) => {
  const seasons = useGetList(
    'season/list',
    { page: 1, perPage: 200 },
    { field: 'start_time', order: 'DESC' },
    { player: (player ? player.id : undefined) })
  const [selectedSeason, setSelectedSeason] = useState(
    () => (seasons && seasons.ids && seasons.ids.length > 0) ? seasons.ids[0] : null)
  if (!selectedSeason && seasons && seasons.ids && seasons.ids.length > 0) {
    setSelectedSeason(seasons.ids[0])
  }

  return <Card>
    <CardContent>
      <MaterialTextField
        select
        label='Pick season'
        value={selectedSeason || ''}
        onChange={event => setSelectedSeason(event.target.value)}
      >
        {Object.entries(seasons.data).map(([key, season]) =>
          <MenuItem key={key} value={key}>
            Season {key} from <TimestampText time={season.startTime} /> {}
            to <TimestampText time={season.endTime} />
          </MenuItem>
        )}
      </MaterialTextField>
    </CardContent>
    <CardContent>
      {selectedSeason && seasons.data[selectedSeason] ?
        <SeasonInfo player={player} seasonDescription={seasons.data[selectedSeason]} /> :
        null
      }
    </CardContent>
  </Card>
}

export default PlayerSeasonsWidget
