import React, { useEffect, useMemo, useRef, useState } from 'react'
import { Card, CardContent, CardHeader, Grid } from '@material-ui/core'
import { Link, useGetOne } from 'react-admin'
import {
  INITIAL_VALUE,
  ReactSVGPanZoom,
  TOOL_AUTO,
} from 'react-svg-pan-zoom'
import chroma from 'chroma-js'

import { describeError } from '../utils/errors'
import { territoryBorderToPath } from './maputils'
import ShowItem from '../components/ShowItem'
import PlayerLink from '../components/PlayerLink'

/**
 * @typedef {Object} Guild
 * @property {number} id
 * @property {string} name
 * @property {string} shortName
 * @property {{color: string, secondaryColor: string}} details
 */
/**
 * @typedef {Object} Poi
 * @property {string} type
 * @property {number} playerId
 * @property {number} guildId
 * @property {number} contesterGuildId
 */
/** @typedef {{id: number, border: string, guildId: number, contesterGuildId: number}} Territory */
/** @typedef {{x: number, y: number, poi: Poi, territory: number}} Hex */
/** @typedef {{guildId: number, contesterGuildId: number}} Contest */
/** @typedef {{minx: number, miny: number, maxx: number, maxy: number}} Bounds */
/**
 * @typedef {Object} MapData
 * @property {Object.<number, Guild>} guilds
 * @property {Object.<number, Territory} territories
 * @property {Object.<string, Hex>} hexes
 * @property {Array.<Contest>} contests
 * @property {Bounds} bounds
 */

// Scale X-coordinates to obtain a nice aspect ratio for the resulting image
const scaleFactor = 1.8

/**
 * Gets the guild colour for given object.
 * @param {{guildId: (string|number)}} obj 
 * @param {Object.<string, Guild>} guilds 
 */
const getColor = (obj, guilds) => {
  if (!obj || !obj.guildId) return '#0d1f6f'
  return '#' + guilds[obj.guildId].details.color
}

/**
 * SVG viewBox attribute that includes hexes within given bounds
 * @param {Bounds} bounds 
 */
const viewBoxForBounds = (bounds) => {
  const maxx = (bounds.maxx + 1) * scaleFactor
  const maxy = bounds.maxy + 1
  const minx = (bounds.minx - 1) * scaleFactor
  const miny = (bounds.miny - 1)
  return `${minx} ${miny} ${maxx - minx} ${maxy - miny}`
}

const MapPreview = ({ map }) => {
  const mapContainer = useRef(null)
  const Viewer = useRef(null);
  const [tool, setTool] = useState(TOOL_AUTO)
  const [value, setValue] = useState(INITIAL_VALUE)
  const [clickedHex, setClickedHex] = useState(null)

  const mapData = useGetOne('maps/pois', map.id)

  /** @type {MapData} */
  const mapContents = useMemo(() => {
    if (!mapData || !mapData.data) return null

    const map = mapData.data
    const mapTerritories = map?.territories || []
    const mapTerritoryControllers = map?.territoryControllers || []
    const mapPois = map?.pois || []
    const mapHexes = map?.mapHexes || []

    /** @type {Object.<string, Hex>} */
    const hexmap = Object.fromEntries(mapHexes.map(hex => [`${hex.x}/${hex.y}`, hex]))
    mapPois.forEach(poi => {
      hexmap[`${poi.position.x}/${poi.position.y}`].poi = {
        type: poi.type,
        playerId: poi.playerId,
        guildId: poi.guildId,
        contesterGuildId: poi.contesterGuildId,
      }
    })
    mapTerritories.forEach(territory =>
      territory.hexes.forEach(hex =>
        hexmap[`${hex.x}/${hex.y}`].territory = territory.id))

    /** @type {Object.<string, Guild>} */
    const guilds = Object.fromEntries(mapData.data.guilds.map(g => [g.id, g]))

    /** @type {Object.<string, Territory>} */
    const territories = Object.fromEntries(mapTerritories.map(territory => {
      const border = territoryBorderToPath(territory)
      return [territory.id, { id: territory.id, border, guildId: 0, contesterGuildId: 0 }]
    }))
    mapTerritoryControllers.forEach(tc => territories[tc.id].guildId = tc.guildId)

    /** @type {Array.<Contest>} */
    const contests = []
    Object.values(hexmap).forEach(hex => {
      if (hex.poi?.type === 'OUTPOST' && hex.poi?.contesterGuildId) {
        contests.push({ guildId: hex.poi.guildId, contesterGuildId: hex.poi.contesterGuildId })
        territories[hex.territory].contesterGuildId = hex.poi.contesterGuildId
      }
    })

    /** @type {Bounds} */
    const bounds = { minx: Infinity, miny: Infinity, maxx: -Infinity, maxy: -Infinity }
    mapHexes.forEach(hex => {
      if (hex.x < bounds.minx) bounds.minx = hex.x
      if (hex.y < bounds.miny) bounds.miny = hex.y
      if (hex.x > bounds.maxx) bounds.maxx = hex.x
      if (hex.y > bounds.maxy) bounds.maxy = hex.y
    })

    return { guilds, territories, hexes: hexmap, contests, bounds }
  }, [mapData])

  useEffect(() => {
    const fit = () => {
      if (Viewer.current) Viewer.current.fitToViewer()
      else window.setTimeout(fit)
    }
    fit()
  }, [mapContents]);

  const handleClick = (event) => {
    // Image coordinates to map coordinates
    const x = event.x / scaleFactor
    const y = -event.y
    // Closest rectangle block to the hex
    const xRow = Math.round(x)
    const yShift = (Math.abs(xRow) % 2)
    const yRow = Math.round((y + yShift) / 2) * 2 - yShift

    // Most likely: the rectangle and the hex are the same
    const guessed = { x: xRow, y: yRow }
    const guessedDiscance = Math.abs(guessed.x - x) + Math.abs(guessed.y - y)
    // Corner case (literally): corners of the rectangle belong to the next hex
    const contester = {
      x: x > xRow ? xRow + 1 : xRow - 1,
      y: y > yRow ? yRow + 1 : yRow - 1,
    }
    const contesterDistance = Math.abs(contester.x - x) + Math.abs(contester.y - y)

    if (guessedDiscance < contesterDistance) setClickedHex(guessed)
    else setClickedHex(contester)
  }

  const size = mapContainer.current ? mapContainer.current.clientWidth : null

  return <Card>
    <CardContent>
      <Grid container spacing={2} alignItems='stretch'>
        <Grid item xs={12} md={8} ref={mapContainer}>
          {mapData.error && <p>{describeError(mapData.error)}</p>}
          {(mapContents && size) ?
            <ReactSVGPanZoom
              ref={Viewer}
              width={size} height={size}
              tool={tool} onChangeTool={setTool}
              value={value} onChangeValue={setValue}
              onClick={handleClick}
            >
              <svg
                xmlns="http://www.w3.org/2000/svg"
                {...{ 'xmlns:xlink': 'http://www.w3.org/1999/xlink' }}
                viewBox={viewBoxForBounds(mapContents.bounds)}
              >
                <Map map={mapContents} />
              </svg>
            </ReactSVGPanZoom>
            :
            <p>Map loading...</p>
          }
        </Grid>
        <Grid item xs={12} md={4}>
          <PoiDetails map={mapContents} location={clickedHex} />
        </Grid>
      </Grid>
    </CardContent>
  </Card>
}

/**
 * @param {{location: {x: number, y: number}, map: MapData}} props
 */
const PoiDetails = ({ location, map }) => {
  const item = map?.hexes[`${location?.x}/${location?.y}`]
  if (!location || !item) {
    return <Card elevation={0}>
      <CardHeader
        avatar={<HexIcon />}
        title='Nothing selected'
      />
    </Card>
  }

  const territory = item.territory !== undefined ? map.territories[item.territory] : null

  let title = item.poi?.type
  switch (item.poi?.type) {
    case 'RESERVED':
      if (territory) title = 'Reserved area'
      else title = 'Outside playable map'
      break
    case 'PLAYER_BASE':
      title = 'Player base'
      break
    case 'MINING_SPOT':
      title = 'Mining spot'
      break
    case 'OUTPOST':
      if (item.poi?.contesterGuildId) title = 'Contested outpost'
      else if (item.poi?.guildId) title = 'Outpost'
      else title = 'Open outpost'
      break
    case 'NPC':
      title = 'Crooks'
      break
    default:
    // Fall through
  }
  if (!item.poi?.type) {
    if (territory) {
      if (territory.contesterGuildId) title = 'Contested territory'
      else if (territory.guildId) title = 'Guild territory'
      else title = 'Open territory'
    } else {
      title = 'Between territories'
    }
  }

  return <Grid container direction='column' justify='space-between' style={{height: '100%'}}>
    <Grid item>
      <Card elevation={0}>
        <CardHeader
          avatar={
            <HexIcon
              backgroundColor={getColor(territory, map.guilds)}
              poiType={item.poi?.type}
              poiColor={getColor(item.poi, map.guilds)}
              contesterColor={territory?.contesterGuildId ?
                getColor({ guildId: territory.contesterGuildId }, map.guilds) : null}
            />
          }
          title={title}
          subheader={`x: ${location.x}, y: ${location.y}`}
        />
        <CardContent>
          {!!item.poi?.playerId &&
            <ShowItem label='Base owner'>
              <PlayerLink
                id={item.poi.playerId}
                startIcon={player => (
                  <ColorBadge color={getColor({ guildId: player?.guildId }, map.guilds)} />
                )}
              />
            </ShowItem>
          }
          {!!territory?.guildId &&
            <ShowItem label='Territory owner'>
              <Link to={`/guilds/${territory.guildId}/show`}>
                <span><ColorBadge color={getColor(territory, map.guilds)} /> </span>
                {map.guilds[territory.guildId]?.name || `Guild #${territory.guildId}`}
              </Link>
            </ShowItem>
          }
          {!!territory?.contesterGuildId &&
            <ShowItem label='Contesting guild'>
              <Link to={`/guilds/${territory.contesterGuildId}/show`}>
                <span><ColorBadge color={getColor({ guildId: territory.contesterGuildId }, map.guilds)} /> </span>
                {map.guilds[territory.contesterGuildId]?.name || `Guild #${territory.contesterGuildId}`}
              </Link>
            </ShowItem>
          }
        </CardContent>
      </Card>
    </Grid>
    <Grid item>
      {!!territory && <p>Territory id: {territory.id}</p>}
    </Grid>
  </Grid>
}

const ColorBadge = ({ color, width = 16, height = 16 }) => {
  return <svg
    xmlns="http://www.w3.org/2000/svg"
    width={width} height={height}
    viewBox='0 0 1 1'
  >
    <rect x='0' y='0' width='1' height='1' fill={color} stroke='black' strokeWidth='0.1' />
  </svg>
}

const HexIcon = ({ backgroundColor, poiType, poiColor, contesterColor }) => {
  const fill = backgroundColor || '#0d1f6f'
  const isDarkBackground = chroma(fill).hsl()[2] <= 0.4
  const stroke = chroma(fill).darken(2)
  const poiFill = poiColor || '#aaaaaa'
  const poiStroke = isDarkBackground ? chroma(poiFill).brighten(4) : chroma(poiFill).darken(4)

  return <svg
    xmlns="http://www.w3.org/2000/svg"
    xmlnsXlink='http://www.w3.org/1999/xlink'
    viewBox='-1.1 -1.1 2.2 2.2'
    width={64} height={64}
  >
    <path
      d='M 1 0 L 0.5 0.8 L -0.5 0.8 L -1 0 L -0.5 -0.8 L 0.5 -0.8 z'
      fill={fill}
    />
    {contesterColor &&
      <path
        d='M 1 0 L 0.5 0.8 L -0.5 -0.8 L 0.5 -0.8 z'
        fill={contesterColor}
      />
    }
    <path
      d='M 1 0 L 0.5 0.8 L -0.5 0.8 L -1 0 L -0.5 -0.8 L 0.5 -0.8 z'
      strokeWidth={0.1}
      stroke={stroke}
      fill='none'
    />
    {poiType === 'OUTPOST' &&
      <OutpostShape fill={poiColor} stroke={poiStroke} strokeWidth={1} />
    }
    {(poiType === 'PLAYER_BASE' || poiType === 'TUTORIAL_PLAYER_BASE' ||
      poiType === 'TUTORIAL_ENEMY_BASE' || poiType === 'NPC_PLAYER_BASE') &&
      <PlayerBaseShape transform='scale(0.8)' fill={poiFill} stroke={poiStroke} strokeWidth={10} />
    }
    {poiType === 'NPC' && <CrooksShape transform='scale(0.8)' /> }
    {poiType === 'MINING_SPOT' && <MiningSpotShape transform='scale(0.7)' />}
  </svg>
}

/**
 * 
 * @param {{ map: MapData }} props 
 */
const Map = ({ map }) => {
  const worldTransform = `scale(${scaleFactor}, -1)`
  const itemTransform = `scale(${1 / scaleFactor}, -1)`

  return <>
    <defs>
      <path
        id='hex'
        d='M 0.667 0 L 0.333 1 L -0.333 1 L -0.667 0 L -0.333 -1 L 0.333 -1 z'
        strokeWidth={0.2}
      />
      <OutpostShape id='op' transform={itemTransform} fill={null} />
      <CrooksShape id='crooks' transform={itemTransform} />
      <MiningSpotShape id='miningspot' transform={itemTransform} />
      <PlayerBaseShape id='base'
        transform={`${itemTransform} scale(1.2)`}
        fill={null} stroke={null} strokeWidth={10}
      />
      {map.contests.map(c => {
        const color1 = getColor({ guildId: c.guildId }, map.guilds)
        const color2 = getColor({ guildId: c.contesterGuildId }, map.guilds)
        return <pattern
          id={`stripes-${c.guildId}-${c.contesterGuildId}`}
          key={`stripes-${c.guildId}-${c.contesterGuildId}`}
          width={2} height={2}
          patternUnits='userSpaceOnUse'
        >
          <rect x={0} y={0} width={2} height={2} fill={color1} />
          <path d='M 1 0 L 2 0 L 0 2 L 0 1 z' fill={color2} />
          <path d='M 2 1 L 2 2 L 1 2 z' fill={color2} />
        </pattern>
      })}
    </defs>
    <g transform={worldTransform}>
      {Object.values(map.territories).map(territory => {
        const fill = territory.contesterGuildId ?
          `url(#stripes-${territory.guildId || 0}-${territory.contesterGuildId})` :
          getColor(territory, map.guilds)
        return <path
          key={`territory-${territory.id}`}
          d={territory.border}
          strokeWidth={0.2}
          fill={fill}
          stroke={chroma(getColor(territory, map.guilds)).darken(2)}
        />
      })}
      {Object.values(map.hexes).map(hex => {
        // Draw hexes that aren't otherwise properly displayed:
        // * reserved hexes inside territories are blocked from players
        // * non-reserved hexes outside of territories are a playable part of the map
        const reserved = hex.poi?.type === 'RESERVED'
        if ((hex.territory !== undefined && reserved) || (hex.territory === undefined && !reserved)) {
          const territory = hex.territory !== undefined ? map.territories[hex.territory] : null
          const fill = chroma(getColor(territory, map.guilds)).desaturate(2).brighten()
          const stroke = chroma(fill).darken(2)
          return (
            <use key={`${hex.x}:${hex.y}`}
              x={hex.x} y={hex.y}
              xlinkHref='#hex'
              fill={fill}
              stroke={stroke}
            />)
        }
        return null
      })}
      {Object.values(map.hexes).map(hex => {
        if (!hex.poi) return null
        const poiColor = getColor(hex.poi, map.guilds)
        const territory = map.territories[hex.territory]
        const backgroundColor = getColor(territory, map.guilds)
        const isDarkBackground = chroma(backgroundColor).hsl()[2] <= 0.4
        switch (hex.poi.type) {
          case 'OUTPOST':
            return (
              <use key={`op:${hex.x}:${hex.y}`}
                x={hex.x} y={hex.y}
                xlinkHref='#op'
                fill={isDarkBackground ? 'white' : 'black'}
              />)
          case 'PLAYER_BASE':
          case 'TUTORIAL_PLAYER_BASE':
          case 'TUTORIAL_ENEMY_BASE':
          case 'NPC_PLAYER_BASE':
            return (
              <use key={`base:${hex.x}:${hex.y}`}
                x={hex.x} y={hex.y}
                xlinkHref='#base'
                fill={poiColor}
                stroke={isDarkBackground ? 'white' : 'black'}
              />)
          case 'NPC':
            return (
              <use key={`crooks:${hex.x}:${hex.y}`}
                x={hex.x} y={hex.y}
                xlinkHref='#crooks'
              />)
          case 'MINING_SPOT':
            return (
              <use key={`mine:${hex.x}:${hex.y}`}
                x={hex.x} y={hex.y}
                xlinkHref='#miningspot'
              />)
          case 'RESERVED':
            // No POI icon, handled already above, by modifying the base hex
            return null
          default:
            return (
              <use key={`base:${hex.x}:${hex.y}`}
                x={hex.x} y={hex.y}
                xlinkHref='#base'
                fill='#ff00ff'
              />)
        }
      })}
    </g>
  </>
}

const OutpostShape = ({ fill = 'black', stroke = 'black', strokeWidth = 0, ...rest }) => (
  <g {...rest}>
    <path
      transform='scale(0.03) translate(-32, -32)'
      d='m 34.062878,1.3685571 -2.396927,1.8868882 -2.054509,14.5841397 -6.420072,-0.171584 -3.851935,4.63224 1.455548,2.059014 h 4.108478 l -14.551695,37.489319 -2.9958903,-0.171585 -0.8560458,3.774318 0.8560458,1.029505 10.1007993,0.08606 1.369133,-1.02951 -0.342419,-3.860378 h -1.711551 l 1.455007,-3.431686 15.836033,-13.125404 15.664826,12.868298 1.968634,3.774318 h -1.968634 l -0.513627,3.860381 1.198462,0.857921 10.186133,0.08606 1.11259,-1.287154 -0.941381,-3.602734 -2.824679,0.08552 -14.637571,-37.403255 4.194353,0.08606 1.369675,-2.488246 -3.680727,-4.203549 -6.591282,0.08606 L 36.545142,3.169926 Z M 31.323533,24.359255 h 5.735236 l 3.081765,7.978409 -5.992319,4.975407 -6.162988,-4.975407 z m 11.384598,13.897802 2.739343,8.149451 -6.334195,-5.233055 z m -16.948623,0.171584 3.42418,2.830876 -6.59128,5.061471 z'
      fill={fill}
      stroke={stroke}
      strokeWidth={strokeWidth}
    />
  </g>
)

const CrooksShape = (props) => (
  <g {...props}>
    <path
      transform='scale(0.03) translate(-32, -32)'
      d='M 12.285369,3.2760983 C 5.1632178,10.384798 2.1294639,31.122934 3.4399034,39.395082 c 3.603708,2.926804 5.9788792,3.066289 8.5178556,4.177025 -0.491415,1.556147 -3.5218058,15.070052 -2.9484887,16.298589 1.0836317,1.049316 10.0776627,5.070991 11.3844417,5.65127 0.819024,-1.310439 3.194196,-20.148005 3.194196,-20.148005 h 3.112294 c 0.856108,6.770603 0.698166,13.868816 0.573315,20.721322 3.71575,0.783209 7.724515,0.687373 11.875857,0.163804 0.264062,-8.304968 -0.217591,-15.174898 0.245708,-20.803224 -0.280727,0.0067 2.077566,-0.146388 3.030392,-0.163803 0.998865,1.065461 3.479144,16.794613 3.439903,19.902296 4.712768,-1.099371 8.691877,-2.722784 11.875855,-4.914148 C 56.974853,54.64025 56.214863,48.999015 54.383233,43.572107 57.140615,42.677136 59.897998,41.691619 62.65538,39.722692 63.46092,26.467273 62.793604,14.695296 53.891817,3.1941957 40.127753,0.70745689 26.267766,0.52285159 12.285369,3.2760983 Z'
      fill='white'
      stroke='black'
      strokeWidth='1'
    />
    <path
      transform='scale(0.03) translate(-32, -32)'
      d='M 11.957758,15.889077 C 9.66449,19.65659 8.927368,29.894396 9.66449,32.760984 c 2.784683,2.129462 13.350101,2.211365 14.33293,0.819025 0.98283,-2.866589 1.965659,-12.121565 0.819025,-16.052883 -2.538977,-1.400195 -11.548247,-1.72596 -12.858687,-1.638049 z m 21.212737,15.151954 c -1.610748,2.287444 -3.303399,4.677191 -4.914147,7.616929 3.361143,0.898759 6.603619,0.848192 9.746393,0 -1.495078,-2.99519 -3.124308,-5.260317 -4.832246,-7.616929 z m 8.599759,-13.432002 c -1.458809,3.471555 -0.481408,11.711981 0.32761,15.889077 2.446547,1.632786 12.722272,1.037475 14.578637,-0.573318 0.573318,-3.358 -0.163805,-14.251027 -2.211366,-17.035711 -3.313519,-0.544014 -11.695563,1.187857 -12.694881,1.719952 z'
      fill='black'
    />
  </g>
)

const MiningSpotShape = (props) => (
  <g {...props}>
    <path
      transform='scale(0.03) translate(-32, -32)'
      d='m 4.5865377,44.145424 c -1.8018542,2.620878 -0.7204712,9.001638 -0.1638051,10.237809 0.5671388,1.259427 4.4791351,6.597792 8.4359534,6.552195 0.573317,0.0819 35.054252,0.163806 35.627569,0.245707 2.866586,-0.655219 7.911421,-5.043259 8.435954,-7.862636 0.655217,-3.521805 -0.491415,-8.927368 -0.491415,-8.927368 -1.228537,3.439903 -4.340832,6.797904 -7.698831,6.797904 -3.358002,0 -9.500685,-0.409512 -9.500685,-0.409512 0.07253,1.857986 -1.869695,3.294379 -3.358001,3.2761 l -10.565418,-0.0819 c -1.55768,-0.01208 -3.68561,-1.392342 -3.603708,-2.866586 -1.474244,0 -8.026441,-0.32761 -8.599758,-0.32761 C 10.483515,50.20621 5.1598549,48.158649 4.5865377,44.145428 Z M 14.66054,1.3104393 c -4.586538,10e-8 -10.3197097,5.733172 -10.5654171,8.8454657 -0.1870431,2.369211 -5e-7,31.778154 -5e-7,31.778154 0.2457074,4.258928 15.3157586,3.194195 17.7728326,3.194195 0.163805,-1.392341 -0.0819,-3.931317 3.112294,-4.013219 3.194194,-0.0819 11.05683,-0.0819 12.694883,0 1.638047,0.0819 1.310436,2.129461 1.310436,3.439903 4.852622,-0.03354 18.091453,1.323491 17.527127,-3.030393 0.184822,-3.927824 0.32761,-30.549616 0.32761,-30.549616 C 57.086012,5.4874647 50.288108,1.7199516 46.930109,1.6380491 43.572107,1.5561467 14.66054,1.3104393 14.66054,1.3104393 Z'
      fill='#84f5ff'
      stroke='black'
      strokeWidth='1'
    />
    <path
      transform='scale(0.03) translate(-32, -32)'
      d='m 35.054251,54.465135 c -0.737121,1.064731 -1.064731,2.702779 -1.801852,2.702779 -0.737124,0 -3.112294,-0.982829 -4.095123,-0.409511 -0.982832,0.573317 -2.797182,3.018787 -0.982832,4.99605 1.057926,1.152918 2.823526,0.115887 3.767515,1.064731 1.228102,1.234419 0.615004,2.6213 1.63805,3.2761 2.224618,1.423865 5.733172,-0.982829 5.323659,-3.521807 -0.404497,-2.507885 -2.211368,-2.293269 -2.211368,-2.948489 0,-0.655219 1.883756,-3.112293 1.883756,-3.112293 z'
      fill='#f5f5f5'
      stroke='black'
      strokeWidth='1'
    />
    <path
      transform='scale(0.03) translate(-32, -32)'
      d='m 33.580009,53.154696 c 0.982829,-1.474245 2.021391,-1.888116 2.37517,-3.93132 0.949068,-5.48121 -1.801855,-12.039662 1.638048,-20.475613 3.439904,-8.435954 13.677712,-11.548247 20.803226,-7.371221 6.640636,3.892788 9.582585,13.513906 5.569367,21.130835 -4.013221,7.616928 -16.462394,11.056831 -19.410882,11.056831 -2.94849,0 -4.504637,3.358001 -4.504637,3.358001 -2.690753,-0.508253 -4.871463,-1.730566 -6.470292,-3.767513 z'
      fill='#fd9e9e'
      stroke='black'
      strokeWidth='1'
    />
  </g>
)

const PlayerBaseShape = ({ fill = 'black', stroke = 'black', strokeWidth = 0, ...rest }) => (
  <g {...rest}>
    <path
      transform='scale(0.03) translate(-33, -33)'
      d='m 33.825717,1.884597 10.967487,19.940577 22.35251,4.269408 -15.574232,16.59336 2.847124,22.579216 L 33.825716,55.581841 13.232825,65.267157 16.07995,42.687942 0.50571909,26.09458 22.858231,21.825174 Z'
      fill={fill}
      stroke={stroke}
      strokeWidth={strokeWidth}
    />
  </g>
)

export default MapPreview
