import React from 'react'
import PropTypes from 'prop-types'
import Drawer from '@material-ui/core/Drawer'
import { makeStyles } from '@material-ui/core'
import {
  useNotify,
  UPDATE,
  CREATE,
  DELETE,
  useDataProvider,
} from 'react-admin'

import SaveButton from '../components/SaveButton'
import DeleteButton from '../components/DeleteButton'
import TextField from '../components/TextField'
import SelectInput from '../components/SelectInput'
import { required } from '../utils/validations'
import useValidation from '../components/useValidation'

const constraints = {
  name: required,
  game: required,
  description: required,
}

const useStyles = makeStyles(_theme => ({
  container: {
    width: 400,
    padding: 30,
  },
  row: {
    display: 'flex',
    marginBottom: 20,
    flexDirection: 'column',
  },
  form: {
    display: 'flex',
    flexDirection: 'column',
  },
  buttons: {
    marginTop: 30,
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
  },
}))

const RoleEditDrawer = ({
  role,
  open,
  games,
  onClose,
  onRequestHide,
}) => {
  const notify = useNotify()
  const classes = useStyles()
  const dataProvider = useDataProvider()

  const handleFormSubmit = state => {
    const role = {...state.values}
    if (role.game === '__all__') {
      delete role.game
    }
    if (!role._isNew && role.id) {
      handleUpdate(role)
    } else {
      handleCreate(role)
    }
  }

  const handleUpdate = role => {
    dataProvider(UPDATE, 'roles', {
      id: role.id,
      data: role,
    })
    .then(() => {
      notify('Role updated')
      onRequestHide()
    })
    .catch(e => {
      notify(e.message, 'warning')
    })
  }

  const handleCreate = role => {
    dataProvider(CREATE, 'roles', {
      data: role,
    })
    .then(() => {
      notify('Role created')
      onRequestHide()
    })
    .catch(e => {
      notify(e.message, 'warning')
    })
  }

  const handleDelete = () => {
    dataProvider(DELETE, 'roles', {
      id: role.id,
    })
    .then(() => {
      notify('Role deleted')
      onRequestHide()
    })
    .catch(e => {
      notify(e.message, 'warning')
    })
  }

  const {
    getFieldProps,
    getFormProps,
    errors,
    isValid,
  } = useValidation(
    {
      constraints,
      showErrors: 'blur',
      initialValues: role,
      onSubmit: handleFormSubmit,
    }
  )

  return (
    <Drawer
      anchor='right'
      open={ open }
      onClose={ onClose }
    >
      <div className={ classes.container }>
        { role && (
          <form
            className={ classes.form }
            { ...getFormProps() }
          >
            <div className={ classes.row }>
              <TextField
                error={ !!errors.name }
                errorMessage={ errors.name }
                label='Name'
                required
                { ...getFieldProps('name') }
              />
            </div>

            <div className={ classes.row }>
              <SelectInput
                items={ games }
                valueField='id'
                labelField='name'
                label='Game'
                includeAllItem={ true }
                error={ !!errors.game }
                errorMessage={ errors.game }
                required
                { ...getFieldProps('game') }
              />
            </div>

            <div className={ classes.row }>
              <TextField
                error={ !!errors.description }
                errorMessage={ errors.description }
                label='Description'
                multiline
                required
                { ...getFieldProps('description') }
              />
            </div>

            <div className={ classes.buttons }>
              <SaveButton
                type='submit'
                disabled={ !isValid }
              />

              { role && !role._isNew && role.id && (
                <DeleteButton
                  onClick={ handleDelete }
                />
              )}
            </div>
          </form>
        )}
      </div>
    </Drawer>
  )
}

RoleEditDrawer.propTypes = {
  open: PropTypes.bool,
  role: PropTypes.object,
  games: PropTypes.arrayOf(PropTypes.object),
  onClose: PropTypes.func.isRequired,
  onRequestHide: PropTypes.func.isRequired,
}

export default RoleEditDrawer
