import React, { useState } from 'react'
import PropTypes from 'prop-types'

import ShowHeader from '../components/ShowHeader'
import { Button } from '@material-ui/core'
import CloseMapDialog from './CloseMapDialog'

const MapShowHeader = ({ map }) => {
  const [closeMapOpen, setCloseMapOpen] = useState(false)

  return <>
    <ShowHeader
      items={[
        {
          label: 'Design',
          value: map.mapDesign,
        },
        {
          label: 'Tier',
          value: map.tier,
        },
        {
          label: 'Status',
          value: map.status,
        }
      ]}
      actions={[
        <Button onClick={() => setCloseMapOpen(true)}>Close map</Button>,
      ]}
    />
    {closeMapOpen && <CloseMapDialog map={map} onClose={() => setCloseMapOpen(false)} />}
  </>
}

MapShowHeader.propTypes = {
  map: PropTypes.object.isRequired,
}

MapShowHeader.displayName = 'MapShowHeader'

export default MapShowHeader
