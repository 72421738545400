import React, { useMemo, useState } from 'react'
import {
  Button,
  Card,
  CardContent,
  Grid,
  TextField as MaterialTextField
} from '@material-ui/core'
import {
  ListContextProvider,
  TextField,
  useGetList,
  useGetMany,
  useGetOne,
  useRecordContext
} from 'react-admin'
import ReactiveDataList from '../components/react-admin-addons/ReactiveDataList'
import { useListContextFromData } from '../utils/listings'

const SingleEnvironmentStatus = ({ tag, env }) => {
  const statusFetch = useGetMany('environment/clientbuilds/env', [env])
  const statusList = statusFetch?.data?.[0]?.statuses || []
  const statusSearch = statusList.filter(status => status.tag === tag)
  const statusEntry = statusSearch.length === 1 ? statusSearch[0] : {}

  return <div>
    {env} - {statusEntry.status}
  </div>
}

const EnvironmentStatusesField = ({ environments, ...rest }) => {
  const record = useRecordContext(rest)
  const tag = record?.record?.id

  return <div>
    {environments.map(env => <SingleEnvironmentStatus key={env} tag={tag} env={env} />)}
  </div>
}

const ClientBuilds = () => {
  const [environmentsText, setEnvironmentsText] = useState('')
  const builds = useGetList('environment/clientbuilds/build')

  useGetOne('environment', 'name', {
    onSuccess: response => {
      setEnvironmentsText(previous => previous ? previous : (response.data.name || ''))
    },
  })

  const environments = useMemo(
    () => environmentsText.split(/[ ,]+/).filter(x => !!x),
    [environmentsText])
  const buildContext = useListContextFromData('environment/clientbuilds/build', builds)

  return <Card>
    <CardContent>
      <Grid container spacing={2} justify='space-between'>
        <Grid item>
          <Button
            variant='contained'
            color='primary'
            disabled
          >
            Add build
          </Button>
        </Grid>
        <Grid item>
          <MaterialTextField
            label='Environments to manage'
            variant='outlined'
            value={environmentsText}
            size='small'
            onChange={event => setEnvironmentsText(event.target.value)}
          />
        </Grid>
      </Grid>

      <ListContextProvider value={buildContext}>
        <ReactiveDataList>
          <TextField source='id' label='Build tag' />
          <TextField source='hash' label='Git hash' />
          <EnvironmentStatusesField label='Environment' source='id' environments={environments} />
        </ReactiveDataList>
      </ListContextProvider>
    </CardContent>
  </Card>
}

export default ClientBuilds
