const storagePrefix = 'rovio.admintool'

export const localStorageGet = key => {
  return localStorage.getItem(`${storagePrefix}.${key}`)
}

export const localStorageSet = (key, value) => {
  return localStorage.setItem(`${storagePrefix}.${key}`, value)
}

export const localStorageRemove = key => {
  return localStorage.removeItem(`${storagePrefix}.${key}`)
}
