import React, { useState, useMemo, useEffect } from 'react'
import { Card, CardContent, Button, Grid, makeStyles, Paper } from '@material-ui/core'
import {
  BooleanInput,
  FileField,
  FileInput,
  FormDataConsumer,
  FormWithRedirect,
  SaveButton,
  SelectInput,
  TextInput,
  required,
  useGetOne,
  useMutation,
  useVersion
} from 'react-admin'
import SendIcon from '@material-ui/icons/Send'
import { useCurrentGame } from '../utils/games'
import EnvironmentIcon from '../components/EnvironmentIcon'
import { useNotify, useRefresh } from 'ra-core'
import { describeError } from '../utils/errors'

const recipientTypes = [
  { id: 'csv', name: 'CSV file', },
  { id: 'ids', name: 'List of player IDs', },
  { id: 'all', name: 'All Players', },
]

const initialValues = {
  recipientsType: 'csv',
  csvFile: null,
  playerIds: '',
  boostType: null,
  agentNote: '',
  supportTicket: '',
  deduplicate: false,
}

const useStyles = makeStyles(theme => ({
  fileInput: {
    margin: 0,
  },
  inlineNotification: {
    padding: theme.spacing(1),
    background: '#fff3e0',
    width: 'max-content',
  },
  inlineNotificationProd: {
    border: 'thick double red',
    padding: theme.spacing(1),
    background: '#fff3e0',
    width: 'max-content',
  },
  error: {
    padding: theme.spacing(1),
    background: '#ffe0e0',
    width: 'max-content',
  },
}))

const fileProvided = value => {
  if (!value) {
    return 'A file is required'
  }
}

const WatchStatus = ({id}) => {
  const [timer, setTimer] = useState(0)
  const [running, setRunning] = useState(true)
  const status = useGetOne('boost/activate', id, {version: timer})

  useEffect(() => {
    if (running) setTimeout(() => setTimer(t => t + 1), 1000)
  }, [timer, running])

  if (!status.data) return null
  if (running && status.data.targeted_count === status.data.processed_count) setRunning(false)

  return <>
    {status.data.targetedCount && <p>Total {status.data.targetedCount} players selected</p>}
    {status.data.processedCount && <p>Operation completed for {status.data.processedCount} players</p>}
    {status.data.successCount && <p>Out of completed operations, {status.data.successCount} were successful</p>}
    {status.data.errors && status.data.errors.map(e => <p>{e}</p>)}
  </>
}

const ActivateBoostForm = () => {
  const version = useVersion()
  const notify = useNotify()
  const refresh = useRefresh()
  const classes = useStyles()
  const currentGame = useCurrentGame()

  const [send, { loading: sending }] = useMutation()

  const [duplicates, setDuplicates] = useState([])
  const [confirmMode, setConfirmMode] = useState(false)
  const [errorMessage, setErrorMessage] = useState('')
  const [boostActivationId, setBoostActivationId] =  useState(null)

  const config = useGetOne('boost', 'config')
  const availableBoosts = useMemo(() => {
    if (!config.loaded || config.error || !config.data || !config.data.types) return {}
    const boosts = {}
    config.data.types.forEach(b => boosts[b.id] = b)
    return boosts
  }, [config.data, config.loaded, config.error])

  const sendBoost = (values) => {
    const message = {
      group: {
        automaticallyDeduplicate: values.deduplicate,
      },
      boost: {
        boostId: values.boostType,
        count: 1,
        attributes: {},
      },
      agentNote: values.agentNote,
      supportTicket: values.supportTicket,
    }
    switch (values.recipientsType) {
      case 'all':
        message.group.allPlayers = true
        break
      case 'ids':
        message.group.playerIds = values.playerIds.split(/[,\s\n]/).filter(p => !!p)
        break
      case 'csv':
        message.group.csv = values.csvFile
        break
      default:
        setErrorMessage(`Internal error: unknown target group type ${values.recipientsType}`)
        return
    }

    send(
      {
        type: 'create',
        resource: 'boost/activate',
        payload: {
          data: message,
        },
      },
      {
        onSuccess: result => {
          if (result.response.status === 202) {
            setBoostActivationId(result.data.id)
          } else {
            notify('Boost successfully activated')
            setDuplicates([])
            setConfirmMode(false)
            setErrorMessage('')
            setBoostActivationId(null)
            refresh()
          }
        },
        onFailure: error => {
          if (error.body && error.body.group && error.body.group.duplicatedIds && error.body.group.duplicatedIds.length > 0) {
            setDuplicates(error.body.group.duplicatedIds)
          } else {
            setConfirmMode(false)
            setErrorMessage(describeError(error))
          }
        },
      }
    )
  }

  return <Card>
    <CardContent>
      <FormWithRedirect
        save={sendBoost}
        saving={sending}
        initialValues={initialValues}
        version={version}
        render={formProps => (
          <Grid container spacing={4}>
            <Grid item container xs={12} alignContent='flex-start'>
              <Grid item xs={12} lg={5}>
                <SelectInput
                  choices={recipientTypes}
                  source='recipientsType'
                  label='Recipient'
                  fullWidth
                  validate={required()}
                />
              </Grid>
              <Grid item xs={12} lg={7}>
                <FormDataConsumer>
                  {({ formData, ...rest }) => {
                    switch (formData.recipientsType) {
                      case 'csv':
                        return <FileInput
                          source='csvFile'
                          label=''
                          multiple={false}
                          accept='.csv,.txt'
                          placeholder={<p>Drop or click to select .csv or .txt file with player IDs</p>}
                          className={classes.fileInput}
                          validate={fileProvided}
                          {...rest}
                        >
                          <FileField source='src' title='title' />
                        </FileInput>
                      case 'ids':
                        return <TextInput
                          source='playerIds'
                          label='Player IDs'
                          multiline rows={2} rowsMax={Infinity}
                          fullWidth
                          validate={required()}
                          {...rest}
                        />
                      case 'all':
                        return null
                      default:
                        return <p>Internal error: bad value for recipientsType</p>
                    }
                  }}
                </FormDataConsumer>
              </Grid>
            </Grid>

            <Grid item xs={12}>
              <SelectInput
                choices={Object.values(availableBoosts)}
                source='boostType'
                label='Booster'
                fullWidth
                validate={required()}
              />
            </Grid>

            <Grid item xs={12}>
              <TextInput source='agentNote' label='Support agent notes (optional)' multiline fullWidth rows={2} rowsMax={Infinity} />
            </Grid>
            <Grid item xs={12}>
              <TextInput source='supportTicket' label='Support ticket no. (optional)' fullWidth />
            </Grid>

            {duplicates.length > 0 ?
              <Grid item xs={12}>
                <Paper className={classes.inlineNotification} >
                  <p>There {duplicates.length === 1 ? 'was a' : ('were ' + duplicates.length)} {}
                  player {duplicates.length === 1 ? 'ID' : 'IDs'} that appeared more than once.</p>
                  <p>Duplicated IDs: {duplicates.join(', ')}</p>
                  <BooleanInput label='Automatically deduplicate' source='deduplicate' />
                </Paper>
              </Grid>
              : null}
            <Grid item xs={12}>
              <Button
                variant='contained'
                color='primary'
                startIcon={<SendIcon />}
                disabled={formProps.pristine || formProps.invalid || confirmMode}
                onClick={() => { setConfirmMode(true); setErrorMessage('') }}
              >
                Activate in {currentGame.id}-{currentGame.env}
              </Button>
              {formProps.pristine || formProps.invalid ? <span>Some required fields are empty</span> : null}
            </Grid>
            {errorMessage &&
              <Grid item xs={12}>
                <Paper className={classes.error}>
                  <p>Booster activation failed</p>
                  <p>{errorMessage}</p>
                </Paper>
              </Grid>
            }
            {confirmMode &&
              <Grid item xs={12}>
                <Paper
                  className={currentGame.env === 'prod' ?
                    classes.inlineNotificationProd :
                    classes.inlineNotification}
                >
                  <FormDataConsumer>
                    {({ formData }) => (<>
                      <p><strong>{currentGame.id}-{currentGame.env}</strong>
                        <EnvironmentIcon env={currentGame.env} />
                      </p>
                      <p>You are about to activate {}
                        <strong>{availableBoosts[formData.boostType].name}</strong> {}
                        for <strong>{
                          formData.recipientsType === 'all' ? 'all players' :
                          formData.recipientsType === 'ids' ? `list of ${formData.playerIds.split(/[,\s\n]/).filter(p => !!p).length} players` :
                          formData.recipientsType === 'csv' ? `players in file "${formData.csvFile ? formData.csvFile.title : '(file missing)'}"` :
                            '...somewhere? An internal error occurred'
                        }</strong>. This action can not be undone.</p>
                    </>)}
                  </FormDataConsumer>
                  {boostActivationId ?
                    <WatchStatus id={boostActivationId} /> :
                    <SaveButton
                      submitOnEnter
                      label='Confirm activation'
                      saving={formProps.saving}
                      handleSubmitWithRedirect={formProps.handleSubmitWithRedirect}
                      icon={<SendIcon />}
                    />
                  }
                </Paper>
              </Grid>
            }
          </Grid>
        )}
      />
    </CardContent>
  </Card>
}

export default ActivateBoostForm
