import React, { useCallback, useState } from 'react'
import { Button, Tooltip } from '@material-ui/core'
import {
  BooleanInput,
  FormDataConsumer,
  FormWithRedirect,
  NumberInput,
  SaveButton,
  SelectInput,
  TextInput,
  useGetList,
  useMutation,
  useRefresh,
  useVersion,
} from 'react-admin'
import EditIcon from '@material-ui/icons/Edit'
import DeleteIcon from '@material-ui/icons/Delete'
import CancelIcon from '@material-ui/icons/Cancel'
import EventIcon from '@material-ui/icons/Event'
import moment from 'moment'

import DetailsDrawer from '../components/DetailsDrawer'
import DrawerItem from '../components/DrawerItem'
import TimestampText from '../components/TimestampText'
import { statusForEvent } from './eventUtils'
import DateRangeInput from '../components/DateRangeInput'
import { describeError } from '../utils/errors'
import { useHasPermission } from '../utils/auth'
import ProcessSequenceDialog from '../components/ProcessSequenceDialog'
import TextWithSuggestionsInput from '../components/TextWithSuggestionsInput'

const describeEventRange = (minLength, maxLength) => {
  const dmin = minLength ? moment.duration(minLength) : null
  const dmax = maxLength ? moment.duration(maxLength) : null
  if (dmin && dmax) {
    if (dmin.seconds() === dmax.seconds()) {
      return `Event length must be ${dmin.humanize()}`
    }
    return `Event length must be between ${dmin.humanize()} and ${dmax.humanize()}`
  }
  if (dmin) {
    return `Event minimum length is ${dmin.humanize()}`
  }
  if (dmax) {
    return `Event maximum length is ${dmax.humanize()}`
  }
  return undefined
}

const CalendarDrawer = ({ open, onClose, event, createNew = false }) => {
  const version = useVersion()
  const refresh = useRefresh()
  const hasPermission = useHasPermission()
  const [edit, setEdit] = useState(createNew)
  const [error, setError] = useState(null)
  const [eventToDelete, setEventToDelete] = useState(null)
  const [saveEventRequest, setSaveEventRequest] = useState(null)
  const [minLength, setMinLength] = useState(null)
  const [maxLength, setMaxLength] = useState(null)
  const [send] = useMutation()
  const config = useGetList('scheduledevents/config')

  const handleFormSubmit = (values) => {
    if (!createNew) {
      setError({ message: 'Editing events is not currently supported' })
    }

    const def = values.definition || {}
    const request = {
      type: values.definition?.type,
      version: values.definition?.version,
      configId: {
        id: values.definition?.configId?.id,
        version: values.definition?.configId?.version,
      },
      startTime: values.definition?.startTime,
      endTime: values.definition?.endTime,
      applyOnNewMaps: values.definition?.applyOnNewMaps,
    }
    // Set parentDefinitionId only when there's a value in the input field
    if (!!def.parentDefinitionId || def.parentDefinitionId === 0) {
      request.parentDefinitionId = values.definition.parentDefinitionId
    }
    // Also for configOverridesJson. Early Hydra can't actua
    if (values.definition?.configOverridesJson) {
      request.configOverridesJson = values.definition?.configOverridesJson
    }

    setSaveEventRequest(request)
  }

  const saveEvent = useCallback((request) => {
    return new Promise((resolve, reject) => {
      send(
        {
          type: 'create',
          resource: 'scheduledevents',
          payload: { data: request },
        },
        {
          onSuccess: resolve,
          onFailure: reject,
        }
      )
    })
  }, [send])

  const deleteEvent = useCallback((event) => {
    return new Promise((resolve, reject) => {
      send(
        {
          type: 'delete',
          resource: 'scheduledevents',
          payload: {
            id: event.id,
            previousData: event,
          },
        },
        {
          onSuccess: resolve,
          onFailure: reject,
        }
      )
    })
  }, [send])

  const shortWait = (data) => {
    return new Promise((resolve, _reject) => {
      let wait = setTimeout(() => {
        clearTimeout(wait);
        resolve(data);
      }, 2000)
    })
  }

  const updateServers = () => {
    return new Promise((resolve, reject) => {
      send(
        {
          type: 'create',
          resource: 'scheduledevents/update',
          payload: { data: null },
        },
        {
          onSuccess: result => {
            var c = result?.json?.count
            if (!isFinite(c)) {
              console.error('Missing json.count from result', result)
              reject("Server update returned successfully, but number of updated servers is missing")
            } else {
              resolve(c)
            }
          },
          onFailure: reject,
        }
      )
    })
  }

  const handleConfigIdUpdate = (configId, change) => {
    const configs = Object.values(config.data).filter(c => c.configId.id === configId)
    if (configs.length === 0) {
      setMinLength(null)
      setMaxLength(null)
      return
    }
    if (configs.length === 1) {
      change('definition.configId.version', configs[0].configId.version)
    }
    singleConfigVersionUpdate(configs[0], change)
  }

  const handleConfigVersionUpdate = (configId, version, change) => {
    const configs = Object.values(config.data)
      .filter(c => c.configId.id === configId && c.configId.version === version)
      if (configs.length === 0) {
        setMinLength(null)
        setMaxLength(null)
        return
      }
      singleConfigVersionUpdate(configs[0], change)
  }

  const singleConfigVersionUpdate = (config, change) => {
    change('definition.applyOnNewMaps', config.applyOnNewMapsDefault)
    change('definition.type', config.archType)
    if (config.lengthSecondsMin) setMinLength({seconds: config.lengthSecondsMin})
    else setMinLength(null)
    if (config.lengthSecondsMax) setMaxLength({seconds: config.lengthSecondsMax})
    else setMaxLength(null)
  }

  const eventStatus = statusForEvent(event)
  const toDeleteStatus = statusForEvent(eventToDelete)


  return <>
    <ProcessSequenceDialog
      title='Save the event definition'
      steps={[
        { label: 'Save event definition into database', func: saveEvent },
        { label: 'Wait for 2 seconds', func: shortWait },
        { label: 'Re-load event definitions in map servers', func: updateServers },
      ]}
      initialValue={saveEventRequest}
      open={!!saveEventRequest}
      onCloseSuccess={() => { setSaveEventRequest(null); onClose(); refresh() }}
      onCloseDefault={() => { setSaveEventRequest(null) }}
    />
    <ProcessSequenceDialog
      title='Delete the event'
      subtitle={
        toDeleteStatus === 'Upcoming' ?
          'This event has not yet started, but may have alredy been announced to users.' :
        toDeleteStatus === 'Ended' ? 'This event has already ended.' :
        <>DANGER: This event may be <strong>CURRENTLY LIVE</strong>!</>
      }
      steps={[
        { label: 'Delete event definition from database', func: deleteEvent },
        { label: 'Wait for 2 seconds', func: shortWait },
        { label: 'Re-load event definitions in map servers', func: updateServers },
      ]}
      initialValue={eventToDelete}
      open={!!eventToDelete}
      onCloseSuccess={() => { setEventToDelete(null); onClose(); refresh() }}
      onCloseDefault={() => { setEventToDelete(null) }}
    />

    <DetailsDrawer open={open} onClose={onClose}>
      {edit || createNew ?
        <FormWithRedirect
          save={handleFormSubmit}
          saving={!!saveEventRequest}
          initialValues={event}
          version={version}
          render={formProps => {
            return <>
              <div>
                <SaveButton
                  submitOnEnter
                  label='Save'
                  saving={formProps.saving}
                  handleSubmitWithRedirect={formProps.handleSubmitWithRedirect}
                  icon={<EventIcon />}
                />
                <Button
                  variant='outlined'
                  color='primary'
                  startIcon={<CancelIcon />}
                  onClick={() => createNew ? onClose() : setEdit(false)}
                >
                  Cancel
                </Button>
              </div>

              {error && <div>{describeError(error)}</div>}

              <DrawerItem label='Status'>
                Editing
              </DrawerItem>

              <DrawerItem label='Event configuration'>
                <FormDataConsumer>
                  {({ formData, ...rest }) => (
                    <>
                      <TextWithSuggestionsInput
                        source='definition.configId.id'
                        label='ID'
                        choices={Object.values(config.data).map(c => c.configId.id)}
                        onChange={value => handleConfigIdUpdate(value, formProps.form.change)}
                      />

                      <TextWithSuggestionsInput
                        source='definition.configId.version'
                        label='Version'
                        choices={Object.values(config.data)
                          .filter(c => c.configId.id === formData.definition?.configId?.id)
                          .map(c => c.configId.version)
                        }
                        onChange={value =>
                          handleConfigVersionUpdate(
                            formData.definition?.configId?.id, value, formProps.form.change)
                        }
                        {...rest}
                      />
                    </>
                  )}
                </FormDataConsumer>
              </DrawerItem>

              <DrawerItem label='Event type'>
                <SelectInput
                  source='definition.type'
                  label=''
                  choices={[
                    { id: 'normal', name: 'Normal' },
                    { id: 'guild_wars', name: 'Guild Wars' },
                  ]}
                />
              </DrawerItem>

              <DrawerItem label='Schedule'>
                <DateRangeInput
                  label={describeEventRange(minLength, maxLength)}
                  fullWidth
                  sourceFrom='definition.startTime'
                  sourceTo='definition.endTime'
                  past={true} future={true}
                  minRange={minLength} maxRange={maxLength}
                  helperText={(minLength || maxLength) &&
                    'When start is set, end is automatically updated to be in the correct range'
                  }
                />
              </DrawerItem>

              <DrawerItem label='Event version'>
                <TextInput source='definition.version' label='' fullWidth />
              </DrawerItem>

              <DrawerItem label='Apply on new maps'>
                <BooleanInput source='definition.applyOnNewMaps' label='Apply' />
              </DrawerItem>

              <DrawerItem label='Parent definition ID'>
                <NumberInput source='definition.parentDefinitionId' label='' />
              </DrawerItem>

              <DrawerItem label='Configuration overrides'>
                <TextInput
                  source='definition.configOverridesJson'
                  label=''
                  multiline rows={3} rowsMax={Infinity}
                  fullWidth
                />
              </DrawerItem>
            </>

          }}
        />
        :
        <>
          <div>
            <Tooltip title='Editing events has not been implemented'><span>
              <Button
                variant='contained'
                color='primary'
                startIcon={<EditIcon />}
                onClick={() => setEdit(true)}
                disabled={true || !hasPermission('UpdateEvent')}
              >
                Edit
              </Button>
            </span></Tooltip>
            <Button
              variant='outlined'
              color='secondary'
              startIcon={<DeleteIcon />}
              onClick={() => setEventToDelete(event)}
              disabled={!hasPermission('DeleteEvent') || eventStatus === 'Live'}
            >
              Delete
            </Button>
          </div>

          {error && <div>{describeError(error)}</div>}

          <DrawerItem label='Status'>
            {statusForEvent(event)}
          </DrawerItem>

          <DrawerItem label='Event configuration'>
            {event?.definition?.configId?.id || '?'} at version { }
            {event?.definition?.configId?.version || '?'}
          </DrawerItem>

          <DrawerItem label='Event type'>
            {event?.definition?.type || '?'}
          </DrawerItem>

          <DrawerItem label='Schedule'>
            <TimestampText time={event?.definition?.startTime} /> &ndash; { }
            <TimestampText time={event?.definition?.endTime} />
          </DrawerItem>

          <DrawerItem label='Event version'>
            {event?.definition?.version || '?'}
          </DrawerItem>

          <DrawerItem label='Apply on new maps'>
            {
              event?.definition?.applyOnNewMaps === true ? 'Yes' :
                event?.definition?.applyOnNewMaps === false ? 'No' :
                  'Unknown'
            }
          </DrawerItem>

          <DrawerItem label='Parent definition ID'>
            {event?.definition?.parentDefinitionId || '?'}
          </DrawerItem>

          <DrawerItem label='Event configuration'>
            {event?.definition?.configOverridesJson}
          </DrawerItem>
        </>
      }
    </DetailsDrawer>
  </>
}

export default CalendarDrawer
