import React, { useState} from 'react'
import Quill from 'quill'
import RichTextInput from 'ra-input-rich-text'
import Typography from '@material-ui/core/Typography'
import { makeStyles } from '@material-ui/core/styles'
import EmojiPicker from 'emoji-picker-react'
import ClickAwayListener from '@material-ui/core/ClickAwayListener'

var Module = Quill.import('core/module');
class EmojiModule extends Module { }
EmojiModule.blotName = 'custombutton';
Quill.register(EmojiModule, true);

var icons = Quill.import('ui/icons');
icons['custombutton'] = '😊';
Quill.register(icons, true);

const Inline = Quill.import('blots/inline')
class SimpleLink extends Inline {
  static create(value) {
    const node = super.create(value);
    node.setAttribute('href', this.sanitize(value));
    return node;
  }

  static formats(domNode) {
    return domNode.getAttribute('href');
  }

  static sanitize(url) {
    const PROTOCOL_WHITELIST = ['http:', 'https:'];
    if (url.indexOf('://') < 0) url = `https://${url}`
    try {
      const parsed = new URL(url)
      if (PROTOCOL_WHITELIST.indexOf(parsed.protocol) > -1) return parsed.toString()
    } catch {
      // Fall through
    }
    return 'about:blank';
  }

  format(name, value) {
    if (name !== this.statics.blotName || !value) {
      super.format(name, value);
    } else {
      this.domNode.setAttribute('href', this.constructor.sanitize(value));
    }
  }
}
SimpleLink.blotName = 'link';
SimpleLink.tagName = 'A';
Quill.register(SimpleLink)

const useStyles = makeStyles(theme => ({
  editor: {
    '& .ql-editor': {
      minHeight: '6rem',
      '& span': {
        '&:hover': {
          border: 'solid thin black',
          margin: '-1px',
        },
        '&::before': {
          content: '\'✦\'',
        },
      },
      '& a': {
        '&:hover': {
          border: 'solid thin black',
          margin: '-1px',
        },
        '&::before': {
          content: '\'🔗\'',
        },
      },
    },
    '& .emojiBox': {
      position: 'absolute',
      left: '180px',
      top: '250px',
      background: 'red',
      zIndex: '99999'
    }
  },
  notice: {
    marginTop: 0,
  },
  editorError: {
    display: 'flex',
    marginBottom: theme.spacing(2),
    padding: theme.spacing(1),
    flexDirection: 'column',
    color: 'red',
  }
}))

const MessageBodyInput = (props) => {
  const classes = useStyles()
  const [cursorPosBeforeLeaving, setCursorPos] = useState(null);
  const [emojiButtonClicked, setEmojiButtonClicked] = useState(false);
  const [error/* , setError */] = useState(null)
  var quill = null

  //TODO: take use of the whitespace check before on submit
  /*   function onClickSubmitButton(event) { 
      var text = quill.getText();
      if (/\S/.test(text) === false) {
        setError("Message cannot be empty")
        event.preventDefault();
      }
    } */

  function insertTextAtCursorPos(quill, text) {
    quill.insertText(cursorPosBeforeLeaving, text.emoji);
  }

  const onEmojiClick = (event, emojiObject) => {
    const el = document.querySelector('.ql-editor > p')
    if (el) {
      var container = document.querySelector(".ql-container");
      var quill = container.__quill;
      quill.focus()
      insertTextAtCursorPos(quill, emojiObject)
    }

    setEmojiButtonClicked(false);
  }

  var toolbarOptions = {
    container: [['bold', 'italic', 'underline', 'strike', { 'color': [] }, 'link', 'custombutton']],
    handlers: {
      'custombutton': function () {
        onCustomButtonClick()
      }
    }
  }

  const onCustomButtonClick = () => {
    setCursorPos(quill.getSelection().index)
    setEmojiButtonClicked(!emojiButtonClicked);
  }

  const configureQuill = (richTextQuill) => {
    quill = richTextQuill
  }

  const handleClickAway = () => {
    setEmojiButtonClicked(false);
  };

  // The link symbol used in text below is apparently considered an emoji
  /* eslint-disable jsx-a11y/accessible-emoji */
  return <div className={classes.editor}>

    {emojiButtonClicked &&
      <ClickAwayListener onClickAway={handleClickAway}>
        <div className='emojiBox'>
          <EmojiPicker id="emoji-picker" native={true} onEmojiClick={onEmojiClick} />
        </div>
      </ClickAwayListener>
    }
    <RichTextInput toolbar={toolbarOptions} configureQuill={configureQuill} {...props} />
    <Typography variant='caption' className={classes.notice}>
      NB: the ✦ and 🔗 symbols are not part of the text itself, but denote text colour changes and
      links instead
    </Typography>
    {error ? <div className={classes.editorError}>{error}</div> : null}

  </div>
  /* eslint-enable jsx-a11y/accessible-emoji */
}

export default MessageBodyInput
