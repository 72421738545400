import React, { useState } from 'react'
import { Button, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle } from '@material-ui/core'
import { describeError } from '../utils/errors'

const statusArrayFromSteps = steps => {
  return Array(steps.length).fill(false)
}

const run = (steps, i, previous, setStatus, setRunning) => {
  if (i >= steps.length) {
    setRunning(false)
    return
  }

  Promise.resolve(steps[i].func(previous)).then(
    result => {
      setStatus(status => {
        const updated = Array.from(status)
        updated[i] = true
        return updated
      })
      run(steps, i + 1, result, setStatus, setRunning)
    },
    error => {
      setRunning(false)
      setStatus(status => {
        const updated = Array.from(status)
        updated[i] = error
        return updated
      })
    }
  )
}

/**
 * 
 * @param {{
 *  steps: Array<{label: string, func: function(previous: any): Promise<any>}>,
 *  title: string,
 *  open: boolean,
 *  onCloseSuccess: function(),
 *  onCloseDefault: function(),
 * }} props 
 */
const ProcessSequenceDialog = ({
  steps = [], initialValue, title, subtitle, open, onCloseSuccess, onCloseDefault
}) => {
  const [status, setStatus] = useState(() => statusArrayFromSteps(steps))
  const [running, setRunning] = useState(false)

  const onClose = () => {
    if (running) return  // Don't close the dialog while a process is running

    const isSuccess = status.length > 0 && status[status.length - 1] === true
    if (isSuccess) onCloseSuccess()
    onCloseDefault()

    setStatus(statusArrayFromSteps(steps))
  }

  const showRun = status.length > 0 && status[status.length - 1] === false

  return <Dialog open={open} onClose={onClose}>
    <DialogTitle>{title}</DialogTitle>
    <DialogContent>
      {subtitle && <DialogContentText>{subtitle}</DialogContentText>}
      <ol>
        {status.map((row, i) =>
          <li key={i}>
            {steps[i].label}: {}
            {
              row === false ? 'Pending' :
              row === true ? 'Done' :
              describeError(row)
            }
          </li>)
        }
      </ol>
    </DialogContent>
    <DialogActions>
      {showRun ?
        <>
          <Button variant='outlined' color='primary' onClick={onClose} disabled={running}>
            Cancel
          </Button>
          <Button
            variant='contained'
            color='primary'
            onClick={() => {
              setStatus(statusArrayFromSteps(steps))
              setRunning(true)
              run(steps, 0, initialValue, setStatus, setRunning)
            }}
            disabled={running}
          >
            Run
          </Button>
        </>
        :
        <Button variant='contained' color='primary' onClick={onClose}>
          Close
        </Button>
      }
    </DialogActions>
  </Dialog>
}

export default ProcessSequenceDialog
