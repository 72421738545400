import React from 'react'
import Tooltip from '@material-ui/core/Tooltip'
import AccessTimeIcon from '@material-ui/icons/AccessTime'
import moment from 'moment'
import { makeStyles } from '@material-ui/core'

const useStyles = makeStyles(theme => ({
  clock: {
    color: theme.palette.text.secondary,
  },
}))

const TimestampText = ({ time }) => {
  const classes = useStyles()

  if (!time) return <span>-</span>

  // If time zone info is missing, assume UTC
  const t = moment.utc(time)
  t.local()

  let pretty
  if (t.isBetween(moment().startOf('day'), moment().endOf('day'))) {
    pretty = t.format('H:mm')
  } else if (t.isBetween(moment().startOf('year'), moment().endOf('year'))) {
    pretty = t.format('D.M. H:mm')
  } else {
    pretty = t.format('D.M.YYYY H:mm')
  }

  const localPart = t.format('D.M.YYYY H.mm.ss Z [(]dddd[)]')
  const offsetPart = t.fromNow()
  t.utc()
  const utcPart = t.format('D.M.YYYY H.mm.ss [UTC (]dddd[)]')
  const isoPart = t.format()
  const full = <>
    <div>{localPart}</div>
    <div>{utcPart}</div>
    <div>{offsetPart}</div>
    <div>{isoPart}</div>
  </>
  return <Tooltip title={full} interactive>
    <span>
      {pretty} <AccessTimeIcon className={classes.clock} fontSize='inherit' />
    </span>
  </Tooltip>
}

export default TimestampText