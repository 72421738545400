import React, { Fragment } from 'react'
import Card from '@material-ui/core/Card'
import CardContent from '@material-ui/core/CardContent'
import {
  ShowController,
  Title,
} from 'react-admin'
import PlayerShowHeader from './PlayerShowHeader'
import PlayerAccountWidget from './PlayerAccountWidget'
import PlayerDevicesWidget from './PlayerDevicesWidget'
import PlayerInventoryWidget from './PlayerInventoryWidget'
import TabsView from '../components/TabsView'
import AuditlogListView from '../audit-log/AuditlogListView'
import PlayerEventsWidget from "./PlayerEventsWidget"
import PlayerActivityWidget from "./PlayerActivityWidget"
import PlayerChatsList from './PlayerChatsList'
import PlayerReportsList from './PlayerReportsList'
import PlayerLeaderboardsWidget from './PlayerLeaderboardsWidget'
import { useGameFeatures } from '../utils/games'
import WithPermission from '../components/WithPermission'
import { useHasPermission } from '../utils/auth'
import PlayerMissionsList from './PlayerMissionsList'
import PlayerSeasonsWidget from './PlayerSeasonsWidget'
import { makeStyles } from '@material-ui/core/styles'
import UserStatusGroupIcon from '../components/UserStatusGroupIcon'

const useStyles = makeStyles(theme => ({

  header: {
    marginBottom: theme.spacing(2),
  },
  logs: {
    marginTop: theme.spacing(2),
  },
}))

const PlayerShow = ({
  ...rest
}) => {
  const gameFeatures = useGameFeatures()
  const hasPermission = useHasPermission()
  const classes = useStyles()

  return (
    <ShowController {...rest}>
      {({ record, version }) => {
        if (!record) {
          return null
        }

        record.avatar = 'https://material-ui.com/static/images/avatar/1.jpg'

        return (
          <Fragment>
            <Title title={record.publicName} />
            <div className={classes.header}>
              <PlayerShowHeader player={record} />
            </div>
           
            <div style={{ right: '36px', top: '66.84px', position: 'fixed', zIndex: 1499 }}>
              <UserStatusGroupIcon basePath={"accounts/" + record.id + "/show"} />
            </div>

            <TabsView
              tabs={[
                {
                  label: 'Account info',
                  content: <PlayerAccountWidget player={record} key={version} />,
                },
                {
                  label: 'Inventory',
                  content: <PlayerInventoryWidget player={record} />,
                },
                gameFeatures['messaging/chat'] && {
                  label: 'Chats',
                  content: <WithPermission
                    permissions='ViewChats'
                    fallback={<Card>
                      <CardContent>
                        You do not have permissions to view player chats. (permission ViewChats)
                      </CardContent>
                    </Card>}
                  >
                    <PlayerChatsList player={record} key={version} />
                  </WithPermission>,
                },
                gameFeatures['messaging/chat'] && {
                  label: 'Reports',
                  content: <WithPermission
                    permissions='ViewChats'
                    fallback={<Card>
                      <CardContent>
                        You do not have permissions to view chat reports. (permission ViewChats)
                      </CardContent>
                    </Card>}
                  >
                    <PlayerReportsList player={record} key={version} />
                  </WithPermission>,
                },
                gameFeatures['players/missions'] && {
                  label: 'Missions',
                  content: <PlayerMissionsList player={record} />,
                },
                gameFeatures['seasons/show'] && {
                  label: 'Seasons',
                  content: <PlayerSeasonsWidget player={record} />,
                },
                gameFeatures['players/events'] && {
                  label: 'Events',
                  content: <PlayerEventsWidget player={record} key={version} />,
                },
                gameFeatures['leaderboards'] && {
                  label: 'Leaderboards',
                  content: <PlayerLeaderboardsWidget player={record} key={version} />,
                },
                gameFeatures['players/activity'] && {
                  label: 'Activity',
                  content: <PlayerActivityWidget player={record} />,
                },
                gameFeatures['players/devices'] && {
                  label: 'Devices',
                  content: <PlayerDevicesWidget player={record} key={version} />,
                },
              ]}
            />

            {hasPermission('ViewAuditLogs') &&
              <div className={classes.logs}>
                <AuditlogListView
                  entityId={record.id}
                  entityType={"Player"}
                  title={null}
                  defaultFilters={[]}
                  key={version}
                />
              </div>
            }
          </Fragment>
        )
      }}
    </ShowController>
  )
}

PlayerShow.displayName = 'PlayerShow'

export default PlayerShow
