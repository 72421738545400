const authProvider = {
  // authentication
  login: () => {
    return fetch('/api/login/beacon', { method: 'POST' })
      .then(
        resp => {
          if (resp.status !== 200) return Promise.reject('Beacon auth not available')
          return resp.json()
        },
        error => {
          console.log('Beacon authentication failed', error)
          throw new Error('Failed to contact the Admin Tool authentication backend – are you connected to VPN or office network?')
        })
  },

  checkAuth: (_userInfo) => Promise.resolve(),

  // TODO: can we actually do anything for logout?
  logout: () => Promise.reject(),

  /* NOTE: part of react-admin AuthProvider interface, but not actually used here. */
  checkError: (_error) => Promise.reject(),
  getIdentity: () => Promise.reject(),
  getPermissions: () => Promise.reject(),
}

export default authProvider
