/**
 * Maps a resource/id pair as used by react-admin to a corresponding API URL snippet.
 * 
 * @param {string} resource Name of the resource, as defined in App.js
 * @param {string} [id=null] ID of the specific resource item, leave empty if not applicable.
 */
const urlMapper = (resource, id = null) => {
  if (id === null) return resource

  if (resource === 'config' && id !== 'accounts') {
    return `${id}/${resource}`
  }

  var parts = resource.split('/')
  if (parts.length === 2) {
    switch(parts[0]) {
      case 'maps':
      case 'accounts':
        return `${parts[0]}/${id}/${parts[1]}`
      default:
        // fall through
    }
  }

  return `${resource}/${id}`
}

export default urlMapper
