import {
  useEffect,
  useReducer,
  useMemo,
} from 'react'
import validate from 'validate.js'
import {
  set,
  unset,
  get,
  isObject,
  isArray,
  reduce,
  merge,
  cloneDeep,
} from 'lodash'

const flattenKeys = (obj, path = []) =>
    !isObject(obj)
        ? { [path.join('.')]: obj }
        : reduce(obj, (cum, next, key) => merge(cum, flattenKeys(next, [...path, key])), {})

const reIndex = obj => {
  if (!obj || typeof obj !== 'object') {
    return
  }
  for (let prop in obj) {
    if (isArray(obj[prop])) {
      obj[prop] = obj[prop].filter(v => v)
      obj[prop].forEach(item => reIndex(item))
    } else {
      reIndex(obj[prop])
    }
  }
}

const initialState = {
  values: {},
  errors: {},
  blurred: {},
  submitted: false,
  isValid: false,
}

const validationReducer = (state, action) => {
  switch (action.type) {
    case 'init':
      return {
        ...action.payload,
      }

    case 'change':
      for (let fieldName in action.payload) {
        set(state.values, fieldName, action.payload[fieldName])
      }
      return {
        ...state,
        values: { ...state.values },
      }

    case 'validate':
      return {
        ...state,
        ...action.payload,
      }

    case 'blur':
      set(state.blurred, action.payload, true)
      return {
        ...state,
        blurred: { ...state.blurred },
      }

    case 'remove':
      for (let prop in state) {
        if (typeof state[prop] === 'object') {
          unset(state[prop], action.payload)
        }
      }
      reIndex(state)
      return {
        ...state,
      }

    case 'submit':
      return {
        ...state,
        submitted: true,
      }

    default:
      throw new Error(`Unknown action type ${action.type}`)
  }
}

const  getErrors = (state, showErrors) => {
  if (state.submitted) {
    return state.errors
  }
  if (showErrors === 'always') {
    return state.errors
  }
  if (showErrors === 'blur') {
    const flatten = flattenKeys(state.blurred)
    return Object.entries(flatten)
      .filter(([, blurred]) => blurred)
      .reduce((acc, [name]) => set(acc, name, get(state.errors, name)), {})
  }
  return {}
}

const validateFields = (values, constraints) => {
  const errors = {}

  for (let fieldName in constraints) {
    const friendlyName = fieldName.split('.').pop()
    const value = get(values, fieldName)
    const result = validate({
      [friendlyName]: value,
    }, {
      [friendlyName]: constraints[fieldName],
    })
    if (result) {
      set(errors, fieldName, (result[friendlyName] && result[friendlyName][0]) || '')
    }
  }

  const invalid = Object.entries(flattenKeys(errors))
    .some(([, error]) => error)

  return {
    errors,
    isValid: !invalid,
  }
}

const getInitialState = (initialValues) => {
  // To fix uncontrolled input warning
  if (!initialValues) {
    return { ...initialState }
  }

  const initState = {
    values: cloneDeep(initialValues),
    errors: {},
    isValid: true,
    blurred: {},
    submitted: false,
  }

  return initState
}

const useValidation = config => {
  const {
    onSubmit,
    constraints,
    showErrors,
    initialValues,
  } = config

  const [state, dispatch] = useReducer(validationReducer, initialState, getInitialState)

  // Re-initialize whenever the initial values change
  useEffect(() => { dispatch({ type: 'init', payload: getInitialState(initialValues) }) }, [initialValues])

  useEffect(
    () => {
      const result = validateFields(state.values, constraints)
      dispatch({
        type: 'validate',
        payload: result,
      })
    },
    [state.values, constraints]
  )

  const errors = useMemo(
    () => getErrors(state, showErrors),
    [state, showErrors]
  )
  const getFieldProps = (fieldName, override = {}) => ({
    onBlur: () => {
      dispatch({
        type: 'blur',
        payload: fieldName,
      })
    },
    onChange: event => {
      dispatch({
        type: 'change',
        payload: {
          [fieldName]: event.target.value,
        },
      })
      if (override.onChange) {
        override.onChange(event, state.values)
      }
    },
    name: fieldName,
    value: get(state.values, fieldName) || '',
  })

  return {
    errors,
    isValid: state.isValid,
    values: state.values,
    getFormProps: () => ({
      onSubmit: event => {
        event.preventDefault()
        dispatch({ type: 'submit' })
        if (onSubmit) {
          onSubmit(state)
        }
      },
    }),
    getFieldProps,
    changeFieldValue: (fieldName, value) => {
      dispatch({
        type: 'change',
        payload: {
          [fieldName]: value,
        },
      })
    },
    removeField: fieldName => {
      dispatch({
        type: 'remove',
        payload: fieldName,
      })
    },
    validate: () => {
      dispatch({ type: 'submit' })
    },
    reset: () => {
      dispatch({
        type: 'init',
        payload: getInitialState(initialValues, constraints),
      })
    },
  }
}

export default useValidation
