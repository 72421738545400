import React, { useMemo, useState } from 'react'
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Card,
  CardContent,
  makeStyles,
} from '@material-ui/core'
import {
  Datagrid,
  Filter,
  FunctionField,
  ListContextProvider,
  ListToolbar,
  SelectArrayInput,
  TextField,
  TextInput,
  useGetOne,
} from 'react-admin'
import ExpandMoreIcon from '@material-ui/icons/ExpandMore'
import lodash from 'lodash'

import TimestampField from '../components/TimestampField'
import { useFiltersController, useListContextFromData, useSort } from '../utils/listings'
import { describeError } from '../utils/errors'
import { fieldAsListData, filterDataBy, filterFieldIsOneOf, filterMatchAny, groupBy, sortDataBy } from '../utils/dataTransforms'

const groupLabels = {
  '': 'Missions',
  'NO_GROUP': 'Missions',
  'BASE': 'Main missions - base',
  'TACTICAL': 'Main missions - tactical',
  'SIDE': 'Side missions',
  'DAILY': 'Daily missions',
  'DAILY_MILESTONE': 'Daily rewards',
}

const groupOrdering = {
  'BASE': 1,
  'TACTICAL': 2,
  'SIDE': 3,
  'DAILY_MILESTONE': 4,
  'DAILY': 5,
  'NO_GROUP': 6,
  '': 7,
}

const RewardField = ({ source, record = {} }) => {
  const rewards = lodash.get(record, source)
  if (!rewards || !Array.isArray(rewards)) return null
  return rewards.map(r => `${r.count} ${r.name || r.itemType}`).join(', ')
}

const useMissionGroupStyles = makeStyles(theme => ({
  primaryTitle: {
    flex: '1 0 auto',
  },
  secondaryTitle: {
    color: theme.palette.text.secondary,
  }
}))

const MissionsGroup = ({ title, missions, filters, currentSort, setSort }) => {
  const classes = useMissionGroupStyles()

  const isFiltering = Object.values(filters).some(x => Array.isArray(x) ? x.length > 0 : !!x)
  const [openWithoutFilter, setOpenWithoutFilter] = useState(false)
  const [openWithFilter, setOpenWithFilter] = useState(true)

  const filteredMissions = filterDataBy(missions, filters,
    {
      q: filterMatchAny(),
      state: filterFieldIsOneOf('state'),
    })
  const sortedMissions = sortDataBy(filteredMissions, currentSort)
  const listData = useListContextFromData(
    'missions/player', sortedMissions, { currentSort, setSort })

  return <Accordion
    expanded={isFiltering ? openWithFilter : openWithoutFilter}
    onChange={isFiltering ?
      () => setOpenWithFilter(f => !f) :
      () => setOpenWithoutFilter(f => !f)
    }
  >
    <AccordionSummary expandIcon={<ExpandMoreIcon />}>
      <span className={classes.primaryTitle}>{title}</span>
      <span className={classes.secondaryTitle}>
        {isFiltering ?
          `${listData.total} / ${missions.total} ` :
          `${listData.total} `
        }
        missions
      </span>
    </AccordionSummary>
    <AccordionDetails>
      <ListContextProvider value={listData}>
        <Datagrid>
          <FunctionField label='Name' source='id' render={record => record.name || record.id} />
          <TextField label='State' source='state' />
          <TimestampField label='Started' source='startedTime' />
          <TimestampField label='Completed' source='completedTime' />
          <TimestampField label='Claimed' source='claimedTime' />
          <RewardField label='Reward' source='rewards' />
        </Datagrid>
      </ListContextProvider>
    </AccordionDetails>
  </Accordion>
}

const useMissionListStyles = makeStyles(theme => ({
  input: {
    minWidth: theme.spacing(24),
  }
}))

const PlayerMissionsList = ({ player }) => {
  const classes = useMissionListStyles()

  const missions = useGetOne('missions/player', player.id)
  const groupedMissions = useMemo(
    () => groupBy(fieldAsListData(missions, 'missions'), 'group'),
    [missions])
  const sortedGroups = useMemo(
    () => Object.keys(groupedMissions).sort((a, b) => {
      const ka = groupOrdering[a]
      const kb = groupOrdering[b]
      if (ka && kb) return ka - kb
      if (!ka) return 1
      if (!kb) return -1
      return String(a).localeCompare(String(b))
    }),
    [groupedMissions])

  const filtering = useFiltersController()
  const [currentSort, setSort] = useSort('startedTime', 'DESC')

  return <Card>
    <CardContent>
      {missions.error && <p>{describeError(missions.error)}</p>}
      <ListToolbar
        filters={<Filter>
          <TextInput label='Search' source='q' alwaysOn className={classes.input} />
          <SelectArrayInput
            label='State' source='state' alwaysOn className={classes.input}
            choices={[
              { id: 'NOT_STARTED', name: 'Not started' },
              { id: 'INCOMPLETE', name: 'Incomplete' },
              { id: 'COMPLETED', name: 'Completed' },
              { id: 'CLAIMED', name: 'Claimed' },
              { id: 'UNDEFINED', name: 'Unknown state' },
            ]}
          />
        </Filter>}
        {...filtering}
      />
    </CardContent>
    {sortedGroups.map(key => (
      <MissionsGroup
        key={key}
        title={groupLabels[key] || key}
        missions={groupedMissions[key]}
        filters={filtering.filterValues}
        currentSort={currentSort}
        setSort={setSort}
      />
    ))}
  </Card>
}

export default PlayerMissionsList
