import React, { useMemo, useState } from 'react'
import {
  useGetList,
  useRefresh,
  ListContextProvider,
  ListToolbar,
  SearchInput,
  TextField,
  Datagrid,
  FunctionField,
  Filter,
  Pagination,
} from 'react-admin'

import EditButton from '../components/EditButton'
import RoleEditDrawer from './RoleEditDrawer'
import AddButton from '../components/AddButton'
import { useListContextFromData, usePaginateDataOnClient, useSortDataOnClient, useFilterDataOnClient } from '../utils/listings'
import { filterMatchAny, repackProcessedData } from '../utils/dataTransforms'
import { CardContent } from '@material-ui/core'

const RoleListView = ({
  game = null,
}) => {
  const refresh = useRefresh()
  const [selectedRole, setSelectedRole] = useState(null)
  const games = useGetList('games')
  const roles = useGetList('roles')

  const handleCloseDrawer = () => setSelectedRole(null)

  const handleAddRole = () => {
    setSelectedRole({
      name: 'New role',
      _isNew: true,
    })
  }

  const handleHideDrawer = () => {
    setSelectedRole(null)
    refresh()
  }

  const gameList = game ? [games.data[game.id]] : Object.values(games.data);
  let baseRoles = useMemo(() => {
    var ret = Object.values(roles.data).filter(role => !role.environment)
    if (game) {
      ret = ret.filter(role => role.game === game.id)
    }
    return repackProcessedData(roles, ret)
  }, [game, roles])

  const rolesContext = useListContextFromData('roles',
    usePaginateDataOnClient(
      useSortDataOnClient(
        useFilterDataOnClient(baseRoles, {
          q: filterMatchAny(),
        })
      )
    ))

  return <>
    <ListContextProvider value={rolesContext}>
      <CardContent>
        <ListToolbar
          filters={
            <Filter>
              <SearchInput source='q' alwaysOn />
            </Filter>
          }
          actions={
            <AddButton
              text='Create'
              onClick={handleAddRole}
            />
          }
        />
      </CardContent>
      <Datagrid>
        {!game &&
          <FunctionField
            source='game'
            render={record => {
              const game = games.loaded ? games.data[record.game] : null
              return game ? game.name : record.game
            }}
          />}
        <TextField source='name' />
        <TextField source='description' />
        <FunctionField render={record => {
          if (record.name === 'SuperAdmin') return null
          return <EditButton onClick={() => setSelectedRole(record)} />
        }} />
      </Datagrid>
      <Pagination rowsPerPageOptions={rolesContext.rowsPerPageOptions} />
    </ListContextProvider>
    <RoleEditDrawer
      open={!!selectedRole}
      role={selectedRole}
      games={gameList}
      onClose={handleCloseDrawer}
      onRequestHide={handleHideDrawer}
    />
  </>
}

RoleListView.displayName = 'RoleListView'

export default RoleListView
