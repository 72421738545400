import React from 'react'
import { useGetList } from 'react-admin'
import { Card, CardContent } from '@material-ui/core'

import MessageList from '../messaging/MessageList'

const MapChatWidget = ({ map }) => {
  const sort = { field: 'id', order: 'DESC' }
  const filter = { map: map.id }
  const channels = useGetList('messaging/channel', null, sort, filter)

  return <Card>
    <CardContent>
      {Object.values(channels.data).map(ch =>
        <MessageList filter={{ channel: ch.id }} key={ch.id} />
      )}
    </CardContent>
  </Card>
}

export default MapChatWidget
