import React from 'react'
import PropTypes from 'prop-types'
import Button from '@material-ui/core/Button'
import DeleteIcon from '@material-ui/icons/Delete'
import { withStyles, createStyles } from '@material-ui/core/styles'
import { fade } from '@material-ui/core/styles/colorManipulator'
import classnames from 'classnames'

const DeleteButton = ({
  text,
  classes,
  ...rest
}) => (
  <Button
    color='default'
    className={ classnames(
      'ra-delete-button',
      classes.deleteButton,
    ) }
    { ...rest }
  >
    <DeleteIcon style={ { marginRight: '0.5em' } } />
    { text }
  </Button>
)

DeleteButton.propTypes = {
  text: PropTypes.string,
}

DeleteButton.defaultProps = {
  text: 'Delete',
}

DeleteButton.displayName = 'DeleteButton'

const styles = theme => createStyles({
  deleteButton: {
    color: theme.palette.error.main,
    '&:hover': {
      backgroundColor: fade(theme.palette.error.main, 0.12),
      // Reset on mouse devices
      '@media (hover: none)': {
        backgroundColor: 'transparent',
      },
    },
  },
})

export default withStyles(styles)(DeleteButton)
