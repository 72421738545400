import React, { useState } from 'react'
import Grid from '@material-ui/core/Grid'
import Card from '@material-ui/core/Card'
import CardContent from '@material-ui/core/CardContent'
import CardActions from '@material-ui/core/CardActions'
import Button from '@material-ui/core/Button'
import { makeStyles } from '@material-ui/core'
import {
  useNotify,
  useRefresh,
} from 'react-admin'

import {
  fetchJsonPost,
} from '../utils/fetch'
import ShowItem from '../components/ShowItem'
import NameEditDialog from '../components/NameEditDialog'
import WithPermission from '../components/WithPermission'
import MapLink from '../components/MapLink'

const useStyles = makeStyles(_theme => ({
  container: {
    display: 'inline-block',
  },
}))

const GuildInfoWidget = (props) => {
  const refresh = useRefresh()
  const notify = useNotify()
  const classes = useStyles()
  const [nameDialogOpen, setNameDialogOpen] = useState(false)
  const [guild, setGuild] = useState(props.guild)

  const handleEditButtonClick = () => setNameDialogOpen(true)
  const handleNameDialogClose = () => setNameDialogOpen(false)
  const handleNameUpdate = ({ name, reason }) => {
    if (name === guild.name) {
      handleNameDialogClose()
      return
    }

    const request = {
      oldName: guild.name,
      newName: name,
      reason,
    }
    fetchJsonPost(`api/guilds/${guild.id}/setname`, request, { gameSpecific: true })
      .then(() => {
        guild.name = name
        setGuild({ ...guild })
        refresh()
        handleNameDialogClose()
      })
      .catch(error => {
        notify(error.message || 'Failed to change player name', 'warning')
      })
  }

  return (
    <div className={ classes.root }>
      <Card>
        <CardContent>
          <Grid
            container
            spacing={ 4 }
          >
            <Grid
              container
              item
              xs={ 6 }
              className={ classes.container }
            >
              <ShowItem label='Guild ID'>
                { guild.id }
              </ShowItem>

              <ShowItem label='Guild name'>
                {guild.name} ({guild.shortName}) #{guild.nameTag}
                <WithPermission permissions='EditGuildName'>
                  <Button
                    color='primary'
                    onClick={ handleEditButtonClick }
                  >
                    Edit
                  </Button>
                </WithPermission>
              </ShowItem>

              <ShowItem label='Map'>
                <MapLink id={guild.mapId} />
              </ShowItem>

              <ShowItem label='Country'>
                { guild.countryCode || '-' }
              </ShowItem>

              <ShowItem label='Language'>
                { guild.languageCode || '-' }
              </ShowItem>

              <ShowItem label='Public desc'>
                { guild.publicDescription || '-' }
              </ShowItem>

              <ShowItem label='Private desc'>
                { guild.privateDescription || '-' }
              </ShowItem>
            </Grid>

            <Grid
              container
              item
              xs={ 6 }
              className={ classes.container }
            >
              <ShowItem label='Number of members'>
                {guild.memberCount} / {guild.capacity}
              </ShowItem>

              <ShowItem label='Joinability'>
                { guild.joinability }
              </ShowItem>

              <ShowItem label='Created'>
                { guild.createdAt }
              </ShowItem>

              <ShowItem label='Time of last activity'>
                { guild.lastActivity || '-'  }
              </ShowItem>

              <ShowItem label='Guild power'>
                { guild.power || '-' }
              </ShowItem>

              {guild.details && Object.entries(guild.details).map(([key, val]) => {
                return <ShowItem
                  key={key}
                  label={key.substring(0, 1).toUpperCase() + key.substring(1)}
                >
                  {val}
                </ShowItem>
              })}
            </Grid>
          </Grid>
        </CardContent>

        <CardActions>
          <Button disabled
            color='primary'
          >
            Move to another map
          </Button>

          <WithPermission permissions='BanGuild'>
            <Button disabled
              color='primary'
            >
              Ban/Suspend
            </Button>
          </WithPermission>
        </CardActions>
      </Card>

      { nameDialogOpen && (
        <NameEditDialog
          record={ guild }
          title='Edit guild name'
          nameLabel='Guild name'
          onClose={ handleNameDialogClose }
          onSave={ handleNameUpdate }
        />
      )}
    </div>
  )
}

export default GuildInfoWidget
