// Six base directions, starting from +x and rotating counter-clockwise
const vectors = [
  { x: 2 / 3, y: 0 / 3 },
  { x: 1 / 3, y: 3 / 3 },
  { x: -1 / 3, y: 3 / 3 },
  { x: -2 / 3, y: 0 / 3 },
  { x: -1 / 3, y: -3 / 3 },
  { x: 1 / 3, y: -3 / 3 },
]

// Relative coordinate of the next hex, if we traveled forward from an edge of current hex.
// E.g. with direction 0 we'd travel along the bottom edge of current hex towards positive x
// and end up in hex to bottom right from current one.
const nextHexCoordinate = [
  {x: 1, y: -1},
  {x: 1, y: 1},
  {x: 0, y: 2},
  {x: -1, y: 1},
  {x: -1, y: -1},
  {x: 0, y: -2},
]

/**
 * Finds a starting position hex, from top-left corner of the territory.
 * Specifically, find the smallest x-coordinate, and where equal, the largest y-coordinate.
 * @param {Array<{x: number, y: number}>} hexes 
 */
const getStartHex = (hexes) => {
  let best = hexes[0]
  hexes.forEach(hex => {
    if(hex.x < best.x || (hex.x === best.x && hex.y > best.y)) best = hex
  })
  return best
}

/**
 * 
 * @param {{x: number, y: number}} current Location of current hex
 * @param {number} direction Direction of latest drawn edge on current hex
 */
const getNext = (current, direction) => {
  const step = nextHexCoordinate[direction]
  return {x: current.x + step.x, y: current.y + step.y}
}

/**
 * 
 * @param {Set<string>} hexes Set of available hex coordinates, in 'x/y' format
 * @param {{x: number, y: number}} current Location of current hex
 * @param {number} direction Direction of latest drawn edge on current hex
 */
const hasNext = (hexes, current, direction) => {
  const next = getNext(current, direction)
  return hexes.has(`${next.x}/${next.y}`)
}

export const territoryBorderToPath = (territory) => {
  const hexes = new Set(territory.hexes.map(h => `${h.x}/${h.y}`))
  const startHex = getStartHex(territory.hexes)
  const steps = []

  let current = { x: startHex.x, y: startHex.y }
  // Due to how startHex is chosen, edges 3, 4 and 5 can't have neighbours
  // First, locate the first neighbour after edge 5
  let dir = 5
  while (!hasNext(hexes, current, dir)) {
    dir = (dir + 1) % 6
  }
  // Then locate first edge after which there is no neighbour
  dir = (dir + 1) % 6
  while (hasNext(hexes, current, dir)) {
    dir = (dir + 1) % 6
  }
  // Then draw the border around first hex until there's a neighbour again
  dir = (dir + 1) % 6
  steps.push(dir)
  while (!hasNext(hexes, current, dir)) {
    dir = (dir + 1) % 6
    steps.push(dir)
  }
  current = getNext(current, dir)

  while (!(current.x === startHex.x && current.y === startHex.y)) {
    // Direction of first edge of current hex after the incoming edge
    dir--
    if (dir < 0) dir += 6
    // Draw the first edge
    steps.push(dir)
    // Draw edges around the hex until we find the next hex
    while (!hasNext(hexes, current, dir)) {
      dir = (dir + 1) % 6
      steps.push(dir)
    }
    // Start drawing the next hex
    current = getNext(current, dir)
  }

  // Turn steps to a SVG path
  const startVector = vectors[(steps[0] - 2 + 6) % 6]
  const start = `M ${startHex.x + startVector.x} ${startHex.y + startVector.y}`
  const line = steps.map(dir => {
    const vec = vectors[dir]
    return `l ${vec.x} ${vec.y}`
  })
  return `${start} ${line.join(' ')} z`
}