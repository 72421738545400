import React, { useState, useEffect } from 'react'
import PropTypes from 'prop-types'
import Drawer from '@material-ui/core/Drawer'
import Typography from '@material-ui/core/Typography'
import FormControlLabel from '@material-ui/core/FormControlLabel'
import Checkbox from '@material-ui/core/Checkbox'
import { makeStyles } from '@material-ui/core'
import {
  useNotify,
  UPDATE,
  CREATE,
  DELETE,
  useDataProvider,
} from 'react-admin'
import { useDispatch } from 'react-redux'

import SaveButton from '../components/SaveButton'
import AddButton from '../components/AddButton'
import DeleteButton from '../components/DeleteButton'
import TextField from '../components/TextField'
import useValidation from '../components/useValidation'
import FileUpload from '../components/FileUpload'
import { required } from '../utils/validations'
import { featureList } from '../config/app'
import { useCurrentGame } from '../utils/games'
import { switchGame } from '../redux/actions'
import GameEnvironment from './GameEnvironment'

const defaultConstraints = {
  id: required,
  name: required,
}

const defaultConfig = {
  features: {
    players: true,
  },
}

const useStyles = makeStyles(theme => ({
  container: {
    width: 400,
    padding: 30,
  },
  row: {
    display: 'flex',
    marginBottom: theme.spacing(2),
    flexDirection: 'column',
  },
  environments: {
    marginBottom: theme.spacing(2),
  },
  form: {
    display: 'flex',
    flexDirection: 'column',
  },
  buttons: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
  },
}))

const GameEditDrawer = ({
  game,
  open,
  onClose,
  onRequestHide,
}) => {
  const classes = useStyles()
  const currentGame = useCurrentGame()
  const dataProvider = useDataProvider()
  const dispatch = useDispatch()
  const [environments, setEnvironments] = useState((game && [ ...game.environments ]) || [])
  const [constraints, setConstraints] = useState(defaultConstraints)
  const [config, setConfig] = useState(defaultConfig)
  const [newIcon, setNewIcon] = useState(null)

  const notify = useNotify()

  function handleFormSubmit(state) {
    const game = state.values
    game.config = JSON.stringify(config)

    if (newIcon) {
      game.newIcon = newIcon;
    }

    let promise = null
    if (!game._isNew && game.id) {
      promise = handleUpdate(game)
    } else {
      promise = handleCreate(game)
    }

    promise.then(() => {
      if (!currentGame || game.id === currentGame.id) {
        dispatch(switchGame({
          id: game.id,
          env: currentGame ? currentGame.env : game.environments[0].name,
          config,
        }))
      }
    })
  }

  function handleUpdate(game) {
    return dataProvider(UPDATE, 'games', {
      id: game.id,
      data: game,
    })
    .then(() => {
      notify('Game updated')
      onRequestHide()
    })
    .catch(e => {
      notify(e.message, 'warning')
    })
  }

  function handleCreate(game) {
    return dataProvider(CREATE, 'games', {
      data: game,
    })
    .then(() => {
      notify('Game created')
      onRequestHide()
    })
    .catch(e => {
      notify(e.message, 'warning')
    })
  }

  function handleDelete() {
    dataProvider(DELETE, 'games', {
      id: game.id,
    })
    .then(() => {
      notify('Game deleted')
      onRequestHide()
    })
    .catch(e => {
      notify(e.message, 'warning')
    })
  }

  function handleFeatureChange({ value, required }) {
    if (!required) {
      config.features[value] = !config.features[value]
      setConfig({ ...config })
    }
  }

  // This effect will run on componentDidMount & componentDidUpdate
  // whenever `game` prop is changed.
  useEffect(
    () => {
      setEnvironments((game && [ ...game.environments ]) || [])
    },
    [game]
  )

  useEffect(
    () => {
      const constraints = { ...defaultConstraints }

      environments.forEach((env, index) => {
        constraints[`environments[${index}].name`] = required
        constraints[`environments[${index}].beaconApp`] = required
        constraints[`environments[${index}].beaconEnv`] = required
        constraints[`environments[${index}].rootUrl`] = {
          // TODO: Add custom URL validation. The one provided by validate.js is way too slow.
          ...required,
        }

        if (env.headers) {
          env.headers.forEach((header, hIndex) => {
            constraints[`environments[${index}].customHeaders[${hIndex}].key`] = required
            constraints[`environments[${index}].customHeaders[${hIndex}].value`] = required
          })
        }
      })

      setConstraints(constraints)
    },
    [environments]
  )

  useEffect(
    () => {
      let config = defaultConfig
      if (game && game.config) {
        config = JSON.parse(game.config)
      }

      setConfig(config)
    },
    [game]
  )

  const {
    getFieldProps,
    getFormProps,
    removeField,
    errors,
    isValid,
  } = useValidation(
    {
      constraints,
      showErrors: 'blur',
      initialValues: game,
      onSubmit: handleFormSubmit,
    }
  )

  const handleAddEnvironment = () => {
    const envs = [ ...environments ]
    envs.push({
      name: 'Name',
      rootUrl: 'Url',
      headers: [],
    })
    setEnvironments(envs)
  }

  const handleDeleteEnvironment = index => {
    const envs = [ ...environments ]
    envs.splice(index, 1)
    removeField(`environments[${index}]`)
    setEnvironments(envs)
  }

  const handleAddHeader = env => {
    env.customHeaders = env.customHeaders || []
    env.customHeaders.push({
      key: '',
      value: ''
    })
    setEnvironments([ ...environments ])
  }

  const handleDeleteHeader = (env, envIndex, headerIndex) => {
    env.headers.splice(headerIndex, 1)
    removeField(`environments[${envIndex}].customHeaders[${headerIndex}]`)
    setEnvironments([ ...environments ])
  }

  return (
    <Drawer
      anchor='right'
      open={ open }
      onClose={ onClose }
    >
      <div className={ classes.container }>
        <form
          className={ classes.form }
          { ...getFormProps() }
        >
          <div className={ classes.row }>
            <TextField
              error={ !!errors.id }
              errorMessage={ errors.id }
              label='Id'
              required
              { ...getFieldProps('id') }
            />
          </div>

          <div className={ classes.row }>
            <TextField
              error={ !!errors.name }
              errorMessage={ errors.name }
              label='Name'
              required
              { ...getFieldProps('name') }
            />
          </div>

          <div className={classes.row}>
            <TextField
              error={!!errors.icon}
              errorMessage={errors.icon}
              label='Icon'
              {...getFieldProps('icon')}
            />
            <FileUpload
              onFileDrop={files => setNewIcon(files[0])}
              acceptedExt={'.png, .jpg, .jpeg'}
              text='Click or drag-and-drop to select a new image to use as game icon'
            />
          </div>

          <div className={ classes.row }>
            <Typography
              variant='caption'
              className={ classes.environments }
            >
              Features
            </Typography>
            { featureList.map((feature, index) => (
              <FormControlLabel
                key={ index }
                control={(
                  <Checkbox
                    checked={ !!config.features[feature.value] || !!feature.required }
                    onChange={ () => handleFeatureChange(feature) }
                  />
                )}
                label={
                  <div>
                    <span>{ feature.label }</span>
                    { feature.required && (
                      <Typography
                        variant='caption'
                        style={{ display: 'inline' }}
                      >
                        { ` (required)` }
                      </Typography>
                    )}
                  </div>
                }
              />
            ))}

          </div>

          <div className={ classes.row }>
            <Typography
              variant='caption'
              className={ classes.environments }
            >
              Environments
            </Typography>

            {
              environments.map((env, index) => (
                <div
                  className={ classes.row }
                  key={ env.name }
                >
                  <GameEnvironment
                    index={ index }
                    environment={ env }
                    onDelete={ () => handleDeleteEnvironment(index) }
                    getFieldProps={ getFieldProps }
                    errors={ errors }
                    onAddHeader={ () => handleAddHeader(env) }
                    onDeleteHeader={ handleDeleteHeader }
                  />
                </div>
              ))
            }

            <AddButton
              text='Add environment'
              onClick={ handleAddEnvironment }
            />
          </div>

          <div className={ classes.buttons }>
            <SaveButton
              type='submit'
              disabled={ !isValid }
            />
            { game && game.id && (
              <DeleteButton
                onClick={ handleDelete }
              />
            )}
          </div>
        </form>
      </div>
    </Drawer>
  )
}

GameEditDrawer.propTypes = {
  open: PropTypes.bool,
  game: PropTypes.object,
  onClose: PropTypes.func,
  onSave: PropTypes.func,
}

export default GameEditDrawer
