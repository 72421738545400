export const SWITCH_GAME = 'SWITCH_GAME'
export const UPDATE_GAME = 'UPDATE_GAME'
export const REFRESH_GAMES = 'REFRESH_GAMES'

export const switchGame = game => ({
  type: SWITCH_GAME,
  payload: {
    game,
  },
})

export const updateGame = game => ({
  type: UPDATE_GAME,
  payload: {
    game,
  },
})

export const refreshGames = () => ({
  type: REFRESH_GAMES,
})
