import React from 'react'
import AuditlogListView from '../audit-log/AuditlogListView'
import { Card, CardContent } from '@material-ui/core'

const MessagingHistory = () => {
  return <Card><CardContent>
    <AuditlogListView
      title={null}
      actionFilter={['SendAnnouncement', 'PlayerBoost']}
    />
  </CardContent></Card>
}

export default MessagingHistory