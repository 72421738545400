import React, { useState, Fragment } from 'react'
import PropTypes from 'prop-types'
import Dialog from '@material-ui/core/Dialog'
import DialogActions from '@material-ui/core/DialogActions'
import DialogContent from '@material-ui/core/DialogContent'
import DialogTitle from '@material-ui/core/DialogTitle'
import Typography from '@material-ui/core/Typography'
import Card from '@material-ui/core/Card'
import CardContent from '@material-ui/core/CardContent'
import Chip from '@material-ui/core/Chip'
import FormControlLabel from '@material-ui/core/FormControlLabel'
import Switch from '@material-ui/core/Switch'
import { makeStyles } from '@material-ui/core'

import { required } from '../utils/validations'
import useValidation from '../components/useValidation'
import TextField from '../components/TextField'
import SelectInput from '../components/SelectInput'
import CancelButton from '../components/CancelButton'
import ConfirmButton from '../components/ConfirmButton'
import Autocomplete, { suggestItems } from '../components/Autocomplete'
import {
  fetchJsonPost,
} from '../utils/fetch'
import ItemAttributes from './ItemAttributes'
import { useGameFeatures } from '../utils/games'
import { describeError } from '../utils/errors'

const useStyles = makeStyles(theme => ({
  row: {
    display: 'flex',
    marginBottom: theme.spacing(2),
    flexDirection: 'column',
  },
  container: {
    display: 'flex',
    alignItems: 'top',
    flexDirection: 'row',
  },
  item: {
    width: '33.333%',
  },
  infoRow: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    marginBottom: 10,
  },
  pre: {
    padding: 10,
    wordBreak: 'break-all',
    wordWrap: 'break-word',
    backgroundColor: '#f5f5f5',
    border: '1px solid #ccc',
    borderRadius: 5,
  },
  error: {
    display: 'flex',
    marginBottom: theme.spacing(2),
    padding: theme.spacing(1),
    flexDirection: 'column',
    background: '#fff3e0',
  },
}))

// NOTE: constraints needs to be put here or useEffect() in useValidation()
// will run in  a loop.
const constraints = {
  amount: {
    numericality: {
      onlyInteger: true,
      greaterThan: 0,
      strict: true,
    },
    ...required,
  },
  section: required,
}

let initialValues = {
  amount: '',
}

const PlayerDonateInventoryDialog = ({
  title,
  player,
  inventory,
  type,
  onClose,
  itemTypes,
  onRequestUpdateView,
}) => {
  const classes = useStyles()
  const gameFeatures = useGameFeatures()
  const [itemType, _setItemType] = useState(type)
  const [claimMode, setClaimMode] = useState(true)
  const [attributes, setAttributes] = useState(type ? type.defaultAttributes : {})
  const [error, setError] = useState(null)
  const setItemType = type => {
    _setItemType(type)
    setAttributes(type ? type.defaultAttributes : {})
  }

  let sections = []
  if (itemType) {
    sections = itemType.compatibleSections.map(secName => ({
      value: secName,
      label: secName,
    }))
    if (sections.length === 1 && initialValues.section !== sections[0].value) {
      initialValues = { ...initialValues, section: sections[0].value }
    }
  }

  const {
    getFieldProps,
    errors,
    isValid,
    values,
  } = useValidation(
    {
      constraints,
      initialValues,
      showErrors: 'blur',
    }
  )

  function handleAttributesChange(newAttributes) {
    setAttributes(newAttributes)
  }

  function handleSave(event) {
    event.preventDefault()
    setError(null)
    const query = {
      section: values.section,
      item: {
        id: null,
        count: values.amount,
        typeId: itemType.id,
        attributes: attributes,
      },
      supportNote: values.note,
      supportTicket: values.supportTicket,
      playerMessage: {},
    }

    if (gameFeatures['donation/message']) {
      query.playerMessage.title = values.messageTitle
      query.playerMessage.description = values.messageDescription
      // TODO: use a real rich text input
      const node = document.createElement('p')
      node.textContent = values.playerMessage
      query.playerMessage.html = '<p>' + node.innerHTML + '</p>'
      // TODO (2020-03-26): Transitional property, remove later
      let title = values.messageTitle || values.messageDescription
      if (values.messageTitle && values.messageDescription) title = `${values.messageTitle} - ${values.messageDescription}`
      let message = values.playerMessage || title
      if (values.playerMessage && title) message = `${title}: ${values.playerMessage}`
      query.customMessage = message
    }
    if (gameFeatures['donation/claim']) query.playerMessage.mustClaimRewards = claimMode

    return fetchJsonPost(`api/inventory/${player.id}/donate`, query, { gameSpecific: true })
    .then(() => {
      onRequestUpdateView()
    })
    .catch(error => {
      setError(error)
    })
  }

  const inventoryItem = inventory.find(item => itemType && item.typeId === itemType.id)
  let current = inventoryItem ? inventoryItem.count : 0
  let total = current
  const amount = parseInt(values.amount)
  if (`${amount}` === values.amount) {
    total += amount
  }

  return (
    <Dialog
      open
      fullWidth
      onClose={ onClose }
    >
      <DialogTitle>
        { title }
      </DialogTitle>

      <DialogContent style={{ minHeight: 500 }}>
        { !type && (
          <div className={ classes.row }>
            <Autocomplete
              onChange={ setItemType }
              getItemId={ item => item.id }
              getItemLabel={ item => item.name }
              getSuggestions={query => suggestItems(itemTypes, query)}
              textFieldProps={{
                label: 'Item type',
                placeholder: 'Search by item name',
                autoFocus: true,
              }}
            />
          </div>
        )}

        { itemType && (
          <Fragment>
            { type && (
              <div className={ classes.row }>
                <Typography
                  variant='caption'
                >
                  Item type
                </Typography>
                <Typography
                  variant='body2'
                  component='span'
                >
                  { itemType.name }
                </Typography>
              </div>
            )}

            <div className={ classes.row }>
              <SelectInput
                fullWidth
                label='Section'
                items={ sections }
                includeAllItem={ false }
                error={ !!errors.section }
                errorMessage={ errors.section }
                required
                { ...getFieldProps('section') }
              />
            </div>

            <div className={ classes.row }>
              <div className={ classes.container }>
                <div className={ classes.item }>
                  <Typography
                    variant='caption'
                  >
                    Current
                  </Typography>
                  <Typography
                    variant='body2'
                  >
                    { current.toLocaleString() }
                  </Typography>
                </div>

                <div
                  className={ classes.item }
                  style={{ textAlign: 'center' }}
                >
                  <Typography
                    variant='caption'
                  >
                    Amount
                  </Typography>
                  <TextField
                    error={ !!errors.amount }
                    errorMessage={ errors.amount }
                    autoFocus
                    fullWidth
                    required
                    messageProps={{
                      style: { textAlign: 'center' }
                    }}
                    style={{ width: 90, margin: '0 auto' }}
                    inputProps={{
                      style: {
                        textAlign: 'center',
                      }
                    }}
                    { ...getFieldProps('amount') }
                  />
                </div>

                <div
                  className={ classes.item }
                  style={{ textAlign: 'right' }}
                >
                  <Typography
                    variant='caption'
                  >
                    Total
                  </Typography>
                  <Typography
                    variant='body2'
                  >
                    { total.toLocaleString() }
                  </Typography>
                </div>
              </div>
            </div>

            <div className={ classes.row }>
              <Typography
                variant='caption'
              >
                Categories:
              </Typography>
              <Typography
                variant='body2'
                component='span'
              >
                { itemType.categories.map(r => (
                  <Chip
                    key={ r }
                    label={ r }
                    style={ { marginRight: 10 } }
                  />
                ))}
              </Typography>
            </div>

            { itemType.defaultAttributes && Object.keys(itemType.defaultAttributes).length > 0 && (
              <div className={ classes.row }>
                <Typography
                  variant='caption'
                  style={{ width: 100 }}
                >
                  Attributes
                </Typography>
                <ItemAttributes
                  key={ itemType.id }
                  attributes={ attributes }
                  onChange={ handleAttributesChange }
                />
              </div>
            )}

            { gameFeatures['donation/claim'] && (
              <FormControlLabel
                control={(
                  <Switch
                    checked={claimMode}
                    onChange={event => setClaimMode(event.target.checked)}
                    value='claimMode'
                    color='primary'
                  />
                )}
                label='Player must claim'
              />
            )}

            {gameFeatures['donation/message'] && (
              <div className={classes.row}>
                <Card variant='outlined'>
                  <CardContent>
                    Message to player
                    <TextField
                      error={!!errors.messageTitle}
                      errorMessage={errors.messageTitle}
                      label='Title'
                      fullWidth
                      {...getFieldProps('messageTitle')}
                    />
                    <TextField
                      error={!!errors.messageDescription}
                      errorMessage={errors.messageDescription}
                      label='Description'
                      fullWidth
                      {...getFieldProps('messageDescription')}
                    />
                    <TextField
                      error={!!errors.playerMessage}
                      errorMessage={errors.playerMessage}
                      label='Message'
                      fullWidth
                      {...getFieldProps('playerMessage')}
                    />
                  </CardContent>
                </Card>
              </div>
            )}

            <div className={ classes.row }>
              <TextField
                error={ !!errors.note }
                errorMessage={ errors.note }
                label='Support agent note'
                fullWidth
                { ...getFieldProps('note') }
              />
            </div>
            <div className={ classes.row }>
              <TextField
                error={ !!errors.supportTicket }
                errorMessage={ errors.supportTicket }
                label='Support ticket number'
                fullWidth
                { ...getFieldProps('supportTicket') }
              />
            </div>
          </Fragment>
        )}

        {error ? <div className={classes.error}>{describeError(error)}</div> : null}
      </DialogContent>

      <DialogActions>
        <CancelButton
          onClick={ onClose }
        />
        <ConfirmButton
          onClick={ handleSave }
          text='Donate'
          disabled={ !isValid }
        />
      </DialogActions>
    </Dialog>
  )
}

PlayerDonateInventoryDialog.propTypes = {
  player: PropTypes.object.isRequired,
  title: PropTypes.string.isRequired,
  onClose: PropTypes.func.isRequired,
  inventory: PropTypes.array.isRequired,
  type: PropTypes.object,
  itemTypes: PropTypes.array.isRequired,
  onRequestUpdateView: PropTypes.func.isRequired,
}

PlayerDonateInventoryDialog.displayName = 'PlayerDonateInventoryDialog'

export default PlayerDonateInventoryDialog
