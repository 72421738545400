import React, { Fragment, useState, useEffect } from "react"
import PropTypes from "prop-types"
import { useMutation } from "react-admin";
import Tooltip from "@material-ui/core/Tooltip"
import Avatar from "@material-ui/core/Avatar"
import AvatarGroup from "@material-ui/lab/AvatarGroup"
import purple from "@material-ui/core/colors/purple"
import orange from "@material-ui/core/colors/orange"
import teal from "@material-ui/core/colors/teal"
import { localStorageGet } from "../utils/localStorage"
import { useCurrentGame } from '../utils/games'

//the master switch to disable the functionality from all pages
const enableGroupIcon = false;


const getColorFrom3ChoisesByIndex = (colorIndex) => {
  const shade = 500;
  const colors = [purple[shade], orange[shade], teal[shade]];
  return colors[colorIndex];
};

const getUserId = () => {
  const authInfo = localStorageGet("user_info");
  var auth = authInfo ? JSON.parse(authInfo) : null;
  return authInfo ? auth.id : null;
};

const UserStatusGroupIcon = ({ basePath }) => {
  const [otherViewers, setOtherViewers] = useState(false);
  const [send] = useMutation();
  const [isStatusCheckEnabled, setIsStatusCheckEnabled] = useState(null);
  const currentGame = useCurrentGame()
  const tabIdKey = 'reloadstate_' + basePath //TODO: unique key in case of tab duplication

useEffect(() => {
    if (!enableGroupIcon) {
      setIsStatusCheckEnabled(false);
      return;
    }

    addVisit();
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    if (!isStatusCheckEnabled) return;

    var currentPathReloadState = JSON.parse(window.sessionStorage.getItem(tabIdKey))
    //reload is possible after initial page load
    if (window.performance && currentPathReloadState === 'allow') {
      if (performance.navigation.type === 1) {
        unVisit();
      }
    }

    checkStatus();
    window.sessionStorage.setItem(tabIdKey, JSON.stringify('allow'));

    return () => {
      unVisit();
      window.sessionStorage.setItem(tabIdKey, JSON.stringify('prevent'));
    };
  }, [isStatusCheckEnabled]); //eslint-disable-line react-hooks/exhaustive-deps

  const checkStatus = () => {
    send(
      {
        type: "create",
        resource: "loggeduserstatus/status",
        payload: {
          data: { basePath: basePath },
        },
      },
      {
        onSuccess: (result) => {
          if (result.response.status === 200) {
            setOtherViewers(result.response.json.otherViewers);
          } else {
            setOtherViewers(false);
          }
        },
        onFailure: (error) => {
          console.log(error);
          setOtherViewers(false);
        },
      }
    );
  };

  const addVisit = () => {
    send(
      {
        type: "create",
        resource: "loggeduserstatus/visit",
        payload: {
          data: { basePath: basePath },
        },
      },
      {
        onSuccess: (result) => {
          if (result.response.status === 200) {
            setIsStatusCheckEnabled(true);
          } else {
            setIsStatusCheckEnabled(false);
            console.warm("visit error, UserStatusGroupIcon is disabled");
          }
        },
        onFailure: (error) => {
          setIsStatusCheckEnabled(false);
          console.warm("visit error, UserStatusGroupIcon is disabled.", error);
        },
      }
    );
  };

  const unVisit = () => {
    let gameIdAndEnv = `${currentGame.id}/${currentGame.env}`;
    let url = `${window.location.origin}/${gameIdAndEnv}/api/loggeduserstatus/unvisit`;
    let body = {
      basePath: basePath,
      id: getUserId(),
    };
    let headers = {
      type: "application/json",
    };
    let blob = new Blob([JSON.stringify(body)], headers);
    navigator.sendBeacon(url, blob);
  };

  const GroupAvatars = ({ otherViewers }) => {
    return (
      <AvatarGroup id="avatargroupicontestid" max={3}>
        {otherViewers.map((name, index) => {
          return (
            <Tooltip
              key={index}
              title={name.concat(" has the same resource open")}
            >
              <Avatar
                key={index}
                alt={name}
                style={{ backgroundColor: getColorFrom3ChoisesByIndex(index) }}
              >
                {name.charAt(0)}
              </Avatar>
            </Tooltip>
          );
        })}
      </AvatarGroup>
    );
  };

  return (
    <Fragment>
      {otherViewers && otherViewers.length > 0 ? (
        <div>
          <GroupAvatars otherViewers={otherViewers}></GroupAvatars>
        </div>
      ) : null}
    </Fragment>
  );
};

UserStatusGroupIcon.displayName = "UserStatusGroupIcon";
UserStatusGroupIcon.propTypes = {
  basePath: PropTypes.string.isRequired,
};

export default UserStatusGroupIcon;
