import React, { useEffect, useState } from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import {
  useNotify,
} from 'react-admin'

import { formatBan, formatSpend } from './format'
import { fetchJsonPost } from '../utils/fetch'
import ShowHeader from '../components/ShowHeader'
import { useHasPermission } from '../utils/auth'
import PlayerMessageEditDialog from '../components/PlayerMessageEditDialog'


function formatBans(player) {
  if (typeof player.activeBans !== 'object')
    return '-'

  let r = Object.values(player.activeBans).map(formatBan).join('\n')
  return r === '' ? '-' : r
}

const PlayerShowHeader = ({
  player,
}) => {
  const [lastAction, setLastAction] = useState(null)
  const notify = useNotify()
  const hasPermission = useHasPermission()

  useEffect(
    () => {
      if (!hasPermission('ViewAuditLogs')) return
      const filters = {
        entityId: player.id,
        paging: {
          startIndex: 0,
          size: 1,
        },
      }
      fetchJsonPost('api/auditlog/search', filters, { gameSpecific: true, allowForbidden: true })
        .then(result => {
          if (result.json.length > 0) {
            setLastAction(result.json[0])
          } else {
            setLastAction({ })
          }
        })
        .catch(() => {
          setLastAction(null)
          notify('Failed to fetch last customer support action', 'warning')
        })
      },
    [player, notify, hasPermission]
  )

  const items = [
    {
      label: 'Ban',
      value: formatBans(player),
    },
    {
      label: 'Total spend',
      value: formatSpend(player),
    },
    {
      label: 'Time played',
      value: '-',
    },
    {
      label: 'Level',
      value: player.level,
    },
  ]

  if (lastAction) {
    let desc = lastAction.description || 'None'
    if (lastAction.userName) {
      desc += ' by ' + lastAction.userName
    }
    items.push({
      label: 'Last customer support action',
      value: desc,
      size: 3,
    })
  }

  return (
    <>
    <ShowHeader
      items={ items }
      actions={[<PlayerMessageEditDialog player={player}></PlayerMessageEditDialog>,
      ]}
    />
    </>
  )
}

PlayerShowHeader.propTypes = {
  player: PropTypes.object.isRequired,
  views: PropTypes.number.isRequired,
}

const mapStateToProps = state => ({ views: state.admin.ui.viewVersion })

export default connect(mapStateToProps)(PlayerShowHeader)
