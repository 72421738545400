import React from 'react'
import PropTypes from 'prop-types'
import { withStyles } from '@material-ui/core/styles'
import IconButton from '@material-ui/core/IconButton'
import DeleteIcon from '@material-ui/icons/Delete'
import Typography from '@material-ui/core/Typography'
import { get } from 'lodash'

import TextField from '../components/TextField'
import SelectInput from '../components/SelectInput'
import GameEnvironmentHeader from './GameEnvironmentHeader'
import AddButton from '../components/AddButton'

const beaconEnvs = [
  {
    value: 'dev', label: 'Development',
  },
  {
    value: 'stage', label: 'Staging',
  },
  {
    value: 'prod', label: 'Production',
  },
]

const GameEnvironment = ({
  classes,
  index,
  onDelete,
  getFieldProps,
  environment,
  errors,
  onAddHeader,
  onDeleteHeader,
}) => {
  const fieldMapping = {
    name: `environments[${index}].name`,
    rootUrl: `environments[${index}].rootUrl`,
    apiVersion: `environments[${index}].apiVersion`,
    beaconApp: `environments[${index}].beaconApp`,
    beaconEnv: `environments[${index}].beaconEnv`,
  }

  return (
    <div className={ classes.container }>
      <div className={ classes.counter }>
        <Typography>
          { index + 1 }
.
        </Typography>
      </div>

      <div className={ classes.fieldsContainer }>
        <div className={ classes.row }>
          <TextField
            error={ !!get(errors, fieldMapping['name']) }
            errorMessage={ get(errors, fieldMapping['name']) }
            label='Name'
            required
            { ...getFieldProps(fieldMapping['name']) }
          />
        </div>

        <div className={ classes.row }>
          <TextField
            error={ !!get(errors, fieldMapping['rootUrl']) }
            errorMessage={ get(errors, fieldMapping['rootUrl']) }
            label='Root URL'
            required
            { ...getFieldProps(fieldMapping['rootUrl']) }
          />
        </div>

        <div className={ classes.row }>
          <TextField
            error={ !!get(errors, fieldMapping['apiVersion']) }
            errorMessage={ get(errors, fieldMapping['apiVersion']) }
            label='API Version'
            required
            { ...getFieldProps(fieldMapping['apiVersion']) }
          />
        </div>

        <div className={ classes.row }>
          <TextField
            error={ !!get(errors, fieldMapping['beaconApp']) }
            errorMessage={ get(errors, fieldMapping['beaconApp']) }
            label='Beacon App ID'
            required
            { ...getFieldProps(fieldMapping['beaconApp']) }
          />
        </div>

        <div className={ classes.row }>
          <SelectInput
            items={ beaconEnvs }
            label='Beacon Environment'
            includeAllItem={ false }
            error={ !!get(errors, fieldMapping['beaconEnv']) }
            errorMessage={ get(errors, fieldMapping['beaconEnv']) }
            required
            { ...getFieldProps(fieldMapping['beaconEnv']) }
          />
        </div>
        <Typography
          variant='caption'
          style={{ marginBottom: 16 }}
        >
          Request headers
        </Typography>
        {
          environment.customHeaders && environment.customHeaders.map((_header, headerIndex) => (
            <div
              className={ classes.row }
              key={ headerIndex }
            >
              <GameEnvironmentHeader
                headerIndex={ headerIndex }
                envIndex={ index }
                getFieldProps={ getFieldProps }
                onDelete={ () => onDeleteHeader(environment, index, headerIndex) }
                errors={ errors }
              />
            </div>
          ))
        }
        <AddButton
          text='Add header'
          onClick={ onAddHeader }
        />
      </div>

      <div className={ classes.actionContainer }>
        <IconButton
          variant='fab'
          onClick={ onDelete }
        >
          <DeleteIcon />
        </IconButton>
      </div>
    </div>
  )
}

GameEnvironment.propTypes = {
  classes: PropTypes.object.isRequired,
  index: PropTypes.number.isRequired,
  onDelete: PropTypes.func.isRequired,
  getFieldProps: PropTypes.func.isRequired,
  errors: PropTypes.object,
  environment: PropTypes.object.isRequired,
}

const styles = theme => ({
  container: {
    display: 'flex',
    flexDirection: 'row',
  },
  fieldsContainer: {
    flex: 1,
  },
  counter: {
    marginRight: theme.spacing(2),
  },
  button: {
    marginLeft: theme.spacing(1),
  },
  row: {
    display: 'flex',
    marginBottom: theme.spacing(2),
    flexDirection: 'column',
  },
})

export default withStyles(styles)(GameEnvironment)
