import React from 'react'

import GlobalViewLayout from './GlobalViewLayout'
import GamesListView from '../games/GamesListView'

const GlobalGameManagement = () =>
  <GlobalViewLayout>
    <GamesListView />
  </GlobalViewLayout>

export default GlobalGameManagement
