import { useCallback, useEffect, useState, useMemo, useRef } from 'react'
import { useDataProvider, useVersion } from 'react-admin'
import { isEqual } from 'lodash'
import { sortDataBy, paginateDataBy, filterDataBy } from './dataTransforms'

export const useSort = (field, order = 'ASC') => {
  const [sort, setSort] = useState({ field: field, order: order })

  const updateSort = useCallback((field, order = null) => {
    const newSort = { field: sort.field, order: sort.order }
    if (order) {
      newSort.field = field
      newSort.order = order
    } else if (sort.field === field) {
      if (sort.order === 'ASC') newSort.order = 'DESC'
      else newSort.order = 'ASC'
    } else {
      newSort.field = field
      newSort.order = 'ASC'
    }
    setSort(newSort)
  }, [sort.field, sort.order])

  return [sort, updateSort]
}

export const usePaginatedListController = (
  resource,
  {
    basePath = null,
    sort = null,
    perPage = 10,
    perPageOptions = [10, 25, 50],
  } = {}
) => {
  const [currentSort, setSort] = useSort(
    (sort && sort.field) ? sort.field : 'id',
    (sort && sort.order) ? sort.order : 'ASC')
  const [page, setPage] = useState(1)
  const [currentPerPage, setPerPage] = useState(perPage)
  const rowsPerPageOptions = useMemo(() => {
    var opt = perPageOptions
    if (!perPageOptions.includes(perPage)) opt = [...opt, perPage].sort()
    return opt
  }, [perPage, perPageOptions])

  return {
    resource, basePath: basePath ? basePath : '/' + resource,
    currentSort, setSort: sort !== false ? setSort : () => false,
    page, setPage, perPage: currentPerPage, setPerPage, rowsPerPageOptions,
    pagination: { page, perPage: currentPerPage },
    selectedIds: [],
  }
}

export const listPropsForData = (result) => {
  return { ...result, total: result.total || 0 }
}

export const useBulkActionsController = () => {
  const [selected, setSelected] = useState([])

  return {
    selectedIds: selected,
    hasBulkActions: true,
    onSelect: setSelected,
    onToggleItem: id => setSelected(a => a.includes(id) ? a.filter(x => x !== id) : [...a, id]),
  }
}

export const useFiltersController = (staticFilters = {}, defaultFilters = {}) => {
  const [filterValues, setFilterValues] = useState(defaultFilters)
  const [displayedFilters, setDisplayedFilters] = useState(() =>
    Object.fromEntries(Object.keys(defaultFilters).map(k => [k, true])))

  return {
    showFilter: (id, defaultValue) => {
      setFilterValues(v => ({ ...v, [id]: defaultValue, }))
      setDisplayedFilters(f => ({ ...f, [id]: true }))
    },
    hideFilter: (id) => {
      setFilterValues(v => ({ ...v, [id]: undefined }))
      setDisplayedFilters(f => ({ ...f, [id]: false }))
    },
    setFilters: (filters, displayed) => {
      setFilterValues(filters)
      setDisplayedFilters(displayed)
    },
    filterValues: { ...filterValues, ...staticFilters },
    displayedFilters,
  }
}

export const useListContextFromData = (resource, data, ...rest) => {
  const [filterValues, setFilterValues] = useState({})
  const [displayedFilters, setDisplayedFilters] = useState({})
  const context = {
    resource,
    selectedIds: [],
    currentSort: {
      field: 'id',
      order: 'ASC',
    },
    ids: [],
    basePath: '/' + resource,
    showFilter: (id, defaultValue) => {
      setFilterValues(v => ({ ...v, [id]: defaultValue, }))
      setDisplayedFilters(f => ({ ...f, [id]: true }))
    },
    hideFilter: (id) => {
      setFilterValues(v => ({ ...v, [id]: undefined }))
      setDisplayedFilters(f => ({ ...f, [id]: false }))
    },
    setFilters: (filters, displayed) => {
      setFilterValues(filters)
      setDisplayedFilters(displayed)
    },
    filterValues,
    displayedFilters,
    ...data,
  }
  // `data` may contain `total: undefined`, which triggers errors. Use zero instead
  if (!context.total) context.total = 0
  rest.forEach(params => Object.entries(params).forEach(([k, v]) => context[k] = v))
  return context
}

export const useListContextFromArray = (resource, array, ...rest) => {
  if (!Array.isArray(array)) array = []
  const data = {
    ids: array.map((_v, k) => k),
    data: Object.fromEntries(array.map((v, k) => [k, v])),
    total: array.length,
  }

  return useListContextFromData(resource, data, ...rest)
}

export function useStableCopy(data) {
  const [copy, setCopy] = useState(data)
  if (isEqual(data, copy)) return copy
  setCopy(data)
  return data
}

export function useGetIndirectList(directEntity, filter, indirectEntity, filterKind, paging, sort) {
  const dataProvider = useDataProvider()
  const [directData, setDirectData] = useState(null)
  const storedFilter = useStableCopy(filter)
  const storedPaging = useStableCopy(paging)
  const storedSort = useStableCopy(sort)

  const [loading, setLoading] = useState(true)
  const [error, setError] = useState(null)
  const [data, setData] = useState(null)
  const [total, setTotal] = useState(0)

  useEffect(() => {
    const directPaging = { page: 1, perPage: 100 }
    const directSort = { field: 'id', order: 'ASC' }
    setLoading(true)
    setData(null)
    setError(null)
    setTotal(0)
    dataProvider.getList(
      directEntity,
      { pagination: directPaging, sort: directSort, filter: storedFilter }
    ).then(
      result => {
        setDirectData(Object.fromEntries(result.data.map(row => [row.id, row])))
      },
      error => {
        setError(error)
        setDirectData(null)
        setLoading(false)
      }
    )
  }, [directEntity, storedFilter, dataProvider])

  useEffect(() => {
    if (!directData) return
    setLoading(true)
    setData(null)
    setError(null)
    setTotal(0)
    const ids = Object.keys(directData)
    if (ids.length === 0) {
      setLoading(false)
      setData({})
      return
    }
    dataProvider.getList(indirectEntity,
      { pagination: storedPaging, storedSort, filter: { [filterKind]: ids } }
    ).then(
      result => {
        // Short-circuit: don't bother processing indirect results if they don't exist
        if (result.data && result.data.length > 0) {
          const data = Object.fromEntries(result.data.map(row => [row.id, row]))
          // TODO: Instead of this hack, return the matched ID from the indirect search
          Object.keys(directData).forEach(did => {
            const re = RegExp(`\\b${did}\\b`)
            const matchedIds = Object.keys(data).filter(iid => re.test(iid))
            if (matchedIds.length !== 1) {
              console.log('No precise match of id %s to list %o', did, Object.keys(data))
            }
            if (matchedIds.length >= 1) {
              data[matchedIds[0]].backref = directData[did]
            }
          })
          setData(data)
        } else {
          setData({})
        }
        setTotal(result.total)
        setLoading(false)
      },
      error => {
        setError(error)
        setData(null)
        setLoading(false)
      }
    )
  }, [indirectEntity, directData, storedPaging, storedSort, filterKind, dataProvider])

  return useMemo(() => ({
    data: data ? data : {},
    ids: data ? Object.keys(data) : [],
    loading, loaded: !loading,
    error: error,
    total,
  }), [data, loading, error, total])
}

const tokenChars = ['a', 'b', 'c', 'd', 'e', 'f', 'g', 'h', 'i', 'k', 'm', 'o', 'p', 'q', 'r', 's']
/** Gets a random token that updates whenever page update is requested. */
export const useToken = () => {
  const length = 6;
  const version = useVersion()
  const [base] = useState(() => Math.floor(Math.random() * Math.pow(tokenChars.length, length)))
  return useMemo(() => {
    let value = base ^ version
    let ret = ''
    for (let i = 0; i < length; ++i) {
      ret = tokenChars[value % tokenChars.length] + ret
      value = Math.floor(value / tokenChars.length)
    }
    return ret
  }, [base, version])
}

export const useDeleteSafeCopy = (data, key) => {
  const version = useVersion()
  const fullKey = JSON.stringify({ key, version })
  const storedData = useRef(null)
  const storedKey = useRef(fullKey)
  if (data.loading) {
    storedData.current = null
    return data
  }
  if (!storedData.current || storedKey.current !== fullKey) {
    storedData.current = data
    storedKey.current = fullKey
  }
  return storedData.current
}

export const useAtLoadingFinished = (loading, func) => {
  const [prevLoading, setPrevLoading] = useState(true)

  if (!loading && prevLoading) func()
  if (loading !== prevLoading) setPrevLoading(loading)
}

export const useSortDataOnClient = (data) => {
  const [currentSort, setSort] = useSort()
  return useMemo(() => ({
    ...sortDataBy(data, currentSort),
    currentSort,
    setSort,
  }), [data, currentSort, setSort])
}

export const usePaginateDataOnClient = (data, settings = {}) => {
  const [page, setPage] = useState(1)
  const [perPage, setPerPage] = useState(settings?.perPage || 20)
  const rowsPerPageOptions = useMemo(() => {
    var opt = settings?.perPageOptions || [10, 20, 50, 100]
    if (settings?.perPage && !opt.includes(settings?.perPage)) {
      opt = [...opt, settings?.perPage].sort()
    }
    return opt
  }, [settings])

  return useMemo(() => ({
    ...paginateDataBy(data, { page, perPage }),
    page, setPage, perPage, setPerPage, rowsPerPageOptions,
    pagination: { page, perPage },
  }), [data, page, perPage, rowsPerPageOptions])
}

export const useFilterDataOnClient = (data, filterConfig = {}) => {
  const controller = useFiltersController()
  return useMemo(() => ({
    ...filterDataBy(data, controller.filterValues, filterConfig),
    ...controller,
  }), [controller, data, filterConfig])
}
