import React from 'react'
import PropTypes from 'prop-types'
import Button from '@material-ui/core/Button'
import SaveIcon from '@material-ui/icons/Save'

const SaveButton = ({ text, ...rest }) => (
  <Button
    variant='contained'
    color='primary'
    { ...rest }
  >
    <SaveIcon style={ { marginRight: '0.5em' } } />
    { text }
  </Button>
)

SaveButton.propTypes = {
  text: PropTypes.string,
}

SaveButton.defaultProps = {
  text: 'Save',
}

SaveButton.displayName = 'SaveButton'

export default SaveButton
