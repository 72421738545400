import React, {
  useState,
  Fragment,
} from 'react'
import PropTypes from 'prop-types'
import Grid from '@material-ui/core/Grid'
import Typography from '@material-ui/core/Typography'
import Card from '@material-ui/core/Card'
import CardContent from '@material-ui/core/CardContent'
import CardActions from '@material-ui/core/CardActions'
import Button from '@material-ui/core/Button'
import Tooltip from '@material-ui/core/Tooltip'
import { makeStyles } from '@material-ui/core'
import {
  Query,
  useNotify,
  useRefresh
} from 'react-admin'
import plugin from 'js-plugin'

import ExternalLink from '../components/ExternalLink'
import PlayerUnbanButton from './PlayerUnbanButton'
import PlayerBanDialog from './PlayerBanDialog'
import {
  fetchJsonPost,
  fetchJsonDelete,
} from '../utils/fetch'
import { formatBan } from './format'
import ShowItem from '../components/ShowItem'
import NameEditDialog from '../components/NameEditDialog'
import PlayerAccountRecoveryDialog from './PlayerAccountRecoveryDialog'
import WithPermission from '../components/WithPermission'
import { getBeaconProfileUrl } from "../utils/beacon";
import TimestampText from '../components/TimestampText'
import GuildLink from '../components/GuildLink'
import MapLink from '../components/MapLink'
import { useCurrentGame } from '../utils/games'

/** @typedef {import('admintool-api/v1/account').Account} Account */

const useStyles = makeStyles(theme => ({
  container: {
    maxWidth: 600,
    display: 'inline-block',
  },
  item: {
    marginBottom: theme.spacing(1),
  },
  label: {
    width: 200,
    display: 'inline-block',
  },
  value: {
    width: 300,
    display: 'inline-block',
  },
}))

/**
 * 
 * @param {{player: Account}}} props
 */
const PlayerAccountWidget = ({ player, }) => {
  const classes = useStyles()
  const refreshView = useRefresh()
  const showNotification = useNotify()
  const currentGame = useCurrentGame()
  const [nameDialogOpen, setNameDialogOpen] = useState(false)
  const [banDialogOpen, setBanDialogOpen] = useState(false)
  const [recoveryDialogOpen, setRecoveryDialogOpen] = useState(false)

  const handleBanButtonClick = () => setBanDialogOpen(true)
  const handleBanDialogClose = () => setBanDialogOpen(false)
  const handleRecoveryDialogClose = () => setRecoveryDialogOpen(false)
  const handleRecoveryButtonClick = () => setRecoveryDialogOpen(true)

  const handleBanPlayer = ({ type, duration, reason }) => {
    let now = Date.now()
    let end = typeof duration === 'number' ?
      new Date(now + duration * 3600 * 1000).toISOString() :
      null

    const ban = {
      start: new Date(now).toISOString(),
      end: end,
      type: type,
      userReason: reason,
      internalComment: null,
    }

    return fetchJsonPost(`api/accounts/${player.id}/bans`, ban, { gameSpecific: true })
      .then(resp => {
        const banId = resp.body
        player.activeBans[banId] = ban
        refreshView()
        setBanDialogOpen(false)
      })
      .catch(error => {
        showNotification(error.message || 'Failed to ban player', 'warning')
      })
  }

  const handlePlayerUnban = (player, banId) => {
    return fetchJsonDelete(`api/accounts/${player.id}/bans/${banId}`, { gameSpecific: true })
      .then(() => {
        delete player.activeBans[banId]
        refreshView()
      })
      .catch(error => {
        showNotification(error.message || 'Failed to unban player', 'warning')
      })
  }

  const handleEditButtonClick = () => setNameDialogOpen(true)

  const handleNameDialogClose = () => setNameDialogOpen(false)

  const handleNameChange = ({ name, reason }) => {
    if (name === player.publicName) {
      handleNameDialogClose()
      return
    }

    const request = {
      oldName: player.publicName,
      newName: name,
      reason,
    }
    return fetchJsonPost(`api/accounts/${player.id}/setname`, request, { gameSpecific: true })
      .then(() => {
        player.publicName = name
        refreshView()
        handleNameDialogClose()
      })
      .catch(error => {
        showNotification(error.message || 'Failed to change player name', 'warning')
      })
  }

  function configForAccountId(id, config) {
    if (config && config.accountIdTypes) {
      for (const c of config.accountIdTypes) {
        if (c.type === id.type) {
          return c
        }
      }
    }
    return { type: id.type, label: id.type }
  }

  function configForDetail(detail, config) {
    if (config && config.accountDetailsFields) {
      for (const c of config.accountDetailsFields) {
        if (c.type === detail.type) {
          return c
        }
      }
    }
    return { type: detail.type, label: detail.type }
  }

  function configForBan(config) {
    if (config && config.banConfiguration) {
      return config.banConfiguration.types.map(type => ({ value: type.toLowerCase(), label: type }))
    }
    return []
  }

  const details = player.accountDetailFields || player.details
  return (
    <div>
      <Card>
        <CardContent>
          <Grid
            container
            spacing={ 4 }
          >
            <Grid
              container
              item
              xs={ 4 }
              className={ classes.container }
            >
              <ShowItem label='Player ID'>
                { player.id }
              </ShowItem>

              {player.accountIds && (
                <Query type="GET_ONE" resource="config" payload={{ id: 'accounts' }}>
                  {({ data, loading, error }) => {
                    if (error) console.log(error)
                    return player.accountIds.map(id => {
                      const cfg = configForAccountId(id, (!loading && !error) ? data : [])
                      const key = `accountid.${id.type}`
                      const item = <ShowItem label={cfg.label} key={key}>{id.value}</ShowItem>
                      if (cfg.description) {
                        return <Tooltip title={cfg.description} placement='bottom-start' key={key}>
                          {item}
                        </Tooltip>
                      }
                      return item
                    })
                  }}
                </Query>
              )}

              <ShowItem label='Player name'>
                { player.publicName }
                <WithPermission permissions='EditPlayerName'>
                  <Button color='primary' onClick={ handleEditButtonClick } >
                    Edit
                  </Button>
                </WithPermission>
              </ShowItem>

              { player.guildId && (
                <ShowItem label='Guild'>
                  <GuildLink id={player.guildId} />
                </ShowItem>
              )}

              { player.mapId && (
                <ShowItem label='Map'>
                  <MapLink id={player.mapId} />
                </ShowItem>
              )}

              {details && (
                <Query type="GET_ONE" resource="config" payload={{ id: 'accounts' }}>
                  {({ data, loading, error }) => {
                    if (error) console.log(error)
                    return details.map(detail => {
                      const cfg = configForDetail(detail, (!loading && !error) ? data : [])
                      if (detail.link) {
                        return <ShowItem key={detail.type} label={cfg.label}>
                          <ExternalLink href={detail.link}>{detail.value}</ExternalLink>
                        </ShowItem>
                      }
                      return <ShowItem key={detail.type} label={cfg.label}>{detail.value}</ShowItem>
                    })
                  }}
                </Query>
              )}
            </Grid>

            <Grid
              container
              item
              xs={4}
              className={classes.container}
            >
              {player.lastLogin && (
                <Fragment>
                  <ShowItem label='Country'>
                    {player.lastLogin.country || '-'}
                  </ShowItem>

                  <ShowItem label='Language'>
                    {typeof player.lastLogin.device === 'object' ? player.lastLogin.device.locale : '-'}
                  </ShowItem>

                  <ShowItem label='Account created'>
                    <TimestampText time={player.createdAt} />
                  </ShowItem>

                  <ShowItem label='Last login'>
                    <TimestampText time={player.lastLogin.timestamp} />
                  </ShowItem>

                  <ShowItem label='Last session length'>
                    {typeof player.lastSessionLength === 'number' ? `${player.lastSessionLength} m` : '-'}
                  </ShowItem>

                  {player.lastLogin.device && (
                    <ShowItem label='Last known OS'>
                      {player.lastLogin.device.os || '-'}
                      {player.lastLogin.device.osVersion ? ' (' + player.lastLogin.device.osVersion + ')' : ''}
                    </ShowItem>
                  )}

                  <ShowItem label='Last IP address'>
                    {player.lastLogin.ipAddress || '-'}
                  </ShowItem>

                  <ShowItem label='Last client version'>
                    {player.lastLogin.clientVersion || '-'}
                  </ShowItem>

                  <ShowItem label='Total logins (all devices)'>
                    {player.totalLogins || '-'}
                  </ShowItem>
                </Fragment>
              )}
            </Grid>

            <Grid
              container
              item
              xs={ 4 }
              className={ classes.container }
            >
              {Array.from(player.boundLoginIds).sort((a, b) => {
                if (a.provider < b.provider) return -1
                if (a.provider > b.provider) return 1
                return 0
              }).map(li => (
                <ShowItem label={li.provider} key={li.id}>
                  {li.id}
                </ShowItem>
              ))}

              { player.beaconPlayerId && (
                <ShowItem label='Beacon player ID'>
                  <ExternalLink href={ getBeaconProfileUrl(currentGame, player.beaconPlayerId) }>
                    { player.beaconPlayerId }
                  </ExternalLink>
                </ShowItem>
              )}

              { player.activeBans && Object.keys(player.activeBans).length > 0 && (
                <ShowItem label='Active bans'>
                  { Object.entries(player.activeBans).map(kv => (
                    <div key={ kv[0] }>
                      <Typography style={ { display: 'inline' } }>
                        { formatBan(kv[1]) }
                      </Typography>
                      <PlayerUnbanButton
                        player={ player }
                        banId={ kv[0] }
                        onConfirm={ handlePlayerUnban }
                      />
                    </div>
                  ))}
                </ShowItem>
              )}
            </Grid>
          </Grid>
        </CardContent>

        <CardActions>
          <WithPermission permissions='RecoverPlayerAccount'>
            <Button
              color='primary'
              onClick={ handleRecoveryButtonClick }
            >
              Recover account
            </Button>
          </WithPermission>

          <WithPermission permissions='BanPlayer'>
            <Button
              color='primary'
              onClick={ handleBanButtonClick }
            >
              Ban/Suspend
            </Button>
          </WithPermission>

          {plugin.invoke('accounts.show.info.actions', { player })}

          <Button
            color='primary'
            disabled
          >
            Get data summary
          </Button>
        </CardActions>
      </Card>

      { nameDialogOpen && (
        <NameEditDialog
          record={ { name: player.publicName } }
          title='Edit player name'
          onClose={ handleNameDialogClose }
          onSave={ handleNameChange }
        />
      )}

      { banDialogOpen && (
        <Query type="GET_ONE" resource="config" payload={{ id: 'accounts' }}>
          {({ data, loading, error }) => {
            if (error) console.log(error)
            const banTypes = configForBan((!loading && !error) ? data : [])
            return <PlayerBanDialog
              onClose={ handleBanDialogClose }
              onSubmit={ handleBanPlayer }
              banTypes={ banTypes }
            />
          }}
        </Query>
      )}

      { recoveryDialogOpen && (
        <PlayerAccountRecoveryDialog
          onClose={ handleRecoveryDialogClose }
          player={ player }
        />
      )}
    </div>
  )
}

PlayerAccountWidget.propTypes = {
  player: PropTypes.object.isRequired,
}

export default PlayerAccountWidget
