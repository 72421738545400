import React, { useState } from 'react'
import { useDropzone } from 'react-dropzone'
import PropTypes from 'prop-types'
import { withStyles } from '@material-ui/core/styles'
import UploadIcon from '@material-ui/icons/CloudUpload'
import Typography from '@material-ui/core/Typography'

const FileInfo = ({ file }) => (
  <Typography
    variant='body2'
    style={{
      wordBreak: 'break-all'
    }}
  >
    { file.name }
  </Typography>
)

const FileUpload = ({
  classes,
  onFileDrop,
  text,
  maxFileSize,
  acceptedExt,
}) => {
  const [files, setFiles] = useState([])
  const extraTexts = []
  const { getRootProps, getInputProps } = useDropzone(
    { onDrop: handleFileDrop, maxSize: maxFileSize, multiple: false, accept: acceptedExt })

  function handleFileDrop(acceptedFiles) {
    setFiles(acceptedFiles)
    if (onFileDrop) {
      onFileDrop(acceptedFiles)
    }
  }

  if (maxFileSize > 0) {
    extraTexts.push(`max file size: ${maxFileSize/1e+6} MB`)
  }
  const desc = text || 'Drop files here or click to select files'

  return (
    <div className={classes.dropZone} {...getRootProps()} >
      <input {...getInputProps()} />
      <Typography
        variant='body1'
      >
        {desc}
        <br />
        {
          extraTexts.length > 0 && `(${extraTexts.join(', ')})`
        }
      </Typography>
      <UploadIcon />
      {files.map((file, index) => (
        <FileInfo
          key={index}
          file={file}
        />
      ))}
    </div>
  )
}

FileUpload.displayName = 'FileUpload'

FileUpload.propTypes = {
  onFileDrop: PropTypes.func,
  text: PropTypes.string,
  maxFileSize: PropTypes.number,
  acceptedExt: PropTypes.string.isRequired,
}

FileUpload.defaultProps = {
  maxFileSize: 5e+6, // 5MB
  acceptedExt: '.txt',
}

const styles = _theme => ({
  dropZone: {
    width: '100%',
    minHeight: 150,
    borderWidth: 2,
    borderColor: '#666',
    borderStyle: 'dashed',
    borderRadius: 0,
    display: 'inline-flex',
    alignItems: 'center',
    textAlign: 'center',
    justifyContent: 'center',
  },
  dropZoneActive: {
    borderStyle: 'solid',
    borderColor: '#6c6',
    backgroundColor: '#eee'
  },
  dropZoneReject: {
    borderStyle: 'solid',
    borderColor: '#c66',
    backgroundColor: '#eee'
  },
})

export default withStyles(styles)(FileUpload)
