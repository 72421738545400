import URI from 'urijs'
import { useSelector } from 'react-redux'
import { call, put } from 'redux-saga/effects'

import {
  localStorageGet,
  localStorageSet,
} from './localStorage'
import { updateGame } from '../redux/actions'
import { fetchJson } from './fetch'

let currentGameId = ''
let currentGameEnv = ''
let currentAppId = ''
let cachedCurrent = null
let resolveGame = {
  task: null,
  resolve: null,
  lockCount: 0,
}

/**
 * @returns {{
 *   id: string,
 *   env: string,
 *   beaconApp: string,
 *   beaconEnv: string,
 *   config: {
 *     features: Object<string, boolean>
 *   }
 * }}
 */
export const useCurrentGame = () => {
  return useSelector(state => state.games.currentGame)
}

/**
 * @returns {Object<string, boolean>}
 */
export const useGameFeatures = () => {
  const game = useSelector(state => state.games.currentGame)
  if (game && game.config && game.config.features) return game.config.features
  return {}
}

/**
 * @returns {{
 *   id: string,
 *   env: string,
 *   beaconApp: string,
 *   beaconEnv: string,
 *   config: {
 *     features: Object<string, boolean>
 *   }
 * }}
 */
const gameByIdAndEnv = (gameId, env) => {
  try {
    const games = JSON.parse(localStorageGet('games'))
    const cachedGame = games.find(g => g.id === gameId && g.environments.some(e => e.name === env))
    if (cachedGame) {
      const cachedEnv = cachedGame.environments.find(e => e.name === env)
      return {
        id: gameId,
        env: env,
        config: JSON.parse(cachedGame.config),
        beaconApp: cachedEnv.beaconApp,
        beaconEnv: cachedEnv.beaconEnv,
      }
    }
  } catch {
    // Fall through
  }
  return null
}

const gameByBeaconAppId = (appId) => {
  try {
    const games = JSON.parse(localStorageGet('games'))
    const cachedGame = games.find(g => g.environments.some(e => e.beaconApp === appId))
    if (cachedGame) {
      const cachedEnv = cachedGame.environments.find(e => e.beaconApp === appId)
      return {
        id: cachedGame.id,
        env: cachedEnv.name,
        config: JSON.parse(cachedGame.config),
        beaconApp: cachedEnv.beaconApp,
        beaconEnv: cachedEnv.beaconEnv,
      }
    }
  } catch {
    // Fall through
  }
  return null
}

/** @deprecated Use useCurrentGame() hook instead whenever possible */
export const getCurrentGame = () => {
  if (cachedCurrent && cachedCurrent.id === currentGameId && cachedCurrent.env === currentGameEnv) {
    return cachedCurrent
  }
  let found = gameByIdAndEnv(currentGameId, currentGameEnv)
  cachedCurrent = found
  return found
}

export const getCurrentGameAsync = async () => {
  // If games list is not yet available, wait until it is
  await resolveGame.task

  if (cachedCurrent && cachedCurrent.id === currentGameId && cachedCurrent.env === currentGameEnv) {
    return cachedCurrent
  }
  let found = gameByIdAndEnv(currentGameId, currentGameEnv)
  cachedCurrent = found
  return found
}

export const getInitialGame = () => {
  var game = null
  var mayUseFakeGame = false
  // If the query string specifies a game, let's use that
  const params = URI.parseQuery(window.location.href.split('?')[1])
  if (params && params.game && params.env) {
    mayUseFakeGame = true
    game = gameByIdAndEnv(params.game, params.env)
    currentGameId = params.game
    currentGameEnv = params.env
  } else if (params && params.appId) {
    mayUseFakeGame = true
    game = gameByBeaconAppId(params.appId)
    currentAppId = params.appId;
  }

  if (game) {
    currentGameId = game.id
    currentGameEnv = game.env
    currentAppId = game.beaconApp
    localStorageSet('game', JSON.stringify(game))
    return game
  } else if (mayUseFakeGame) {
    // Games list may not have been loaded yet. Store whatever we have now, in hopes that
    // this data gets filled in after user login is completed.
    const fakeGame = {
      id: params.game,
      env: params.env,
      config: { features: { players: true, }, },
      beaconApp: '',
      beaconEnv: '',
    }
    cachedCurrent = fakeGame
    localStorageSet('game', JSON.stringify(fakeGame))
    return fakeGame
  }

  // Check if there's a selected game in the local storage
  const gameJson = localStorageGet('game')
  if (gameJson) {
    try {
      const game = JSON.parse(gameJson)
      currentGameId = game.id
      currentGameEnv = game.env
      currentAppId = game.beaconApp
      return game
    } catch (error) {
      console.log('Failed to parse game from local storage', gameJson, error)
    }
  }

  // Failed to select an initial game.
  return null
}

// This should only ever be called from sagas/switchGame.js or from the games reducer. Otherwise
// the state defined here and the one in redux store may diverge.
export const setCurrentGame = game => {
  currentGameId = game.id
  currentGameEnv = game.env
  currentAppId = game.beaconApp
  localStorageSet('game', JSON.stringify(game))
}


/**
 * Updates stored games data and returns the updated version of current game.
 * 
 * @returns {Object} Updated version of the current game.
 */
export const updateStoredGames = (games) => {
  localStorageSet('games', JSON.stringify(games))
  if (currentGameId && currentGameEnv) {
    const updated = gameByIdAndEnv(currentGameId, currentGameEnv)
    if (!updated) {
      throw new Error(`The game ${currentGameId} or environment ${currentGameEnv} were not found in updated game config`)
    }
    currentAppId = updated.beaconApp
    return updated
  } else if (currentAppId) {
    const updated = gameByBeaconAppId(currentAppId)
    if (!updated) {
      throw new Error(`The game with Beacon appId ${currentAppId} were not found in updated game config`)
    }
    currentGameId = updated.id
    currentGameEnv = updated.env
    return updated
  }
  return null
}

/**
 * Fetches latest version of games data and returns the updated version of current game.
 * 
 * @returns {Promise} Promise representing an updated version of the current game.
 */
const fetchAndUpdateStoredGames = () => {
  return fetchJson('/api/games').then(
    result => {
      try {
        return updateStoredGames(result.json)
      } catch (error) {
        console.warn('Error while processing fetched games', error)
      }
    },
    error => {
      console.error(error)
    }
  )
}

/**
 * Saga helper: updates games list and current game info from server.
 */
export function* updateGamesFromServer() {
  blockGameDependentFetches()
  try {
    const updated = yield call(fetchAndUpdateStoredGames)
    if (updated) {
      localStorageSet('game', JSON.stringify(updated))
      cachedCurrent = updated
      yield put(updateGame(updated))
    }
  } finally {
    unblockGameDependentFetches()
  }
}

export const blockGameDependentFetches = () => {
  if (resolveGame.lockCount === 0) {
    resolveGame.task = new Promise((resolve, _reject) => {
      resolveGame.resolve = resolve
    })
  }
  resolveGame.lockCount++
}

export const unblockGameDependentFetches = () => {
  resolveGame.lockCount--
  if (resolveGame.lockCount === 0) {
    resolveGame.resolve()
    resolveGame.resolve = null
  }
}
