import React from 'react'
import PropTypes from 'prop-types'
import Button from '@material-ui/core/Button'
import AlertError from '@material-ui/icons/ErrorOutline'

const CancelButton = ({ text, ...rest }) => (
  <Button
    { ...rest }
  >
    <AlertError
      style={ { paddingRight: '0.5rem' } }
    />
    { text }
  </Button>
)

CancelButton.propTypes = {
  text: PropTypes.string,
}

CancelButton.defaultProps = {
  text: 'Cancel',
}

CancelButton.displayName = 'CancelButton'

export default CancelButton
