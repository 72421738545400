import React, { useState } from 'react'
import { Button, Card, CardContent, CardHeader, Collapse } from '@material-ui/core'
import { makeStyles } from '@material-ui/styles'
import { linkToRecord, RecordContextProvider, useListContext } from 'react-admin'
import lodash from 'lodash'
import { Link } from 'react-router-dom'
import RemoveRedEye from '@material-ui/icons/RemoveRedEye'
import ExpandMoreIcon from '@material-ui/icons/ExpandMore'
import ExpandLessIcon from '@material-ui/icons/ExpandLess'

const ActionButton = ({ record = {}, basePath, icon = <RemoveRedEye />, label = 'Show' }) => {
  return <Button
    startIcon={icon}
    component={Link}
    to={linkToRecord(basePath, record.id, 'show')}
    color='primary'
    variant='contained'
    size='large'
  >
    {label}
  </Button>
}

const useCardLineStyles = makeStyles(theme => ({
  label: {
    color: theme.palette.text.secondary,
  }
}))

const CardLine = ({ record = {}, field, basePath, resource }) => {
  const classes = useCardLineStyles()
  const label = field.props.label || field.props.source

  return <div>
    <span className={classes.label}>{label}</span>: { }
    <span>
      {React.cloneElement(field, { record, basePath, resource })}
    </span>
  </div>
}

const useCardStyles = makeStyles(theme => ({
  cardRoot: {
    margin: theme.spacing(1),
    flex: '1 1 auto',
  },
  headerRoot: {
    padding: theme.spacing(1),
  },
  contentRoot: {
    padding: theme.spacing(0, 1),
    '&:last-child': {
      paddingBottom: theme.spacing(1),
    }
  },
  headerAction: {
    margin: 0,
  },
  expandButton: {
    float: 'right',
  },
}))

const DataCard = ({
  id, record, basePath, resource,
  sourcePrimary, titlePrimary,
  sourceSecondary, titleSecondary,
  actionField,
  expand,
  children,
}) => {
  const [recordExpanded, setRecordExpanded] = useState(false)
  const classes = useCardStyles()
  const primaryValue = lodash.get(record, sourcePrimary)
  const secondaryValue = lodash.get(record, sourceSecondary)

  return <Card key={id} classes={{ root: classes.cardRoot }}>
    <CardHeader
      classes={{ root: classes.headerRoot, action: classes.headerAction }}
      title={titlePrimary ? `${titlePrimary}: ${primaryValue}` : primaryValue}
      subheader={titleSecondary ? `${titleSecondary}: ${secondaryValue}` : secondaryValue}
      action={actionField ?
        <ActionButton
          {...actionField.props}
          record={record}
          basePath={basePath}
          resource={resource}
        />
        : null}
    />
    <CardContent classes={{ root: classes.contentRoot }}>
      {React.Children.map(children, (field, i) => {
        // Primary & secondary are shown in header
        if (field.props.source === sourcePrimary || field.props.source === sourceSecondary) {
          return null
        }
        // Don't show fields without value or non-data fields
        if (!field.props.source || !lodash.get(record, field.props.source)) return null
        if (React.isValidElement(field)) {
          return (
            <CardLine
              key={`line-${field.key || field.props.source || i}`}
              record={record}
              field={field}
              basePath={basePath}
              resource={resource}
            />
          )
        }
        return null
      })}
      {!!expand &&
        <Button
          className={classes.expandButton}
          endIcon={recordExpanded ? <ExpandLessIcon /> : <ExpandMoreIcon />}
          onClick={() => setRecordExpanded(expanded => !expanded)}
        >
          More details
        </Button>
      }
    </CardContent>
    {!!expand &&
      <Collapse in={recordExpanded} timeout='auto' unmountOnExit>
        <CardContent>
          {React.cloneElement(expand, { id, record, basePath, resource })}
        </CardContent>
      </Collapse>
    }
  </Card>
}

const DataCardList = ({
  sourcePrimary, titlePrimary,
  sourceSecondary, titleSecondary,
  children,
  rowClick,
  expand,
}) => {
  const { ids, data, basePath, resource } = useListContext()
  let actionField = null

  React.Children.forEach(children, field => {
    if (!sourcePrimary && field.props.source && field.props.source !== sourceSecondary) {
      sourcePrimary = field.props.source
    }
    if (!sourceSecondary && field.props.source && field.props.source !== sourcePrimary) {
      sourceSecondary = field.props.source
    }
    if (titleSecondary === undefined && field.props.source === sourceSecondary) {
      titleSecondary = field.props.label || field.props.source
    }
    if (!field.props.source) {
      if (actionField) {
        console.warn('DataCardList: only one field without source attribute supported')
      }
      actionField = field
    }
  })

  if (rowClick) {
    switch (rowClick) {
      case 'show':
        if (!actionField) actionField = { props: { icon: <RemoveRedEye />, label: 'Show' } }
        break
      case 'expand':
        if (!expand) console.warn('rowClick=expand, but no expand component provided')
        break
      default:
        console.warn('Unsupported rowClick provided to DataCardList', rowClick)
    }
  }

  return <div>
    {Array.isArray(ids) && ids.map(id =>
      <RecordContextProvider value={data[id]}>
        <DataCard key={id}
          id={id} record={data[id]} basePath={basePath} resource={resource}
          sourcePrimary={sourcePrimary} titlePrimary={titlePrimary}
          sourceSecondary={sourceSecondary} titleSecondary={titleSecondary}
          actionField={actionField}
          expand={expand}
        >
          {children}
        </DataCard>
      </RecordContextProvider>
    )}
  </div>
}

export default DataCardList
