import React from 'react'
import { Datagrid, Pagination, ReferenceField, ReferenceManyField, TextField } from 'react-admin'

const MapPlayersWidget = ({ map, ...rest }) => (
  <ReferenceManyField reference='accounts' target='mapId' pagination={<Pagination />} record={map} {...rest}>
    <Datagrid rowClick='show'>
      <TextField source='id' label='ID' />
      <TextField source='publicName' label='Name' />
      <TextField source='nameTag' label='Name tag' />
      <TextField source='level' label='Level' />
      <ReferenceField label='Guild' source='guildId' reference='guilds' link='show' allowEmpty>
        <TextField source='name' />
      </ReferenceField>
    </Datagrid>
  </ReferenceManyField>
)

export default MapPlayersWidget
