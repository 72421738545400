import { put, all, takeLatest, call, takeLeading } from 'redux-saga/effects'
import { push } from 'connected-react-router'

import { REFRESH_GAMES, SWITCH_GAME } from '../redux/actions'
import { setCurrentGame, updateGamesFromServer } from '../utils/games'

import plugin from 'js-plugin'
import hydra from '../hydra'

function* switchGameHandler({ payload: { game } }) {
  // TODO: don't depend on exact game ID, and allow plugins for other games
  // See also: redux/reducers/games.js
  if (game && game.id === 'hydra') {
    if (!plugin.getPlugin(hydra.name)) plugin.register(hydra)
  } else {
    if (plugin.getPlugin(hydra.name)) plugin.unregister(hydra.name)
  }

  setCurrentGame(game)
  yield put(push('/'))
}

function* refreshGamesHandler() {
  yield call(updateGamesFromServer)
}

export default function* switchGameSaga() {
  // Watch for all dispatched SWITCH_GAME actions.
  yield all([
    takeLatest(SWITCH_GAME, switchGameHandler),
    takeLeading(REFRESH_GAMES, refreshGamesHandler),
  ])
}
