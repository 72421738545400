import lodash from 'lodash'

/** @typedef {{
 *    data: import('react-admin').RecordMap,
 *    ids: Array<import('react-admin').Identifier>,
 *    total: number,
 *    error: any,
 *    loading: boolean,
 *    loaded: boolean
 * }} FetchManyResult
 */

/** @typedef {{
 *    data: {id: string},
 *    error: any,
 *    loading: boolean,
 *    loaded: boolean
 * }} FetchResult
 */

/**
 * Extract an array field from fetched data, and treat it as a fetch result.
 * @param {FetchResult} data 
 * @param {string} field 
 * @param {string} idField 
 * @returns {FetchManyResult}
 */
export const fieldAsListData = (data, field, idField='id') => {
  const extracted = lodash.get(data.data, field, [])
  const ids = extracted.map(row => lodash.get(row, idField))
  const newData = extracted.reduce((acc, row) => {
    acc[lodash.get(row, idField)] = row
    return acc
  }, {})
  return {
    ids,
    data: newData,
    total: ids.length,
    error: data.error,
    loading: data.loading,
    loaded: data.loaded,
  }
}

/**
 * Extract an array field from fetched data, and treat it as a fetch result.
 * @param {FetchResult} originalFetch
 * @param {Array<{id: string}>} processed
 * @param {string} idField 
 * @returns {FetchManyResult}
 */
export const repackProcessedData = (originalFetch, processed, idField='id') => {
  if(!Array.isArray(processed)) processed = []
  const ids = processed.map(row => lodash.get(row, idField))
  const newData = processed.reduce((acc, row) => {
    acc[lodash.get(row, idField)] = row
    return acc
  }, {})
  return {
    ids,
    data: newData,
    total: ids.length,
    error: originalFetch.error,
    loading: originalFetch.loading,
    loaded: originalFetch.loaded,
  }
}

/**
 * Divide the data into multiple groups, each having a common value in the specified field.
 * @param {FetchManyResult} data 
 * @param {string} field 
 * @returns {Object<import('react-admin').Identifier, FetchManyResult>}
 */
export const groupBy = (data, field) => {
  /** @type {Object<import('react-admin').Identifier, FetchManyResult>} */
  const ret = {}
  const keys = data?.ids || []
  const entries = data?.data || {}
  keys.forEach(key => {
    const value = entries[key]
    const groupKey = lodash.get(value, field, '')
    if(!ret[groupKey]) ret[groupKey] = {
      ids: [],
      data: {},
      total: 0,
      error: data.error,
      loading: data.loading,
      loaded: data.loaded,
    }
    ret[groupKey].data[key] = value
    ret[groupKey].ids.push(key)
    ret[groupKey].total++
  })
  return ret
}

/**
 * 
 * @param {FetchManyResult} data 
 * @param {import('react-admin').FilterPayload} filters 
 * @param {Object<string, function(Object, any): boolean>} matchers 
 * @returns {FetchManyResult}
 */
export const filterDataBy = (data, filters, matchers) => {
  let currentData = data
  if(!data || !data.ids || !filters) return data
  Object.entries(filters).forEach(([key, value]) => {
    const matcher = matchers[key]
    if(!matcher) return
    /** @type {FetchManyResult} */
    const newData = {
      ids: [],
      data: {},
      total: 0,
      error: currentData.error,
      loading: currentData.loading,
      loaded: currentData.loaded,
    }
    currentData.ids.forEach(id => {
      const item = currentData.data[id]
      if(matcher(item, value)) {
        newData.ids.push(id)
        newData.data[id] = item
        newData.total++
      }
    })
    currentData = newData
  })
  return currentData
}

/**
 * Matches any part of a record against string query.
 * @returns {function(Object, string): boolean}
 */
export const filterMatchAny = () => {
  const match = (record, needle) => {
    if(!needle) return true
    if(!record) return false
    const needleLower = needle.toLowerCase()
    return Object.values(record).some(value => {
      if(Array.isArray(value)) {
        if(value.some(subVal => match(subVal, needleLower))) return true
      }
      if(typeof(value) === 'object' && match(value, needleLower)) return true
      if(String(value).toLowerCase().includes(needleLower)) return true
      return false
    })
  }
  return match
}

/**
 * Checks if the value in the specified field is one of the items in query array.
 * @param {string} field 
 * @returns {function(Object, Array<string>): boolean}
 */
export const filterFieldIsOneOf = (field) => (record, values) => {
  if(!Array.isArray(values) || values.length === 0) return true
  const fieldValue = lodash.get(record, field)
  return values.some(value => fieldValue === value)
}

/**
 * Matches when the given field is exactly the searched value. In case of array fields, matches
 * if any of the elements is the searched value.
 * @param {string} field Name of a field in record
 * @returns {function(Object, string): boolean} Matcher function compatible with filterDataBy
 */
 export const filterMatchFieldExact = (field) => (record, needle) => {
  if(!needle) return true
  if(!record) return false
  const fieldValue = lodash.get(record, field)
  if(Array.isArray(fieldValue)) return fieldValue.some(v => String(v) === needle)
  return String(fieldValue) === needle
}

/**
 * Matches the contents of multiple fields against a string query.
 * @param {Array<string>} fields Names of the relevant fields in record
 * @returns {function(Object, string): boolean} Matcher function compatible with filterDataBy
 */
export const filterMatchAnyOfFields = (fields) => (record, needle) => {
  if(!needle) return true
  if(!record) return false
  const needleLower = needle.toLowerCase()
  return fields.some(f => {
    const fieldValue = lodash.get(record, f)
    return String(fieldValue).toLowerCase().includes(needleLower)
  })
}

/**
 * Sort the data by the contents of the specified field.
 * @param {FetchManyResult} data 
 * @param {{field: string, order: string}} sort 
 * @returns {FetchManyResult}
 */
export const sortDataBy = (data, sort) => {
  if(!data) return data
  if(!sort || !sort.field || !sort.order) return data
  const ordering = (a, b) => {
    const aval = lodash.get(data.data[a], sort.field)
    const bval = lodash.get(data.data[b], sort.field)
    if(aval < bval) return -1
    if(aval > bval) return 1
    return 0
  }
  return {
    ...data,
    ids: [...data.ids].sort((a, b) => sort.order === 'ASC' ? ordering(a, b) : ordering(b, a)),
  }
}

/**
 * Get a single page of the data.
 * @param {FetchManyResult} data 
 * @param {{page: number, perPage: number}} pagination 
 */
export const paginateDataBy = (data, pagination) => {
  if(!data) return data
  if(!pagination || !pagination.page || !pagination.perPage) return data
  const start = (pagination.page - 1) * pagination.perPage
  if(start < 0 || start >= data.ids.length) {
    return {
      ...data,
      ids: [],
    }
  }
  return {
    ...data,
    ids: data.ids.slice(start, start + pagination.perPage),
  }
}
