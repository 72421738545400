import React, { useState } from 'react'
import { Datagrid, Pagination, TextField, useGetList } from 'react-admin'
import { useSort } from '../utils/listings'
import LeaderboardContents from './LeaderboardContents'

const ScoreKindField = ({ record, source }) => {
  var text = ''
  if (Array.isArray(record[source])) text = record[source].join(',')
  return <span>{text}</span>
}

const Expansion = ({ record }) => {
  return <LeaderboardContents leaderboard={record} />
}

const LeaderboardDatagrid = ({ filter }) => {
  const [page, setPage] = useState(1)
  const [perPage, setPerPage] = useState(25)
  const [sort, setSort] = useSort('name')
  const leaderboards = useGetList('leaderboard/board', { page, perPage }, sort, filter)

  return <>
    <Datagrid
      data={leaderboards.data}
      ids={Object.keys(leaderboards.data)}
      currentSort={sort}
      setSort={setSort}
      resource='leaderboard/board'
      basePath='/leaderboard/board'
      expand={<Expansion />}
      rowClick='expand'
      selectedIds={[]}
    >
      <TextField source='kind.name' label='Name' sortBy='name' />
      <TextField source='kind.type' label='Event type' sortable={false} />
      <TextField source='kind.scope' label='Scope' sortBy='scope' />
      <TextField source='groupId' label='Group ID' sortable={false} />
      <ScoreKindField source='scoreKind' sortBy='score' />
    </Datagrid>
    <Pagination
      page={page}
      perPage={perPage}
      setPage={setPage}
      setPerPage={setPerPage}
      total={leaderboards.total || 0}
    />

  </>
}

export default LeaderboardDatagrid
