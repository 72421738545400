import React from 'react'
import ContentContainer from '../components/ContentContainer'
import TabsView from '../components/TabsView'
import ChatMonitorSearch from './ChatMonitorSearch'
import ReportsList from './ReportsList'

const ChatMonitor = () => {
  return <ContentContainer title='Chat Monitor'>
    <TabsView tabs={[
      {
        label: 'Search',
        content: <ChatMonitorSearch />,
      },
      {
        label: 'Reports',
        content: <ReportsList />,
      },
    ]} />
  </ContentContainer>
}

export default ChatMonitor
