import React, { useRef, useEffect } from 'react'
import { useField, useForm } from 'react-final-form'
import IconButton from '@material-ui/core/IconButton'
import InputAdornment from '@material-ui/core/InputAdornment'
import TextField from '@material-ui/core/TextField'
import ClearIcon from '@material-ui/icons/Clear'
import { makeStyles } from '@material-ui/core/styles'
import moment from 'moment'
import daterangepicker from 'daterangepicker'
import lodash from 'lodash'
import 'daterangepicker/daterangepicker.css'

// https://momentjs.com/docs/#/displaying/format/
const dateTimeFormat = 'D.M.YYYY H:mm'

// ISO-8601, Europe -- https://momentjs.com/docs/#/customization/dow-doy/
moment.updateLocale("en", { week: {
  dow: 1, // First day of week is Monday
  doy: 4  // First week of year must contain 4 January (7 + 1 - 4)
}});

const restrictedEndTime = (start, end, minRange, maxRange) => {
  let ret = end
  if (minRange && end.isBefore(moment(start).add(minRange))) ret = moment(start).add(minRange)
  if (maxRange && end.isAfter(moment(start).add(maxRange))) ret = moment(start).add(maxRange)
  return ret
}

const InputComponent = ({
  sourceFrom, sourceTo, inputRef, past, future, minRange, maxRange, ...rest
}) => {
  const containingForm = useForm()
  const from = useField(sourceFrom)
  const to = useField(sourceTo)
  const fieldRef = useRef(null)
  inputRef(fieldRef.current)

  useEffect(() => {
    const pickStartOnly = !!minRange && !!maxRange && lodash.isEqual(minRange, maxRange)

    if (!fieldRef.current) return
    if (fieldRef?.current?.daterangepicker) {
      fieldRef.current.minRange = minRange
      fieldRef.current.maxRange = maxRange

      if (fieldRef.current.daterangepicker.singleDatePicker === pickStartOnly) {
        return
      }
      // If pickStartOnly changed, fall through and re-create the daterangepicker
      fieldRef.current.daterangepicker.remove()
    }

    const defaultStart = past ? moment().subtract(9, 'days').startOf('day') : moment().startOf('day')
    const defaultEnd = moment().endOf('day')
    const pastRanges = {
      'Yesterday': [moment().subtract(1, 'days').startOf('day'), moment().subtract(1, 'days').endOf('day')],
      'Last 7 Days': [moment().subtract(6, 'days').startOf('day'), moment().endOf('day')],
      'Last 30 Days': [moment().subtract(29, 'days').startOf('day'), moment().endOf('day')],
      'This Month': [moment().startOf('month'), future ? moment().endOf('month') : moment().endOf('day')],
      'Last Month': [moment().subtract(1, 'month').startOf('month'), moment().subtract(1, 'month').endOf('month')],
    }
    const futureRanges = {
      'Tomorrow': [moment().add(1, 'days').startOf('day'), moment().add(1, 'days').endOf('day')],
      'This week': [past ? moment().startOf('week') : moment().startOf('day'), moment().endOf('week')],
      'Next week': [moment().add(1, 'week').startOf('week'), moment().add(1, 'week').endOf('week')],
    }
    fieldRef.current.daterangepicker = new daterangepicker(
      fieldRef.current,
      {
        timePicker: true,
        timePicker24Hour: true,
        autoUpdateInput: false,
        alwaysShowCalendars: true,
        startDate: from.input.value ? moment(from.input.value) : defaultStart,
        endDate: to.input.value ? moment(to.input.value) : defaultEnd,
        minDate: past ? moment('2019-01-01T00:00:00.000Z') : moment().startOf('day'),
        maxDate: future ? undefined : moment().endOf('day'),
        // component doesn't handle maxSpan well -- e.g. when maxSpan is two hours, the start time
        // can only be moved in two-hour increments
        //maxSpan: maxRange,
        ranges: pickStartOnly ? null : {
          ...(past ? pastRanges : {}),
          'Today': [moment().startOf('day'), moment().endOf('day')],
          ...(future ? futureRanges : {}),
        },
        locale: {
          format: dateTimeFormat,
        },
        singleDatePicker: pickStartOnly,
      },
      (_start, _end, _label) => {
        // Do nothing: this callback is not fired when the user selects the default range
      })
    fieldRef.current.daterangepicker.element.on(
      'apply.daterangepicker',
      (_event, picker) => {
        const start = picker.startDate
        const end = picker.endDate.endOf('minute')
        const restrictedEnd = restrictedEndTime(
          start, end, fieldRef.current.minRange, fieldRef.current.maxRange)
        containingForm.batch(() => {
          containingForm.change(sourceFrom, start.toISOString())
          containingForm.change(sourceTo, restrictedEnd.toISOString())          
        })
      })
  }, [from.input.value, to.input.value, containingForm,
      sourceFrom, sourceTo, past, future, minRange, maxRange])

  return <input ref={fieldRef} {...rest} />
}

const useStyles = makeStyles(_theme => ({
  textfield: {
    minWidth: '40ex',
  },
}))

const DateRangeInput = ({
  sourceFrom = 'from', sourceTo = 'to',
  label,
  past=true, future=false,
  minRange, maxRange,
  allowEmpty: _ignored, // TODO: should be used?
  ...rest
 }) => {
  const classes = useStyles()
  const containingForm = useForm()
  const from = useField(sourceFrom)
  const to = useField(sourceTo)

  useEffect(() => {
    const newEnd = restrictedEndTime(
      moment(from.input.value), moment(to.input.value), minRange, maxRange)
    if(newEnd.isValid() && !moment(to.input.value).isSame(newEnd)) {
      containingForm.change(sourceTo, newEnd.toISOString())
    } 
  }, [sourceTo, minRange, maxRange, from.input.value, to.input.value, containingForm])

  const clearDates = () => {
    containingForm.batch(() => {
      containingForm.change(sourceFrom, '')
      containingForm.change(sourceTo, '')
    })
  }

  const value = (from.input.value && to.input.value) ?
    `${moment(from.input.value).format(dateTimeFormat)} – ${moment(to.input.value).format(dateTimeFormat)}` :
    ''

  return <TextField
    classes={{ root: classes.textfield }}
    label={label}
    variant='filled'
    margin='dense'
    value={value}
    inputProps={{ sourceFrom, sourceTo, past, future, minRange, maxRange }}
    InputProps={{
      inputComponent: InputComponent,
      endAdornment: <InputAdornment position='end'>
        <IconButton onClick={clearDates} edge='end'><ClearIcon /></IconButton>
      </InputAdornment >,
    }}
    {...rest}
  />
}

export default DateRangeInput
