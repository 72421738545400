/* In-memory cache for things like description of available inventory items.
 * These things don't change often, so there's no need to re-load them every time they are used.
 */

import { CACHE_STORE, CACHE_INIT, SWITCH_GAME } from '../actions'

const defaultState = {}

const cacheReducer = (state = defaultState, { type, payload }) => {
  if (type === SWITCH_GAME) {
    return {}
  }
  if (type === CACHE_STORE) {
    return { ...state, [payload.key]: payload.data }
  }
  if (type === CACHE_INIT && state[payload.key] === undefined) {
    // Promise.then is executed asynchronously. As the payload.fetch may contain Redux
    // operations itself, it's not safe to call it synchronously.
    const deferred = Promise.resolve(null)
    const process = deferred.then(payload.fetch)
    return { ...state, [payload.key]: process }
  }
  return state
}

export default cacheReducer
