import React from 'react'
import { Route } from 'react-router-dom'

import GamesList from './GamesList'
import CalendarView from '../calendar/CalendarView'
import AuditlogListView from '../audit-log/AuditlogListView'
import UserManagementView from '../user-management/UserManagementView'
import GamesListView from '../games/GamesListView'
import GlobalUserManagement from './GlobalUserManagement'
import GlobalGameManagement from './GlobalGameManagement'
import BulkActionsView from '../messaging/BulkActionsView'
import ChatMonitor from '../messaging/ChatMonitor'
import ServerToolsView from '../servertools/ServerToolsView'

const routes = [
  <Route
    path='/select-games'
    component={ GamesList }
    noLayout
  />,
  <Route
    path='/calendar'
    component={ CalendarView }
  />,
  <Route
    path='/auditlog'
    component={ AuditlogListView }
  />,
  <Route
    path='/user-management'
    component={ UserManagementView }
  />,
  <Route
    path='/games'
    component={ GamesListView }
  />,
  <Route
    path='/bulkactions'
    component={BulkActionsView}
  />,
  <Route
    path='/messaging/monitor'
    component={ChatMonitor}
  />,
  <Route
    path='/server-tools'
    component={ServerToolsView}
  />,
  <Route
    path='/global/user-management'
    component={GlobalUserManagement}
    noLayout
  />,
  <Route
    path='/global/game-management'
    component={GlobalGameManagement}
    noLayout
  />,
]

export default routes
