import googleOauth from './googleAuthProvider'
import beaconRsid from './beaconAuthProvider'

import { localStorageGet, localStorageRemove, localStorageSet } from './utils/localStorage'

const authenticators = {
  googleOauth,
  beaconRsid,
}

const userInfoKey = 'user_info'

/** @typedef {{id: number, fullName: string}} UserIdentity  */
/** @typedef {{name: string, permissions: Array.<string>}} UserRole */
/** @typedef {{id: number, fullName: string, email: string, roles: Array.<UserRole>}} UserInfo */

/**
 * @returns {UserInfo}
 */
const getUserInfo = () => {
  const authInfo = localStorageGet(userInfoKey)
  return authInfo ? JSON.parse(authInfo) : null
}

/**
 * @param {UserInfo} userInfo 
 */
const setUserInfo = userInfo => {
  localStorageSet(userInfoKey, JSON.stringify(userInfo))
}

const clearUserInfo = () => {
  localStorageRemove(userInfoKey)
}

export const getJwtToken = () => {
  const user = getUserInfo()
  if(!user || !user.token) return null
  return user.token
}

const authProvider = {
  // authentication
  login: ({ method, ...rest }) => {
    let loginMethod = null
    if (method && authenticators[method]) {
      loginMethod = authenticators[method].login(rest).then(result => ({ ...result, method }))
    } else {
      console.warn(`Unknown auth method "${method}" - let's try all methods!`)
      loginMethod = Promise.any(
        Object.entries(authenticators).map(
          ([method, auth]) => auth.login(rest).then(result => ({ ...result, method }))))
    }
    return loginMethod
      .then(userInfo => {
        setUserInfo(userInfo)
      })
  },

  checkError: (error) => {
    if (error.status === 401 || error.status === 403) {
      return Promise
        .allSettled(Object.values(authenticators).map(auth => auth.logout()))
        .then(() => {
          clearUserInfo()
          return Promise.reject()
        })
    }
    return Promise.resolve()
  },

  checkAuth: () => {
    const user = getUserInfo()
    if (user && user.method) {
      return authenticators[user.method].checkAuth(user)
    }
    return Promise.reject()
  },

  logout: () => Promise
    .allSettled(Object.values(authenticators).map(auth => auth.logout()))
    .then(() => {
      clearUserInfo()
      return null
    }),

  /** @returns {Promise.<UserIdentity>} */
  getIdentity: () => {
    const user = getUserInfo()
    if (!user) return Promise.reject()
    return Promise.resolve(user)
  },

  // authorization
  getPermissions: (_params) => {
    const user = getUserInfo()
    if (!user) {
      return Promise.reject()
    }

    const roles = user.roles.map(r => r.name)
    const permissions = user.roles.flatMap(r => r.permissions)

    return Promise.resolve({
      user,
      roles,
      permissions,
    })
  },
}

export default authProvider
