import React, { useState } from 'react'
import {
  Card,
  CardContent,
  Grid,
  MenuItem,
  TextField as MaterialTextField,
} from '@material-ui/core'
import {
  Datagrid,
  Link,
  ListContextProvider,
  ReferenceField,
  TextField,
  useGetList,
  useGetOne
} from 'react-admin'
import TimestampText from '../components/TimestampText'
import ShowItem from '../components/ShowItem'
import MapLink from '../components/MapLink'
import { useListContextFromArray } from '../utils/listings'
import InventoryItemsField from '../components/InventoryItemsField'
import { describeError } from '../utils/errors'

const SeasonInfo = ({ guild, seasonDescription }) => {
  const seasonFetch = useGetOne('season/guild', `${guild.id}/${seasonDescription.id}`)
  const leaderboard = useGetOne('season/leaderboard/guild', `${guild.id}/${seasonDescription.id}`)

  const season = seasonFetch.data || {}
  const score = season.score || {}
  const rewards = season.rewards || {}
  const leaderboardListContext = useListContextFromArray(
    'season/leaderboard/guild',
    (leaderboard && leaderboard.data) ? leaderboard.data.leaderboard : null)

  if (seasonFetch.error) return <p>{describeError(seasonFetch.error)}</p>

  return <Grid container spacing={4}>
    <Grid container item xs={6}>
      <ShowItem label='Domination points'>
        {score.domination !== undefined ? score.domination : '?'}
      </ShowItem>

      <ShowItem label='Season position'>
        {season.rank !== undefined ? season.rank : '?'} {}
        {season.mapId !== undefined ?
          <Link to={`/maps/${season.mapId}/show/seasons`}>View map leaderboard</Link>
          : null
        }
      </ShowItem>

      <ShowItem label='Season played on map'>
        {season.mapId !== undefined ? <MapLink id={season.mapId} /> : '?'}
      </ShowItem>

      <ShowItem label='Reward level unlocked'>
        {season.rewardLevel !== undefined ? season.rewardLevel : '?'}
      </ShowItem>
    </Grid>

    <Grid container item xs={6}>
      <ShowItem label='Total outposts owned'>
        {score.outposts !== undefined ? score.outposts : '?'}
      </ShowItem>

      <ShowItem label='Total NPC kills'>
        {score.npcKills !== undefined ? score.npcKills : '?'}
      </ShowItem>

      <ShowItem label='Total power'>
        {score.power !== undefined ? score.power : '?'}
      </ShowItem>

      <ShowItem label='Trophies gained'>
        {rewards.trophies !== undefined ? rewards.trophies : '?'}
      </ShowItem>
    </Grid>

    <Grid item xs={12}>
      {(leaderboard && leaderboard.data) &&
        <ListContextProvider value={leaderboardListContext}>
          <Datagrid>
            <TextField label='' source='rank' />
            <ReferenceField label='User' source='player.playerId' reference='accounts' link='show'>
              <TextField source='publicName' />
            </ReferenceField>
            <TextField label='Fame points' source='player.score.fame' />
            <TextField label='NPC kills' source='player.score.npcKills' />
            <TextField label='Reward level' source='player.rewardLevel' />
            <InventoryItemsField label='Rewards' source='player.rewards' />
          </Datagrid>
        </ListContextProvider>
      }
    </Grid>
  </Grid>
}

const GuildSeasonsWidget = ({ guild }) => {
  const seasons = useGetList('season/list', null, null, { guild: (guild ? guild.id : undefined) })
  const [selectedSeason, setSelectedSeason] = useState(
    () => (seasons && seasons.ids && seasons.ids.length > 0) ? seasons.ids[0] : null)
  if (!selectedSeason && seasons && seasons.ids && seasons.ids.length > 0) {
    setSelectedSeason(seasons.ids[seasons.ids.length - 1])
  }

  return <Card>
    <CardContent>
      <MaterialTextField
        select
        label='Pick season'
        value={selectedSeason || ''}
        onChange={event => setSelectedSeason(event.target.value)}
      >
        {Object.entries(seasons.data).map(([key, season]) =>
          <MenuItem key={key} value={key}>
            Season {key} from <TimestampText time={season.startTime} /> {}
            to <TimestampText time={season.endTime} />
          </MenuItem>
        )}
      </MaterialTextField>
    </CardContent>
    <CardContent>
      {selectedSeason && seasons.data[selectedSeason] ?
        <SeasonInfo guild={guild} seasonDescription={seasons.data[selectedSeason]} /> :
        null
      }
    </CardContent>
  </Card>
}

export default GuildSeasonsWidget
