import React, { useState } from 'react'
import {
  useGetOne, ListContextProvider, Datagrid, TextField, NumberField, FunctionField,
} from 'react-admin'
import { Card, CardContent, Button, Typography } from '@material-ui/core'
import EyeIcon from '@material-ui/icons/RemoveRedEye'

import DetailsDrawer from '../components/DetailsDrawer'
import { useListContextFromArray } from '../utils/listings'
import { describeError } from '../utils/errors'

/** @typedef {import('admintool-api/v1/account').Account} Account */
/** @typedef {import('admintool-api/v1/account').DeviceInformation} DeviceInformation */

const Item = ({ label, children }) =>
<div style={{ marginBottom: 20 }}>
  <Typography variant='caption'>{label}</Typography>
  <Typography variant='body2'>{children}</Typography>
</div>

/**
 * @param {{device: DeviceInformation}} props
 */
const DrawerContents = ({ device }) => {
if (!device) {
  return null
}
return <div>
  <Item label='Device'>{device.model || 'Unknown'}</Item>
  <Item label='Locale'>{device.locale || 'Unknown'}</Item>
  <Item label='OS'>{device.os || 'Unknown'} {device.os_version ? '(' + device.os_version + ')' : ''}</Item>
  <Item label='RAM'>{device.ram || '?'} MiB</Item>
  <Item label='CPU'>{device.cpu || 'Unknown'} ({device.coreCount || '?'} cores, {device.cpuSpeed || '?'} MHz)</Item>
  <Item label='GPU'>{device.gpu || 'Unknown'} ({device.vram || '?'} MiB VRAM)</Item>
  <Item label='Graphics API'>{device.graphicsApi || 'Unknown'} ({device.graphicsVersion})</Item>
  <Item label='Game screen size'>{device.renderWidth || '?'}&times;{device.renderHeight || '?'}
    (on {device.screenWidth || '?'}&times;{device.screenHeight || '?'} screen)</Item>
  <Item label='Login count'>{device.loginCount || 'Unknown'}</Item>
</div>
}

/**
 * @param {{player: Account}} props
 */
const PlayerDevicesWidget = ({ player }) => {
  const devices = useGetOne('accounts/devices', player.id)
  const [selectedDevice, setSelectedDevice] = useState(null)

  var context = useListContextFromArray('accounts/devices', devices.data)

  return <>
    <ListContextProvider value={context}>
      <Card>
        <Datagrid
          rowClick={(_id, _basePath, record) => {
            setSelectedDevice(record)
            return false
          }}
        >
          <TextField source='model' />
          <TextField source='os' label='OS' />
          <NumberField source='loginCount' label='Login count' />
          <FunctionField
            label=''
            render={() => (
              <Button color='primary' startIcon={<EyeIcon />}>
                Show
              </Button>
            )}
          />
        </Datagrid>
        {devices.error &&
          <CardContent><p>{describeError(devices.error)}</p></CardContent>
        }
        {(devices.loaded && (!Array.isArray(devices.data) || devices.data.length === 0)) &&
          <CardContent><p>No devices found for this player</p></CardContent>
        }
      </Card>
    </ListContextProvider>
    <DetailsDrawer
      open={!!selectedDevice}
      onClose={() => setSelectedDevice(null)}
    >
      <DrawerContents device={selectedDevice} />
    </DetailsDrawer>
  </>
}

export default PlayerDevicesWidget
