import React, { useCallback, useEffect, useState } from 'react'
import PropTypes from 'prop-types'
import { TablePagination, Toolbar, useMediaQuery } from '@material-ui/core'
import Button from '@material-ui/core/Button'
import ChevronLeft from '@material-ui/icons/ChevronLeft'
import ChevronRight from '@material-ui/icons/ChevronRight'
import { makeStyles, useTheme } from '@material-ui/core/styles'
import {
  useTranslate,
  sanitizeListRestProps,
  ComponentPropType,
  PaginationLimit,
  useListContext,
} from 'react-admin'
import classnames from 'classnames';
import { useAtLoadingFinished } from '../utils/listings'

const useTrackCount = () => {
  const [low, setLow] = useState(0)
  const [high, setHigh] = useState(Infinity)

  const observe = useCallback((total, visibleCount, page, perPage) => {
    if(visibleCount === 0 && page > 1) {
      // Not on the first page, and no results found: must have gone too far
      setHigh(current => Math.min(current, (page - 1) * perPage))
    } else if(isFinite(total) && total >= 0) {
      // The server provided us with a total amount! Let's use that one
      setLow(total)
      setHigh(total)
    } else if(visibleCount > 0) {
      // Received some results
      const totalWithVisible = (page - 1) * perPage + visibleCount
      if(visibleCount < perPage) {
        // Not a full page of results => this looks like the last page
        setLow(totalWithVisible)
        setHigh(totalWithVisible)
      } else {
        // Might not be the last page, so just update the lower bound
        setLow(current => Math.max(current, (page - 1) * perPage + visibleCount))
        // Sanity check: if we can see more results than the current upper bound, reset the bound
        setHigh(current => current >= totalWithVisible ? current : Infinity)
      }
    }
  }, [])

  return [low, high, observe]
}

const useStyles = makeStyles(
  theme => ({
    actions: {
      flexShrink: 0,
      color: theme.palette.text.secondary,
      marginLeft: 20,
    },
    button: {
      minWidth: theme.spacing(4),
    },
    currentPageButton: {},
    hellip: { padding: '1.2em' },
  }),
  { name: 'RaPaginationActions' }
)

function InfinitePaginationActions(props) {
  const { page, rowsPerPage, count, onChangePage, color = 'primary', size = 'small' } = props;
  const classes = useStyles(props)
  const translate = useTranslate()
  const theme = useTheme()
  const numAround = 6

  // Jumping beyond end of a list of unknown length may result in total === 0
  const isItemCountKnown = (isFinite(count) && count >= 0) && !(count === 0 && page > 1)
  const numPages = (isItemCountKnown) ? Math.ceil(count / rowsPerPage) || 1 : NaN

  /* NOTE: here we use material-ui's 0-based `page`, while react-admin uses 1-based. */
  const range = () => {
    if (isNaN(page) || numPages === 1) return []

    const itemsInBlock = Math.max(Math.ceil(numAround / 2), 1)
    const currentPage = page + 1 // User-visible number of current page, 1-based

    const longBackward = currentPage - 10
    const longForward = currentPage === 1 ? 10 : currentPage + 10
    const blocks = [
      Array(itemsInBlock).fill(0).map((_, x) => x + 1),
      longBackward > itemsInBlock + 2 ? [longBackward] : null,
      Array(2 * itemsInBlock + 1).fill(0).map((_, x) => currentPage + x - itemsInBlock),
      !isItemCountKnown || longForward <= numPages - itemsInBlock - 2 ? [longForward] : null,
      isItemCountKnown ? Array(itemsInBlock).fill(0).map((_, x) => numPages - itemsInBlock + x + 1) : null,
    ]

    const input = []
    let lastWritten = 0
    blocks.forEach(block => {
      if (block) {
        if (block[0] === lastWritten + 2) input.push(++lastWritten)
        else if (block[0] > lastWritten + 2) input.push('.')
        block.forEach(p => {
          if (p > lastWritten && (!isItemCountKnown || p <= numPages)) {
            input.push(p)
            lastWritten = p
          }
        })
      }
    })
    if (!isItemCountKnown) input.push('.')

    return input
  };

  const prevPage = event => {
    if (page === 0) throw new Error(translate('ra.navigation.page_out_from_begin'))
    onChangePage(event, page - 1)
  }

  const nextPage = event => {
    if (isItemCountKnown && page >= numPages) throw new Error(translate('ra.navigation.page_out_from_end'))
    onChangePage(event, page + 1)
  }

  const gotoPage = event => {
    const page = parseInt(event.currentTarget.dataset.page, 10)
    if (page < 0 || (isItemCountKnown && page >= numPages)) {
      throw new Error(
        translate('ra.navigation.page_out_of_boundaries', { page: page + 1, })
      )
    }
    onChangePage(event, page)
  }

  const renderPageNums = () => {
    return range().map((pageNum, index) =>
      pageNum === '.' ? (
        <span key={`hyphen_${index}`} className={classes.hellip}>
          &hellip;
        </span>
      ) : (
          <Button
            size={size}
            className={classnames('page-number', classes.button, {
              [classes.currentPageButton]: pageNum === page + 1,
            })}
            color={pageNum === page + 1 ? 'default' : color}
            key={pageNum}
            data-page={pageNum - 1}
            onClick={gotoPage}
          >
            {pageNum}
          </Button>
        )
    )
  }

  if (numPages === 1 && page === 1) {
    return <div className={classes.actions} />
  }

  return (
    <div className={classes.actions}>
      {page > 0 && (
        <Button
          color={color}
          size={size}
          key="prev"
          onClick={prevPage}
          className="previous-page"
        >
          {theme.direction === 'rtl' ? <ChevronRight /> : <ChevronLeft />}
          {translate('ra.navigation.prev')}
        </Button>
      )}
      {renderPageNums()}
      {(!isFinite(numPages) || page !== numPages - 1) && (
        <Button
          color={color}
          size={size}
          key="next"
          onClick={nextPage}
          className="next-page"
        >
          {translate('ra.navigation.next')}
          {theme.direction === 'rtl' ? <ChevronLeft /> : <ChevronRight />}
        </Button>
      )}
    </div>
  )
}

const emptyArray = [];

const InfinitePagination = ({
  addLabel: _ignored, //  let's not pass this to the contained Pagination component
  actions = InfinitePaginationActions,
  limit = <PaginationLimit />,
  ...rest
}) => {
  const {
    loading,
    page,
    perPage,
    rowsPerPageOptions = [5, 10, 25],
    total,
    setPage,
    setPerPage,
  } = useListContext(rest)

  const translate = useTranslate()
  const isSmall = useMediaQuery(theme => theme.breakpoints.down('sm'))

  useEffect(() => {
    if ((page < 1 || isNaN(page)) && setPage) {
      setPage(1)
    }
  }, [page, setPage])

  // Jumping beyond end of a list of unknown length may result in total === 0
  const isItemCountKnown = (isFinite(total) && total >= 0) && !(total === 0 && page > 1)
  const numPages = isItemCountKnown ? Math.max(Math.ceil(total / perPage), 1) : NaN
  const [/* totalLow */, totalHigh, observeTotal] = useTrackCount()
  useAtLoadingFinished(loading, () => observeTotal(total, rest.ids?.length, page, perPage))

  /* NOTE: material-ui's page is 0-based, react-admin's page is 1-based */
  const handlePageChange = useCallback(
    (event, page) => {
      event && event.stopPropagation();
      if (page < 0 || (isItemCountKnown && page >= numPages)) {
        throw new Error(`Tried to change to page ${page + 1} which doesn't exist`)
      }
      setPage(page + 1)
    },
    [isItemCountKnown, numPages, setPage]
  )

  const handlePerPageChange = useCallback(
    event => {
      setPerPage(event.target.value)
    },
    [setPerPage]
  )

  if (total === 0 && page === 1) {
    return loading ? <Toolbar variant="dense" /> : limit
  }

  if (isSmall) {
    return (
      <TablePagination
        count={isFinite(totalHigh) ? totalHigh : -1}
        rowsPerPage={perPage}
        page={page - 1}
        onChangePage={handlePageChange}
        rowsPerPageOptions={emptyArray}
        component="span"
        {...sanitizeListRestProps(rest)}
      />
    );
  }

  return (
    <TablePagination
      count={isFinite(totalHigh) ? totalHigh : -1}
      rowsPerPage={perPage}
      page={page - 1}
      onChangePage={handlePageChange}
      onChangeRowsPerPage={handlePerPageChange}
      ActionsComponent={actions}
      component="span"
      labelRowsPerPage={translate('ra.navigation.page_rows_per_page')}
      rowsPerPageOptions={rowsPerPageOptions}
      {...sanitizeListRestProps(rest)}
    />
  )
}

InfinitePagination.propTypes = {
  actions: ComponentPropType,
  ids: PropTypes.array,
  limit: PropTypes.element,
  loading: PropTypes.bool,
  page: PropTypes.number,
  perPage: PropTypes.number,
  rowsPerPageOptions: PropTypes.arrayOf(PropTypes.number),
  setPage: PropTypes.func,
  setPerPage: PropTypes.func,
  total: PropTypes.number,
}

export default InfinitePagination
